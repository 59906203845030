import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntervalTime from '../IntervalTime';
import { createInterval, stopInterval, getRunningIntervalsByTask, getUserIntervals } from '../Intervals/actions';
import './index.scss';

class BtnTimer extends Component {

	constructor() {
		super();
	}

	componentDidMount() {
	}

	componentDidUpdate(){
    }
    renderStartStopIcon = ()=>{
        return <i
        className={
            'icon trkr-margin-right-5 trkr-icon-' +
            (this.props.taskHasRunningTimer ? 'stop' : 'start')
        }
    />;
    }
    startTimerForTask = async (taskId)=>{
        await this.props.createInterval({_id:taskId});
        this.props.getRunningIntervalsByTask();
    }
    stopTimerForInterval = async (intervalId)=>{
        this.props.stopInterval();
    }
    startOrStopTimer = async (e, taskId) =>{
        e.stopPropagation();
        if (this.props.taskHasRunningTimer && this.props.runningIntervalId){
            await this.stopTimerForInterval(this.props.runningIntervalId);
        }else if (!this.props.taskHasRunningTimer){
            await this.startTimerForTask(taskId);
        }
        this.props.getUserIntervals(0,25,true);
    }
	render() {
        let startLabel = this.props.showStartLabelAs || "Start";
		return <button className='btn trkr-btn trkr-btn-timer' onClick={(event)=>{this.startOrStopTimer(event, this.props.taskId)}}>
            {this.renderStartStopIcon()}
            {this.props.taskHasRunningTimer && <IntervalTime running={this.props.taskHasRunningTimer} taskId={this.props.taskId} ttlElapsedTime={this.props.taskTtlElapsedMilliseconds}/>}
            {!this.props.taskHasRunningTimer && " " + startLabel}
        </button>
    }
}

const mapStateToProps = (state, ownProps) => {
    if (!ownProps.taskId){
        console.error("BtnTimer Please pass a taskId to props");
    }
    let runningIntervalId = ownProps.taskId && state.intervals && state.intervals.runningIntervalsByTask && state.intervals.runningIntervalsByTask[ownProps.taskId] && state.intervals.runningIntervalsByTask[ownProps.taskId]._id;
    return {
        runningIntervalId: runningIntervalId
    }
};

const mapDispatchToProps = {
	createInterval,
    stopInterval,
    getRunningIntervalsByTask,
    getUserIntervals
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnTimer);

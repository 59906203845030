import agent from '../../agent';
import { VERIFY_TOKEN_SUCCESS, VERIFY_TOKEN_ERROR, CREATE_PASSWORD_SUCCESS, CREATE_PASSWORD_ERROR } from './constants';

export const secretGet = async token => {
	try {
		const response = await agent.Auth.verifyToken(token);
		const secret = response.secret;
		return { type: VERIFY_TOKEN_SUCCESS, secret };
	} catch (error) {
		return { type: VERIFY_TOKEN_ERROR, error };
	}
};

export const createPassword = async (secret, password, passwordConfirmation, reCaptcha) => {
	try {
		const response = await agent.Auth.createNewPassword(secret, password, passwordConfirmation, reCaptcha);
		const passwordCreated = response.success;
		return { type: CREATE_PASSWORD_SUCCESS, passwordCreated };
	} catch (error) {
		return { type: CREATE_PASSWORD_ERROR, error };
	}
};

import agent from '../../agent';

import {
	RELEASES_GET_PROJECT_RELEASES_SUCCESS,
	RELEASES_GET_PROJECT_RELEASES_ERROR,
	RELEASES_CREATE_RELEASE_SUCCESS,
	RELEASES_CREATE_RELEASE_ERROR,
	RELEASES_GET_RELEASE_SUCCESS,
	RELEASES_GET_RELEASE_ERROR,
} from './constants';

export const getProjectReleases = async projectId => {
	try {
		const { releases } = await agent.Releases.getByProject(null, projectId);
		let action = {
			type: RELEASES_GET_PROJECT_RELEASES_SUCCESS,
		};
		action[projectId] = {
			releases,
		};
		return action;
	} catch (error) {
		return { type: RELEASES_GET_PROJECT_RELEASES_ERROR, error };
	}
};

export const createRelease = async ReleaseObject => {
	try {
		const { release } = await agent.Releases.create(ReleaseObject);
		return { type: RELEASES_CREATE_RELEASE_SUCCESS, newRelease: release };
	} catch (error) {
		return { type: RELEASES_CREATE_RELEASE_ERROR, error };
	}
};

export const getRelease = async releaseId => {
	try {
		const { release } = await agent.Releases.get(releaseId);
		return { type: RELEASES_GET_RELEASE_SUCCESS, release };
	} catch (error) {
		return { type: RELEASES_GET_RELEASE_ERROR, error };
	}
};

import {
	WORKSPACES_INVITE_USERS_SUCCESS,
	WORKSPACES_INVITE_USERS_ERROR,
	WORKSPACES_INVITE_USERS_RESET,
} from './constants';

const initialState = {
	sentEmails: false,
	errors: [],
};

// This function is called to update the redux store when any action is received.
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case WORKSPACES_INVITE_USERS_SUCCESS:
			return {
				...state,
				sentEmails: action.sentEmails,
			};
		case WORKSPACES_INVITE_USERS_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case WORKSPACES_INVITE_USERS_RESET:
			return {
				...state,
				sentEmails: false,
			};
	}
	return state;
};

export default reducer;

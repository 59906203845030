import React from 'react';

import './priorityTag.scss';

// TODO:
const PriorityTag = ({ label, iconOnly }) => {
	// NOTE: an image path and username must be supplied
	if (!label) {
		return null;
	}

	// strip any white space from the beginning of the label before retrieving the first character
	label = label.replace(/^\s+/, '');

	// get the first character of the label to use in the icon
	let pattern = '^.{1}';
	let reg = new RegExp(pattern, 'gi');
	let iconLetter = reg.exec(label);

	return (
		<label className="trkr-label__priority">
			<span className={`trkr-label__priority-symbol trkr-label--${label.toLowerCase()}`}>{iconLetter}</span>
			<span className="trkr-label__priority-name trkr-label--${label.toLowerCase()}">{label}</span>
		</label>
		// <div className={`priority-tag ${iconOnly ? 'icon-only' : ''}`}>
		// 	<p>
		// 		<span className={`priority-tag-icon priority-value-${label.toLowerCase()}`}></span>
		// 		<span className="priority-tag-label">{label}</span>
		// 	</p>
		// </div>
	);
};

export default PriorityTag;

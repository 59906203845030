import {
	PROJECTS_GET_ALL_PROJECTS_SUCCESS,
	PROJECTS_GET_ALL_PROJECTS_ERROR,
	PROJECTS_ADD_PROJECT_SUCCESS,
	PROJECTS_ADD_PROJECT_ERROR,
	PROJECTS_RESET_ADD_PROJECT,
} from './constants';

const initialState = {
	allProjects: [],
	newProject: {},
	errors: [],
};

// This function is called to update the redux store when any action is received.
export default (state = initialState, action) => {
	switch (action.type) {
		case PROJECTS_GET_ALL_PROJECTS_SUCCESS:
			return {
				...state,
				allProjects: action.allProjects,
			};
		case PROJECTS_GET_ALL_PROJECTS_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case PROJECTS_ADD_PROJECT_SUCCESS:
			return {
				...state,
				newProject: action.newProject,
			};
		case PROJECTS_ADD_PROJECT_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case PROJECTS_RESET_ADD_PROJECT:
			return {
				...state,
				newProject: {},
			};
	}
	return state;
};

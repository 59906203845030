import { SortByWeight } from '../../lib/Utils';

const initialState = {
	allTagColours: [
		{ value: '#FF0050', label: 'Red 1', type: 'tagColour' },
		{ value: '#FF6E76', label: 'Red 2', type: 'tagColour' },
		{ value: '#FEB97C', label: 'Orange 1', type: 'tagColour' },
		{ value: '#FF7C01', label: 'Orange 2', type: 'tagColour' },
		{ value: '#FEF001', label: 'Yellow 1', type: 'tagColour' },
		{ value: '#E0FE91', label: 'Yellow 2', type: 'tagColour' },
		{ value: '#94FF02', label: 'Green 1', type: 'tagColour' },
		{ value: '#BAFF79', label: 'Green 2', type: 'tagColour' },
		{ value: '#04C35B', label: 'Green 3', type: 'tagColour' },
		{ value: '#75FF9E', label: 'Green 4', type: 'tagColour' },
		{ value: '#01D9D8', label: 'Blue 1', type: 'tagColour' },
		{ value: '#AAFFFF', label: 'Blue 2', type: 'tagColour' },
		{ value: '#1E8DE8', label: 'Blue 3', type: 'tagColour' },
		{ value: '#6DB9FF', label: 'Blue 4', type: 'tagColour' },
		{ value: '#A244FF', label: 'Purple 1', type: 'tagColour' },
		{ value: '#BB76FF', label: 'Purple 2', type: 'tagColour' },
		{ value: '#FF00DD', label: 'Pink 1', type: 'tagColour' },
		{ value: '#FF77ED', label: 'Pink 2', type: 'tagColour' },
		{ value: '#000000', label: 'Default', type: 'tagColour' },
	],
};

export default (state = initialState, action) => {
	function CreateReleaseTasks(tasks, releases) {
		// create a release with tasks without a release reference
		function unspecifiedTasks() {
			var estimate = 0;
			var actualTime = 0;

			// console.log('[CreateReleaseTasks] unspecified');

			var releaseTasks = tasks.filter(task => {
				if (!task.release) {
					estimate += task.estimate;
					actualTime += task.actualTime ? task.actualTime : 0;
					return task;
				}
				return false;
			});

			return {
				name: 'Release not specified',
				id: 'na101',
				estimate: estimate || 0,
				actualTime: actualTime || 0,
				tasks: releaseTasks.sort(SortByWeight),
			};
		}

		var data = {
			releases: [unspecifiedTasks()].concat(
				releases.map(release => {
					var estimate = 0;
					var actualTime = 0;

					var releaseTasks = tasks.filter(task => {
						if (task.release && task.release.name === release.name) {
							estimate += task.estimate;
							actualTime += task.actualTime ? task.actualTime : 0;
							return task;
						}

						return false;
					});
					console.log('[CreateReleaseTasks]', actualTime, releaseTasks);
					return {
						name: release.name,
						id: release.id || release._id,
						estimate: estimate || 0,
						actualTime: actualTime || 0,
						tasks: releaseTasks.sort(SortByWeight),
					};
				})
			),
		};

		data.releases = data.releases.filter(release => {
			if (release.id === 'na101' && release.tasks.length === 0) {
				return false;
			} else {
				return true;
			}
		});

		return data.releases;
	}

	// map the new task to the correct release
	function PushReleaseTasks(newTask, currentReleaseTasks) {
		if (!newTask.release) {
			// currentReleaseTasks[0] ? currentReleaseTasks[0].tasks.push(newTask) : false;
			if (currentReleaseTasks[0]) {
				currentReleaseTasks[0].tasks.push(newTask);
			}
		} else {
			for (var i = 0; i < currentReleaseTasks.length; i++) {
				if (newTask.release.name === currentReleaseTasks.name || newTask.release === currentReleaseTasks.id) {
					currentReleaseTasks[i].tasks.push(newTask);
				}
			}
		}

		return currentReleaseTasks;
	}

	switch (action.type) {
		case 'PROJECT_EDITOR_PAGE_LOADED':
			// console.log('[PROJECT_EDITOR_PAGE_LOADED]', action)

			var obj = {
				...state,
				isLoading: false,

				projectSlug: '',
				title: '',
				createdAt: undefined,
				dueDate: undefined,
				description: '',
				tagInput: '',
				tagList: [],
				// releaseTasks: action.payload[4].tasks || [], // TODO: redefine
				allClients: [],
				allReleases: [],
				allTags: [],
			};

			// NOTE: current request call promise structure - 10/01/2019
			// [0]: agent.Projects.getTreeBySlug(this.props.projectSlug)
			// [1]: agent.Clients.all()
			// [2]: agent.Tags.all()
			// [3]: agent.Status.all()
			// [4]: agent.Users.all()
			// [5]: agent.Categories.all()

			// NOTE: previous request call promise
			/*[0]: agent.Projects.getBySlug(this.props.projectSlug)
			[1]: agent.Clients.all()
			[2]: agent.Releases.getByProject(this.props.projectSlug)
			[3]: agent.Tags.all()*/

			if (action.payload) {
				if (action.payload[0]) {
					var project = action.payload[0].project;

					obj.projectTree = project;
					obj.createdAt = project.createdAt;
					obj.projectSlug = project.slug;
					obj.projectId = project.id;
					obj.title = project.title;
					obj.estimate = project.estimate;
					obj.actualTime = project.actualTime;
					obj.description = project.description;
					obj.tagInput = '';
					obj.workspaceCounter = project.workspaceCounter;
					obj.tagList = project.tagList
						? project.tagList.map(tag => {
								return {
									value: tag._id,
									label: tag.name,
									key: 'tag-' + tag._id,
								};
						  })
						: [];

					let mappedColour = state.allTagColours
						? state.allTagColours.filter(colour => colour.value === project.tagColour)
						: undefined;

					obj.tagColour =
						mappedColour && mappedColour.length > 0
							? {
									value: mappedColour[0].value,
									label: mappedColour[0].label,
									type: 'tagColour',
							  }
							: undefined;
					obj.client = project.client ? { value: project.client._id, label: project.client.name } : undefined;
					obj.release = project.release
						? { value: project.release._id, label: project.release.name }
						: undefined;
					obj.dueDate = project.dueDate;
				} else {
					obj.projectTree = project;
					obj.projectSlug = '';
					obj.createdAt = undefined;
					obj.dueDate = undefined;
					obj.title = '';
					obj.estimate = '';
					obj.actualTime = '';
					obj.description = '';
					obj.tagInput = '';
					obj.tagList = [];
					obj.client = '';
					obj.release = undefined;
					obj.releaseTasks = [];
				}

				if (action.payload[1]) {
					obj.allClients = action.payload[1].clients.map(client => {
						return { value: client.id, label: client.name };
					});
				} else {
					obj.allClients = [];
				}

				if (action.payload[2]) {
					obj.allReleases = action.payload[2].releases.map(release => {
						return { value: release.id, label: release.name };
					});
				} else {
					obj.allReleases = [];
				}

				if (action.payload[3]) {
					obj.allTags = action.payload[3].tags.map(tag => {
						return { value: tag.id, label: tag.name };
					});
				} else {
					obj.allTags = [];
				}
			}

			console.log('[PROJECT_EDITOR_PAGE_LOADED]', obj);

			return obj;

		case 'PROJECT_EDITOR_PAGE_UNLOADED':
			return {};

		case 'PROJECT_SUBMITTED':
			console.log('[PROJECT_SUBMITTED]', action);
			return {
				...state,
				inProgress: null,
				errors: action.error ? action.payload.errors : null,
				// only redirect if a new project?
				// redirectTo: !action.error ? '/project/' + action.payload.project.slug : null,
				redirectTo: !action.error ? '/' : null,
			};

		case 'PROJECT_UPDATE_FIELD':
			console.log('projectEditor.UPDATE_FIELD_EDITOR', action, state);
			var obj = {
				...state,
			};

			obj[action.key] = action.value;

			// if project change, then release will be replaced with a matching one from the new project
			if (action.payload && action.key === 'project') {
				// obj.allReleases = action.payload.releases;

				obj.allReleases = action.payload.releases.map(release => {
					return { value: release.id, label: release.name };
				});

				// console.log('UPDATE_FIELD_EDITOR project changed', action, state);
				if (state.release) {
					var foundExistingReleases = obj.allReleases.filter(release => {
						return release.label === state.release.label;
					});
					var foundExistingRelease = foundExistingReleases[0];

					if (foundExistingRelease) {
						obj.release = foundExistingRelease;
					} else {
						obj.release = [];
					}

					// console.log('UPDATE_FIELD_EDITOR filtered releases', foundExistingReleases);
				}
			}

			return obj;

		case 'REDIRECT':
			return { ...state, redirectTo: null };

		case 'ADD_CLIENT':
			const newClient = {
				value: action.payload.client.id,
				label: action.payload.client.name,
			};

			var obj = {
				...state,
				inProgress: null,
				errors: action.error ? action.payload.errors : null,
				allClients: state.allClients.concat([newClient]),
				client: newClient,
			};
			console.log('[ADD_CLIENT]', state, obj);

			return obj;

		case 'PROJECT_NEW_TASK':
			const newTaskInfo = {
				title: '',
				description: '',
				estimate: undefined,
				tagList: [],
				status: { value: 'backlog', label: 'backlog' },
				category: { value: 'task', label: 'task' },
				release: state.release ? { value: state.release.id, label: state.release.name } : null,
			};

			console.log('[project.reducer]', state);

			return {
				...state,
				newTaskInfo: newTaskInfo,
			};

		case 'PROJECT_CANCEL_NEW_TASK':
			return {
				...state,
				newTaskInfo: undefined,
			};

		case 'PROJECT_NEW_TASK_UPDATE_FIELD':
			console.log('[PROJECT_NEW_TASK_UPDATE_FIELD]', action);

			return {
				...state,
				newTaskInfo: {
					...state.newTaskInfo,
					[action.key]: action.value,
				},
			};

		case 'PROJECT_SUBMIT_NEW_TASK':
			// TODO:
			// 1. push new task to the current task list
			// 2. update project estimates
			console.log('[PROJECT_SUBMIT_NEW_TASK]', state, action.payload);

			var tasks = state.releaseTasks;

			if (!tasks || tasks.length === 0) {
				tasks = CreateReleaseTasks([action.payload.task], state.allReleases);
			} else {
				tasks = PushReleaseTasks(action.payload.task, tasks);
			}

			return {
				...state,
				errors: action.error ? action.payload.errors : null,
				newTaskInfo: undefined,
				releaseTasks: tasks,
				taskCount: Number(state.taskCount || 0) + 1,
			};

		case 'PROJECT_NEW_TASK_ADD_STATUS':
			var newStatus = {
				value: action.payload.status.name,
				label: action.payload.status.name,
			};

			var obj = {
				...state,
				inProgress: null,
				errors: action.error ? action.payload.errors : null,
				statusList: state.statusList.concat([newStatus]),
				newTaskInfo: {
					...state.newTaskInfo,
					status: newStatus,
				},
			};
			console.log('[PROJECT_ADD_STATUS]', state, obj);

			return obj;

		case 'PROJECT_NEW_TASK_ADD_TAG':
			var newTag = {
				value: action.payload.tag.id,
				label: action.payload.tag.name,
			};

			var tagList = state.newTaskInfo.tagList;

			tagList = tagList || [];

			return {
				...state,
				newTaskInfo: {
					...state.newTaskInfo,
					tagList: tagList.concat([newTag]),
					tagInput: '',
				},
			};

		case 'PROJECT_NEW_TASK_REMOVE_TAG':
			var tagList = state.newTaskInfo.tagList || [];

			return {
				...state,
				newTaskInfo: {
					...state.newTaskInfo,
					tagList: tagList.filter(tag => tag !== action.tag),
					tagInput: '',
				},
			};
	}

	return state;
};

import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { Menu, Item, MenuProvider, contextMenu, theme, animation } from 'react-contexify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tippy';

import 'react-contexify/dist/ReactContexify.min.css';
import 'react-tippy/dist/tippy.css';
import './index.scss';

import ProgressBar from '../ProgressBar';
import Avatar from '../Icons/Avatar';
import ProjectTag from '../Icons/ProjectTag';
import PriorityTag from '../Icons/PriorityTag';
import  IntervalTime from '../IntervalTime';
import BtnTimer from '../BtnTimer';

import { formatDuration, numberPad, tick, formatDuration20 } from '../../lib/Utils';

import { onShowModalExistingTask, onHideModal } from '../App/actions';
// timer specific actions
import { createInterval, stopInterval } from '../Intervals/actions';

import { updateTask, getTask } from '../Tasks/actions';

import history from '../../lib/History';

class TaskCard extends Component {
	state = {
		currentRunningTime: 0,
	};
	componentDidMount() {
		this.intervalID = false;
		const task = this.props.tasksById[this.props.taskId];
	}
	componentDidUpdate(prevProps, prevState) {
		const task = this.props.tasksById[this.props.taskId];
	}
	componentWillUnmount() {
	}
	onCardClick = event => {
		// This is a hack to get around bug in react-contexify dependency.
		// contextMenu in ProgressBar component cannot prevent onClick event bubbling upwards to the function.
		const contextMenuClasses = ['react-contexify__item__content', 'react-contexify', 'glyphicon-flag'];
		const classList = [...event.target.classList];
		for (const className of classList) {
			if (contextMenuClasses.includes(className)) {
				return;
			}
		}
		const task = this.props.tasksById[this.props.taskId];
		this.props.onShowModalExistingTask({ _id: task._id });
	};
	// referenced in button template (clearer to have logic here)
	actionBtnClick = (ev) => {
		ev.stopPropagation();
		console.log('actionBtnClick', this.props);
		const task = this.props.tasksById[this.props.taskId];
		if (task.hasRunningTimer) {
			this.props.stopInterval();
		} else {
			this.props.createInterval({
				_id: task.id || task._id,
			});
		}
	};
	isTaskFlagged = (task) => {
		return (
			task?.flaggedBy && task?.flaggedBy.filter((userId) => userId === this.props?.currentUser?._id).length > 0
		);
	};
	updateTaskPropertyFlagged = async (boolean, task) => {
		await this.props.updateTask({ ...task, flagged: boolean });
		setTimeout(() => {
			this.props.getTask(task._id);
		}, 500);
		contextMenu.hideAll();
	};
	showContextMenu = (event, task) => {
		event.stopPropagation();
		event.preventDefault();
		contextMenu.show({
			id: `menu_id-${task._id}-${this.props.viewId}`,
			event: event,
		});
	};
	renderContextMenu = (task) => {
		return (
			<Menu
				id={`menu_id-${task._id}-${this.props.viewId}`}
				theme={theme.dark}
				animation={animation.zoom}
				style={{ zIndex: '10000' }}>
				<Item onClick={() => this.updateTaskPropertyFlagged(this.isTaskFlagged(task) ? false : true, task)}>
					<span className="glyphicon glyphicon-flag trkr-margin-right-10" />
					{this.isTaskFlagged(task) ? 'Unflag' : 'Flag'}
				</Item>
				<Item disabled>
					<span className="glyphicon glyphicon-trash trkr-margin-right-10" />
					Delete
				</Item>
			</Menu>
		);
	};
	render() {
		let task = this.props.tasksById[this.props.taskId];
		const project = task?.project;
		// NOTE: returns an empty state, if no task information is available
		// TODO: use a default state to change props as needed to avoid having to duplicate the template
		if (!task || (!task.id && !task._id)) {
			return null;
		}
		return (
			<div
				className={`task-card ${this.props.style}`}
				onClick={(event) => this.onCardClick(event)}
				style={{ borderColor: project ? project.tagColour : '#000000' }}>
				<div className="row">
					<div className="col-xs-12">
						<ul className="horizontal-list trkr-full-width trkr-padding-top-10 task-card-priority-meta">
							{this.isTaskFlagged(task) ? (
								<li
									className="task-card-flag"
									onClick={() => this.updateTaskPropertyFlagged(false, task)}>
									<Tooltip position="left" trigger="mouseenter" offset="40" html={<h5>Unflag</h5>}>
										<span className="glyphicon glyphicon-flag" />
									</Tooltip>
								</li>
							) : null}
							<li>
								{/* <p className="font-color-white-2">#{task.workspaceCounter}</p> */}
								<h4>
									{/* <span class="glyphicon glyphicon-flag trkr-margin-right-5"></span> */}#
									{task.workspaceCounter}
								</h4>
							</li>

							{task.priority && task.priority.name && (
								<li>
									<PriorityTag label={task.priority.name} />
								</li>
							)}
							<li className="avatar">
								{task?.assignedTo?._id && (
									<Avatar
										className="user-avatar-small inline font-color-white-2 font-light"
										user={
											this.props?.currentUser?._id &&
											this.props.currentUser._id === task.assignedTo._id
												? this.props.currentUser
												: task.assignedTo
										}
									/>
								)}
							</li>
						</ul>
					</div>
				</div>

				{/* NOTE: task title meta */}
				<div className="row trkr-margin-top-10 task-card-title-meta">
					<div className="col-xs-12">
						<p>
							{!task.managementEstimate && '[!] '}
							{task.title}
						</p>
					</div>
				</div>

				<div className="row task-card-project-meta vertical-align-middle">
					{/* NOTE: project meta */}
					{project && (
						<div className={`${this.props.style === 'no-progress-bar' ? 'col-xs-6' : 'col-xs-12'}`}>
							<ProjectTag projectTitle={project.title} projectColour={project.tagColour} />
						</div>
					)}

					{this.props.style === 'no-progress-bar' && (
						<div className="col-xs-6 text-right">
							<button
								className={
									'btn trkr-btn trkr-btn-timer ' +
									(task.hasRunningTimer ? ' bg-green-active' : '') +
									(task.elapsedTime && task.elapsedTime > 0 ? ' bg-green-inactive' : '')
								}
								onClick={this.actionBtnClick}>
								<i
									className={
										'icon trkr-margin-right-5 trkr-icon-' +
										(task.hasRunningTimer ? 'stop' : 'start')
									}
								/>
								{task.hasRunningTimer
									? formatDuration(this.state ? this.state.currentRunningTime : 0)
									: formatDuration(task.elapsedTime, 'hhmmss')}
							</button>
						</div>
					)}
				</div>

				{/* NOTE:
					progress bar and timer action not needed for milestones
					TODO: current running interval to show on stop button
				*/}
				{task.category !== 'milestone' && this.props.showTimer && (
					<div className="row trkr-align-middle task-card-time-meta">
						{task?.assignedTo?._id &&
							this.props?.currentUser?._id &&
							task.assignedTo._id === this.props.currentUser._id && (
								<div className="col-xs-12">
									{
										<div>
											<BtnTimer taskId={task._id} taskHasRunningTimer={task.hasRunningTimer} />
										</div>
									}
								</div>
							)}
						<div className={'col-xs-11'}>
							<ProgressBar
								estimate={task.estimate}
								elapsedTime={task.elapsedTime}
								runningTimer={task.hasRunningTimer}
								interval={task.intervals && task.intervals.length > 0 ? task.intervals[0] : {}}
								serverOffset={this.props.serverOffset}
								currentRunningTime={this.state.currentRunningTime}
								
								running={task.hasRunningTimer}
								ttlElapsedMilliseconds={task.ttlElapsedMilliseconds}
								taskId={task._id}
							/>
						</div>
						{task && (
							<Fragment>
								<div
									className="task-card-context-menu"
									onClick={(e) => this.showContextMenu(e, task)}
									onContextMenu={(e) => this.showContextMenu(e, task)}>
									<FontAwesomeIcon icon={faEllipsisV} />
								</div>
								{this.renderContextMenu(task)}
							</Fragment>
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	currentUser: state.users.currentUser,
	serverOffset: state.intervals.serverOffset,
	tasksById: state.tasks.tasksById
});

const mapDispatchToProps = {
	createInterval,
	stopInterval,
	onShowModalExistingTask,
	updateTask,
	getTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskCard);

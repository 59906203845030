import agent from '../../agent';
import moment from 'moment';

import {
	INTERVALS_GET_USERS_INTERVALS_SUCCESS,
	INTERVALS_GET_USERS_INTERVALS_ERROR,
	INTERVALS_GET_USER_INTERVALS_DURATION_SUCCESS,
	INTERVALS_GET_USER_INTERVALS_DURATION_ERROR,
	INTERVALS_CREATE_INTERVAL_SUCCESS,
	INTERVALS_CREATE_INTERVAL_ERROR,
	INTERVALS_UPDATE_INTERVAL_SUCCESS,
	INTERVALS_UPDATE_INTERVAL_ERROR,
	INTERVALS_GET_SERVER_OFFSET_SUCCESS,
	INTERVALS_GET_SERVER_OFFSET_ERROR,
	INTERVALS_GET_RUNNING_BY_TASK,
	INTERVALS_TICK_RUNNING_INTERVAL
} from './constants';
import { SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_SUCCESS } from '../Subscriptions/constants';

export const getUserIntervals = async (skip, limit, empty) => {
	try {
		const { nextSkip, currentSkip, intervals } = await agent.Intervals.getUserIntervals(skip, limit);
		return {
			type: INTERVALS_GET_USERS_INTERVALS_SUCCESS,
			intervals,
			nextSkip,
			currentSkip,
			empty,
		};
	} catch (error) {
		return { type: INTERVALS_GET_USERS_INTERVALS_ERROR, error };
	}
};

export const getTotalDurationForUser = async () => {
	try {
		const { totalDuration: intervalsDuration } = await agent.Intervals.getTotalDurationForUser();
		return {
			type: INTERVALS_GET_USER_INTERVALS_DURATION_SUCCESS,
			intervalsDuration,
		};
	} catch (error) {
		return { type: INTERVALS_GET_USER_INTERVALS_DURATION_ERROR, error };
	}
};

export const createInterval = async task => {
	try {
		await agent.Intervals.create(task);
		return {
			type: INTERVALS_CREATE_INTERVAL_SUCCESS,
		};
	} catch (error) {
		return { type: INTERVALS_CREATE_INTERVAL_ERROR, error };
	}
};

export const stopInterval = async () => {
	try {
		await agent.Intervals.stopAll();
		return {
			type: INTERVALS_UPDATE_INTERVAL_SUCCESS,
		};
	} catch (error) {
		return { type: INTERVALS_UPDATE_INTERVAL_ERROR, error };
	}
};

export const getServerOffset = async () => {
	try {
		const { serverDate } = await agent.Intervals.getServerDate();
		const serverOffset = moment(serverDate).diff(new Date());
		console.log('serverOffset: ', serverOffset);
		return {
			type: INTERVALS_GET_SERVER_OFFSET_SUCCESS,
			serverOffset,
		};
	} catch (error) {
		return { type: INTERVALS_GET_SERVER_OFFSET_ERROR, error };
	}
};

export const getRunningIntervalsByTask = async() => {
	try {
		const { runningIntervalsByTask } = await agent.Intervals.getRunningIntervalsByTask();
		console.log('runningIntervalsByTask: ', runningIntervalsByTask);
		return {
			type: INTERVALS_GET_RUNNING_BY_TASK,
			success: true,
			runningIntervalsByTask
		};
	} catch (error) {
		return { type: INTERVALS_GET_RUNNING_BY_TASK, success: false, error };
	}
}

export const tickRunningIntervals = () => {
	return {
		type: INTERVALS_TICK_RUNNING_INTERVAL,
		success: true
	}
}

// Makes sure that action and reducers use the same strings.
import {
	SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_SUCCESS,
	SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_ERROR,
	SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_SUCCESS,
	SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_ERROR,
	SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_RESET,
	SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_SUCCESS,
	SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_ERROR,
	SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_RESET,
	SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_SUCCESS,
	SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_ERROR,
	SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_SUCCESS,
	SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_ERROR,
} from './constants';

// The data looks like below before any action is received.
const initialState = {
	subscriptions: [],
	invoices: [],
	subscriptionCancelled: false,
	completed: false,
	updatedSubscription: false,
	errors: [],
};

// This function is called to update the redux store when any action is received.
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_SUCCESS:
			return {
				...state,
				subscriptions: action.subscriptions,
			};
		case SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				invoices: action.invoices,
			};
		case SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_RESET:
			return {
				...state,
				invoices: [],
			};
		case SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				subscriptionCancelled: action.subscriptionCancelled,
			};
		case SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_RESET:
			return {
				...state,
				subscriptionCancelled: false,
			};
		case SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_SUCCESS:
			return {
				...state,
				completed: action.completed,
			};
		case SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				updatedSubscription: action.success,
			};
		case SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
	}
	return state;
};

export default reducer;

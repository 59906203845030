import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { promiseMiddleware } from './middleware';

import app from './components/App/reducer';
import { loadingBarReducer } from 'react-redux-loading-bar';

// TODO: clean up required, a lot of these reducers should be redundant
import intervalEditor from './components/IntervalModal/reducer';
import projectEditor from './components/ProjectModal/reducer';
import releaseEditor from './components/ReleaseModal/reducer';
import intervals from './components/Intervals/reducer';
import resetPassword from './components/ResetPassword/reducer';
import views from './components/Views/reducer';
import tasks from './components/Tasks/reducer';
import projects from './components/Projects/reducer';
import stories from './components/Stories/reducer';
import users from './components/Users/reducer';
import subscriptions from './components/Subscriptions/reducer';
import workspaceUsers from './components/WorkspaceUsers/reducer';
import workspaces from './components/Workspaces/reducer';
import activities from './components/Activities/reducer';
import messages from './components/Messages/reducer';
import scheduleSlots from './components/ScheduleSlots/reducer';
import releases from './components/Releases/reducer';
import priorities from './components/Priorities/reducer';
import gantt from './components/Views/components/View/components/ExpGantt/reducer';

import workspaceRegistrationPage from './pages/WorkspaceRegistrationPage/reducer';
import teamInvitePage from './pages/TeamInvitePage/reducer';
import projectCreationPage from './pages/ProjectCreationPage/reducer';
import passwordFormPage from './pages/PasswordFormPage/reducer';

const reducer = combineReducers({
	app,
	loadingBar: loadingBarReducer,

	intervalEditor,
	projectEditor,
	releaseEditor,
	intervals,
	tasks,
	resetPassword,
	projects,
	stories,
	users,
	scheduleSlots,
	subscriptions,
	workspaceUsers,
	workspaces,
	activities,
	messages,
	releases,
	priorities,
	workspaceRegistrationPage,
	teamInvitePage,
	projectCreationPage,
	passwordFormPage,
	views,
	gantt
});

const composeSetup =
	process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
		: compose;

const middleware = applyMiddleware(promiseMiddleware);

const store = createStore(reducer, composeSetup(middleware));

export default store;

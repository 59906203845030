import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import Profile from '../Profile';
import Members from '../Members';
import Billing from '../Billing';

import { getActiveSubscriptionsByUser } from '../Subscriptions/actions';

import { getWorkspaceUsersForUser } from '../WorkspaceUsers/actions';

import './index.scss';

class SettingsModal extends Component {
	state = {
		showProfile: true,
		showMembers: true,
		selectedSubscription: {},
	};
	componentDidMount() {
		if (this.props.currentUser) {
			this.props.getWorkspaceUsersForUser(this.props.currentUser._id);
		}
		if (this.props.currentUser && this.props.subscriptions.length > 0) {
			this.setState({
				selectedSubscription: this.props.subscriptions[0],
			});
		} else {
			this.props.getActiveSubscriptionsByUser(this.props.currentUser._id);
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.subscriptions.length !== prevProps.subscriptions.length) {
			this.setState({
				selectedSubscription: this.props.subscriptions[0],
			});
		}
	}
	showProfile = showProfile => {
		this.setState({ showProfile });
	};
	changeSubscription = selectedSubscription => {
		this.setState({ selectedSubscription: selectedSubscription.value });
	};
	showMembers = showMembers => {
		this.setState({ showMembers });
	};
	render() {
		return (
			<div className="flex-container row modal-content settings">
				<div className="row vertical-align-middle">
					<div className="col-xs-11">
						<h5 className={'settings-title text-center'}>Settings</h5>
					</div>
					<div className="col-xs-1">
						<button
							className="trkr-icon-close settings-button-close pull-right"
							onClick={this.props.onClose}
						/>
					</div>
				</div>
				<div className="flex-body">
					<div className="settings-side-tabs">
						<div
							className={`settings-side-tabs-title ${
								this.state.showProfile ? 'settings-side-tabs-title-selected' : ''
							}`}
							onClick={() => this.showProfile(true)}>
							My profile
						</div>
						{this.props.subscriptions &&
							this.props.workspaceUsersForUser &&
							this.props.subscriptions.length > 0 &&
							this.props.workspaceUsersForUser.filter(workspaceUser => workspaceUser.role === 'owner')
								.length > 0 && (
								<div
									className={`settings-side-tabs-title ${
										!this.state.showProfile ? 'settings-side-tabs-title-selected' : ''
									}`}
									onClick={() => this.showProfile(false)}>
									Workspaces
								</div>
							)}
					</div>
					{this.state.showProfile ? (
						<div className="scrollable-content settings-panel">
							<Profile />
						</div>
					) : (
						<div className="scrollable-content settings-panel ">
							<div className="workspaces">
								<div className="workspaces-side-tabs">
									<div>
										<Select
											onChange={this.changeSubscription}
											value={{
												label: this.state.selectedSubscription.workspace.slug,
												value: this.state.selectedSubscription,
											}}
											options={this.props.subscriptions.map(subscription => ({
												label: subscription.workspace.slug,
												value: subscription,
											}))}
										/>
									</div>
									<div
										className={`workspaces-side-tabs-title ${
											this.state.showMembers ? 'workspaces-side-tabs-title-selected' : ''
										}`}
										onClick={() => this.showMembers(true)}>
										Members
									</div>
									<div
										className={`workspaces-side-tabs-title ${
											!this.state.showMembers ? 'workspaces-side-tabs-title-selected' : ''
										}`}
										onClick={() => this.showMembers(false)}>
										Billing
									</div>
								</div>
								<div className="workspaces-panel">
									{this.state.showMembers ? (
										<Members
											subscriptions={this.props.subscriptions}
											selectedSubscription={this.state.selectedSubscription}
										/>
									) : (
										<Billing selectedSubscription={this.state.selectedSubscription} />
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	subscriptions: state.subscriptions.subscriptions,
	workspaceUsersForUser: state.workspaceUsers.workspaceUsersForUser,
	currentUser: state.users.currentUser,
});

export default connect(
	mapStateToProps,
	{
		getActiveSubscriptionsByUser,
		getWorkspaceUsersForUser,
	}
)(SettingsModal);

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';

import App from './components/App';
// test message
// import 'bootstrap/dist/css/bootstrap.css'; // add Boostrapp CSS into the DOM.

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// export default AuthExample;

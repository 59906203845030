import React, { memo } from 'react';
import Avatar from '../Icons/Avatar';
import { formatDate, formatDuration } from '../../lib/Utils';
import Diff from 'react-stylable-diff';
import { Value } from 'slate';
import Plain from 'slate-plain-serializer';

function tryParse(str) {
	try {
		return { value: JSON.parse(str), isValid: str !== null };
	} catch (e) {
		return { value: str, isValid: false };
	}
}

// TODO:
// X. format estimate duration
// 2. detect tagList changes
// 3. no specific "task-deleted" activity yet, currently utilising the "status-change"

const ActivityEntry = props => {
	// console.log('[ActivityEntry.render]', props);
	const _renderActionTitle = val => {
		switch (val) {
			case 'task-created':
				return 'Task created';
			case 'story-created':
				return 'Story created';
			case 'task-deleted':
				return 'Task deleted';
			case 'task-title-change':
			case 'story-title-change':
				return 'Title updated';
			case 'task-status-change':
				return 'Status updated';
			case 'task-description-change':
			case 'story-description-change':
				return 'Description updated';
			case 'task-flag-notes-change':
				return 'Flag Notes updated';
			case 'task-estimate-change':
				return 'Estimate updated';
			case 'task-release-change':
			case 'story-release-change':
				return 'Release updated';
			case 'task-category-change':
				return 'Category updated';
			case 'task-assigned-change':
				return 'Assigned updated';
			case 'task-project-change':
			case 'story-project-change':
				return 'Project changed';
			case 'task-priority-change':
			case 'story-priority-change':
				return 'Priority changed';
			case 'task-story-change':
				return 'Story changed';
			case 'task-interval-added':
				return 'Time logged';
			case 'task-interval-started':
				return 'Timer started';
			default:
				return 'N/A';
		}
	};

	if (!props.author) {
		return null;
	}

	if (props.action === 'task-created' || props.action === 'story-created') {
		return (
			<div className="activity-entry row trkr-align-middle">
				<div className="col-xs-2 trkr-align-top">
					<Avatar user={props.author} className="small trkr-full-width" />
				</div>

				<div className="col-xs-10 trkr-padding-left-0">
					<p className="text-small time-stamp">{formatDate(props.createdAt, 'LT').toLowerCase()}</p>
					<div
						className={
							'message-bubble' + (props.author.id === props.currentUser._id ? ' own-message' : '')
						}>
						<h6>{_renderActionTitle(props.action)}</h6>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="activity-entry row trkr-align-middle">
			<div className="col-xs-2 trkr-align-top">
				<Avatar user={props.author} className="small trkr-full-width" />
			</div>

			<div className="col-xs-10 trkr-padding-left-0">
				<p className="text-small time-stamp">{formatDate(props.createdAt, 'LT').toLowerCase()}</p>

				<div className={'message-bubble'}>
					<div className="content">
						{(() => {
							switch (props.action) {
								case 'task-interval-added':
									return (
										<div>
											<h6 className="trkr-margin-bot-5">{_renderActionTitle(props.action)}</h6>

											<p>
												<span className="value value-after">
													{formatDuration(props.afterValue)}
												</span>
											</p>
										</div>
									);

								case 'task-estimate-change':
									return (
										<div>
											<h6 className="trkr-margin-bot-5">{_renderActionTitle(props.action)}</h6>

											<p>
												<span className="value value-before">
													{props.beforeValue ? formatDuration(props.beforeValue) : 'X'}
												</span>
												<span className="value value-after">
													{formatDuration(props.afterValue)}
												</span>
											</p>
										</div>
									);
								case 'task-description-change':
								case 'story-description-change':
								case 'task-flag-notes-change':
									return (
										<div>
											<h6 className="trkr-margin-bot-5">{_renderActionTitle(props.action)}</h6>
											<Diff
												inputA={
													tryParse(props.beforeValue).isValid
														? Plain.serialize(Value.fromJSON(JSON.parse(props.beforeValue)))
														: props.beforeValue
												}
												inputB={
													tryParse(props.afterValue).isValid
														? Plain.serialize(Value.fromJSON(JSON.parse(props.afterValue)))
														: props.afterValue
												}
												type="chars"
											/>
										</div>
									);

								default:
									return (
										<div>
											<h6
												className={
													'' +
													(props.beforeValue || props.afterValue ? 'trkr-margin-bot-5' : '')
												}>
												{_renderActionTitle(props.action)}
											</h6>

											{(props.beforeValue || props.afterValue) && (
												<p>
													<span className="value value-before">
														{props.beforeValue || 'X'}
													</span>
													<span className="value value-after">{props.afterValue}</span>
												</p>
											)}
										</div>
									);
							}
						})()}
					</div>
				</div>
			</div>
		</div>
	);
};

const areEqual = (prevProps, nextProps) => {
	if (prevProps._id === nextProps._id) {
		// the component will not re-render
		return true;
	} else {
		// the component will re-render
		return false;
	}
};

export default memo(ActivityEntry, areEqual);

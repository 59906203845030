import React from 'react';
import history from '../../../../../../../lib/History';

import ActivityLinks from './ActivityLinks';

const MessageEntry = props => {
	const { action, afterValue, author, beforeValue, task, story } = props;

	// colouring slack like mentions @
	const contentSubStringsRegex = /(\[[^\]]*])/g;
	const contentSubStrings = props.content.split(contentSubStringsRegex).map(str => str.replace(/[[\]']+/g, ''));
	for (var i = 1; i < contentSubStrings.length; i += 2) {
		contentSubStrings[i] = (
			<span className="mention" key={i}>
				{contentSubStrings[i]}
			</span>
		);
	}

	return (
		<div className="row">
			<div className="col-xs-12">
				<p className="trkr-margin-0 content">
					{props.author.fullName} said on {ActivityLinks(props)}
					<br />
					<span className="font-color-white">"{contentSubStrings}"</span>
				</p>
			</div>
		</div>
	);
};

export default MessageEntry;

// Makes sure that action and reducers use the same strings.
import agent from '../../agent';
import {
	GET_SUBSCRIPTION_SUCCESS,
	GET_SUBSCRIPTION_ERROR,
	UPDATE_WORKSPACE_SUCCESS,
	UPDATE_WORKSPACE_ERROR,
} from './constants';

export const getSubscription = async subscriptionId => {
	try {
		const response = await agent.Subscriptions.get(subscriptionId);
		const subscription = response.subscription;

		console.log(`getSubscription ${subscriptionId}`, subscription);

		return { type: GET_SUBSCRIPTION_SUCCESS, subscription };
	} catch (error) {
		return { type: GET_SUBSCRIPTION_ERROR, error };
	}
};

export const updateWorkspace = async (workspaceId, updateObject) => {
	try {
		const response = await agent.Workspaces.update(workspaceId, updateObject);
		const workspace = response.workspace;
		return { type: UPDATE_WORKSPACE_SUCCESS, workspace };
	} catch (error) {
		return { type: UPDATE_WORKSPACE_ERROR, error };
	}
};

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import parseDomain from 'parse-domain';

import { dateDiff2, formatDuration, tick } from '../../lib/Utils';

import Avatar from '../Icons/Avatar';
import ProjectTag from '../Icons/ProjectTag';
import history from '../../lib/History';

// timer specific actions
import { createInterval, stopInterval } from '../Intervals/actions';
// app actions
import {
	onShowTimerMenu,
	onShowModalNewTask,
	onShowModalExistingTask,
	onShowModalNewStory,
	onShowModalNewRelease,
} from '../App/actions';
import { logoutUser } from '../Users/actions';
import { getWorkspaceUsersForUser } from '../WorkspaceUsers/actions';

import Logo from '../../assets/images/logo-bw.png';

import './index.scss';

const BASE_URL = process.env.REACT_APP_TRKR_CLIENT_BASE_URL;
console.log('process.env', process.env);
if (!BASE_URL) {
	throw new Error('You must set REACT_APP_TRKR_CLIENT_BASE_URL');
} else {
	console.log('BASE_URL', BASE_URL);
}

class Header extends React.Component {
	state = { currentRunningTime: 0 };
	constructor() {
		super();
		this.intervalID = false;
	}
	componentDidMount() {
		if (!this.props?.intervals[0]?.endDateTime) {
			if (!this.intervalID) {
				this.intervalID = setInterval(() => {
					const currentRunningTime = tick(this.props?.intervals[0], this.props.serverOffset);
					// ensure this is active to prevent mount errors
					this.intervalID &&
						this.setState({
							currentRunningTime,
						});
				}, 1000);
			}
		}
		if (this.props.currentUser && this.props.currentUser._id) {
			this.props.getWorkspaceUsersForUser(this.props.currentUser._id);
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (!this.props?.intervals[0]?.endDateTime) {
			clearInterval(this.intervalID);
			this.intervalID = false;
			if (!this.intervalID) {
				this.intervalID = setInterval(() => {
					const currentRunningTime = tick(this.props?.intervals[0], this.props.serverOffset);
					// ensure this is active to prevent mount errors
					this.intervalID &&
						this.setState({
							currentRunningTime,
						});
				}, 1000);
			}
		}
		if (this.intervalID && this.props?.intervals[0]?.endDateTime) {
			if (this.intervalID) {
				clearInterval(this.intervalID);
				this.intervalID = false;
			}
		}
	}
	componentWillUnmount() {
		if (this.intervalID) {
			clearInterval(this.intervalID);
			this.intervalID = false;
		}
	}
	// starting a new interval based on the "lastInterval" recorded
	onStartInterval = () => {
		if (this.props?.intervals[0]?.endDateTime) {
			const taskId = this.props?.intervals[0]?.task._id;
			this.props.createInterval({ _id: taskId });
		}
	};
	renderTimerBar = () => {
		let interval;
		let projectColor = '#000000'; // defaults to black (also done on back-end, this is just for safety)
		if (
			this.props?.intervals[0] &&
			this.props?.intervals[0]?.task?.project &&
			!this.props?.intervals[0]?.endDateTime
		) {
			interval = this.props?.intervals[0];
			projectColor = interval.task.project.tagColour;
			return (
				<div className="flex-container header-running-timer" style={{ borderColor: projectColor }}>
					<div
						className="flex-header counter-meta"
						onClick={() => this.props.onShowModalExistingTask({ _id: interval.task._id })}>
						<h4 className="">#{interval.task.workspaceCounter}</h4>
					</div>

					<div
						className="flex-body title-meta"
						onClick={() => this.props.onShowModalExistingTask({ _id: interval.task._id })}>
						<h5 className="trim-text-1">{interval.task.title}</h5>
						{interval.task.project && (
							<ProjectTag projectTitle={interval.task.project.title} projectColour={projectColor} />
						)}
					</div>
					<div className="flex-footer time-meta">
						<button className="trkr-btn trkr-btn-stop bg-green-active" onClick={this.props.stopInterval}>
							<h4>
								<i className="trkr-icon-stop trkr-margin-right-15" />
								<span className="">{formatDuration(this.state.currentRunningTime)}</span>
							</h4>
						</button>
					</div>
				</div>
			);
		} else if (this.props?.intervals[0] && this.props?.intervals[0]?.task?.project) {
			interval = this.props?.intervals[0];
			projectColor = interval.task.project.tagColour;
			return (
				<div className="flex-container header-running-timer" style={{ borderColor: projectColor }}>
					<div
						className="flex-header counter-meta"
						onClick={() => this.props.onShowModalExistingTask({ _id: interval.task._id })}>
						<h4 className="">#{interval.task.workspaceCounter || interval.task.workspaceCounter}</h4>
					</div>

					<div
						className="flex-body title-meta"
						onClick={() => this.props.onShowModalExistingTask({ _id: interval.task._id })}>
						<h5 className="trim-text-1">{interval.task.title}</h5>
						{interval.task.project && (
							<ProjectTag projectTitle={interval.task.project.title} projectColour={projectColor} />
						)}
					</div>
					<div className="flex-footer time-meta">
						<button className="trkr-btn bg-green-inactive trkr-full-width" onClick={this.onStartInterval}>
							<h4>
								<i className="trkr-icon-start trkr-margin-right-15" />
								<span className="">
									{formatDuration(dateDiff2(interval.endDateTime, interval.startDateTime))}
								</span>
							</h4>
						</button>
					</div>
				</div>
			);
		} else {
			return null;
		}
	};
	renderLoggedInView = () => {
		const subdomain = this.props.currentUser.selectedWorkspace.slug;
		const interval = this.props?.intervals[0];
		const allStatus = this.props.allStatus;
		const status = allStatus?.filter((status) => status._id === interval?.task?.status)[0];
		return (
			<div className="row vertical-align-middle">
				<div className="col-sm-3">
					<button
						className={
							`trkr-btn trkr-btn-default trkr-margin-left-30 trkr-btn-timesheet` +
							(this.props.showTimerMenu ? ' bg-blue-active' : '')
						}
						onClick={this.props.onShowTimerMenu}>
						<p>
							<i className={'icon trkr-margin-right-5 trkr-icon-timesheet-menu'} />
							Timesheet
						</p>
					</button>
				</div>
				<div className="col-sm-6">
					{this.props?.intervals[0] && status?.name !== 'Done' && this.renderTimerBar()}
				</div>
				<div className="col-sm-3">
					<ul className="nav navbar-nav vertical-align-middle">
						<li className="nav-item trkr-margin-right-30">
							<ul className="dropdown-menu onboarding">
								<li>
									<a>
										<i className="trkr-btn trkr-btn__add trkr-btn--gradient trkr-btn--shadow" />
									</a>
									<div className="dropdown-submenu">
										<ul>
											<li>
												<Link to={'./project/new'} className="nav-link">
													<i className="ion-compose" />
													&nbsp;New Project
												</Link>
											</li>
											{/* <li>
												<a
													className="nav-link pointer"
													onClick={this.props.onShowModalNewStory}>
													<i className="ion-compose" />
													&nbsp;New Story
												</a>
											</li> */}
											{/* <li>
												<a
													className="nav-link pointer"
													onClick={this.props.onShowModalNewRelease}>
													<i className="ion-compose" />
													&nbsp;New Release
												</a>
											</li> */}
											<li>
												<a
													className="nav-link pointer"
													onClick={() =>
														this.props.onShowModalNewTask({
															defaultAssigned: this.props.currentUser._id,
														})
													}>
													<i className="ion-compose" />
													&nbsp;New Task
												</a>
											</li>
											<li className="trkr-margin-top-15">
												<Link to={'./task/new'} className="nav-link">
													&nbsp;Invite
												</Link>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</li>
						<li className="nav-item trkr-margin-right-30">
							<ul className="dropdown-menu">
								<li>
									<a>
										{/* NOTE: using placeholder images until user profiles are available */}
										<Avatar
											user={this.props.currentUser}
											showWorskpace={true}
											className="user-avatar-small font-color-white inline avatar-header"
										/>
									</a>
									<div className="dropdown-submenu">
										<ul>
											<li>
												<a className="cursor" onClick={this.props.logoutUser}>
													Logout
												</a>
											</li>
											{this.props.currentUser.role === 'admin' && (
												<li>
													<a className="cursor" href="/reports">
														Reports
													</a>
												</li>
											)}
											{this.props.workspaceUsersForUser.map(
												(workspaceUser) =>
													workspaceUser.workspace._id !==
														this.props.currentUser.selectedWorkspace._id && (
														<li key={workspaceUser._id}>
															<a
																className="cursor"
																href={`${window.location.origin.replace(
																	subdomain,
																	workspaceUser.workspace.slug
																)}`}>
																Change to {workspaceUser.workspace.name} workspace
															</a>
														</li>
													)
											)}
											{/*<li key={'sony-1234'}>
												<a
													className="cursor"
													href={`${window.location.origin.replace(subdomain, 'sony')}`}>
													Change to "sony" workspace
												</a>
											</li>
											<li key={'testurl-1234'}>
												<a
													className="cursor"
													href={`${window.location.origin.replace(subdomain, 'apple')}`}>
													Change to "null" workspace
												</a>
											</li>*/}
											<li>
												<a className="cursor" onClick={() => history.push('/boards/settings')}>
													Settings
												</a>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		);
	};
	renderLoggedOutView = () => {
		return (
			<div className="">
				<div className="brand">
					<a href="/">
						<img src={Logo} />
					</a>
				</div>
			</div>
		);
	};
	render() {
		if (!this.state) {
			return null;
		}
		return (
			<nav className={`navbar flex-header ${!this.props.currentUser ? ' dark' : ''}`}>
				{!this.props.currentUser ? this.renderLoggedOutView() : this.renderLoggedInView()}
			</nav>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.page,
		appName: state.app.appName,
		currentUser: state.users.currentUser,
		intervals: state.intervals.intervals,
		showTimerMenu: state.app.showTimerMenu,
		serverOffset: state.intervals.serverOffset,
		workspaceUsersForUser: state.workspaceUsers.workspaceUsersForUser,
		allStatus: state.app.allStatus,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		createInterval,
		stopInterval,
		onShowTimerMenu,
		logoutUser,
		onShowModalNewTask,
		getWorkspaceUsersForUser,
		onShowModalExistingTask,
		onShowModalNewStory,
		onShowModalNewRelease,
	})(Header)
);

import agent from './agent';

// TODO: utilise redux-thunk instead of custom middleware
const promiseMiddleware = store => next => async action => {
	if (isPromise(action.payload)) {
		store.dispatch({ type: 'ASYNC_START', subtype: action.type });

		// console.log('[promiseMiddleware]', action);

		action.payload.then(
			res => {
				action.payload = res;
				store.dispatch(action);
			},
			error => {
				action.error = true;
				action.payload = error.response ? error.response.body : error.response;
				store.dispatch(action);
			}
		);

		return;
	}
	if (isPromise(action)) {
		const awaitedAction = await action;
		return next(awaitedAction);
	}

	next(action);
};

function isPromise(v) {
	return v && typeof v.then === 'function';
}

export { promiseMiddleware };

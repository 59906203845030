import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';
import ReCAPTCHA from 'react-google-recaptcha';

import { resetPassword } from './actions';

class ResetPassword extends Component {
	state = {
		email: '',
		reCaptcha: '',
	};
	submit = event => {
		event.preventDefault();
		if (this.state.reCaptcha && this.state.email) {
			this.props.resetPassword(this.state.email, this.state.reCaptcha);
		} else {
			notify.show('Please complete the captcha.', 'error', 5000);
		}
	};
	changeReCaptcha = value => {
		const reCaptcha = value;
		this.setState({ reCaptcha });
	};
	changeEmail = event => {
		const email = event.target.value;
		this.setState({ email });
	};
	render() {
		return (
			<form className="form-horizontal" onSubmit={this.submit}>
				<h2 className="text-center font-bold">Forgotten your password?</h2>

				<p className="trkr-margin-top-30 text-center">
					Please enter your e-mail address below for a reset link
				</p>
				<div className="trkr-margin-top-30">
					<label htmlFor="email">Email</label>
					<input
						className="form-control"
						type="email"
						name={'email'}
						required={true}
						value={this.state.email}
						onChange={this.changeEmail}
					/>
				</div>
				<br />
				<ReCAPTCHA
					ref="g-recaptcha"
					sitekey="6LfjnqwUAAAAANojlJPhnnawwMdB9prOlpoYXQ5U"
					onChange={this.changeReCaptcha}
				/>
				<div className="text-center trkr-margin-top-30">
					<button className="trkr-btn btn-primary" type="submit">
						<h4>Reset Password</h4>
					</button>

					<p
						className="link font-color-blue-active trkr-margin-top-15"
						onClick={() => {
							this.props.onClose();
						}}>
						Cancel
					</p>
				</div>
			</form>
		);
	}
}

// tell Redux what data where are interested in.
const mapStateToProps = state => ({
	resetPassword: state.resetPassword,
});

// pass Redux data and action creators to our component.
export default connect(
	mapStateToProps,
	{ resetPassword }
)(ResetPassword);

import agent from '../../agent';

import {
	PROJECTS_GET_ALL_PROJECTS_SUCCESS,
	PROJECTS_GET_ALL_PROJECTS_ERROR,
	PROJECTS_ADD_PROJECT_SUCCESS,
	PROJECTS_ADD_PROJECT_ERROR,
	PROJECTS_RESET_ADD_PROJECT
} from './constants';

export const getAllProjects = async () => {
	try {
		const { projects } = await agent.Projects.all();
		return { type: PROJECTS_GET_ALL_PROJECTS_SUCCESS, allProjects: projects };
	} catch (error) {
		return { type: PROJECTS_GET_ALL_PROJECTS_ERROR, error };
	}
};

export const addProject = async str => {
	try {
		const { project } = await agent.Projects.create({ title: str });
		return { type: PROJECTS_ADD_PROJECT_SUCCESS, newProject: project };
	} catch (error) {
		return { type: PROJECTS_ADD_PROJECT_ERROR, error };
	}
};

export const resetNewProject = () => {
	return { type: PROJECTS_RESET_ADD_PROJECT };
};

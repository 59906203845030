import React from 'react';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Task from './Task';

import { onShowModalNewTask } from '../../../../../App/actions';

const Column = (props) => {
	const createTask = (e, status) => {
		// need to determine laneId by parent
		var laneTitle;
		if (!status) {
			laneTitle = e.target.closest('section').getAttribute('title');
		} else {
			laneTitle = status;
		}
		if (laneTitle !== 'na101') {
			// prepopulate the status depending on column

			// pass default props
			props.onShowModalNewTask({
				defaultStatus: laneTitle,
				defaultPriority: 'medium',
				// defaultAssigned: props.currentUser._id,
			});
		}
	};
	const isColumnExpanded = () => {
		return props.columnSetting?.expanded === undefined || props.columnSetting?.expanded;
	};
	const toggleShowTasks = () => {
		let update = {};
		update.expanded = !isColumnExpanded();
		update.statusId = props.column._id;
		props.updateView(update);
	};

	return (
		<div
			className={
				'kanban-column-container ' +
				(props.dashBoardHeight ? 'kanban-column-container-height' : '') +
				(isColumnExpanded() ? 'kanban-column-container-expanded' : '')
			}>
			<div className="column-header">
				<div className="column-sorting">
					<p className="font-light">
						<span>Sort by</span> <i className="ion-chevron-down" />
					</p>
				</div>
				<div className="row vertical-align-middle">
					<div className="col-xs-8">
						{isColumnExpanded() ? (
							<span className="glyphicon glyphicon-resize-small" onClick={toggleShowTasks} />
						) : (
							<span className="glyphicon glyphicon-resize-full" onClick={toggleShowTasks} />
						)}
						<h4 className="font-color-white-1">{props.column.title}</h4>
					</div>
					{props.showAddButton && (
						<div className="col-xs-4 text-right">
							<button
								className="trkr-btn trkr-btn__add"
								onClick={(e) => createTask(e, props.column.title)}
							/>
						</div>
					)}
				</div>
			</div>
			{isColumnExpanded() && (
				<Droppable droppableId={props.column._id} type="task">
					{(provided, snapshot) => (
						<div
							onScroll={props.handleScroll}
							className={`kanban-task-list ${
								snapshot.isDraggingOver
									? 'kanban-task-list-dragging-over-background'
									: Boolean(snapshot.draggingFromThisWith)
									? 'kanban-task-list-dragging-from-background'
									: 'kanban-task-list-normal-background'
							}`}
							ref={provided.innerRef}
							{...provided.droppableProps}>
							{props.taskIds.map((taskId, index) => (
								<Task
									key={taskId}
									index={index}
									taskId={taskId}
									showTimer={props.showTimer}
									viewId={props.viewId}
								/>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	onShowModalNewTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(Column);

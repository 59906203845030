import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';

import { Option, SingleValue } from '../../../ReactSelect';
import Form from '../../../Form';
import RichTextEditor from '../../../RichTextEditor';

import {
	changeEstimateToMilliseconds,
	milliSecondsToHoursAndMinutes,
	formatDuration20,
	formatOption,
	mapProjectOptions,
} from '../../../../lib/Utils';

import { addProject, getAllProjects, resetNewProject } from '../../../Projects/actions';
import { getStoriesByProject, addStory, getStoriesByTaskCounter } from '../../../Stories/actions';
import { getAllTasks } from '../../../Tasks/actions';

import './index.scss';

const TaskForm = (props) => {
	console.log('TaskForm props: ', props);
	const [task, setTask] = useState({
		priority: props.priorityOptions.filter(priorityOption => priorityOption.label.toLowerCase() === props.defaultPriority.toLowerCase())[0],
		status: props.statusOptions.filter(statusOption => statusOption.label.toLowerCase() === props.defaultStatus.toLowerCase())[0],
		assignedTo: props.userOptions.filter(userOption => userOption.value === props.defaultAssignedTo)[0],
		category: props.categoryOptions.filter(categoryOption => categoryOption.label.toLowerCase() === props.defaultCategory.toLowerCase())[0],
		estimate: 0,
		managementEstimate: 0,
		...props.task
	});

	// If commented out code below is no longer needed, then it can be deleted.
	useEffect(() => {
		if (props.workspaceCounter) {
			// Below code does API call to get stories and tasks, so that Story and Dependencies fields below have options.
			// However, those fields are currently hidden, so no need to make those API calls.
			// props.getStoriesByTaskCounter(props.task.workspaceCounter);
			// props.getAllTasks();
		}
	}, []);

	// Get stories for existing task based on the project of the task.
	useEffect(() => {
		if(props?.task?.story){
			props.getStoriesByProject(props.task.project.value, props.task.story);
		}
	}, [props?.task?.project]);

	// update state if an existing task is being rendered.
	useEffect(() => {
		if (props.task) {
			setTask(props.task);
		}
	}, [props.workspaceCounter]);

	// after new project is created, we get projects again.
	useEffect(() => {
		if (props.newProject?._id) {
			props.getAllProjects();
		}
	}, [props.newProject]);

	// after new project is created, we select it.
	useEffect(() => {
		if (props.newProject?._id) {
			const project = props.allProjects.find(project => project._id === props.newProject._id);
			const projectOption = formatOption(project, 'project');
			addFieldToTask('project', projectOption);
			addFieldToTask('story', {});
			props.getStoriesByProject(projectOption.value, task.story);
			props.resetNewProject();
		}
	}, [props.allProjects]);

	// after new story is created, we select it.
	useEffect(() => {
		if (props.newStory?._id) {
			const story = formatOption(props.newStory, 'story');
			addFieldToTask('story', story);
		}
	}, [props.newStory]);

	useEffect(() => {
		props.updateTaskInTaskModal(task);
	}, [task]);

	const addFieldToTask = (field, value) => {
		setTask((prevTask) => {
			return {
				...prevTask,
				[field]: value,
			};
		});
	};

	const addProject = (str) => {
		// TODO: this is to be handled at a global level - so the new project is shown everywhere needed (filters etc)
		props.addProject(str);
	};
	const addStory = (str) => {
		const story = { title: str, project: task.project.value };
		props.addStory(story);
	};

	return (
		<Form>
			<div className="form-group row vertical-align-middle">
				<div className="col-xs-12 col-lg-2 lg-text-right">
					<label htmlFor={'title'}>Title*</label>
				</div>
				<div className="col-xs-12 col-lg-10 xs-margin-top-10">
					<textarea
						className="form-control"
						rows="1"
						name={'title'}
						required={true}
						placeholder="My new task"
						defaultValue={task.title}
						onMouseLeave={(ev) => addFieldToTask('title', ev.target.value)}
						onBlur={(ev) => addFieldToTask('title', ev.target.value)}
					/>
					<div className="invalid-feedback" />
				</div>
			</div>
			<div className="form-group row vertical-align-middle">
				<div className="col-xs-12 col-lg-2 lg-text-right">
					<label htmlFor={'project'}>Project*</label>
				</div>
				<div className="col-xs-12 col-lg-10 xs-margin-top-10">
					<input
						className="form-control hide"
						type="Text"
						name={'project-validator'}
						placeholder="project"
						defaultValue={task.project}
						pattern=".{1,}"
						required={true}
						validationmessage={`A project must be selected`}
					/>
					<CreatableSelect
						className="react-select-custom"
						classNamePrefix="react-select-custom"
						components={{ Option, SingleValue }}
						name={'project'}
						onChange={(ev) => {
							addFieldToTask('project', ev);
							addFieldToTask('story', {});
							props.getStoriesByProject(ev.value, task.story);
						}}
						value={task.project}
						options={mapProjectOptions(props.allProjects)}
						onCreateOption={(input) => {
							addProject(input);
						}}
						placeholder="My Project"
						validationMessage={`A project must be selected`}
					/>
					<div className="invalid-feedback" />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group row vertical-align-middle">
						<div className="col-xs-12 col-lg-4 lg-text-right">
							<label htmlFor={'estimate'}>Estimate</label>
						</div>
						<div className="col-xs-12 col-lg-8 xs-margin-top-10">
							<input
								className="form-control"
								type="text"
								name={'estimate'}
								placeholder="123"
								value={task.estimate}
								onChange={(ev) => addFieldToTask('estimate', ev.target.value)}
								onKeyDown={(ev) => {
									if (ev.key === 'Enter') {
										const estimateInMilliseconds = changeEstimateToMilliseconds(task.estimate);
										const formattedEstimate = milliSecondsToHoursAndMinutes(estimateInMilliseconds);
										addFieldToTask('estimate', formattedEstimate);
									}
								}}
							/>
							<div className="invalid-feedback" />
							{task.originalEstimate && (
								<p className="text-small trkr-margin-top-5">
									<span className="text-light">Original Estimate:</span>
									<span className="trkr-margin-left-5">
										{formatDuration20(task.originalEstimate)}
									</span>
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group row vertical-align-middle">
						<div className="col-xs-12 col-lg-4 lg-text-right">
							<label htmlFor={'priority'}>Priority</label>
						</div>
						<div className="col-xs-12 col-lg-8 xs-margin-top-10">
							<Select
								className="react-select-custom"
								classNamePrefix="react-select-custom"
								components={{ Option, SingleValue }}
								name={'priority'}
								onChange={(ev) => addFieldToTask('priority', ev)}
								value={task.priority}
								options={props.priorityOptions}
								placeholder="Select a priority"
							/>
							<div className="invalid-feedback" />
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group row vertical-align-middle">
						<div className="col-xs-12 col-lg-4 lg-text-right">
							<label htmlFor={'status'}>Status*</label>
						</div>
						<div className="col-xs-12 col-lg-8 xs-margin-top-10">
							<Select
								className="react-select-custom"
								classNamePrefix="react-select-custom"
								components={{ Option, SingleValue }}
								name={'status'}
								onChange={(ev) => addFieldToTask('status', ev)}
								value={task.status}
								options={props.statusOptions}
								placeholder="Select a status"
							/>
							<div className="invalid-feedback" />
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group row vertical-align-middle">
						<div className="col-xs-12 col-lg-4 lg-text-right">
							<label htmlFor={'assignedTo'}>Assigned to</label>
						</div>
						<div className="col-xs-12 col-lg-8 xs-margin-top-10">
							<Select
								className="react-select-custom"
								classNamePrefix="react-select-custom"
								components={{ Option, SingleValue }}
								name={'assignedTo'}
								onChange={(ev) => addFieldToTask('assignedTo', ev)}
								value={task.assignedTo}
								options={props.userOptions}
								placeholder="Assigned to"
							/>
							<div className="invalid-feedback" />
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group row vertical-align-middle">
						<div className="col-xs-12 col-lg-4 lg-text-right">
							<label htmlFor={'managementEstimate'}>Manager Estimate</label>
						</div>
						<div className="col-xs-12 col-lg-8 xs-margin-top-10">
							<input
								className="form-control"
								type="text"
								name={'managementEstimate'}
								placeholder="123"
								value={task.managementEstimate}
								onChange={(ev) => addFieldToTask('managementEstimate', ev.target.value)}
								onKeyDown={(ev) => {
									if (ev.key === 'Enter') {
										const managementEstimateInMilliseconds = changeEstimateToMilliseconds(
											task.managementEstimate
										);
										const formattedManagementEstimate = milliSecondsToHoursAndMinutes(
											managementEstimateInMilliseconds
										);
										addFieldToTask('managementEstimate', formattedManagementEstimate);
									}
								}}
							/>
							<div className="invalid-feedback" />
						</div>
					</div>
				</div>
			</div>
			<div className="row form-group">
				<div className="col-xs-12 col-lg-2 lg-text-right">
					<label htmlFor={'description'}>Description</label>
				</div>
				<div className="col-xs-12 col-lg-10 xs-margin-top-10">
					<RichTextEditor
						value={task.description}
						onBlur={(ev) => addFieldToTask('description', JSON.stringify(ev))}
						onLoseFocus={(ev) => addFieldToTask('description', JSON.stringify(ev))}
						placeholder={'Some more details about this task…'}
						initialText=""
					/>
					<div className="invalid-feedback" />
				</div>
			</div>
			<div className="row form-group">
				<div className="col-xs-12 col-lg-2 lg-text-right">
					<label htmlFor={'flagNotes'}>Flag Notes</label>
				</div>
				<div className="col-xs-12 col-lg-10 xs-margin-top-10">
					<RichTextEditor
						value={task.flagNotes}
						onBlur={(ev) => addFieldToTask('flagNotes', JSON.stringify(ev))}
						onLoseFocus={(ev) => addFieldToTask('flagNotes', JSON.stringify(ev))}
						placeholder={'Some flag notes about this task…'}
						initialText=""
					/>
					<div className="invalid-feedback" />
				</div>
			</div>
			<input type="checkbox" id="form-shower-1" className="eye-shower" />
			{/* <label
					htmlFor="form-shower-1"
					className="form-visibility font-color-white col-xs-12 col-lg-10 col-lg-offset-2 trkr-padding-left-0">
					<p className="eye-shower-off">
						Show more <i className="ion-chevron-down" />
					</p>
					<p className="eye-shower-on">
						Show less <i className="ion-chevron-up" />
					</p>
				</label> */}
			<div className="form-drawer trkr-padding-top-30">
				<div className="form-group row vertical-align-middle">
					<div className="col-xs-12 col-lg-2 lg-text-right">
						<label htmlFor={'category'}>Category*</label>
					</div>
					<div className="col-xs-12 col-lg-10 xs-margin-top-10">
						<Select
							className="react-select-custom"
							classNamePrefix="react-select-custom"
							components={{ Option, SingleValue }}
							name={'category'}
							onChange={(ev) => addFieldToTask('category', ev)}
							value={task.category}
							options={props.categoryOptions}
							placeholder="Select a category"
						/>
						<div className="invalid-feedback" />
					</div>
				</div>
				{task.project && (
					<div className="form-group row vertical-align-middle">
						<div className="col-xs-12 col-lg-2 lg-text-right">
							<label htmlFor={'story'}>Story</label>
						</div>

						<div className="col-xs-12 col-lg-10 xs-margin-top-10">
							<CreatableSelect
								className="react-select-custom"
								classNamePrefix="react-select-custom"
								name={'story'}
								required={true}
								onChange={(ev) => addFieldToTask('story', ev)}
								value={task.story}
								options={props.allStories}
								onCreateOption={addStory}
								placeholder="Select a story"
							/>
							<div className="invalid-feedback" />
						</div>
					</div>
				)}
				<div className="form-group row vertical-align-middle">
					<div className="col-xs-12 col-lg-2 lg-text-right">
						<label className="trim-text-1" htmlFor={'dependencies'}>
							Dependencies
						</label>
					</div>
					<div className="col-xs-12 col-lg-10 xs-margin-top-10">
						<CreatableSelect
							className="react-select-custom"
							classNamePrefix="react-select-custom"
							isMulti
							isClearable
							name={'dependencies'}
							onChange={(ev) => addFieldToTask('taskDependencies', ev)}
							value={task.taskDependencies}
							options={props.allTasks.map((task) => {
								return { value: task._id, label: `#${task.workspaceCounter} - ${task.title}` };
							})}
							placeholder="A dependency task"
						/>
						<div className="invalid-feedback" />
					</div>
				</div>
			</div>
			{!props.hideSubmitBtn && (
				<div className="form-group row">
					<div className="col-xs-6 col-xs-offset-6 text-right">
						<button className="btn btn-lg btn-primary" type="submit" disabled={props.isLoading == true}>
							<h4>{task.workspaceCounter ? 'Update Task' : 'Create Task'}</h4>
						</button>
					</div>
				</div>
			)}
		</Form>
	);
};

// default properties (overwritten by props passed by parent component)
TaskForm.defaultProps = {
	className: '',
	categoryOptions: [],
	statusOptions: [],
	priorityOptions: [],
	userOptions: [],
	allProjects: [],
	allStories: [],
	allTasks: [],
	newProject: {},
	updateTaskInTaskModal: () => {
		console.warn('no updateTaskInTaskModal function parsed to TaskForm');
	},
};

const mapStateToProps = (state) => ({
	categoryOptions: state.app.categoryOptions,
	statusOptions: state.app.statusOptions,
	priorityOptions: state.app.priorityOptions,
	allProjects: state.projects.allProjects,
	newProject: state.projects.newProject,
	allStories: state.stories.allStories,
	newStory: state.stories.newStory,
	// allTasks: state.tasks.allTasks, // Commented out because the Dependencies section that uses it is hidden.
	userOptions: state.app.userOptions,
});

export default connect(mapStateToProps, {
	addProject,
	getAllProjects,
	resetNewProject,
	addStory,
	getStoriesByProject,
	getAllTasks,
	getStoriesByTaskCounter,
})(TaskForm);
export { TaskForm, mapStateToProps };

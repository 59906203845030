import React from 'react';

import Avatar from '../Icons/Avatar';
import { formatDate } from '../../lib/Utils';

class MessageEntry extends React.Component {
	/*constructor() {
      	super();
  	}*/

	componentWillMount() {}

	render() {
		if (this.props.author && this.props.author._id) this.props.author['id'] = this.props.author._id;

		// if a legacy "system-message" then remove from view - this information will be migrated to "activities"
		// TODO: utilise system messaging for other things?
		if (
			!this.props.author ||
			this.props.author.id === '5ba87396bb4eccb1b015a71f' ||
			this.props.author === '5ba87396bb4eccb1b015a71f'
		) {
			return null;
		}

		// colouring slack like mentions @
		const contentSubStringsRegex = /(\[[^\]]*])/g;
		const contentSubStrings = this.props.content
			.split(contentSubStringsRegex)
			.map(str => str.replace(/[[\]']+/g, ''));
		for (var i = 1; i < contentSubStrings.length; i += 2) {
			contentSubStrings[i] = (
				<span className="mention" key={i}>
					{contentSubStrings[i]}
				</span>
			);
		}

		return (
			<div className="message-entry row trkr-align-middle">
				<div className="col-xs-2 trkr-align-top text-center">
					<Avatar
						user={this.props.author}
						fullName={this.props.author.fullName}
						image={this.props.author.image}
						className="small trkr-full-width"
					/>
				</div>

				<div className="col-xs-10 trkr-padding-left-0">
					<p className="text-small time-stamp">{formatDate(this.props.createdAt, 'LT').toLowerCase()}</p>

					<p className="trkr-margin-0 content text-small">{contentSubStrings}</p>
				</div>
			</div>
		);
	}
}

export default MessageEntry;

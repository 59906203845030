import React from 'react';
import PageView from '../../components/PageView';
import Views from '../../components/Views';

const TasksPage = props => {
	return (
		<PageView className="task-list">
			<Views />
		</PageView>
	);
};

export default TasksPage;

// Makes sure that action and reducers use the same strings.
import agent from '../../agent';
import {
	PROJECT_GET_WORKSPACE_SUCCESS,
	PROJECT_GET_WORKSPACE_ERROR,
	CREATE_PROJECT_SUCCESS,
	CREATE_PROJECT_ERROR,
} from './constants';

export const getWorkspace = async workspaceId => {
	try {
		const response = await agent.Workspaces.get(workspaceId);
		const workspace = response.workspace;
		return { type: PROJECT_GET_WORKSPACE_SUCCESS, workspace };
	} catch (error) {
		return { type: PROJECT_GET_WORKSPACE_ERROR, error };
	}
};

export const createProject = async (workspaceId, title) => {
	try {
		const response = await agent.Projects.create({ workspace: workspaceId, title });
		const project = response.project;
		return { type: CREATE_PROJECT_SUCCESS, project };
	} catch (error) {
		return { type: CREATE_PROJECT_ERROR, error };
	}
};

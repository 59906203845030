import agent from '../../agent';

import {
	STORIES_GET_STORIES_BY_PROJECT_SUCCESS,
	STORIES_GET_STORIES_BY_PROJECT_ERROR,
	STORIES_ADD_STORY_SUCCESS,
	STORIES_ADD_STORY_ERROR,
	STORIES_GET_STORIES_BY_TASK_COUNTER_SUCCESS,
	STORIES_GET_STORIES_BY_TASK_COUNTER_ERROR,
	STORIES_GET_STORY_SUCCESS,
	STORIES_GET_STORY_ERROR,
	STORIES_UPDATE_STORY_SUCCESS,
	STORIES_UPDATE_STORY_ERROR,
} from './constants';

export const getStoriesByProject = async (value, currentStory) => {
	try {
		let allStories, story;
		const response = await agent.Stories.getByProject(null, value);
		// if project change, then release will be replaced with a matching one from the new project
		allStories = response.stories.reduce(function(result, story) {
			if (!story.isDefault) {
				result.push({ value: story.id, label: story.title });
			}
			return result;
		}, []);
		if (currentStory) {
			// clear current story selection if the labels aren't similar
			var foundExistingStories = allStories.filter(story => {
				return story.label === currentStory.label;
			});
			var foundExistingStory = foundExistingStories[0];
			var defaultStory = response.stories.filter(story => {
				return story.isDefault;
			})[0];

			if (foundExistingStory) {
				story = foundExistingStory;
			} else if (defaultStory) {
				story = [];
			}
		}
		return { type: STORIES_GET_STORIES_BY_PROJECT_SUCCESS, allStories, story };
	} catch (error) {
		return { type: STORIES_GET_STORIES_BY_PROJECT_ERROR, error };
	}
};

export const addStory = async storyObject => {
	try {
		const { story } = await agent.Stories.create(storyObject);
		return { type: STORIES_ADD_STORY_SUCCESS, newStory: story };
	} catch (error) {
		return { type: STORIES_ADD_STORY_ERROR, error };
	}
};

export const getStoriesByTaskCounter = async workspaceCounter => {
	try {
		let allStories;
		const response = await agent.Stories.getByTaskCounter(workspaceCounter);
		if (response) {
			allStories = response.stories.reduce(function(result, story) {
				if (!story.isDefault) {
					result.push({ value: story.id, label: story.title });
				}
				return result;
			}, []);
		} else {
			allStories = [];
		}
		return { type: STORIES_GET_STORIES_BY_TASK_COUNTER_SUCCESS, allStories };
	} catch (error) {
		console.log('error: ', error);
		return { type: STORIES_GET_STORIES_BY_TASK_COUNTER_ERROR, error };
	}
};

export const getStory = async id => {
	try {
		const { story } = await agent.Stories.get(id);
		return { type: STORIES_GET_STORY_SUCCESS, story };
	} catch (error) {
		return { type: STORIES_GET_STORY_ERROR, error };
	}
};

export const updateStory = async (id, update) => {
	try {
		await agent.Stories.update(id, update);
		return { type: STORIES_UPDATE_STORY_SUCCESS };
	} catch (error) {
		return { type: STORIES_UPDATE_STORY_ERROR, error };
	}
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';

import { getQuery } from '../../lib/Utils';
import { getWorkspaceUsers, getWorkspace, inviteUsers } from './actions';
import './index.scss';
import logo from '../../assets/images/logo-bw.png';

class TeamInvitePage extends Component {
	state = {
		emails: {
			'id-1': {},
		},
		itemsToShow: 3,
		expanded: false,
	};
	componentDidMount() {
		const workspaceId = getQuery(this.props.location.search, 'w');
		if (workspaceId) {
			this.props.getWorkspaceUsers(workspaceId);
			this.props.getWorkspace(workspaceId);
		} else {
			console.log('workspaceId is not provided.');
		}
	}
	componentDidUpdate(prevProps) {
		if (this.props.sentEmails !== prevProps.sentEmails) {
			this.redirectToProjectCreationPage();
		}
	}
	changeEmail = event => {
		const name = event.target.name;
		const email = event.target.value;
		const emails = Object.values(this.state.emails)
			.filter(email => email.value)
			.map(email => email.value);
		if (emails.includes(email)) {
			notify.show('email already exists', 'error');
			return;
		}
		if (email === this.props.currentUser.email) {
			notify.show('You can not invite yourself!', 'error');
			return;
		}
		this.setState(state => {
			const newEmails = { ...state.emails };
			newEmails[name].value = email;
			return { ...state, emails: newEmails };
		});
	};
	inviteMore = () => {
		this.setState(state => {
			const newEmails = { ...state.emails };
			newEmails[`id-${Object.keys(this.state.emails).length + 1}`] = {};
			return { ...state, emails: newEmails };
		});
	};
	inviteLess = id => {
		this.setState(state => {
			const newEmails = { ...state.emails };
			delete newEmails[id];
			return { ...state, emails: newEmails };
		});
	};
	submitForm = event => {
		event.preventDefault();
		const workspaceId = getQuery(this.props.location.search, 'w');
		const emails = Object.values(this.state.emails).map(email => email.value);
		this.props.inviteUsers(workspaceId, emails);
	};
	skipInvites = () => {
		this.redirectToProjectCreationPage();
	};
	redirectToProjectCreationPage = () => {
		const workspaceId = getQuery(this.props.location.search, 'w');
		this.props.history.push(`/project-creation?w=${workspaceId}`);
	};
	showMore = () => {
		this.state.expanded === false
			? this.setState({ itemsToShow: this.props.workspaceUsers.length, expanded: true })
			: this.setState({ itemsToShow: 3, expanded: false });
	};
	goBack = () => {
		this.props.history.goBack();
	};
	render() {
		return (
			<div className="row full-height">
				<div className="col-md-5 col-xs-5 team-invites-form-column full-height">
					<div className="vertical-center full-height">
						<img className="team-invites-form-column-logo" src={logo} />
						<form className="form-horizontal team-invites-form" onSubmit={this.submitForm}>
							<p className="team-invites-form-step">STEP 3/4</p>
							<h2 className="team-invites-form-title">Invite your team</h2>
							{Object.keys(this.state.emails).map((key, index) => (
								<div key={key} className="form-group team-invites-form-group">
									<label
										className="col-sm-1 col-sm-offset-4 control-label team-invites-form-group-label"
										htmlFor={'email'}>
										Email:
									</label>
									<div className="col-sm-6">
										<input
											className="form-control team-invites-form-group-input"
											name={key}
											type="email"
											required={true}
											placeholder="name@company.com"
											value={this.state.emails[key].value}
											onChange={this.changeEmail}
										/>
									</div>
									{index > 0 && (
										<div className="col-sm-1">
											<button
												className="trkr-icon-close trkr-btn-close team-invites-form-group-close"
												onClick={() => this.inviteLess(key)}></button>
										</div>
									)}
								</div>
							))}
							<div>
								<button className="team-invites-form-plus-button">+</button>
								<button
									className="team-invites-form-invite-more"
									type="button"
									onClick={this.inviteMore}>
									Invite more
								</button>
							</div>
							{this.props.workspaceUsers.length > 0 && (
								<Fragment>
									<h5 className="team-invites-form-emails-title">Workspace users</h5>
									{this.props.workspaceUsers.slice(0, this.state.itemsToShow).map(workspaceUser => (
										<p className="team-invites-form-emails-detail" key={workspaceUser._id}>
											{workspaceUser.user.email}
										</p>
									))}
									<button
										className="team-invites-form-emails-show-more"
										type="button"
										onClick={this.showMore}>
										{this.state.expanded ? <span>show less</span> : <span>show more</span>}
									</button>
								</Fragment>
							)}
							<div>
								<button
									className="btn btn-primary team-invites-form-back-button"
									type="button"
									onClick={this.goBack}>
									BACK
								</button>
								<button className="btn btn-primary team-invites-form-invite-button" type="submit">
									INVITE
								</button>
							</div>
							<p className="team-invites-form-skip-button-message">
								or{' '}
								<button
									className="team-invites-form-skip-button"
									type="button"
									onClick={this.skipInvites}>
									skip for now
								</button>
							</p>
						</form>
					</div>
				</div>
				<div className="col-md-7 col-xs-7 team-invites-image-column">
					<div className="team-invites-image-column-navbar">
						<div className="team-invites-image-column-navbar-tab-1">
							<div className="team-invites-image-column-navbar-tab-1-title" />
						</div>
						<div className="team-invites-image-column-navbar-tab-2">
							<div className="team-invites-image-column-navbar-tab-2-title" />
						</div>
						<div className="team-invites-image-column-navbar-name">
							<p>{this.props.currentUser ? this.props.currentUser.fullName : ''}</p>
							<p>{this.props.workspace ? this.props.workspace.name : ''}</p>
						</div>
						<div className="team-invites-image-column-navbar-avatar" />
					</div>
					<div className="team-invites-image-column-board">
						<div className="team-invites-image-column-board-section-1">
							<div className="team-invites-image-column-board-section-1-drop-down" />
							<div className="team-invites-image-column-board-section-1-search-bar" />
						</div>
						<div className="team-invites-image-column-board-section-2">
							<div className="team-invites-image-column-board-section-2-task-card-1">
								<div className="team-invites-image-column-board-section-2-task-card-1-title" />
								<div className="team-invites-image-column-board-section-2-task-card-1-details">
									<div className="team-invites-image-column-board-section-2-task-card-1-details-item-1" />
									<div className="team-invites-image-column-board-section-2-task-card-1-details-item-2" />
									<div className="team-invites-image-column-board-section-2-task-card-1-details-item-3" />
									<div className="team-invites-image-column-board-section-2-task-card-1-details-item-4" />
								</div>
							</div>
							<div className="team-invites-image-column-board-section-2-task-card-2">
								<div className="team-invites-image-column-board-section-2-task-card-2-title" />
								<div className="team-invites-image-column-board-section-2-task-card-2-details">
									<div className="team-invites-image-column-board-section-2-task-card-2-details-item-1" />
									<div className="team-invites-image-column-board-section-2-task-card-2-details-item-2" />
									<div className="team-invites-image-column-board-section-2-task-card-2-details-item-3" />
									<div className="team-invites-image-column-board-section-2-task-card-2-details-item-4" />
								</div>
							</div>
							<div className="team-invites-image-column-board-section-2-task-card-3">
								<div className="team-invites-image-column-board-section-2-task-card-3-title" />
								<div className="team-invites-image-column-board-section-2-task-card-3-details">
									<div className="team-invites-image-column-board-section-2-task-card-3-details-item-1" />
									<div className="team-invites-image-column-board-section-2-task-card-3-details-item-2" />
									<div className="team-invites-image-column-board-section-2-task-card-3-details-item-3" />
									<div className="team-invites-image-column-board-section-2-task-card-3-details-item-4" />
								</div>
							</div>
							<div className="team-invites-image-column-board-section-2-task-card-4">
								<div className="team-invites-image-column-board-section-2-task-card-4-title" />
								<div className="team-invites-image-column-board-section-2-task-card-4-details">
									<div className="team-invites-image-column-board-section-2-task-card-4-details-item-1" />
									<div className="team-invites-image-column-board-section-2-task-card-4-details-item-2" />
									<div className="team-invites-image-column-board-section-2-task-card-4-details-item-3" />
									<div className="team-invites-image-column-board-section-2-task-card-4-details-item-4" />
								</div>
							</div>
						</div>
						<div className="team-invites-image-column-board-section-3">
							<div className="team-invites-image-column-board-section-3-task-card-5">
								<div className="team-invites-image-column-board-section-3-task-card-5-details">
									<div className="team-invites-image-column-board-section-3-task-card-5-details-item-1" />
									<div className="team-invites-image-column-board-section-3-task-card-5-details-item-2" />
									<div className="team-invites-image-column-board-section-3-task-card-5-details-item-3" />
									<div className="team-invites-image-column-board-section-3-task-card-5-details-item-4" />
								</div>
							</div>
							<div className="team-invites-image-column-board-section-3-task-card-6">
								<div className="team-invites-image-column-board-section-3-task-card-6-details">
									<div className="team-invites-image-column-board-section-3-task-card-6-details-item-1" />
									<div className="team-invites-image-column-board-section-3-task-card-6-details-item-2" />
									<div className="team-invites-image-column-board-section-3-task-card-6-details-item-3" />
									<div className="team-invites-image-column-board-section-3-task-card-6-details-item-4" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state.teamInvitePage,
	currentUser: state.users.currentUser,
});

export default connect(
	mapStateToProps,
	{
		getWorkspaceUsers,
		getWorkspace,
		inviteUsers,
	}
)(TeamInvitePage);

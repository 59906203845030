import {
	TASKS_GET_TASKS_SUCCESS,
	TASKS_GET_TASKS_ERROR,
	TASKS_GET_ALL_TASKS_SUCCESS,
	TASKS_GET_ALL_TASKS_ERROR,
	TASKS_GET_TASK_SUCCESS,
	TASKS_GET_TASK_ERROR,
	TASKS_GET_TASK_RESET,
	TASKS_UPDATE_TASK_SUCCESS,
	TASKS_UPDATE_TASK_ERROR,
	TASKS_CREATE_TASK_SUCCESS,
	TASKS_CREATE_TASK_ERROR,
	TASKS_GET_TASK_NO_ESTIMATES,
	TASKS_GET_BY_VIEW_BY_PROJECT_SUCCESS,
	TASKS_GET_BY_VIEW_BY_PROJECT_ERROR,
} from './constants';

const INITIAL_STATE = {
	tasksById: {},
	modifiedTask: null,
	taskUpdated: false,
	taskCreated: false,
	newTaskId: null,
	tasksByViewId: {},
	viewsKanbanInfo: {},
	errors: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TASKS_GET_BY_VIEW_BY_PROJECT_SUCCESS:
			// console.log(`${action.type}`, action);

			const originalViewContent = state.tasksByViewId[action.viewId];
			const newViewContent = action.viewTasks[action.viewId];
			let updatedContent = {};
			let mergedViewContent = state.tasksByViewId;

			if (!originalViewContent || !originalViewContent.feed || action.dumpPreviousData) {
				updatedContent = newViewContent;
			} else {
				updatedContent.tasks = [...originalViewContent.tasks, ...newViewContent.tasks];
				updatedContent.tasksByProject = [
					...originalViewContent.tasksByProject,
					...newViewContent.tasksByProject,
				];
			}

			mergedViewContent[action.viewId] = updatedContent;

			return {
				...state,
				tasksById: {
					...state.tasksById,
					...action.tasksById,
				},
				tasksByViewId: mergedViewContent,
			};
		case TASKS_GET_TASK_NO_ESTIMATES:
			return {
				...state,
				tasksById: {
					...state.tasksById,
					...action.tasksById,
				},
			};
		case TASKS_GET_TASKS_SUCCESS:
			return {
				...state,
				...action,
				tasksById: {
					...state.tasksById,
					...action.tasksById,
				},
				viewsKanbanInfo: {...state.viewsKanbanInfo, [action.viewKanbanInfo._id]: action.viewKanbanInfo },
				type: undefined,
			};
		case TASKS_GET_ALL_TASKS_SUCCESS:
			return {
				...state,
				allTasks: action.allTasks,
			};
		case TASKS_GET_TASK_SUCCESS:
			return {
				...state,
				task: action.obj.task,
				tasksById: {
					...state.tasksById,
					[action.obj.task._id]: action.obj.task,
				},
				modifiedTask: action.obj.modifiedTask,
				taskUpdated: false,
				taskCreated: false,
			};
		case TASKS_GET_TASK_RESET:
			return {
				...state,
				task: null,
				modifiedTask: null,
			};
		case TASKS_UPDATE_TASK_SUCCESS:
			return {
				...state,
				taskUpdated: true,
			};

		case TASKS_CREATE_TASK_SUCCESS:
			return {
				...state,
				taskCreated: true,
				newTaskId: action.newTaskId,
			};
		case TASKS_CREATE_TASK_ERROR:
		case TASKS_UPDATE_TASK_ERROR:
		case TASKS_GET_TASK_ERROR:
		case TASKS_GET_ALL_TASKS_ERROR:
		case TASKS_GET_TASKS_ERROR:
		case TASKS_GET_BY_VIEW_BY_PROJECT_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		default:
			return state;
	}
};

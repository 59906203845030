// Makes sure that action and reducers use the same strings.
import agent from '../../agent';

import {
	CREATE_VIEW_SUCCESS,
	CREATE_VIEW_ERROR,
	GET_VIEWS_SUCCESS,
	GET_VIEWS_ERROR,
	GET_VIEW_SUCCESS,
	GET_VIEW_ERROR,
	DELETE_VIEW_SUCCESS,
	DELETE_VIEW_ERROR,
	UPDATE_VIEW_SUCCESS,
	UPDATE_VIEW_ERROR,
	UPDATE_VIEWS_SUCCESS,
	UPDATE_VIEWS_ERROR,
} from './constants';

export const createView = async () => {
	try {
		const response = await agent.Views.create();
		const view = response.view;
		return { type: CREATE_VIEW_SUCCESS, view };
	} catch (error) {
		return { type: CREATE_VIEW_ERROR, error };
	}
};

export const getViews = async () => {
	try {
		const response = await agent.Views.all();
		const views = response.views;
		return { type: GET_VIEWS_SUCCESS, views };
	} catch (error) {
		return { type: GET_VIEWS_ERROR, error };
	}
};

export const getViewById = async viewId => {
	console.log('getViewById', viewId);
	console.log('[JS FILTER UPDATE GET VIEW BY ID API 2');
	try {
		const response = await agent.Views.get(viewId);
		const view = response;
		console.log('getViewById response', response);
		return { type: GET_VIEW_SUCCESS, view };
	} catch (error) {
		return { type: GET_VIEW_ERROR, error };
	}
};

export const deleteView = async viewId => {
	try {
		const response = await agent.Views.delete(viewId);
		console.log('response: ', response);
		const view = response.view;
		return { type: DELETE_VIEW_SUCCESS, view };
	} catch (error) {
		return { type: DELETE_VIEW_ERROR, error };
	}
};

export const updateView = async (viewId, update) => {
	try {
		const response = await agent.Views.update(viewId, update);
		const view = response.view;
		return { type: UPDATE_VIEW_SUCCESS, view };
	} catch (error) {
		return { type: UPDATE_VIEW_ERROR, error };
	}
};

export const updateViews = async views => {
	try {
		await agent.Views.updateBatch(views);
		return { type: UPDATE_VIEWS_SUCCESS };
	} catch (error) {
		return { type: UPDATE_VIEWS_ERROR, error };
	}
};

import React from 'react';
import styled from 'styled-components';
import { cx, css } from 'emotion';

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
	<div
		{...props}
		ref={ref}
		className={cx(
			className,
			css`
				& > * {
					display: inline-block;
				}

				& > * + * {
					margin-left: 15px;
				}
			`
		)}
	/>
));

export const Button = styled.span`
	cursor: pointer;
	color: ${props => (props.reversed ? (props.active ? 'white' : '#aaa') : props.active ? 'black' : '#ccc')};
`;

export const Icon = styled(({ className, ...rest }) => {
	return <span className={`material-icons ${className}`} {...rest} />;
})`
	font-size: 18px;
	vertical-align: text-bottom;
`;

export const Toolbar = styled.div`
	padding: 10px;
	border-bottom: 2px solid #eee;
	${Button} {
		display: inline-block;
		margin-left: 15px;
	}
`;

export const Wrapper = styled('div')`
	display: flex;
	flex-direction: column;
	border-radius: 5px;
`;

export const ItemWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	& + & {
		margin-top: 0;
	}
`;

export const CheckboxWrapper = styled.span`
	padding: 0px 10px !important;
	width: 7% !important;
`;

export const ContentWrapper = styled.span`
	flex: 1;
`;

export const CheckListItem = props => {
	const onChange = event => {
		const checked = event.target.checked;
		const { editor, node } = props;
		editor.setNodeByKey(node.key, { data: { checked } });
	};
	const { attributes, children, node, readOnly } = props;
	const checked = node.data.get('checked');
	return (
		<div>
			<ItemWrapper {...attributes}>
				<CheckboxWrapper>
					<input type="checkbox" checked={checked} onChange={onChange} />
				</CheckboxWrapper>
				<ContentWrapper checked={checked} contentEditable={!readOnly} suppressContentEditableWarning>
					{children}
				</ContentWrapper>
			</ItemWrapper>
		</div>
	);
};

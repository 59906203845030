import { formatDuration } from '../../lib/Utils';
import moment from 'moment';

let dateDiff2 = (endDateTime, startDateTime) => {
	return moment
	.duration(moment(endDateTime).startOf('second').diff(moment(startDateTime).startOf('second')))
	.as('milliseconds');
}

export default (state = {}, action) => {
	function formatTaskOption(task) {
		var optionDetails;
		if (task.project) {
			optionDetails = { type: 'task', objectData: task.project };
		}

		return {
			value: task._id,
			label: `#${task.workspaceCounter} ${task.title}`,
			...optionDetails,
		};
	}

	switch (action.type) {
		case 'INTERVAL_EDITOR_PAGE_LOADED':
			console.log('[INTERVAL_EDITOR_PAGE_LOADED]', action);

			var obj = {
				...state,
				isLoading: false,
			};

			if (action.payload) {
				if (action.payload[0]) {
					var interval = action.payload[0].interval;

					obj.interval = interval;
					obj.title = interval.title;
					obj.duration = dateDiff2(interval.endDateTime, interval.startDateTime);
					obj.durationString = formatDuration(dateDiff2(interval.endDateTime, interval.startDateTime));
					obj.startDateTime = interval.startDateTime;
					obj.endDateTime = interval.endDateTime;

					obj.task = interval.task ? formatTaskOption(interval.task) : undefined;
					obj.project = interval.project
						? { value: interval.project.id, label: interval.project.title }
						: undefined;
				} else {
					obj.title = '';
					obj.task = '';
					obj.project = '';
					// obj.duration = 0;
					obj.start = undefined;
					obj.endDateTime = undefined;
				}

				// not needed
				/*if (action.payload[1]) {
					obj.allProjects = action.payload[1].projects.map(project => {
						return { value: project.id, label: project.title };
					});
				} else {
					obj.allProjects = [];
				}*/

				// move to common reducer?
				if (action.payload[1]) {
					obj.allTasks = action.payload[1].tasks.map((task) => {
						return formatTaskOption(task);
					});
				} else {
					obj.allTasks = [];
				}
			} else {
				obj = {
					...state,
					isLoading: false,
					inProgress: false,
					title: '',
					// duration:0,
					startDateTime: undefined,
					endDateTime: undefined,
					allProjects: [],
					allTasks: [],
				};
			}

			return obj;

		case 'INTERVAL_EDITOR_PAGE_UNLOADED':
			return {};

		case 'INTERVAL_SUBMITTED':
			console.log('[INTERVAL_SUBMITTED]', action);
			return {
				...state,
				inProgress: null,
				errors: action.error ? action.payload.errors : null,
				redirectTo: action.error ? null : '/boards',
			};

		case 'INTERVAL_UPDATE_FIELD':
			console.log('intervalEdito.UPDATE_FIELD_EDITOR', action, state);
			var obj = {
				...state,
			};

			if (action.key === 'startDateTime' || action.key === 'endDateTime') {
				if (action.key === 'startDateTime') {
					obj.duration = dateDiff2(state.endDateTime, action.value);
				} else {
					obj.duration = dateDiff2(action.value, state.startDateTime);
				}

				obj.durationString = formatDuration(obj.duration);
			}

			obj[action.key] = action.value;

			// if project change, then release will be replaced with a matching one from the new project
			if (action.payload && action.key === 'project') {
				// obj.allReleases = action.payload.releases;

				obj.allReleases = action.payload.releases.map((release) => {
					return { value: release.id, label: release.name };
				});

				// console.log('UPDATE_FIELD_EDITOR project changed', action, state);
				if (state.release) {
					var foundExistingReleases = obj.allReleases.filter((release) => {
						return release.label === state.release.label;
					});
					var foundExistingRelease = foundExistingReleases[0];

					if (foundExistingRelease) {
						// obj.release = {value:foundExistingRelease.id, label:foundExistingRelease.name}
						obj.release = foundExistingRelease;
					} else {
						obj.release = [];
					}
				}
			}

			return obj;

		case 'REDIRECT':
			return { ...state, redirectTo: null };

		default:
			return state;
	}
};

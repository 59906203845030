import agent from '../../agent';
import React from 'react';
import { connect } from 'react-redux';

import { ModalController } from '../ModalController';
import Modal from '../Modal';

import './index.scss';

const mapStateToProps = state => ({
	appName: state.app.appName,
	token: state.app.token,
	currentUser: state.users.currentUser,
});

const mapDispatchToProps = dispatch => ({
	onRedirect: () => dispatch({ type: 'REDIRECT' }),
});

// TODO: remove!

class PageView extends React.Component {
	constructor() {
		super();
	}

	componentWillMount() {
		// console.log('[PageView.componentWillMount]', this.props.currentUser);

		// TODO: determine current workspace when worskpace switch logic is in place

		if (this.props.currentUser) {
			agent.Socket.emit('join', 'workspace-' + this.props.currentUser.selectedWorkspace);
			// agent.Socket.emit("join", "user-" + this.props.currentUser._id);
		}

		// this.detectModal(this.props.match);
	}

	componentDidUpdate(nextProps) {
		// console.log('[PageView.componentDidUpdate]', nextProps);
		// let { match } = nextProps;
		// this.detectModal(match);
	}

	detectModal = match => {
		// let { match } = nextProps;

		// consider this a modal
		if (match && !match.isExact) {
			this.isModal = true;

			// TODO: hide Timer Menu
			if (this.props.onHideTimerMenu) this.props.onHideTimerMenu();
		} else {
			this.isModal = false;
		}
	};

	componentWillUnmount() {
		agent.Socket.off('update');

		if (this.props.currentUser) {
			agent.Socket.emit('leave', 'workspace-' + this.props.currentUser.selectedWorkspace);
			agent.Socket.emit('leave', 'user-' + this.props.currentUser._id);
		}
		// agent.Socket.off('update-timer');
	}

	/*componentWillReceiveProps(nextProps) {
		// console.log('[PageView.componentWillReceiveProps]', nextProps);

		if (nextProps.redirectTo) {
			// console.log('[PageView.componentWillReceiveProps] redirecting to', nextProps.redirectTo);

			// this.context.router.replace(nextProps.redirectTo);
			this.props.history.push(nextProps.redirectTo);
			return this.props.onRedirect();
		}
	}*/

	closeModal = () => {
		// this.props.history.push(`${this.props.match.path}/`);
	};

	render() {
		return (
			<div className={'page-view'}>
				{' '}
				{/*flex-body ' + (this.props.className ? this.props.className : '')*/}
				<div className={'page-content ' + (this.props.contentClassName ? this.props.contentClassName : '')}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

// export default PageView
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PageView);
// export default connect(mapDispatchToProps)(PageView);

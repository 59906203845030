import React, { useEffect, useRef } from 'react';

const useKeepKanbanTasksUpToDate = (props) => {
	let keepDataUpToDateID = useRef();
	let viewsKanbanInfo = useRef(); // Props are stale inside setInterval function, so that is why this ref is needed.
	viewsKanbanInfo.current = props.viewsKanbanInfo;

	useEffect(() => {
		if (props.views.length > 0) {
			keepDataUpToDate();
		}
	}, [props.views]);

	// componentWillUnmount
	useEffect(() => {
		return () => {
			if (keepDataUpToDateID.current) {
				clearInterval(keepDataUpToDateID.current);
				keepDataUpToDateID.current = false;
			}
		};
	}, []);

	const keepDataUpToDate = () => {
		const time = 300000;
		clearInterval(keepDataUpToDateID.current);
		keepDataUpToDateID.current = false;
		keepDataUpToDateID.current = setInterval(() => {
			for (const view of props.views) {
				props.getTasksByViewByStatus(
					view._id,
					0,
					true,
					null,
					null,
					viewsKanbanInfo?.current[view._id]?.nextSkip ?? 5
				);
			}
		}, time);
	};
};

export default useKeepKanbanTasksUpToDate;

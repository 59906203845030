// Makes sure that action and reducers use the same strings.
import _ from 'lodash';
import agent from '../../agent';

import {
	TASKS_GET_TASKS_SUCCESS,
	TASKS_GET_TASKS_ERROR,
	TASKS_GET_ALL_TASKS_SUCCESS,
	TASKS_GET_ALL_TASKS_ERROR,
	TASKS_GET_TASK_SUCCESS,
	TASKS_GET_TASK_ERROR,
	TASKS_GET_TASK_RESET,
	TASKS_UPDATE_TASK_SUCCESS,
	TASKS_UPDATE_TASK_ERROR,
	TASKS_CREATE_TASK_SUCCESS,
	TASKS_CREATE_TASK_ERROR,
	TASKS_GET_TASK_NO_ESTIMATES,
	TASKS_TASKS_UPDATE_SUCCESS,
	TASKS_TASKS_UPDATE_ERROR,
	TASKS_GET_BY_VIEW_BY_PROJECT_SUCCESS,
	TASKS_GET_BY_VIEW_BY_PROJECT_ERROR,
} from './constants';

export const getTasksWithNoEstimatesByView = async (viewId) => {
	try {
		const { tasks, taskIds } = await agent.Tasks.noEstimatesByView(viewId);

		const tasksById = _.keyBy(tasks, '_id');

		return { type: TASKS_GET_TASK_NO_ESTIMATES, viewId, unestimatedTasks: taskIds, tasksById };
	} catch (error) {
		return { type: TASKS_GET_TASKS_ERROR, error };
	}
};

export const getTasksByViewByStatus = async (viewId, skip, empty, filters, tasksByStatuses, limit) => {
	try {
		const response = await agent.Tasks.getTasksByViewByStatus(viewId, limit ? limit : 5, skip);
		let newTasksByStatuses = {};
		if (empty) {
			newTasksByStatuses = response.tasksByStatuses;
		} else {
			newTasksByStatuses = tasksByStatuses || response.tasksByStatuses;
			for (let status in tasksByStatuses) {
				newTasksByStatuses[status] = newTasksByStatuses[status].concat(response.tasksByStatuses[status]);
			}
		}
		const taskIdsByStatuses = {};
		for (let status in newTasksByStatuses) {
			taskIdsByStatuses[status] = newTasksByStatuses[status].map((task) => task._id);
		}
		let taskList = [];
		for (let status in newTasksByStatuses) {
			taskList = taskList.concat(newTasksByStatuses[status]);
		}
		const tasks = _.unionBy(taskList, '_id');
		const tasksById = _.keyBy(tasks, '_id');
		const data = {
			type: TASKS_GET_TASKS_SUCCESS,
			filters,
			tasksById,
		};
		data[viewId] = {
			tasksByStatuses: newTasksByStatuses,
			taskIdsByStatuses,
			statuses: response.statuses,
			nextSkip: response.nextSkip,
			currentSkip: response.currentSkip,
		};
		data.viewKanbanInfo = {
			_id: viewId,
			tasksByStatuses: newTasksByStatuses,
			taskIdsByStatuses,
			statuses: response.statuses,
			nextSkip: response.nextSkip,
			currentSkip: response.currentSkip,
		};
		return data;
	} catch (error) {
		return { type: TASKS_GET_TASKS_ERROR, error };
	}
};

// used in gantt chart - DO NOT AMEND unless discussed with DA / JS
export const getTasksByViewByProject = async (viewId) => {
	// console.clear();
	// console.log('getTasksByView', viewId);

	try {
		const { tasks, tasksCount, projectsById, projects } = await agent.Tasks.getTasksByViewByProject(viewId);

		const tasksById = _.keyBy(tasks, '_id');
		const viewTasks = {};

		viewTasks[viewId] = {
			tasks,
			tasksByProject: projects,
		};

		return { type: TASKS_GET_BY_VIEW_BY_PROJECT_SUCCESS, tasksById, viewTasks, viewId };
	} catch (error) {
		return { type: TASKS_GET_BY_VIEW_BY_PROJECT_ERROR, error };
	}
};

export const getAllTasks = async () => {
	try {
		const response = await agent.Tasks.all();
		const allTasks = response.tasks;
		return {
			type: TASKS_GET_ALL_TASKS_SUCCESS,
			allTasks,
		};
	} catch (error) {
		return { type: TASKS_GET_ALL_TASKS_ERROR, error };
	}
};

export const getTask = async (_id) => {
	try {
		const { task: returnedTask } = await agent.Tasks.get(_id);
		let obj = {},
			task = { ...returnedTask },
			modifiedTask = {};
		if (returnedTask.story) {
			if (!returnedTask.story.isDefault) {
				modifiedTask.story = { value: returnedTask.story._id, label: returnedTask.story.title };
			}
			if (returnedTask.story.release) {
				modifiedTask.release = {
					value: returnedTask.story.release._id,
					label: returnedTask.story.release.name,
				};
				task.release = returnedTask.story.release;
				if (returnedTask.project) {
					modifiedTask.project = {
						value: returnedTask.project._id,
						label: returnedTask.project.title,
						type: 'project',
						objectData: returnedTask.project,
					};
					task.project = returnedTask.project;
				}
			}
		}
		modifiedTask.assignedTo = returnedTask.assignedTo
			? {
					value: returnedTask.assignedTo._id,
					label: returnedTask.assignedTo.fullName,
					type: 'user',
					objectData: returnedTask.assignedTo,
			  }
			: undefined;
		if (returnedTask.status) {
			modifiedTask.status = { value: returnedTask.status._id, label: returnedTask.status.name };
		}
		if (returnedTask.category) {
			modifiedTask.category = { value: returnedTask.category._id, label: returnedTask.category.name };
		}
		modifiedTask.priority = returnedTask.priority
			? { value: returnedTask.priority._id, label: returnedTask.priority.name }
			: undefined;
		modifiedTask.taskDependencies = returnedTask.taskDependencies
		obj = {
			task,
			modifiedTask,
		};
		return { type: TASKS_GET_TASK_SUCCESS, obj };
	} catch (error) {
		console.log('error: ', error);
		return { type: TASKS_GET_TASK_ERROR, error };
	}
};

export const getTaskReset = async () => {
	return { type: TASKS_GET_TASK_RESET };
};

// TODO: this should be updating the central store of tasks
export const updateTask = async (task) => {
	try {
		await agent.Tasks.update(task);
		return { type: TASKS_UPDATE_TASK_SUCCESS };
	} catch (error) {
		return { type: TASKS_UPDATE_TASK_ERROR, error };
	}
};

export const createTask = async (task) => {
	try {
		const response = await agent.Tasks.create(task);
		const newTaskId = response.task._id;
		return { type: TASKS_CREATE_TASK_SUCCESS, newTaskId };
	} catch (error) {
		return { type: TASKS_CREATE_TASK_ERROR, error };
	}
};

export const updateTasks = async (updates) => {
	try {
		const response = await agent.Tasks.updateBatch(updates);

		return { type: TASKS_TASKS_UPDATE_SUCCESS };
	} catch (error) {
		return { type: TASKS_TASKS_UPDATE_ERROR, error };
	}
};

// Makes sure that action and reducers use the same strings.

// Data loading
export const ALL_CATEGORIES_LOADED = 'ALL_CATEGORIES_LOADED';
export const ALL_STATUS_LOADED = 'ALL_STATUS_LOADED';
export const ALL_USERS_LOADED = 'ALL_USERS_LOADED';
export const ALL_PRIORITIES_LOADED = 'ALL_PRIORITIES_LOADED';
export const ALL_PROJECTS_LOADED = 'ALL_PROJECTS_LOADED';

export const GET_ALL_LISTED_DATA_SUCCESS = 'GET_ALL_LISTED_DATA_SUCCESS';
export const GET_ALL_LISTED_DATA_ERROR = 'GET_ALL_LISTED_DATA_ERROR';

export const REDIRECT_TO = 'REDIRECT_TO';
export const REDIRECT = 'REDIRECT';
export const SHOW_TIMER_MENU = 'SHOW_TIMER_MENU';
export const HIDE_TIMER_MENU = 'HIDE_TIMER_MENU';
export const ASYNC_START = 'ASYNC_START';

export const LOGIN = 'LOGIN';

export const SHOW_MODAL_NEW_TASK = 'SHOW_MODAL_NEW_TASK';
export const SHOW_MODAL_EXISTING_TASK = 'SHOW_MODAL_EXISTING_TASK';
export const SHOW_MODAL_NEW_STORY = 'SHOW_MODAL_NEW_STORY';
export const SHOW_MODAL_EXISTING_STORY = 'SHOW_MODAL_EXISTING_STORY';
export const SHOW_MODAL_NEW_RELEASE = 'SHOW_MODAL_NEW_RELEASE';
export const HIDE_MODAL = 'HIDE_MODAL';

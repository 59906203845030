import { SCHEDULE_SLOTS_GET_BY_VIEW_SUCCESS, SCHEDULE_SLOTS_GET_BY_VIEW_ERROR } from './constants';

const INITIAL_STATE = {
	scheduleSlotsById: {},
	scheduleSlotsByViewId: {},
	scheduleSlotsByTaskId: {},
	errors: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SCHEDULE_SLOTS_GET_BY_VIEW_SUCCESS:
			console.log(`${action.type}`, action);

			const originalViewContent = state.scheduleSlotsByViewId[action.viewId];
			const newViewContent = action.viewScheduleSlots[action.viewId];
			let updatedContent = {};
			let mergedViewContent = state.scheduleSlotsByViewId;
			let mergedTaskContent = state.scheduleSlotsByTaskId;

			// update the view content
			if (!originalViewContent || !originalViewContent.scheduleSlots) {
				updatedContent = newViewContent;
			} else {
				// prevent shallow copying;
				updatedContent.scheduleSlots = [...newViewContent.scheduleSlots, ...newViewContent.scheduleSlots];
				updatedContent.currentCount = newViewContent.currentCount;
				updatedContent.totalCount = newViewContent.totalCount;
			}

			// TODO sort incoming scheduleSlots by task
			for (const key in action.scheduleSlotsById) {
				const scheduleSlot = action.scheduleSlotsById[key];
				mergedTaskContent[scheduleSlot.task] = mergedTaskContent[scheduleSlot.task]
					? [...mergedTaskContent[scheduleSlot.task], scheduleSlot]
					: [scheduleSlot];
			}

			mergedViewContent[action.viewId] = updatedContent;

			return {
				...state,
				scheduleSlotsById: {
					...state.scheduleSlotsById,
					...action.scheduleSlotsById,
				},
				scheduleSlotsByTaskId: mergedTaskContent,
				scheduleSlotsByViewId: mergedViewContent,
			};
		case SCHEDULE_SLOTS_GET_BY_VIEW_SUCCESS:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		default:
			return state;
	}
};

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import './index.scss';

import IntervalModal from '../IntervalModal';
import ProjectModal from '../ProjectModal';
import ReleaseModal from '../ReleaseModal';
import SettingsModal from '../SettingsModal';

const getModal = function(path) {
	// console.log('[getModal]', path);

	//TODO: create array for available modal types to include in pattern
	// var pattern = '(interval|release|project|story|task)\\/(.+)';
	var pattern = '(^.+)(interval|release|project|story|task|settings)/(.+)';
	var reg = new RegExp(pattern, 'gi');
	var matchGroups = reg.exec(path);

	var settingsPattern = '(^.+)(settings)';
	var settingsReg = new RegExp(settingsPattern, 'gi');
	var settingsMatchGroups = settingsReg.exec(path);
	// console.log('settingsMatchGroups: ', settingsMatchGroups);
	if (settingsMatchGroups && settingsMatchGroups.length > 0) {
		return {
			baseUrl: settingsMatchGroups[1].replace(/\/$/, ''), // remove last '/' in url
			type: settingsMatchGroups[2],
			identifier: null,
		};
	}

	if (matchGroups && matchGroups.length > 0) {
		// console.log('[getModal] pattern:', pattern, ' matchGroups:', matchGroups);

		return {
			baseUrl: matchGroups[1].replace(/\/$/, ''), // remove last '/' in url
			type: matchGroups[2],
			identifier: matchGroups[3] !== 'new' ? matchGroups[3] : null,
		};
	} else {
		return false;
	}
};

class ModalController extends React.Component {
	static defaultProps = {};

	constructor() {
		super();

		this.state = {
			modal: {
				type: '',
				identifier: '',
			},
		};
	}

	componentWillMount() {
		this.modalLoad();
	}

	componentDidMount() {
		console.log('[ModalController.componentDidMount]', this.props);
		this.modalLoad();
	}

	componentWillReceiveProps(nextProps) {
		// console.log('[ModalController.componentWillReceiveProps]', this.props, nextProps);
		this.modalLoad(nextProps.location);
	}

	// TODO: utilise "should render" to call modalLoad() only if there is a url difference
	shouldComponentUpdate(nextProps) {
		// re-render if url's are different
		if (this.props.location.pathname !== nextProps.location.pathname) {
			// console.log('[ModalController.shouldComponentUpdate] YES give that bitch a re-render');
			return true;
		}

		// console.log('[ModalController.shouldComponentUpdate] NO UPDATE');
		return false;
	}

	componentWillUnmount() {
		this.setState({
			modal: {
				type: '',
				identifier: '',
			},
		});
	}

	modalLoad = location => {
		var url = location ? location.pathname : this.props.location.pathname;

		// console.log('[ModalController.modalLoad]', location);

		// TODO: determine modal type
		if (url) {
			var newModal = getModal(url);
			// this.modal = getModal(this.props.location.pathname);
			this.setState({ modal: newModal });
			// this.setProps({modal:getModal(this.props.location.pathname)});

			// console.log('[ModalController.modalLoad]', newModal);
		} else {
			console.warn('no location provided to ModalController');
		}
	};

	closeModal = () => {
		// console.log('[ModalController.closeModal]', this.props);

		// regex the path to correctly go back
		let myRegexp = /\/boards(\/\d+)?/g;
		// let myRegexp = /boards\/(\d+)/g;
		let match = myRegexp.exec(this.props.history.location.pathname);

		let backUrl = match ? match[0] : '/boards';

		console.log('[ModalController.closeModal] history');
		this.props.history.push(backUrl); // need to go back to previous view
	};

	render() {
		// console.log('[ModalController.render]', this.state.modal, this.props);
		if (!this.state.modal || !this.props.location) {
			return null;
		}

		return (
			<div
				className={
					'modal-controller' + (this.state.modal.type === 'settings' ? ' settings-modal-controller' : '')
				}>
				<div className="modal-bg" onClick={this.closeModal} />

				<div
					className={
						'modal-container' +
						(this.state.modal.type === 'interval' || this.state.modal.type === 'release'
							? ' lg-width-6 md-width-8'
							: '') +
						(this.state.modal.type === 'settings' ? ' settings-modal-container' : '')
					}>
					{(() => {
						switch (this.state.modal.type) {
							case 'release':
								return (
									<ReleaseModal
										id={this.state.modal.identifier}
										history={this.props.history}
										match={this.props.match}
										location={this.props.location}
										baseUrl={this.state.modal.baseUrl}
										onClose={this.closeModal}
									/>
								);
							case 'project':
								return (
									<ProjectModal
										projectSlug={this.state.modal.identifier}
										history={this.props.history}
										match={this.props.match}
										location={this.props.location}
										baseUrl={this.state.modal.baseUrl}
										onClose={this.closeModal}
									/>
								);
							case 'interval':
								return (
									<IntervalModal
										intervalId={this.state.modal.identifier}
										history={this.props.history}
										match={this.props.match}
										location={this.props.location}
										baseUrl={this.state.modal.baseUrl}
										onClose={this.closeModal}
									/>
								);
							case 'settings':
								return (
									<SettingsModal
										projectSlug={this.state.modal.identifier}
										history={this.props.history}
										match={this.props.match}
										location={this.props.location}
										baseUrl={this.state.modal.baseUrl}
										onClose={this.closeModal}
									/>
								);

							default:
								// TODO: this should redirect to notFound
								return null;
						}
					})()}
				</div>
			</div>
		);
	}
}

// wraps the component with a router, to provide location and history within props: https://reacttraining.com/react-router/web/api/withRouter
const ModalControllerWithRouter = withRouter(ModalController);

export { getModal, ModalControllerWithRouter as ModalController };

import React, { useState } from 'react';
import Select from 'react-select';

import './index.scss';
import Dashboard from './components/Dashboard';
import Kanban from './components/Kanban';
import FilterBar from './components/FilterBar';
import ExpGantt from './components/ExpGantt';

const View = (props) => {
	const [boardView, setBoardView] = useState({
		label: 'Kanban',
		value: 'kanban',
	});
	const toggleView = (view) => {
		setBoardView(view);
	};

	if (!props.view) {
		return null;
	}

	return (
		<div className="view">
			<div className="row vertical-align-middle">
				<div className="col-sm-4 col-md-3 col-lg-2">
					<Select
						className="react-select-primary trkr-margin-left-15"
						classNamePrefix="react-select-primary"
						onChange={toggleView}
						value={boardView}
						options={[
							{ value: 'kanban', label: 'Kanban' },
							{ value: 'overview', label: 'Overview' },
							{ value: 'gantt', label: 'Gantt' },
						]}
						placeholder="Board view"
					/>
				</div>
				<div className="col-sm-8 col-md-9 col-lg-6 col-lg-offset-1 xs-margin-top-10 md-margin-top-0">
					<FilterBar view={props.view} />
				</div>
			</div>

			{boardView.value === 'kanban' && (
				<div className="board-view active">
					<Kanban view={props.view} />
				</div>
			)}

			{boardView.value === 'overview' && (
				<div className="board-view active">
					<Dashboard view={props.view} />
				</div>
			)}

			{boardView.value === 'gantt' && (
				<div className="board-view active">
					<ExpGantt view={props.view} />
				</div>
			)}
		</div>
	);
};

export default View;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import agent from '../../../../../../../agent';
import { formatDate } from '../../../../../../../lib/Utils';

import ActivityEntry from './ActivityEntry';
import MessageEntry from './MessageEntry';
import CommitMessage from './CommitMessage';

import history from '../../../../../../../lib/History';
import { onShowModalExistingTask } from '../../../../../../App/actions';
import { getActivitiesByView } from '../../../../../../Activities/actions';

import './index.scss';

// TODO:
// X. load activity feed for the given view
// X. paginate / refresh the feed on scroll
// X. sub-components to structure as per design for different "activity" types
// X. reload recent messages every minute (frequency to be confirmed)
// X. if the message count is low, then scrolling won't be available - in this scenario we want to re-load
// 6. Pass ids only to activity sub components, for them to map to the activity redux store

const ActivityFeed = props => {
	const { viewId, view, currentUser, viewActivities, allActivitiesById } = props;
	const paginationDayCount = 5;
	const pollDataIntervalSeconds = 60000; // retrieve recent activities every minute

	const [activityFeed, setActivityFeed] = useState({ linear: [], byDate: [] });
	const [totalFeedEntryCount, setTotalFeedEntryCount] = useState(0);
	const [currentFeedEntryCount, setCurrentFeedEntryCount] = useState(0);

	const [mostRecentDate, setMostRecentDate] = useState({
		startDate: moment.utc(new Date()).startOf('day'),
		endDate: moment.utc(new Date()),
	});
	const [currentDatePagination, setCurrentDatePagination] = useState({
		startDate: moment
			.utc(new Date())
			.startOf('day')
			.subtract(paginationDayCount, 'days'),
		endDate: moment.utc(new Date()).endOf('day'),
	});

	// set interval here to poll for new recent messages
	// NOTE: this is called when any changes occur to the view (e.g. filter change)
	useEffect(
		() => {
			// console.log('ActivityFeed view has changed');

			// NOTE: if the view has changed we should clear previous activity
			setActivityFeed({ linear: [], byDate: [] });
			setTotalFeedEntryCount(0);

			// update the pagination dates - any changes to this will call for data reloading
			updatePagination(true);

			// frequent data polling for recent messages
			const interval = setInterval(() => {
				console.warn('POLLING DATA');
				updateMostRecent();
			}, pollDataIntervalSeconds);

			return () => {
				clearInterval(interval);
			};
		},
		[view]
	);

	// data reload on pagination changes (from scrolling)
	useEffect(
		() => {
			console.log('[ActivityFeed] pagination updated');
			loadFeed();
		},
		[currentDatePagination]
	);

	// data reload from setInterval
	useEffect(
		() => {
			console.log('[ActivityFeed] interval loading data');
			loadFeed(true);
		},
		[mostRecentDate]
	);

	// if changes have occurred for the view activities assigned to this instance, then update the local state
	useEffect(
		() => {
			if (!viewActivities) {
			} else {
				const { feed, feedByDate, feedByDateSorted, totalCount, currentCount } = viewActivities;

				let currentLoadedCount = 0;

				// run through and count messages
				for (let dateGroup in feedByDate) {
					currentLoadedCount += feedByDate[dateGroup].length;
				}

				// If the returned data lenth is low, but there is more to load (from totalCount)
				// then call again for another time period
				// this avoids scenarios where there is 1 message in display preventing scroll to be available, and also if a date range has no information, then we can skip to the next
				// TODO: currently causes an endless loop in some instances. To be reviewed
				if (currentCount === 0 && totalCount > currentFeedEntryCount) {
					return updatePagination(false);
				}

				setActivityFeed({
					linear: feed,
					byDate: feedByDateSorted,
				});

				console.log('feedByDateSorted', feedByDateSorted);

				setTotalFeedEntryCount(totalCount);
				setCurrentFeedEntryCount(currentLoadedCount);
			}
		},
		[viewActivities]
	);

	// triggered on interval, to poll for the most recent data
	const updateMostRecent = () => {
		setMostRecentDate({
			startDate: moment.utc(new Date()).startOf('day'),
			endDate: moment.utc(new Date()),
		});
	};

	const updatePagination = (initialLoad = false) => {
		// local references to pagination filters
		let startDate;
		let endDate;

		// re-calculate pagination dates
		if (initialLoad === false) {
			endDate = currentDatePagination.startDate;

			startDate = moment.utc(currentDatePagination.startDate).subtract(paginationDayCount, 'days');

			console.log(`loadFeed refresh ${JSON.stringify(currentDatePagination)}`);
		} else {
			// reset to the original timinigs
			// scenario - if the filters on the view have changed, we want to retrieve the most recent relevant activity for that

			startDate = moment
				.utc(new Date())
				.startOf('day')
				.subtract(paginationDayCount, 'days');

			endDate = moment.utc(new Date()).endOf('day');
		}

		setCurrentDatePagination({ startDate, endDate });
	};

	const loadFeed = async (mostRecent = false) => {
		// console.log(`[loadFeed] currentDatePagination ${JSON.stringify(currentDatePagination)}`);

		// if current data is null / 0 then we want to dump all prior related view activities from the redux store
		const dumpPreviousData = totalFeedEntryCount === 0;
		let paginationQuery = {};

		if (!mostRecent) {
			paginationQuery = {
				startDate: currentDatePagination.startDate.format().toString(),
				endDate: currentDatePagination.endDate.format().toString(),
			};
		} else {
			paginationQuery = {
				startDate: mostRecentDate.startDate.format().toString(),
				endDate: mostRecentDate.endDate.format().toString(),
			};
		}

		props.getActivitiesByView(viewId, paginationQuery, dumpPreviousData);
	};

	const handleScroll = event => {
		// console.log('[ActivityFeed.handleScroll]');
		// TODO: call the load function - with a parameter? so we know to re-calculate the pagination query

		let isBottom =
			event.currentTarget.scrollTop >= event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
		if (isBottom) {
			// console.log('Reached bottom');
			updatePagination();
		}
	};

	// keyValue with either be the relevant _id or workspaceCounter (legacy modal system)
	// NOTE: function is passed to child components
	const showModal = (type, keyValue) => {
		switch (type) {
			case 'project':
				history.push(`${history.location.pathname}/project/${String(keyValue).toLowerCase()}`);
			case 'story':
				history.push(`${history.location.pathname}/story/${String(keyValue).toLowerCase()}`);
			case 'task':
				return props.onShowModalExistingTask({ _id: keyValue });
		}
	};

	const renderEntryType = msg => {
		switch (msg.type) {
			case 'activity':
				return <ActivityEntry currentUser={currentUser} showModal={showModal} {...msg} />;
			case 'commit':
				return <CommitMessage message={msg} currentUser={currentUser} />;
			default:
				return <MessageEntry currentUser={currentUser} {...msg} showModal={showModal} />;
		}
	};

	return (
		<div className="activity-feed">
			<h4 className="font-color-white">
				Activity feed{' '}
				<span className="trkr-float-right text-right text-small">
					{currentFeedEntryCount} / {totalFeedEntryCount}
				</span>
			</h4>

			<div className="feed-wrapper trkr-margin-top-15" onScroll={handleScroll}>
				{activityFeed.byDate.map(dateGroup => {
					return (
						<div className="message-group" key={`message-group-${dateGroup.date}`}>
							<p className="feed-date-label">{formatDate(dateGroup.date, 'dddd, Do MMMM')}</p>

							{dateGroup.messages.map(msg => (
								<div className="row trkr-margin-top-10" key={`feed-entry-${msg._id}`}>
									<div className="col-sm-2">
										<p className="text-small">{formatDate(msg.createdAt, 'LT').toLowerCase()}</p>
									</div>

									<div className="col-sm-10">{renderEntryType(msg)}</div>
								</div>
							))}
						</div>
					);
				})}
			</div>
		</div>
	);
};

// TODO: move view mapping up to Dashboard
const mapStateToProps = (state, ownProps) => {
	function mapActivities() {
		return state.activities.activitiesByViewId ? state.activities.activitiesByViewId[ownProps.viewId] : null;
	}

	function mapView() {
		return state.views.views ? state.views.views.filter(view => view._id === ownProps.viewId)[0] : null;
	}

	let props = {
		viewActivities: mapActivities(),
		allActivitiesById: state.activities.activitiesById,
		view: mapView(),
		currentUser: state.users.currentUser,
	};

	return props;
};

const mapDispatchToProps = {
	onShowModalExistingTask,
	getActivitiesByView,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ActivityFeed);

import React from 'react';

import IntervalCard from '../IntervalCard';
import { formatDuration, formatDate, getIntervalsByDate } from '../../lib/Utils';

import './index.scss';

class IntervalList extends React.Component {
	static defaultProps = {
		task: {},
		actualTime: 0,
		estimate: 0,
		intervalList: [],
	};
	render() {
		const intervals = this.props?.task?.intervals ?? [];
		const intervalsByDate = getIntervalsByDate(intervals);
		return (
			<div className={`flex-container trkr-full-width ${this.props.className}`}>
				<div className="flex-header time-overview">
					<h4 className="trkr-full-width text-center">
						<span className={'' + (!this.props.actualTime ? 'font-italic text-small' : '')}>
							{this.props.actualTime ||
							(!intervals[0]?.endDateTime && intervals[0]?.task.id === this.props.task.id)
								? formatDuration(
										(this.props.actualTime || 0) +
											(!intervals[0]?.endDateTime && intervals[0]?.task.id === this.props.task.id
												? this.props.timer
												: 0)
								  )
								: 'NA'}
						</span>
						<span className={'' + (!this.props.estimate ? 'font-italic text-small' : '')}>
							{' '}
							/ {this.props.estimate ? formatDuration(this.props.estimate) : 'Not estimated'}
						</span>
					</h4>
				</div>

				<div className="flex-body scrollable-content">
					<div className="trkr-full-width">
						{intervalsByDate.map((group) => {
							return (
								<div className="date-group trkr-full-width" key={`date-group-${group.date}`}>
									<p className="date-label">{formatDate(group.date, 'dddd, Do MMMM')}</p>
									{group.intervals.map((interval) => {
										return (
											<IntervalCard
												style="timesheet"
												interval={interval}
												key={`intervalCard-${interval._id}`}
												className="trkr-full-width trkr-margin-bot-10"
												match={this.props.match}
											/>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default IntervalList;

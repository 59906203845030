import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

// import agent from '../../agent';
// import history from '../../lib/History';
import EstimatePrompt from './EstimatePrompt';
import ActivityFeed from './ActivityFeed';
import Column from '../Kanban/Column';
import UnreadMessages from './UnreadMessages';

// import { onShowModalNewTask, onShowModalExistingTask, onRedirect } from '../App/actions';

import { getTasksByViewByStatus } from '../../../../../Tasks/actions';

import './index.scss';

class Dashboard extends Component {
	loadNextTasks = (skip, filters, empty) => {
		if (typeof skip === 'undefined') {
			skip = this.props[this.props.view._id].nextSkip || 0;
		}
		const taskIdsByStatuses = this.props[this.props.view._id].taskIdsByStatuses;
		const tasksByStatuses = {};
		for (let status in taskIdsByStatuses) {
			tasksByStatuses[status] = taskIdsByStatuses[status].map(taskId => this.props.tasksById[taskId]);
		}
		this.props.getTasksByViewByStatus(this.props.view._id, skip, empty, filters, tasksByStatuses);
	};

	handleScroll = event => {
		let isBottom =
			event.currentTarget.scrollTop >= event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
		if (isBottom) {
			// console.log('Reached bottom');
			this.loadNextTasks();
		}
	};

	onDragEnd = () => {};

	render() {
		if (!this.props[this.props.view._id]) {
			return null;
		}
		const taskIdsByStatuses = this.props[this.props.view._id].taskIdsByStatuses;
		const status = this.props[this.props.view._id].statuses.filter(status => status.name === 'Todo')[0];
		let column = {
			title: status.name,
			name: status.name,
			_id: status._id,
		};
		const taskIds = taskIdsByStatuses[status.name];
		return (
			<div className="dashboard-wrapper">
				<div className="row">
					<div className="col-sm-12">
						<EstimatePrompt viewId={this.props.view._id} />
					</div>
				</div>

				<div className="row">
					<div className="col-sm-6 trkr-margin-top-30">
						<ActivityFeed viewId={this.props.view._id} />
					</div>

					<div className="col-sm-3">
						<DragDropContext onDragEnd={this.onDragEnd}>
							<Column
								key={`${this.props.view._id}-${status.name}-dashboard`}
								taskIds={taskIdsByStatuses[status.name]}
								column={column}
								handleScroll={this.handleScroll}
								showAddButton={false}
								showTimer={false}
								dashBoardHeight={true}
							/>
						</DragDropContext>
					</div>

					<div className="col-sm-3">
						<UnreadMessages />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state.tasks,
});

const mapDispatchToProps = {
	getTasksByViewByStatus,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);

import React from 'react';
// import { ToastContainer } from "react-toastr";
import Notifications, {notify} from 'react-notify-toast';

class ListErrors extends React.Component {
  render() {
    const { errors }  = this.props;
	let container;
	let show = notify.createShowQueue();

	// TODO: clear errors after received once,
	// 1. do this in reducer?

	// console.log('[ListErrors] errors:',this.props);
	if(errors){
		/*Object.keys(errors).map(key => {
		  return container.success(`hi! Now is ${new Date()}`, `///title\\\\\\`, {
	            closeButton: true,
	  		})
		})*/
		console.log('ListErrors',errors);
		for (var key in errors) {
		    if (errors.hasOwnProperty(key)) {
		        // do
		        show(key+' '+errors[key], "error");
		    }
		}
	}

	/*container.success(`hi! Now is ${new Date()}`, `///title\\\\\\`, {
          closeButton: true,
	})*/

	return (
		<Notifications options={{zIndex: 3000}} />
	)
  }
}

export default ListErrors;

import {
	INTERVALS_GET_USERS_INTERVALS_SUCCESS,
	INTERVALS_GET_USERS_INTERVALS_ERROR,
	INTERVALS_GET_USER_INTERVALS_DURATION_SUCCESS,
	INTERVALS_GET_USER_INTERVALS_DURATION_ERROR,
	INTERVALS_CREATE_INTERVAL_SUCCESS,
	INTERVALS_CREATE_INTERVAL_ERROR,
	INTERVALS_UPDATE_INTERVAL_SUCCESS,
	INTERVALS_UPDATE_INTERVAL_ERROR,
	INTERVALS_GET_SERVER_OFFSET_SUCCESS,
	INTERVALS_GET_SERVER_OFFSET_ERROR,
	INTERVALS_GET_RUNNING_BY_TASK,
	INTERVALS_TICK_RUNNING_INTERVAL
} from './constants';

import moment from 'moment';
const initialState = {
	intervals: [],
	intervalCreated: false,
	intervalUpdated: false,
	errors: [],
	runningIntervalsByTask:{}
};


let tickInterval;

export default (state = initialState, action) => {

	if (typeof action.success !== 'undefined' && !action.success){
		return {
			...state,
			errors: state.errors.concat([
				{
					body: action.error.toString(),
					time: new Date()
				},
			])
		}
	}

	switch (action.type) {
		case INTERVALS_TICK_RUNNING_INTERVAL:
			// console.log("JSI");
			let runningIntervalsByTask = state.runningIntervalsByTask;
			if (runningIntervalsByTask){
				for (let taskId in runningIntervalsByTask){
					let interval = runningIntervalsByTask[taskId];
					interval.elapsedMilliseconds = moment.duration(moment().diff(interval.startDateTime)).asMilliseconds();
					interval.elapsedMilliseconds = interval.elapsedMilliseconds + state.serverOffset;
					runningIntervalsByTask[taskId]= interval;
					// console.log("JSI", interval.elapsedMilliseconds);
				}
				// console.log("JSI", runningIntervalsByTask);
				return {
					...state,
					runningIntervalsByTask: runningIntervalsByTask
				}
			}else{
				return {
					...state
				}
			}
		case INTERVALS_GET_RUNNING_BY_TASK: 
			return {
				...state,
				runningIntervalsByTask: action.runningIntervalsByTask
			}
		case INTERVALS_GET_USERS_INTERVALS_SUCCESS:
			let updatedIntervals = state.intervals ? [...state.intervals, ...action.intervals] : action.intervals;
			if (action.empty) {
				updatedIntervals = action.intervals;
			}
			return {
				...state,
				nextSkip: action.nextSkip,
				currentSkip: action.currentSkip,
				intervals: updatedIntervals,
				intervalCreated: false,
				intervalUpdated: false,
			};
		case INTERVALS_GET_USERS_INTERVALS_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case INTERVALS_GET_USER_INTERVALS_DURATION_SUCCESS:
			return {
				...state,
				intervalsDuration: action.intervalsDuration,
				intervalCreated: false,
				intervalUpdated: false,
			};
		case INTERVALS_GET_USER_INTERVALS_DURATION_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case INTERVALS_CREATE_INTERVAL_SUCCESS:
			return {
				...state,
				intervalCreated: true,
			};
		case INTERVALS_CREATE_INTERVAL_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case INTERVALS_UPDATE_INTERVAL_SUCCESS:
			return {
				...state,
				intervalUpdated: true,
			};
		case INTERVALS_UPDATE_INTERVAL_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case INTERVALS_GET_SERVER_OFFSET_SUCCESS:
			return {
				...state,
				serverOffset: action.serverOffset,
			};
		case INTERVALS_GET_SERVER_OFFSET_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		default:
			return state;
	}
};

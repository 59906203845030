import { PRIORITIES_ADD_PRIORITY_SUCCESS, PRIORITIES_ADD_PRIORITY_ERROR } from './constants';

const initialState = {
	errors: [],
	newPriority: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case PRIORITIES_ADD_PRIORITY_SUCCESS:
			return {
				...state,
				newPriority: action.newPriority,
			};
		case PRIORITIES_ADD_PRIORITY_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
	}
	return state;
};

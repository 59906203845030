export const TASKS_GET_TASKS_SUCCESS = 'TASKS_GET_TASKS_SUCCESS';
export const TASKS_GET_TASKS_ERROR = 'TASKS_GET_TASKS_ERROR';
export const TASKS_GET_ALL_TASKS_SUCCESS = 'TASKS_GET_ALL_TASKS_SUCCESS';
export const TASKS_GET_ALL_TASKS_ERROR = 'TASKS_GET_ALL_TASKS_ERROR';
export const TASKS_GET_TASK_SUCCESS = 'TASKS_GET_TASK_SUCCESS';
export const TASKS_GET_TASK_ERROR = 'TASKS_GET_TASK_ERROR';
export const TASKS_UPDATE_TASK_SUCCESS = 'TASKS_UPDATE_TASK_SUCCESS';
export const TASKS_UPDATE_TASK_ERROR = 'TASKS_UPDATE_TASK_ERROR';
export const TASKS_CREATE_TASK_SUCCESS = 'TASKS_CREATE_TASK_SUCCESS';
export const TASKS_CREATE_TASK_ERROR = 'TASKS_CREATE_TASK_ERROR';
export const TASKS_GET_TASK_RESET = 'TASKS_GET_TASK_RESET';
export const TASKS_GET_TASK_NO_ESTIMATES = 'TASKS_GET_TASK_NO_ESTIMATES';
export const TASKS_TASKS_UPDATE_SUCCESS = 'TASKS_TASKS_UPDATE_SUCCESS';
export const TASKS_TASKS_UPDATE_ERROR = 'TASKS_TASKS_UPDATE_ERROR';

export const TASKS_GET_BY_VIEW_BY_PROJECT_SUCCESS = 'TASKS_GET_BY_VIEW_BY_PROJECT_SUCCESS';
export const TASKS_GET_BY_VIEW_BY_PROJECT_ERROR = 'TASKS_GET_BY_VIEW_BY_PROJECT_ERROR';

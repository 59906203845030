import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';

import agent from '../../agent';

import FileUploader from '../FileUploader';

import {
	createProvisionalEmail,
	createProvisionalEmailReset,
	checkPassword,
	checkPasswordReset,
	createNewPassword,
	createNewPasswordReset,
	addNewImage,
	addNewImageReset,
	addNewName,
	addNewNameReset,
	addEmailNotifications,
	addEmailNotificationsReset,
	getCurrentUser,
} from '../Users/actions';

import './index.scss';

class Profile extends Component {
	state = {
		showEmailForm: false,
		email: '',
		newEmail: '',
		newEmailValid: true,
		password: '',
		showPasswordForm: false,
		currentPassword: '',
		newPassword: '',
		showNameForm: false,
		image: '',
		fullName: '',
		newFullName: '',
		allowEmailNotifications: false,
		selectedSubscription: {},
	};
	componentDidMount() {
		if (this.props.currentUser) {
			this.setState({
				email: this.props.currentUser.email,
				image: this.props.currentUser.image,
				fullName: this.props.currentUser.fullName,
				allowEmailNotifications: this.props.currentUser.allowEmailNotifications,
			});
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.currentUser !== prevProps.currentUser && this.props.currentUser) {
			this.setState({
				email: this.props.currentUser.email,
				image: this.props.currentUser.image,
				fullName: this.props.currentUser.fullName,
				allowEmailNotifications: this.props.currentUser.allowEmailNotifications,
			});
		}
		if (this.props.provisionalEmailCreated) {
			notify.show('An email has been sent to your new account.', 'success');
			this.props.createProvisionalEmailReset();
		}
		if (this.props.passwordCorrect) {
			this.props.checkPasswordReset();
			this.props.createNewPassword(this.state.newPassword);
		}
		if (this.props.passwordCreated) {
			notify.show('Your password has been successfully changed.', 'success');
			this.props.createNewPasswordReset();
		}
		if (this.props.imageAdded) {
			this.props.addNewImageReset();
			this.props.getCurrentUser();
		}
		if (this.props.nameAdded) {
			notify.show('Your name has been successfully changed.', 'success');
			this.props.addNewNameReset();
			this.props.getCurrentUser();
		}
		if (this.props.addedEmailNotification) {
			notify.show('Your email notifications preference has been updated.', 'success');
			this.props.addEmailNotificationsReset();
			this.props.getCurrentUser();
		}
	}
	toggleShowEmailForm = () => {
		this.setState(prevState => ({
			showEmailForm: !prevState.showEmailForm,
		}));
	};
	changeEmail = event => {
		const newEmail = event.target.value;
		const emailRegex = new RegExp(`^[^\\.\\s@:](?:[^\\s@:]*[^\\s@:\\.])?@[^\\.\\s@]+(?:\\.[^\\.\\s@]+)*$`);
		const newEmailValid = emailRegex.test(newEmail);
		this.setState({ newEmail, newEmailValid });
	};
	changePassword = event => {
		const password = event.target.value;
		this.setState({ password });
	};
	submitEmailForm = event => {
		event.preventDefault();
		this.props.createProvisionalEmail(this.state.newEmail, this.state.password);
		this.toggleShowEmailForm();
	};
	toggleShowPasswordForm = () => {
		this.setState(prevState => ({
			showPasswordForm: !prevState.showPasswordForm,
		}));
	};
	changeCurrentPassword = event => {
		const currentPassword = event.target.value;
		this.setState({ currentPassword });
	};
	changeNewPassword = event => {
		const newPassword = event.target.value;
		this.setState({ newPassword });
	};
	submitPasswordForm = event => {
		event.preventDefault();
		this.props.checkPassword(this.state.currentPassword);
		this.toggleShowPasswordForm();
	};
	returnedFiles = files => {
		const filename = files[0].filename;
		this.props.addNewImage(filename);
	};
	toggleShowNameForm = () => {
		this.setState(prevState => ({
			showNameForm: !prevState.showNameForm,
		}));
	};
	changeName = event => {
		const newFullName = event.target.value;
		this.setState({ newFullName });
	};
	submitNameForm = event => {
		event.preventDefault();
		this.props.addNewName(this.state.newFullName);
		this.toggleShowNameForm();
	};
	submitEmailNotifications = event => {
		this.props.addEmailNotifications(event.target.checked);
	};
	renderProfileImageForm = () => {
		return (
			<div className="profile-image">
				<h4 className="profile-image-title">My Profile</h4>
				<div className="profile-image-container">
					<img
						src={
							this.state && this.state.image
								? `${agent.API_ROOT}/attachments/${this.state.image.split('.')[0]}-120.jpg`
								: 'https://via.placeholder.com/90'
						}
						alt="image"
					/>
					<FileUploader returnedFiles={file => this.returnedFiles(file)} settings={true} />
					<h5>{this.state.fullName}</h5>
				</div>
			</div>
		);
	};
	renderNameForm = () => {
		return (
			<div className="profile-name">
				<div className="profile-name-row">
					<h5 className="profile-name-row-title">Name: </h5>
					<p className="profile-name-row-text">{this.state.fullName}</p>
					<div className="col-xs-offset-5 profile-name-row-change-button">
						<div className="profile-name-row-change-button-text" onClick={this.toggleShowNameForm}>
							Change
						</div>
						<span className="glyphicon glyphicon-edit" />
					</div>
				</div>
				{this.state.showNameForm ? (
					<form className="form-horizontal profile-name-form" onSubmit={this.submitNameForm}>
						<div className="form-group profile-name-form-group">
							<label className="col-xs-3" htmlFor={'name'}>
								New name:
							</label>
							<div className="col-xs-7">
								<input
									className="form-control"
									type="name"
									name={'name'}
									required={true}
									placeholder="name"
									value={this.state.newFullName}
									onChange={this.changeName}
								/>
							</div>
							<div className="invalid-feedback" />
						</div>
						<div className="row">
							<div className="col-xs-2 col-xs-offset-8">
								<button className="trkr-btn profile-name-form-update-button" type="submit">
									Update
								</button>
							</div>
						</div>
					</form>
				) : null}
			</div>
		);
	};
	renderEmailForm = () => {
		return (
			<div className="profile-email">
				<div className="profile-email-row">
					<h5 className="profile-email-row-title">Email: </h5>
					<p className="profile-email-row-text">{this.state.email}</p>
					<div className="col-xs-offset-5 profile-email-row-change-button">
						<div className="profile-email-row-change-button-text" onClick={this.toggleShowEmailForm}>
							Change
						</div>
						<span className="glyphicon glyphicon-edit" />
					</div>
				</div>
				{this.state.showEmailForm ? (
					<form className="form-horizontal profile-email-form" onSubmit={this.submitEmailForm}>
						<div className="form-group profile-email-form-group">
							<label className="col-xs-3" htmlFor={'profile-email'}>
								New email:
							</label>
							<div className="col-xs-7">
								<input
									className="form-control"
									type="email"
									name={'profile-email'}
									required={true}
									placeholder="email"
									value={this.state.newEmail}
									onChange={this.changeEmail}
								/>
							</div>
							<div className="col-xs-2">
								{this.state.newEmailValid === false ? (
									<p>
										<span className="glyphicon glyphicon-remove" />
										Email invalid!
									</p>
								) : null}
							</div>
						</div>
						<div className="form-group profile-email-form-group">
							<label className="col-xs-3" htmlFor={'profile-password'}>
								Password:
							</label>
							<div className="col-xs-7">
								<input
									className="form-control"
									type="password"
									name={'profile-password'}
									required={true}
									placeholder="password"
									value={this.state.password}
									onChange={this.changePassword}
								/>
							</div>
							<div className="invalid-feedback" />
						</div>
						<div className="row">
							<div className="col-xs-2 col-xs-offset-8">
								<button className="trkr-btn profile-email-form-update-button" type="submit">
									Update
								</button>
							</div>
						</div>
					</form>
				) : null}
			</div>
		);
	};
	renderPasswordForm = () => {
		return (
			<div className="profile-password">
				<div className="profile-password-row">
					<h5 className="profile-password-row-title">Password: </h5>
					<p className="profile-password-row-text">***********</p>
					<div className="col-xs-offset-5 profile-password-row-change-button">
						<div className="profile-password-row-change-button-text" onClick={this.toggleShowPasswordForm}>
							Change
						</div>
						<span className="glyphicon glyphicon-edit" />
					</div>
				</div>
				{this.state.showPasswordForm ? (
					<form className="form-horizontal profile-password-form" onSubmit={this.submitPasswordForm}>
						<div className="form-group profile-password-form-group">
							<label className="col-xs-3" htmlFor={'current-password'}>
								Old password:
							</label>
							<div className="col-xs-7">
								<input
									className="form-control"
									type="password"
									name={'current-password'}
									required={true}
									placeholder="password"
									value={this.state.currentPassword}
									onChange={this.changeCurrentPassword}
								/>
							</div>
							<div className="invalid-feedback" />
						</div>
						<div className="form-group profile-password-form-group">
							<label className="col-xs-3" htmlFor={'new-password'}>
								New password:
							</label>
							<div className="col-xs-7">
								<input
									className="form-control"
									type="password"
									name={'new-password'}
									required={true}
									placeholder="password"
									value={this.state.newPassword}
									onChange={this.changeNewPassword}
								/>
							</div>
							<div className="invalid-feedback" />
						</div>
						<div className="row">
							<div className="col-xs-2 col-xs-offset-8">
								<button className="trkr-btn profile-email-form-update-button" type="submit">
									Update
								</button>
							</div>
						</div>
					</form>
				) : null}
			</div>
		);
	};
	renderEmailNotificationsForm = () => {
		return (
			<div className="profile-notification">
				<h5 className="profile-notification-title">Receive email notification: </h5>
				<p className="profile-notification-text">{this.state.allowEmailNotifications ? 'On' : 'Off'}</p>
				<div className="profile-notification-custom-checkbox">
					<label>
						<input
							type="checkbox"
							checked={this.state.allowEmailNotifications || false}
							onChange={this.submitEmailNotifications}
						/>
						<span />
					</label>
				</div>
			</div>
		);
	};
	render() {
		return (
			<div className="profile">
				{this.renderProfileImageForm()}
				{this.renderNameForm()}
				{this.renderEmailForm()}
				{this.renderPasswordForm()}
				{/* {this.renderEmailNotificationsForm()} */}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	provisionalEmailCreated: state.users.provisionalEmailCreated,
	passwordCorrect: state.users.passwordCorrect,
	passwordCreated: state.users.passwordCreated,
	imageAdded: state.users.imageAdded,
	nameAdded: state.users.nameAdded,
	addedEmailNotification: state.users.addedEmailNotification,
	currentUser: state.users.currentUser,
});

export default connect(
	mapStateToProps,
	{
		createProvisionalEmail,
		createProvisionalEmailReset,
		checkPassword,
		checkPasswordReset,
		createNewPassword,
		createNewPasswordReset,
		addNewImage,
		addNewImageReset,
		addNewName,
		addNewNameReset,
		addEmailNotifications,
		addEmailNotificationsReset,
		getCurrentUser,
	}
)(Profile);

export const SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_SUCCESS';
export const SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_ERROR = 'SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_ERROR';
export const SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_SUCCESS =
	'SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_ERROR = 'SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_ERROR';
export const SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_RESET = 'SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_RESET';
export const SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_ERROR = 'SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_ERROR';
export const SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_RESET = 'SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_RESET';
export const SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_SUCCESS = 'SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_SUCCESS';
export const SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_ERROR = 'SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_ERROR';
export const SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_ERROR = 'SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_ERROR';

import agent from '../../agent';

import { PRIORITIES_ADD_PRIORITY_SUCCESS, PRIORITIES_ADD_PRIORITY_ERROR } from './constants';

export const addPriority = async name => {
	try {
		const { priority } = await agent.Priorities.create({ name });
		return { type: PRIORITIES_ADD_PRIORITY_SUCCESS, newPriority: priority };
	} catch (error) {
		return { type: PRIORITIES_ADD_PRIORITY_ERROR, error };
	}
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';
import { notify } from 'react-notify-toast';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import Form from '../Form';
import Modal from '../Modal';
import { Option, SingleValue } from '../ReactSelect';
import ProjectTag from '../Icons/ProjectTag';

import { formatOption, mapProjectOptions } from '../../lib/Utils';

import { addProject } from '../Projects/actions';

import { createRelease, getRelease } from '../Releases/actions';

import { onHideModal } from '../App/actions';

import './index.scss';

class NewReleaseModal extends Component {
	state = {
		name: '',
		project: null,
		dueDate: moment(),
		description: '',
		release: null,
	};
	constructor() {
		super();
		this.showToastr = notify.createShowQueue();
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.newProjectAddedToStore(prevProps)) {
			this.setState(state => {
				return {
					...state,
					project: formatOption(this.props.newProject, 'project'),
				};
			});
		}
		if (this.newReleaseAddedToStore(prevProps)) {
			console.log('zak: ', this.props);
			// setTimeout(() => {
			this.props?.newRelease?._id && this.props.getRelease(this.props.newRelease._id);
			// }, 500);
		}
		if (this.releaseAddedToStore(prevProps)) {
			this.setState(state => {
				return {
					...state,
					release: this.props.release,
				};
			});
		}
	}
	newProjectAddedToStore = prevProps => {
		return prevProps.newProject !== this.props.newProject;
	};
	newReleaseAddedToStore = prevProps => {
		return prevProps.newRelease !== this.props.newRelease;
	};
	releaseAddedToStore = prevProps => {
		return prevProps.release !== this.props.release;
	};
	changeName = ev => {
		const name = ev.target.value;
		this.setState(state => {
			return {
				...state,
				name,
			};
		});
	};
	changeProject = newValue => {
		if (!newValue.value) {
			return;
		}
		this.setState(state => {
			return {
				...state,
				project: newValue,
			};
		});
	};
	changeDueDate = date => {
		const dueDate = date;
		this.setState(state => {
			return {
				...state,
				dueDate,
			};
		});
	};
	changeDescription = ev => {
		const description = ev.target.value;
		this.setState(state => {
			return {
				...state,
				description,
			};
		});
	};
	submitForm = async () => {
		// check all is valid
		if (!this.state.name || (!this.state.project || !this.state.project.value)) {
			this.showToastr('Please provide required information', 'error');
			return;
		}
		const release = {
			name: this.state.name,
			project: this.state.project.value,
			dueDate: this.state.dueDate,
			description: this.state.description,
		};
		console.log('zak release: ', release);
		await this.props.createRelease(release);
	};
	renderForm = () => {
		return (
			<Form submit={this.submitForm}>
				<div className="form-group">
					<label htmlFor={'name'}>Release name</label>
					<input
						autoFocus
						className="form-control"
						type="text"
						name={'name'}
						required={true}
						placeholder="Release name"
						value={this.state.name}
						onChange={this.changeName}
					/>
					<div className="invalid-feedback" />
				</div>
				<div className="form-group">
					<label htmlFor={'project'}>Project</label>
					<CreatableSelect
						className="react-select-custom"
						classNamePrefix="react-select-custom"
						components={{ Option, SingleValue }}
						name={'project'}
						required={true}
						onChange={this.changeProject}
						value={this.state.project}
						options={this.props.projectOptions}
						onCreateOption={str => this.props.addProject(str)}
						placeholder="Select a project"
					/>
					<div className="invalid-feedback" />
				</div>
				<div className="form-group">
					<label htmlFor={'dueDate'}>Due</label>
					<DatePicker
						className="form-control"
						selected={this.state?.dueDate}
						onChange={this.changeDueDate}
						showTimeSelect
						name="dueDate"
						timeFormat="HH:mm"
						timeIntervals={15}
						dateFormat="LLL"
						locale="en-GB"
					/>
					<div className="invalid-feedback" />
				</div>
				<div className="form-group">
					<label htmlFor={'description'}>Description</label>
					<textarea
						className="form-control"
						rows="5"
						name={'description'}
						placeholder="As a user ..."
						value={this.state.description}
						onChange={this.changeDescription}
					/>
					<div className="invalid-feedback" />
				</div>
			</Form>
		);
	};
	render() {
		return (
			<Modal releaseSuccessModal={this.state.release}>
				<div
					className={
						'flex-container trkr-padding-lr-60 xs-trkr-padding-lr-30 md-display-flex col-md-12' +
						(this.state.showMobilePanel ? ' hide' : '')
					}>
					<div className="modal-header flex-header md-trkr-padding-tb-15">
						<div className="row vertical-align-middle">
							<div className="col-xs-12">
								<h5 className="header-title trkr-margin-top-0">
									Create a new release
									<button
										className="trkr-icon-close trkr-btn-close trkr-float-right"
										onClick={this.props.onHideModal}
									/>
								</h5>
							</div>
						</div>
					</div>
					{!this.state.release ? (
						<Fragment>
							<div className={'flex-body'}>
								<div className="scrollable-content trkr-full-width trkr-margin-top-30">
									{this.renderForm()}
								</div>
							</div>
							<div className="flex-footer">
								<div className="trkr-full-width trkr-padding-tb-10 bg-grey-2" style={{ zIndex: 1 }}>
									<div className="row vertical-align-middle">
										<div className="col-xs-12 text-right">
											<button
												className="trkr-btn"
												type="submit"
												disabled={this.props.inProgress}
												onClick={() => {
													this.submitForm();
												}}>
												Create Release
											</button>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					) : (
						<div className="release-modal">
							<h3 className="release-modal-title">You have successfully created a new release</h3>
							<h3 className="release-modal-name transparent-75">{this.state.release.name}</h3>
							<div className="release-modal-project">
								<ProjectTag
									projectTitle={this.state.release.project.title}
									projectColour={this.state.release.project.tagColour}
								/>
							</div>
						</div>
					)}
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		projectOptions: mapProjectOptions(state.projects.allProjects),
		newProject: state.projects.newProject,
		newRelease: state.releases.newRelease,
		release: state.releases.release,
	};
};

const mapDispatchToProps = {
	addProject,
	onHideModal,
	createRelease,
	getRelease,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NewReleaseModal);

import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import TaskCard from '../../../../../TaskCard';

const Task = props => {
	// console.log('Task', props.task);
	return (
		<Draggable draggableId={props.taskId} index={props.index}>
			{(provided, snapshot) => (
				<div
					className={`${
						snapshot.isDragging ? 'task-container-dragging-background' : 'task-container-normal-background'
					}`}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}>
					<TaskCard taskId={props.taskId} showTimer={props.showTimer} viewId={props.viewId} />
				</div>
			)}
		</Draggable>
	);
};

export default Task;

import _ from 'lodash';
import agent from '../../../../../../agent';

import { GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_SUCCESS, GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_ERROR, GANTT_EXPAND_ITEM_SUCCESS, GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_FOR_PROJECT_SUCCESS, GANTT_GET_TASKS_BY_STORY_FOR_VIEW_SUCCESS, GANTT_GET_PROJECT_FOR_VIEW, GANTT_GET_PROJECT_FOR_VIEW_ERROR, GANTT_UPDATE_SCHEDULE_SLOT } from './constants';

export const getTasksByProjectForView = async (viewId, query) => {
	// console.clear();
	console.log('getTasksByProjectForView', viewId, query);
	
	try {
		console.log("calling agent getTasksByProjectForView");
		let tasksByProjectForView = await agent.ExpGantt.getTasksByProjectForView(viewId, query);
		console.log("getTasksByProjectForView response", tasksByProjectForView);
		return { type: GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_SUCCESS, tasksByProjectForView, viewId };
	} catch (error) {
		return { type: GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_ERROR, error };
	}
};

export const getProjectForView = async (viewId, projectId) => {
	console.log('getTasksByProjectForView', viewId, projectId);
	
	try {
		console.log("calling agent getTasksByProjectForView");
		let project = await agent.ExpGantt.getProjectForView(viewId, projectId);
		console.log("getTasksByProjectForView response", project);
		return { type: GANTT_GET_PROJECT_FOR_VIEW, project, viewId };
	} catch (error) {
		return { type: GANTT_GET_PROJECT_FOR_VIEW_ERROR, error };
	}
}

export const updateScheduleSlot = async (taskId, scheduleId, newStartDate) => {
	console.log('updateScheduleSlot', taskId, scheduleId, newStartDate);
	
	try {
		console.log("calling agent updateScheduleSlot");
		let updated = await agent.ExpGantt.updateScheduleSlot(taskId, scheduleId, newStartDate);
		console.log("updateScheduleSlot response", updated);
		return { type: GANTT_UPDATE_SCHEDULE_SLOT, updated };
	} catch (error) {
		return { type: GANTT_GET_PROJECT_FOR_VIEW_ERROR, error };
	}
}

export const getTasksByProjectForViewForProject = async (viewId, projectId, query) => {
	// console.clear();
	console.log('getTasksByProjectForViewForProject', viewId, projectId, query);
	
	try {
		console.log("calling agent getTasksByProjectForViewForProject");
		let tasksByProjectForViewForProject = await agent.ExpGantt.getTasksByProjectForViewForProject(viewId, projectId, query);
		console.log("tasksByProjectForViewForProject response", tasksByProjectForViewForProject);
		return { type: GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_FOR_PROJECT_SUCCESS, tasksByProjectForViewForProject, viewId, projectId };
	} catch (error) {
		return { type: GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_ERROR, error };
	}
};

export const getTasksByStoryForView = async (storyId, releaseId, projectId, viewId, query) => {
	try {
		console.log(" calling agent tasksByStoryForView", storyId, releaseId, projectId, viewId, query);
		let tasksByStoryForView = await agent.ExpGantt.getTasksByStoryForView(storyId, viewId, query);
		console.log("tasksByStoryForView response", tasksByStoryForView);
		return { type: GANTT_GET_TASKS_BY_STORY_FOR_VIEW_SUCCESS, tasksByStoryForView, storyId, releaseId, projectId, viewId };
	} catch (error) {
		return { type: GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_ERROR, error };
	}
}

export const expandItem = async (viewId, type, id, expand) => { //E.g. VIEW_ID, "project", PROJECT_ID
	try {
		console.log("calling agent expandItem");
		let expandItem = await agent.ExpGantt.expandItem(viewId, type, id, expand);
		return { type: GANTT_EXPAND_ITEM_SUCCESS, expandItem, viewId };
	} catch (error) {
		return { type: GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_ERROR, error };
	}
};

export const USERS_GET_CURRENT_USER_SUCCESS = 'USERS_GET_CURRENT_USER_SUCCESS';
export const USERS_GET_CURRENT_USER_ERROR = 'USERS_GET_CURRENT_USER_ERROR';
export const USERS_GET_CURRENT_USER_RESET = 'USERS_GET_CURRENT_USER_RESET';
export const USERS_LOGOUT_USER_SUCCESS = 'USERS_LOGOUT_USER_SUCCESS';
export const USERS_LOGOUT_USER_ERROR = 'USERS_LOGOUT_USER_ERROR';
export const USERS_CHECK_EMAIL_EXISTS_SUCCESS = 'USERS_CHECK_EMAIL_EXISTS_SUCCESS';
export const USERS_CHECK_EMAIL_EXISTS_ERROR = 'USERS_CHECK_EMAIL_EXISTS_ERROR';
export const USERS_CHECK_EMAIL_EXISTS_RESET = 'USERS_CHECK_EMAIL_EXISTS_RESET';
export const USERS_CREATE_PROVISIONAL_EMAIL_SUCCESS = 'USERS_CREATE_PROVISIONAL_EMAIL_SUCCESS';
export const USERS_CREATE_PROVISIONAL_EMAIL_ERROR = 'USERS_CREATE_PROVISIONAL_EMAIL_ERROR';
export const USERS_CREATE_PROVISIONAL_EMAIL_RESET = 'USERS_CREATE_PROVISIONAL_EMAIL_RESET';
export const USERS_CHECK_PASSWORD_SUCCESS = 'USERS_CHECK_PASSWORD_SUCCESS';
export const USERS_CHECK_PASSWORD_ERROR = 'USERS_CHECK_PASSWORD_ERROR';
export const USERS_CHECK_PASSWORD_RESET = 'USERS_CHECK_PASSWORD_RESET';
export const USERS_CREATE_NEW_PASSWORD_SUCCESS = 'USERS_CREATE_NEW_PASSWORD_SUCCESS';
export const USERS_CREATE_NEW_PASSWORD_ERROR = 'USERS_CREATE_NEW_PASSWORD_ERROR';
export const USERS_CREATE_NEW_PASSWORD_RESET = 'USERS_CREATE_NEW_PASSWORD_RESET';
export const USERS_ADD_NEW_IMAGE_SUCCESS = 'USERS_ADD_NEW_IMAGE_SUCCESS';
export const USERS_ADD_NEW_IMAGE_ERROR = 'USERS_ADD_NEW_IMAGE_ERROR';
export const USERS_ADD_NEW_IMAGE_RESET = 'USERS_ADD_NEW_IMAGE_RESET';
export const USERS_ADD_NEW_NAME_SUCCESS = 'USERS_ADD_NEW_NAME_SUCCESS';
export const USERS_ADD_NEW_NAME_ERROR = 'USERS_ADD_NEW_NAME_ERROR';
export const USERS_ADD_NEW_NAME_RESET = 'USERS_ADD_NEW_NAME_RESET';
export const USERS_ADD_EMAIL_NOTIFICATIONS_SUCCESS = 'USERS_ADD_EMAIL_NOTIFICATIONS_SUCCESS';
export const USERS_ADD_EMAIL_NOTIFICATIONS_ERROR = 'USERS_ADD_EMAIL_NOTIFICATIONS_ERROR';
export const USERS_ADD_EMAIL_NOTIFICATIONS_RESET = 'USERS_ADD_EMAIL_NOTIFICATIONS_RESET';
export const USERS_LOGIN_USER_SUCCESS = 'USERS_LOGIN_USER_SUCCESS';
export const USERS_LOGIN_USER_ERROR = 'USERS_LOGIN_USER_ERROR';
export const USERS_LOGIN_USER_RESET = 'USERS_LOGIN_USER_RESET';
export const USERS_EMAIL_CONFIRMATION_SUCCESS = 'USERS_EMAIL_CONFIRMATION_SUCCESS';
export const USERS_EMAIL_CONFIRMATION_ERROR = 'USERS_EMAIL_CONFIRMATION_ERROR';
export const USERS_EMAIL_CONFIRMATION_RESET = 'USERS_EMAIL_CONFIRMATION_RESET';
export const USERS_AUTHENTICATE_USER_SUCCESS = 'USERS_AUTHENTICATE_USER_SUCCESS';
export const USERS_AUTHENTICATE_USER_ERROR = 'USERS_AUTHENTICATE_USER_ERROR';

import React, { Fragment } from 'react';

import Select, { components } from 'react-select';
import Avatar from '../Icons/Avatar';
import ProjectTag from '../Icons/ProjectTag';
import PriorityTag from '../Icons/PriorityTag';

import './index.scss';

/*
	NOTE: These are common component overrides for the react-select plugin
	Example usage:
	<Select
		className="react-select-no-border"
		classNamePrefix="react-select"
		onChange={this.updateUserFilter}
		value={this.state.userFilter}
		options={groupedOptions}
		placeholder="User"
		isClearable={false}
		isMulti
		closeMenuOnSelect={false}
		menuIsOpen={true}
		components={{ MultiValue, Option }}
	/>
 */

export const Group = props => (
	<div className="col-sm-4">
		<components.Group {...props} />
	</div>
);

export const MenuList = props => {
	return (
		<components.MenuList className="row" {...props}>
			{props.children}
			{/*<div
				class="css-dpec0i-option react-select-custom__option react-select-custom__option--is-focused"
				id="react-select-2-option-3"
				tabindex="-1">
				<div>
					<p>Hint: try typing "#1" to search for the first task</p>
					<h4>Search for "sd"</h4>
				</div>
			</div>*/}
		</components.MenuList>
	);
};

// overrides the options within the open menu
// will add the user avatar / project icon depending on the content
// requires "objectData" to be available within the options specified for the select component
export const Option = props => {
	// console.group(`[FilterBar.Option]`);
	// console.dir(props);
	// console.groupEnd();

	switch (props.data.type) {
		case 'user':
			return (
				<components.Option {...props} className="react-select-custom-option user-option">
					<Avatar className="small trkr-margin-right-10" user={props.data.objectData} hideName={true} />
					{props.children}
				</components.Option>
			);
		case 'priority':
			// console.group(`[ReactSelect.Option] priority`);
			// console.dir(props.data.objectData);
			// console.groupEnd();

			return (
				<components.Option {...props} className="react-select-custom-option project-option">
					<PriorityTag label={props.data.objectData.name} />
				</components.Option>
			);
		case 'project':
			return (
				<components.Option {...props} className="react-select-custom-option project-option">
					<ProjectTag
						projectTitle={props.data.objectData.title}
						projectColour={props.data.objectData.tagColour}
					/>
				</components.Option>
			);
		case 'task':
			return (
				<components.Option {...props} className="react-select-custom-option task-option">
					<ProjectTag
						projectTitle={props.data.objectData.title}
						projectColour={props.data.objectData.tagColour}
					/>
					<p>{props.children}</p>
				</components.Option>
			);
		case 'tagColour':
			return (
				<components.Option {...props} className="react-select-custom-option colour-option">
					<div className="colour-tag" style={{ borderColor: props.data.value }} />
					{props.children}
				</components.Option>
			);
		default:
			return <components.Option {...props}>{props.children}</components.Option>;
	}
};

export const MultiValueContainer = props => {
	// console.group(`[FilterBar.MultiValue]`);
	// console.dir(props);
	// console.groupEnd();

	// TODO: detect selection type (project / user / text), and add the necessary elements

	return (
		<div className="react-select-custom_multivalue-container" title={props.data.label}>
			<components.MultiValueContainer>
				<components.MultiValueLabel {...props} className="react-select-custom_multivalue-label" />
			</components.MultiValueContainer>
		</div>
	);
};

// overrides for the tags within the input area
// will add the user avatar / project icon depending on the content
// requires "objectData" to be available within the options specified for the select component
export const MultiValueLabel = props => {
	// console.group(`[FilterBar.MultiValueLabel]`);
	// console.dir(props);
	// console.groupEnd();

	// TODO: detect selection type (project / user / text), and add the necessary elements
	switch (props.data.type.toLowerCase()) {
		case 'priority':
			return (
				<components.MultiValueLabel {...props} className="react-select-custom_multivalue-label project-option">
					<PriorityTag label={props.data.objectData.name} iconOnly={true} />
				</components.MultiValueLabel>
			);
		case 'searchstring':
			return (
				<components.MultiValueLabel {...props} className="react-select-custom_multivalue-label project-option">
					<i className="trkr-icon-search trkr-margin-right-5" />"{props.children}"
				</components.MultiValueLabel>
			);
		case 'project':
			return (
				<components.MultiValueLabel {...props} className="react-select-custom_multivalue-label project-option">
					<div className="project-tag small" style={{ backgroundColor: props.data.objectData.tagColour }} />
					{props.children}
				</components.MultiValueLabel>
			);
		case 'user':
			return (
				<components.MultiValueLabel {...props} className="react-select-custom_multivalue-label user-option">
					<Avatar
						className="xs-small trkr-margin-right-10 tag"
						user={props.data.objectData}
						hideName={true}
					/>
					{props.children}
				</components.MultiValueLabel>
			);
		default:
			return <components.MultiValueLabel {...props} className="react-select-custom_multivalue-label" />;
	}
};

// to hide the drop down indicator as per design
export const DropdownIndicator = props => {
	return null;
};

export const MultiValueRemove = props => {
	return (
		<components.MultiValueRemove {...props}>
			<i className="trkr-icon-close-circle" />
		</components.MultiValueRemove>
	);
};

export const SingleValue = ({ children, ...props }) => {
	// console.group(`[ReactSelect.SingleValue]`);
	// console.dir(props);
	// console.groupEnd();

	switch (props.data.type) {
		case 'tagColour':
			return (
				<components.SingleValue {...props}>
					<div className="colour-tag" style={{ borderColor: props.data.value }} />
					{children}
				</components.SingleValue>
			);
		case 'priority':
			// console.group(`[ReactSelect.SingleValue] priority`);
			// console.dir(props);
			// console.groupEnd();
			return (
				<components.SingleValue {...props} className="priority-option">
					<PriorityTag label={props.data.objectData.name} />
				</components.SingleValue>
			);
		case 'project':
			return (
				<components.SingleValue {...props}>
					<ProjectTag
						projectTitle={props.data.objectData.title}
						projectColour={props.data.objectData.tagColour}
					/>
				</components.SingleValue>
			);
		case 'task':
			return (
				<components.SingleValue {...props} className="task-option">
					<ProjectTag
						projectTitle={props.data.objectData.title}
						projectColour={props.data.objectData.tagColour}
					/>
					<p className="task-detail">{children}</p>
				</components.SingleValue>
			);
		case 'user':
			return (
				<components.SingleValue {...props}>
					<Avatar
						className="xs-small trkr-margin-right-10 tag"
						user={props.data.objectData}
						hideName={true}
					/>
					{children}
				</components.SingleValue>
			);
		default:
			return <components.SingleValue {...props}>{children}</components.SingleValue>;
	}
};

// export { DropdownIndicator, MultiValueContainer, MultiValueLabel, Option, Group, MenuList };

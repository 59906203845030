
import React, { useEffect } from 'react';

const useInitLoad = (props) => {
    // get user when this component is loaded. 
    useEffect(() => {
		props.getCurrentUser();
	}, []);

    // If the user exists and data lists does not, get data lists.
	useEffect(() => {
		if (props.currentUser && (!props.allUsers || props.allUsers.length === 0)) {
			retrieveDataLists();
		}
	}, [props.currentUser]);

    const retrieveDataLists = async () => {
			props.getRunningIntervalsByTask();
			props.loadAllDataLists();
			props.getAllProjects();
			props.getServerOffset();
	};
}

export default useInitLoad;
import {
	MESSAGES_SEND_MESSAGE_SUCCESS,
	MESSAGES_SEND_MESSAGE_ERROR,
	MESSAGES_SEND_MESSAGE_RESET,
	MESSAGES_GET_USER_UNREAD_MESSAGES_SUCCESS,
	MESSAGES_GET_USER_UNREAD_MESSAGES_ERROR,
} from './constants';

const initialState = {
	messageSent: false,
	errors: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case MESSAGES_SEND_MESSAGE_SUCCESS:
			return {
				...state,
				messageSent: true,
			};
		case MESSAGES_SEND_MESSAGE_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case MESSAGES_SEND_MESSAGE_RESET:
			return {
				...state,
				messageSent: false,
			};
		case MESSAGES_GET_USER_UNREAD_MESSAGES_SUCCESS:
			return {
				...state,
				[action.userId]: {
					messages: state[action.userId]?.messages
						? [...state[action.userId]?.messages, ...action.messages]
						: action.messages,
					nextSkip: action.nextSkip,
					currentSkip: action.currentSkip,
				},
			};
		case MESSAGES_GET_USER_UNREAD_MESSAGES_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
	}
	return state;
};

import agent from '../../agent';
import {
	SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_SUCCESS,
	SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_ERROR,
	SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_SUCCESS,
	SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_ERROR,
	SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_RESET,
	SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_SUCCESS,
	SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_ERROR,
	SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_RESET,
	SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_SUCCESS,
	SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_ERROR,
	SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_SUCCESS,
	SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_ERROR,
} from './constants';

export const getActiveSubscriptionsByUser = async userId => {
	try {
		const response = await agent.Subscriptions.getActiveSubscriptionsByUser(userId);
		const subscriptions = response.subscriptions;
		return { type: SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_SUCCESS, subscriptions };
	} catch (error) {
		return { type: SUBSCRIPTIONS_GET_USER_SUBSCRIPTIONS_ERROR, error };
	}
};

export const getInvoicesForSubscription = async subscriptionId => {
	try {
		const response = await agent.Subscriptions.getInvoicesForSubscription(subscriptionId);
		const invoices = response.invoices;
		return { type: SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_SUCCESS, invoices };
	} catch (error) {
		return { type: SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_ERROR, error };
	}
};

export const getInvoicesForSubscriptionReset = () => {
	return { type: SUBSCRIPTIONS_GET_INVOICES_FOR_SUBSCRIPTION_RESET };
};

export const cancelSubscription = async subscriptionId => {
	try {
		const response = await agent.Subscriptions.cancelSubscription(subscriptionId);
		const subscriptionCancelled = response.success;
		return { type: SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_SUCCESS, subscriptionCancelled };
	} catch (error) {
		return { type: SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_ERROR, error };
	}
};

export const cancelSubscriptionReset = () => {
	return { type: SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_RESET };
};

export const checkSubscriptionCompleted = async subscriptionId => {
	try {
		const response = await agent.Subscriptions.checkSubscriptionCompleted(subscriptionId);
		const completed = response.completed;
		return { type: SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_SUCCESS, completed };
	} catch (error) {
		return { type: SUBSCRIPTIONS_CHECK_SUBSCRIPTION_COMPLETED_ERROR, error };
	}
};

export const updateSubscription = async (token, subscriptionId) => {
	try {
		const response = await agent.Subscriptions.update(token, subscriptionId);
		const success = response.success;
		return { type: SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_SUCCESS, success };
	} catch (error) {
		return { type: SUBSCRIPTIONS_UPDATE_SUBSCRIPTION_ERROR, error };
	}
};

import React, { Fragment, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { isKeyHotkey } from 'is-hotkey';
import { Editor, getEventTransfer } from 'slate-react';
import { Value } from 'slate';
import Plain from 'slate-plain-serializer';
import isUrl from 'is-url';
import { cx, css } from 'emotion';

import {
	Button,
	CheckboxWrapper,
	CheckListItem,
	ContentWrapper,
	Icon,
	ItemWrapper,
	Menu,
	Toolbar,
	Wrapper,
} from './subcomponents';

import './index.scss';

const initialValue = (initialText) => {
	return Value.fromJSON({
		document: {
			nodes: [
				{
					object: 'block',
					type: 'paragraph',
					nodes: [
						{
							object: 'text',
							leaves: [
								{
									text: initialText,
								},
							],
						},
					],
				},
			],
		},
	});
};

const DEFAULT_NODE = 'paragraph';
const isBoldHotkey = isKeyHotkey('mod+b');
const isItalicHotkey = isKeyHotkey('mod+i');
const isUnderlinedHotkey = isKeyHotkey('mod+u');
const isCodeHotkey = isKeyHotkey('mod+`');

const RichTextEditor = React.memo((props) => {
	const [isMounted, setIsMounted] = useState(false);
	const [value, setValue] = useState(initialValue(props.initialText));
	const menuRef = useRef(null);

	let editor;

	const ref = (editorRef) => {
		editor = editorRef;
	};

	useEffect(() => {
		// set state using props when the component is mounted.
		if (props.value !== undefined && isMounted === false) {
			try {
				JSON.parse(props.value);
				setValue(Value.fromJSON(JSON.parse(props.value)));
			} catch (error) {
				const value = Plain.deserialize(props.value);
				setValue(value);
			}

			setIsMounted(true);
		}

		updateMenu();
	});

	// relocates the hover menu relative to the selected text
	const updateMenu = () => {
		const menu = menuRef.current;

		// console.log('updateMenu', menuRef);

		if (!menu) return;

		// const { value } = this.state;
		const { fragment, selection } = value;

		if (selection.isBlurred || selection.isCollapsed || fragment.text === '') {
			menu.removeAttribute('style');
			return;
		}

		const native = window.getSelection();
		const range = native.getRangeAt(0);
		const rect = range.getBoundingClientRect();
		menu.style.opacity = 1;
		menu.style.top = `${rect.top + window.pageYOffset - menu.offsetHeight}px`;

		menu.style.left = `${rect.left + window.pageXOffset - menu.offsetWidth / 2 + rect.width / 2}px`;
	};

	const hasLinks = () => {
		return value.inlines.some((inline) => inline.type === 'link');
	};

	const onClickHref = (href) => {
		const urlRegex = /(https?:\/\/[^ ]*)/;
		let url = href;
		if (!href.match(urlRegex)) {
			url = `http://${url}`;
		}
		window.open(url, '_blank');
	};

	const MarkButton = ({ editor, type, icon }) => {
		const { value } = editor;
		let isActive = value.activeMarks.some((mark) => mark.type === type);

		if (type === 'link') {
			isActive = hasLinks();
		}

		return (
			<Button
				reversed
				active={isActive}
				onMouseDown={(event) => {
					event.preventDefault();

					if (type === 'link') {
						onClickLink(editor);
					}

					editor.toggleMark(type);
				}}>
				<Icon>{icon}</Icon>
			</Button>
		);
	};

	const BlockButton = ({ editor, type, icon }) => {
		const { value } = editor;
		let isActive = value.blocks.some((node) => node.type === type);
		if (['numbered-list', 'bulleted-list'].includes(type)) {
			const { document, blocks } = value;

			if (blocks.size > 0) {
				const parent = document.getParent(blocks.first().key);
				isActive =
					value.activeMarks.some((mark) => mark.type === 'list-item') && parent && parent.type === type;
			}
		}
		return (
			<Button
				active={isActive}
				onMouseDown={(event) => {
					event.preventDefault();
					onClickBlock(type, editor);
				}}>
				<Icon>{icon}</Icon>
			</Button>
		);
	};

	const onChange = ({ value }) => {
		setValue(value);
	};

	const onBlur = () => {
		setValue((previousValue) => {
			props.onBlur(previousValue.toJSON());
			return Value.fromJSON(previousValue.toJSON());
		});
	};

	const onKeyDown = (event, editor, next) => {
		let mark;
		const { value } = editor;
		if (event.key === 'Enter' && value.startBlock.type === 'check-list-item') {
			editor.splitBlock().setBlocks({ data: { checked: false } });
			return;
		}
		if (
			event.key === 'Backspace' &&
			value.isCollapsed &&
			value.startBlock.type === 'check-list-item' &&
			value.selection.startOffset === 0
		) {
			editor.setBlocks('paragraph');
			return;
		}
		if (isBoldHotkey(event)) {
			mark = 'bold';
		} else if (isItalicHotkey(event)) {
			mark = 'italic';
		} else if (isUnderlinedHotkey(event)) {
			mark = 'underlined';
		} else if (isCodeHotkey(event)) {
			mark = 'code';
		} else {
			return next();
		}

		event.preventDefault();
		editor.toggleMark(mark);
	};

	const onClickBlock = (type, editor) => {
		const { value } = editor;
		const { document } = value;

		if (type === 'check-list-item') {
			value.blocks.forEach((node) => {
				editor.setNodeByKey(node.key, {
					type: node.type === 'paragraph' ? 'check-list-item' : 'paragraph',
					data: { checked: false },
				});
			});
			return;
		}
		// Handle everything but list buttons.
		if (type !== 'bulleted-list' && type !== 'numbered-list') {
			const isActive = value.blocks.some((node) => node.type === type);
			const isList = value.blocks.some((node) => node.type === 'list-item');
			if (isList) {
				editor
					.setBlocks(isActive ? DEFAULT_NODE : type)
					.unwrapBlock('bulleted-list')
					.unwrapBlock('numbered-list');
			} else {
				editor.setBlocks(isActive ? DEFAULT_NODE : type);
			}
		} else {
			// Handle the extra wrapping required for list buttons.
			const isList = value.blocks.some((node) => node.type === 'list-item');
			const isType = value.blocks.some((block) => {
				return !!document.getClosest(block.key, (parent) => parent.type === type);
			});
			if (isList && isType) {
				editor.setBlocks(DEFAULT_NODE).unwrapBlock('bulleted-list').unwrapBlock('numbered-list');
			} else if (isList) {
				editor.unwrapBlock(type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list').wrapBlock(type);
			} else {
				editor.setBlocks('list-item').wrapBlock(type);
			}
		}
	};

	// Hover menu element
	const HoverMenu = React.forwardRef(({ editor }, ref) => {
		const root = window.document.getElementById('root');
		return ReactDOM.createPortal(
			<Menu
				ref={ref}
				className={css`
					padding: 8px 7px 6px;
					position: absolute;
					z-index: 2001;
					top: -10000px;
					left: -10000px;
					margin-top: -6px;
					opacity: 0;
					background-color: #222;
					border-radius: 4px;
					transition: opacity 0.75s;
				`}>
				<div>
					<MarkButton editor={editor} type="bold" icon="format_bold" />
					<MarkButton editor={editor} type="italic" icon="format_italic" />
					<MarkButton editor={editor} type="underlined" icon="format_underlined" />
					<MarkButton editor={editor} type="code" icon="code" />
					<MarkButton editor={editor} type="link" icon="link" />
				</div>

				<div>
					<BlockButton editor={editor} type="heading-one" icon="looks_one" />
					<BlockButton editor={editor} type="heading-two" icon="looks_two" />
					<BlockButton editor={editor} type="block-quote" icon="format_quote" />
					<BlockButton editor={editor} type="numbered-list" icon="format_list_numbered" />
					<BlockButton editor={editor} type="bulleted-list" icon="format_list_bulleted" />
					<BlockButton editor={editor} type="check-list-item" icon="check_box" />
				</div>
			</Menu>,
			root
		);
	});

	const wrapLink = (editor, href) => {
		editor.wrapInline({
			type: 'link',
			data: { href },
		});
		editor.moveToEnd();
	};

	const unwrapLink = (editor) => {
		editor.unwrapInline('link');
	};

	// control what happens when a link element is clicked
	const onClickLink = (editor) => {
		// event.preventDefault();
		const { value } = editor;
		const doesHaveLinks = hasLinks();
		if (doesHaveLinks) {
			editor.command(unwrapLink);
		} else if (value.selection.isExpanded) {
			const href = window.prompt('Enter the URL of the link:');
			if (href == null) {
				return;
			}
			editor.command(wrapLink, href);
		} else {
			const href = window.prompt('Enter the URL of the link:');
			if (href == null) {
				return;
			}
			const text = window.prompt('Enter the text for the link:');
			if (text == null) {
				return;
			}
			editor.insertText(text).moveFocusBackward(text.length).command(wrapLink, href);
		}
	};

	const onPaste = (event, editor, next) => {
		const transfer = getEventTransfer(event);
		const { type, text } = transfer;
		if (type !== 'text' && type !== 'html') return next();
		if (!isUrl(text)) return next();
		if (hasLinks()) {
			editor.command(unwrapLink);
		}
		editor.insertText(text).moveFocusBackward(text.length).command(wrapLink, text);
	};

	const renderNode = (props, editor, next) => {
		const { attributes, children, node } = props;
		switch (node.type) {
			case 'block-quote':
				return <blockquote {...attributes}>{children}</blockquote>;
			case 'bulleted-list':
				return <ul {...attributes}>{children}</ul>;
			case 'heading-one':
				return <h1 {...attributes}>{children}</h1>;
			case 'heading-two':
				return <h2 {...attributes}>{children}</h2>;
			case 'list-item':
				return <li {...attributes}>{children}</li>;
			case 'numbered-list':
				return <ol {...attributes}>{children}</ol>;
			case 'link': {
				const { data } = node;
				const href = data.get('href');
				return (
					<a {...attributes} target="_blank" href={href} onClick={() => onClickHref(href)}>
						{children}
					</a>
				);
			}
			case 'check-list-item':
				return <CheckListItem {...props} />;
			default:
				return next();
		}
	};

	const renderEditor = (props, editor, next) => {
		const children = next();
		return (
			<React.Fragment>
				<Toolbar className="toolbar">
					<MarkButton editor={editor} type="bold" icon="format_bold" />
					<MarkButton editor={editor} type="italic" icon="format_italic" />
					<MarkButton editor={editor} type="underlined" icon="format_underlined" />
					<MarkButton editor={editor} type="code" icon="code" />
					<MarkButton editor={editor} type="link" icon="link" />

					<BlockButton editor={editor} type="heading-one" icon="looks_one" />
					<BlockButton editor={editor} type="heading-two" icon="looks_two" />
					<BlockButton editor={editor} type="block-quote" icon="format_quote" />
					<BlockButton editor={editor} type="numbered-list" icon="format_list_numbered" />
					<BlockButton editor={editor} type="bulleted-list" icon="format_list_bulleted" />
					<BlockButton editor={editor} type="check-list-item" icon="check_box" />
				</Toolbar>

				{children}

				<HoverMenu ref={menuRef} editor={editor} />
			</React.Fragment>
		);
	};

	const renderMark = (props, editor, next) => {
		const { children, mark, attributes } = props;
		switch (mark.type) {
			case 'bold':
				return <strong {...attributes}>{children}</strong>;
			case 'code':
				return <code {...attributes}>{children}</code>;
			case 'italic':
				return <em {...attributes}>{children}</em>;
			case 'underlined':
				return <u {...attributes}>{children}</u>;
			default:
				return next();
		}
	};

	return (
		<Wrapper className="rich-text-editor" onMouseLeave={(e) => props.onLoseFocus(value.toJSON())}>
			<Editor
				className="editor"
				spellCheck
				placeholder={props.placeholder}
				ref={ref}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				onKeyDown={onKeyDown}
				renderEditor={renderEditor}
				renderNode={renderNode}
				renderMark={renderMark}
				onPaste={onPaste}
			/>
		</Wrapper>
	);
});

RichTextEditor.defaultProps = {
	placeholder: 'Enter some rich text...',
};

export default RichTextEditor;

import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { onHideModal } from '../App/actions';

import './index.scss';

// This ensures the modal appears above everything without worrying about inheritance and z ordering

const Modal = props => {
	return ReactDOM.createPortal(
		<div className="modal-controller">
			<div className="modal-bg" onClick={props.onHideModal} />

			<div className={'modal-container' + (props.releaseSuccessModal ? ' release-success-modal' : '')}>
				<div className="row modal-content">{props.children}</div>
			</div>
		</div>,
		document.querySelector('#modal')
	);
};

const mapDispatchToProps = {
	onHideModal,
};

export default connect(
	null,
	mapDispatchToProps
)(Modal);

// export default Modal;

import React from 'react';
import agent from '../../agent';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';
import { notify } from 'react-notify-toast';
import DatePicker from 'react-datepicker';

import PageView from '../PageView';
import TaskCard from '../TaskCard';
import Avatar from '../Icons/Avatar';

import ListErrors from '../ListErrors';
import Form from '../Form';

import { Option, SingleValue } from '../ReactSelect';

import history from '../../lib/History';
import { formatDuration, formatDate, numberPad, getQuery } from '../../lib/Utils';

// import { onLoad, onUnload, onSubmit, onUpdateField, onChatToggle, onRedirect } from './actions';

import './index.scss';
import 'react-datepicker/dist/react-datepicker.css';

// TODO: data lists should be loaded at app level
// - map lists as needed for the react-select component (see filterBar.js)
const mapStateToProps = state => ({
	...state.projectEditor,
});

/**
 * TODO: replace with central actions file references
 * `mapDispatchToProps()` needs separate actions for adding/removing
 * tags, submitting an task, updating individual fields, and cleaning
 * up after navigating away from the page.
 */
const mapDispatchToProps = dispatch => ({
	onAddTag: payload => dispatch({ type: 'ADD_TAG', payload }),
	onAddClient: payload => dispatch({ type: 'ADD_CLIENT', payload }),
	onAddRelease: payload => dispatch({ type: 'ADD_RELEASE', payload }),
	onLoad: payload => dispatch({ type: 'PROJECT_EDITOR_PAGE_LOADED', payload }),
	onRemoveTag: tag => dispatch({ type: 'REMOVE_TAG', tag }),
	onSubmit: payload => dispatch({ type: 'PROJECT_SUBMITTED', payload }),
	onUnload: payload => dispatch({ type: 'PROJECT_EDITOR_PAGE_UNLOADED' }),
	onUpdateField: (key, value) => dispatch({ type: 'PROJECT_UPDATE_FIELD', key, value }),

	onNewTask: () => dispatch({ type: 'PROJECT_NEW_TASK' }),
	onCancelTask: () => dispatch({ type: 'PROJECT_CANCEL_NEW_TASK' }),
	onSubmitTask: payload => dispatch({ type: 'PROJECT_SUBMIT_NEW_TASK', payload }),
	onAddTaskStatus: payload => dispatch({ type: 'PROJECT_NEW_TASK_ADD_STATUS', payload }),
	onAddTaskTag: payload => dispatch({ type: 'PROJECT_NEW_TASK_ADD_TAG', payload }),
	onRemoveTaskTag: tag => dispatch({ type: 'PROJECT_NEW_TASK_REMOVE_TAG', tag }),

	onUpdateTaskField: (key, value) => dispatch({ type: 'PROJECT_NEW_TASK_UPDATE_FIELD', key, value }),

	onRedirect: () => dispatch({ type: 'REDIRECT' }),
});

class ProjectModal extends React.Component {
	static defaultProps = {
		// TODO: should this be on the backend?
		allTagColours: [
			{ value: '#FF0050', label: 'Red 1', type: 'tagColour' },
			{ value: '#FF6E76', label: 'Red 2', type: 'tagColour' },
			{ value: '#FEB97C', label: 'Orange 1', type: 'tagColour' },
			{ value: '#FF7C01', label: 'Orange 2', type: 'tagColour' },
			{ value: '#FEF001', label: 'Yellow 1', type: 'tagColour' },
			{ value: '#E0FE91', label: 'Yellow 2', type: 'tagColour' },
			{ value: '#94FF02', label: 'Green 1', type: 'tagColour' },
			{ value: '#BAFF79', label: 'Green 2', type: 'tagColour' },
			{ value: '#04C35B', label: 'Green 3', type: 'tagColour' },
			{ value: '#75FF9E', label: 'Green 4', type: 'tagColour' },
			{ value: '#01D9D8', label: 'Blue 1', type: 'tagColour' },
			{ value: '#AAFFFF', label: 'Blue 2', type: 'tagColour' },
			{ value: '#1E8DE8', label: 'Blue 3', type: 'tagColour' },
			{ value: '#6DB9FF', label: 'Blue 4', type: 'tagColour' },
			{ value: '#A244FF', label: 'Purple 1', type: 'tagColour' },
			{ value: '#BB76FF', label: 'Purple 2', type: 'tagColour' },
			{ value: '#FF00DD', label: 'Pink 1', type: 'tagColour' },
			{ value: '#FF77ED', label: 'Pink 2', type: 'tagColour' },
			{ value: '#000000', label: 'Default', type: 'tagColour' },
		],
	};

	state = { activeView: 'tasks' };

	constructor() {
		super();

		// registerLocale('en-GB', enGB);

		const updateFieldEvent = key => ev => this.props.onUpdateField(key, ev.target.value);
		const updateTaskFieldEvent = key => ev => this.props.onUpdateTaskField(key, ev.target.value);

		this.showToastr = notify.createShowQueue();

		this.changeTitle = updateFieldEvent('title');
		this.changeDescription = updateFieldEvent('description');
		this.changeTagInput = updateFieldEvent('tagInput');

		this.changeTaskTitle = updateTaskFieldEvent('title');
		this.changeTaskDescription = updateTaskFieldEvent('description');
		this.changeTaskEstimate = updateTaskFieldEvent('estimate');
	}

	componentWillReceiveProps(nextProps) {
		console.log('[ProjectModal.componentWillReceiveProps]', this.props, nextProps);

		var urlTitle = getQuery(nextProps.location.search, 'title');

		if (urlTitle) {
			// console.log('[ProjectModal.componentWillReceiveProps] query detected', this.props.location);
			if (urlTitle && this.props.title !== urlTitle) {
				this.props.onUpdateField('title', urlTitle);
			}
		}

		/*if (nextProps.redirectTo) {
			// this.context.router.replace(nextProps.redirectTo);

			// TODO: need to analyse the url before overriding the current url, to cater for modal refreshes (not tied to the route)

			console.log('redirect!', nextProps.redirectTo);

			// TODO: go to previous screen if there is history, else return to kanban
			// if (this.props.history.length && this.props.history.length > 0) {
			// 	this.props.history.goBack();
			// } else {
			// 	// TODO: go to previous screen
			// 	this.props.history.push('kanban');
			// }

			this.props.history.push(nextProps.redirectTo);

			this.props.onRedirect();
		}*/
	}

	componentDidMount() {
		this.pageLoad(this.props.projectSlug);

		// this.changeContentView('tasks');
	}

	componentDidUpdate(prevProps, prevState) {
		// console.log('[TaskModal.componentDidUpdate]', prevProps, this.props.hasRunningTimer);

		// TODO: remove this manual redux redirection, use direct history.push where needed
		if (this.props.redirectTo) {
			// redirect appended to current url path
			// redirect only occurs automatically when new task is submitted
			// this.props.history.push(this.props.match.path + this.props.redirectTo);
			history.push(`/boards${this.props.redirectTo}`);

			this.props.onRedirect();
		}
	}

	componentWillUnmount() {
		this.props.onUnload();
	}

	pageLoad = projectSlug => {
		if (projectSlug) {
			console.log('loading slug', projectSlug);

			return this.props.onLoad(
				Promise.all([
					// agent.Projects.getBySlug(this.props.projectSlug),
					agent.Projects.getTreeBySlug(projectSlug),
					agent.Clients.all(),
					agent.Releases.getByProject(projectSlug),
					agent.Tags.all(),
					// agent.Tasks.getByProject(this.props.projectSlug),
				])
			);
		}
		console.log('[ProjectModal.componentWillMount]', projectSlug);
		this.props.onLoad(
			Promise.all([
				null,
				agent.Clients.all(),
				null,
				agent.Tags.all(),
				// null,
			])
		);
	};

	submitForm = () => {
		// ev.preventDefault();

		// TODO: use proper form validation
		// if (!this.props.title || (!this.props.client || !this.props.client.value)) {
			if (!this.props.title) {
			// alert('Please provide required information');
			this.showToastr('Please provide required information', 'error');
			return;
		} else {
			const project = {
				title: this.props.title,
				client: this.props.client ? this.props.client.value : undefined,
				release: this.props.release ? this.props.release.value : undefined,
				description: this.props.description,
				tagList: this.props.tagList.map(tag => {
					return tag.value;
				}),
				tagColour: this.props.tagColour.value,
				dueDate: this.props.dueDate,
			};

			console.log('submit form', project.client);

			const slug = { slug: this.props.projectSlug };
			const promise = this.props.projectSlug
				? agent.Projects.updateBySlug(Object.assign(project, slug))
				: agent.Projects.create(project);

			this.props.onSubmit(promise);
		}
	};

	onSubmitTask = ev => {
		ev.preventDefault();

		if (
			!this.props.newTaskInfo.title ||
			!this.props.newTaskInfo.category.value ||
			!this.props.newTaskInfo.status.value
		) {
			// alert('Please provide required information');
			this.showToastr('Please provide the required task information', 'error');
			return;
		} else {
			const task = {
				title: this.props.newTaskInfo.title,
				description: this.props.newTaskInfo.description || '',
				estimate: this.props.newTaskInfo.estimate * 3600000 || undefined,
				client: this.props.client.value,
				tagList: this.props.newTaskInfo.tagList.map(tag => {
					return tag.value;
				}),
				project: this.props.projectId,
				status: this.props.newTaskInfo.status ? this.props.newTaskInfo.status.value : undefined,
				assignedTo: this.props.newTaskInfo.assigned ? this.props.newTaskInfo.assigned.value : null,
			};

			console.log('[onSubmitTask]', task);

			const promise = agent.Tasks.create(task);

			this.props.onSubmitTask(promise);
		}
	};

	handleDueDateChange = date => {
		this.props.onUpdateField('dueDate', date);
	};

	// Client selectable stuff below
	handleClientChange = (newValue: any, actionMeta: any) => {
		this.props.onUpdateField('client', newValue);
	};
	handleClientInputChange = (inputValue: any, actionMeta: any) => {};

	addClient = str => {
		console.log('CreatableSelect adding client', str);

		this.props.onAddClient(agent.Clients.create({ name: str }));
	};

	// Client selectable stuff below
	handleTagChange = (newValue: any, actionMeta: any) => {
		this.props.onUpdateField('tagList', newValue);
	};
	handleTagInputChange = (inputValue: any, actionMeta: any) => {};
	addTag = str => {
		this.props.onAddTag(agent.Tags.create({ name: str }));
	};

	// Client selectable stuff below
	handleReleaseChange = (newValue: any, actionMeta: any) => {
		this.props.onUpdateField('release', newValue);
	};
	handleReleaseInputChange = (inputValue: any, actionMeta: any) => {};
	addRelease = str => {
		console.log('CreatableSelect adding release', str);

		this.props.onAddRelease(agent.Releases.create({ name: str, project: this.props.projectId }));
	};

	// NOTE: required for new task forms
	addTaskStatus = str => {
		this.props.onAddTaskStatus(agent.Status.create({ name: str }));
	};
	handleTaskStatusChange = (newValue: any, actionMeta: any) => {
		this.props.onUpdateTaskField('status', newValue);
	};

	handleTaskAssignedChange = (newValue: any, actionMeta: any) => {
		this.props.onUpdateTaskField('assigned', newValue);
	};
	handleTaskAssignedInputChange = (inputValue: any, actionMeta: any) => {};

	handleTaskCategoryChange = (newValue: any, actionMeta: any) => {
		this.props.onUpdateTaskField('category', newValue);
	};
	handleTaskCategoryInputChange = (inputValue: any, actionMeta: any) => {};

	handleTaskTagChange = (newValue: any, actionMeta: any) => {
		this.props.onUpdateTaskField('tagList', newValue);
	};
	handleTaskTagInputChange = (inputValue: any, actionMeta: any) => {};
	addTaskTag = str => {
		this.props.onAddTaskTag(agent.Tags.create({ name: str }));
	};

	// TAG COLOURS
	onTagColourChange = (newValue: any, actionMeta: any) => {
		this.props.onUpdateField('tagColour', newValue);
	};
	onTagColourInputChange = (inputValue: any, actionMeta: any) => {};

	changeContentView = target => {
		console.log('[ProjectModal.changeContentView]', target);
		this.setState({ activeView: target });
	};

	toggleMobilePanel = () => {
		// console.log('[TaskModal.changeContentView]', target);
		let show = !this.state.showMobilePanel || false;
		this.setState({ showMobilePanel: show });
	};

	renderForm = () => {
		return (
			<Form submit={this.submitForm}>
				<div className="form-group">
					<label htmlFor={'title'}>Project title*</label>
					<input
						className="form-control"
						type="text"
						name={'title'}
						required={true}
						placeholder="Project Title"
						value={this.props.title}
						onChange={this.changeTitle}
					/>
					<div className="invalid-feedback" />
				</div>

				<div className="form-group">
					<label htmlFor={'tagColour'}>Tag colour</label>
					<Select
						className="react-select-custom"
						classNamePrefix="react-select-custom"
						name={'tagColour'}
						required={true}
						onChange={this.onTagColourChange}
						onInputChange={this.onTagColourInputChange}
						value={this.props.tagColour}
						options={this.props.allTagColours}
						components={{ Option, SingleValue }}
						placeholder="Select a project colour"
					/>
					<div className="invalid-feedback" />
				</div>

				{/* <div className="form-group">
					<label htmlFor={'client'}>Client*</label>
					<CreatableSelect
						className="react-select-custom"
						classNamePrefix="react-select-custom"
						name={'client'}
						required={true}
						onChange={this.handleClientChange}
						onInputChange={this.handleClientInputChange}
						value={this.props.client}
						options={this.props.allClients}
						onCreateOption={this.addClient}
						placeholder="Select a client"
					/>
					<div className="invalid-feedback" />
				</div> */}

				<div className="form-group">
					<label htmlFor={'description'}>Description</label>
					<textarea
						className="form-control"
						rows="8"
						name={'description'}
						placeholder="Give your project a purpose"
						value={this.props.description}
						onChange={this.changeDescription}
					/>
					<div className="invalid-feedback" />
				</div>

				{/* <div className="form-group">
					<label htmlFor={'release'}>Current release</label>

					<CreatableSelect
						className="react-select-custom"
						classNamePrefix="react-select-custom"
						name={'release'}
						onChange={this.handleReleaseChange}
						onInputChange={this.handleReleaseInputChange}
						value={this.props.release}
						options={this.props.allReleases}
						onCreateOption={this.addRelease}
						placeholder="Select a release"
					/>
					<div className="invalid-feedback" />
				</div> */}

				{/* TODO: ensure is null if not defined, currently defaulting to todays date */}
				{/* <div className="form-group">
					<label htmlFor={'dueDate'}>Due</label>

					<DatePicker
						className="form-control"
						selected={moment(this.props.dueDate)}
						onChange={this.handleDueDateChange}
						showTimeSelect
						name="dueDate"
						timeFormat="HH:mm"
						timeIntervals={15}
						dateFormat="LLL"
						locale="en-GB"
					/>

					<div className="invalid-feedback" />
				</div> */}
			</Form>
		);
	};

	renderTaskList = () => {
		console.log('renderTaskList', this.props.projectTree);
		return (
			<div className="flex-body scrollable-content">
				{this.props.projectTree &&
					this.props.projectTree.releases &&
					this.props.projectTree.releases.map(release => {
						/*if (!release.stories || release.stories.length == 0) {
							return null;
						}*/

						return (
							<div className="trkr-full-width">
								<hr style={{ margin: '30px 10px 20px' }} />
								<label className="trkr-full-width trkr-margin-bot-10">
									{!release.isDefault && (
										<span style={{ display: 'inline-block' }}>
											<Link
												to={`${this.props.match.path}/release/${release._id}`}
												className="font-color-white">
												<i className="icon trkr-icon-release" /> {release.name}
											</Link>
										</span>
									)}

									<span style={{ display: 'inline-block', float: 'right' }}>
										{formatDuration(release.actualTime)} / {formatDuration(release.estimate)}
									</span>
								</label>

								{!release.tasks ||
									(release.tasks.length === 0 && (
										<p className="text-center">
											<i>No tasks</i>
										</p>
									))}

								{/* TODO: add story nest here */}

								{release.stories.map(story => {
									if (!story.tasks || story.tasks.length == 0) {
										return null;
									}

									return (
										<div className="trkr-margin-left-10">
											<label className="trkr-full-width trkr-margin-bot-10">
												{!story.isDefault && (
													<span style={{ display: 'inline-block' }}>
														<Link
															to={`${this.props.match.path}/story/${story.workspaceCounter}`}
															className="font-color-white">
															<i className="icon trkr-icon-story" /> {story.title}
														</Link>
													</span>
												)}
											</label>

											{story.tasks &&
												story.tasks.map(task => {
													return (
														<TaskCard
															task={task}
															key={task._id}
															style="kanban"
															timerString={this.props.timerString}
															timer={this.props.timer}
														/>
													);
												})}
										</div>
									);
								})}
							</div>
						);
					})}
			</div>
		);
	};

	// TODO:
	// 1. separate Form into another component for utilising in other areas of the app

	render() {
		// console.log('[ProjectModal.render] props', this);
		if (!this.props.history || !this.state) {
			return <div>Loading...</div>;
		}

		// console.log('[ProjectModal.render] props', this.props.projectTree);
		return (
			<div className="row modal-content">
				<div
					className={
						'flex-container trkr-padding-lr-60 xs-trkr-padding-lr-30 md-display-flex' +
						(this.props.projectTree ? ' col-md-7' : ' col-md-12') +
						(this.state.showMobilePanel ? ' hide' : '')
					}>
					<div className="modal-header flex-header md-trkr-padding-tb-15">
						{this.props.projectTree && (
							<div className="row md-hide trkr-padding-tb-10">
								<div className="col-xs-6">
									<button
										className={'trkr-icon-close position-fixed trkr-btn-close'}
										onClick={this.props.onClose}
									/>
								</div>
								<div className="col-xs-6 text-right">
									<a
										className="font-color-white"
										onClick={() => {
											this.toggleMobilePanel();
										}}>
										Show panel
									</a>
								</div>
							</div>
						)}

						<div className="row vertical-align-middle">
							<div className={this.props.id ? 'col-xs-10' : 'col-xs-12'}>
								<h5
									className={
										'header-title trkr-margin-top-0' +
										(!this.props.projectTree ? ' text-center' : '')
									}>
									{this.props.title ? this.props.title : 'New Project'}

									{!this.props.projectTree && (
										<button
											className="trkr-icon-close trkr-btn-close float-right"
											onClick={this.props.onClose}
										/>
									)}
								</h5>
							</div>
							<div className={'col-xs-2' + (!this.props.id ? ' hide' : '')}>
								<Avatar
									className="small inline trkr-float-right"
									user={this.props.projectTree ? this.props.projectTree.author : null}
								/>
							</div>
						</div>
					</div>

					<div className="flex-body">
						{/* Should this be within the form? */}
						<ListErrors errors={this.props.errors} />

						<div className="scrollable-content trkr-full-width trkr-margin-top-30">{this.renderForm()}</div>
					</div>

					<div className="flex-footer">
						<div className="trkr-full-width trkr-padding-tb-10 bg-grey-2" style={{ zIndex: 1 }}>
							<div className="row vertical-align-middle">
								<div className="col-xs-6">
									{this.props.projectTree && (
										<p className="text-light text-small test">
											Last updated {formatDate(this.props.projectTree.updatedAt)}
										</p>
									)}
								</div>

								<div className="col-xs-6 text-right">
									<button
										className="trkr-btn"
										type="submit"
										disabled={this.props.isLoading == true}
										onClick={() => {
											this.submitForm();
										}}>
										{this.props.workspaceCounter ? 'Update Project' : 'Create Project'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				{this.props.projectTree && (
					<div className="col-md-5 flex-container bg-color-dark activity-panel xs-trkr-padding-lr-30">
						<div className="modal-header flex-header">
							<div className="row md-hide trkr-padding-tb-10">
								<div className="col-xs-6">
									<button
										className="trkr-icon-close position-fixed trkr-btn-close"
										onClick={this.props.onClose}
									/>
								</div>
								<div className="col-xs-6 text-right">
									<a
										className="font-color-white"
										onClick={() => {
											this.toggleMobilePanel();
										}}>
										Show details
									</a>
								</div>
							</div>
							<div className="text-center trkr-full-width">
								<ul className="pill-nav-list">
									<li
										className={'nav-link ' + (this.state.activeView === 'tasks' ? 'active' : '')}
										onClick={() => {
											this.changeContentView('tasks');
										}}>
										<a>Tasks</a>
									</li>
									<li
										className={
											'nav-link ' + (this.state.activeView === 'timesheet' ? 'active' : '')
										}
										onClick={() => {
											this.changeContentView('timesheet');
										}}>
										<a>Timesheet</a>
									</li>
								</ul>
							</div>

							<button
								className="trkr-icon-close trkr-btn-close position-absolute trkr-display-none md-show"
								onClick={this.props.onClose}
							/>
						</div>
						<div className="flex-body">
							<div className={'flex-container' + (this.state.activeView !== 'tasks' ? ' hide' : '')}>
								{this.renderTaskList()}
							</div>

							<div className={'' + (this.state.activeView !== 'timesheet' ? 'hide' : '')}>
								SHOW INTERVALS
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProjectModal);

import React from 'react';

// NOTE: used in ActivityEntry, MessageEntry and CommitMessage for formattign and actioning the links in the feed
const ActivityLinks = (props) => {
	const { story, task, showModal } = props;
	const project = story?.release?.project || task?.project;
	const taskOrStory = story ? story : task;

	const openTaskOrStory = story
		? () => showModal('story', story.workspaceCounter)
		: () => showModal('task', task._id);

	// if a task, then allow the link to open the model via actions else, needs to be a link for a story

	return (
		<span className="link">
			{project && (
				<span className="font-bold" title={'show project details'}>
					{project.title}{' '}
				</span>
			)}
			<span
				className=""
				title="show task details"
				onClick={() => {
					openTaskOrStory();
				}}>
				#{taskOrStory.workspaceCounter} - {taskOrStory.title}
			</span>
		</span>
	);
};

export default ActivityLinks;

import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import moment  from 'moment';
import { create, all } from 'mathjs'

import { cloneArray } from '../../../../../../lib/Utils';

import {getTasksByProjectForView, getTasksByProjectForViewForProject, getTasksByStoryForView, updateScheduleSlot} from './actions';
import {expandItem} from './actions';
import { InView } from 'react-intersection-observer';

import Draggable from 'react-draggable';

import shortid from 'shortid';

import './index.scss';

const math = create(all, {});
class ExpGantt extends Component {

	ganttSettings = () => {
		let startDate = moment().subtract(1, "years").startOf('day');//Previous 12 months and next 12 months to current day
		let endDate = moment(startDate).add('2', 'years');
		let divisionUnit = "days";
		let noOfDivisions = endDate.diff(startDate, divisionUnit);
		// let noOfDays = endDate.diff(startDate, "days");
		let dayMatrix = {};
		let dayMatrixStartDate = moment(startDate).subtract(1, divisionUnit); //new object to not interfere with startDate.
		for (let i=0; i<noOfDivisions; i++){
			let isoWeekday = dayMatrixStartDate.weekday();
			dayMatrix[dayMatrixStartDate.add(1, "days").format("DD-MM-YYYY")] = {
				workingDay: isoWeekday !== 5 && isoWeekday !== 6, //Hardcoded to weekends for consideration for now.
				usersOff: {} //To be a list of user ids that are off for quick ref by id. {[USERID]: true}
			}
		}

		// console.log('dayMatrix',dayMatrix, noOfDays);

		let isWorkingDay = (dateKey)=>{
			if (!dayMatrix[dateKey]){
				return true;
			}
			return dayMatrix[dateKey].workingDay;
		};

		let hoursInWorkDay = 7.5;

		return {
			hoursInWorkDay: hoursInWorkDay,
			sideBarWidth: 500,
			 divisionUnit: divisionUnit,
			 divisionWidth: 100,
			 startDate: startDate,
			 endDate: endDate,
			 noOfDivisions: noOfDivisions,
			 dayMatrix: dayMatrix,
			 isWorkingDay: isWorkingDay,
			 totalWorkingTimeOffBetween: (startDate, endDate) =>{
				//  console.log("timeOffDuringScheduleSlot totalWorkingTimeOffBetween",startDate.format('DD-MM-YYYY'),endDate.format('DD-MM-YYYY'),endDate);
					let totalDivisions = moment(endDate).add("1","days").startOf("day").diff(startDate.startOf("day"), divisionUnit);
					// console.log("timeOffDuringScheduleSlot totalDivisions",totalDivisions);
					let totalTimeOffForDivision = 0; //E.g. 0 days
					let stepStartDate = moment(startDate).subtract(1,"days"); //For ease of looppurpose;
					for (let i=0; i<totalDivisions; i++){
						stepStartDate.add(1,"days");
						let isNonWorkingDay = !isWorkingDay(stepStartDate.format('DD-MM-YYYY'));
						// console.log('isNonWorkingDay for', stepStartDate.format('DD-MM-YYYY'), isNonWorkingDay);
						if (isNonWorkingDay){
							totalTimeOffForDivision+=1;
						}
					}
					// console.log("timeOffDuringScheduleSlot totalTimeOffForDivision",totalTimeOffForDivision, isWorkingDay(startDate.format('DD-MM-YYYY')));
					return totalTimeOffForDivision;
			 },
			 getSecondsAsWorkingDays: (seconds) => {
				 let multiplier = 24 / hoursInWorkDay;
				 return moment.duration(seconds, "seconds").asDays() * multiplier;
			 },
			 getMillisecondsAsWorkingDays: (milliseconds) => {
				 return this.userGantSettings.getSecondsAsWorkingDays(milliseconds/1000);
			 },
			 pagination: {
				 projects:10, //Each load
				 tasks: 10 //Within each story
			 }
		}
	}

	userGantSettings = this.ganttSettings();

	componentDidMount = async () => {
		console.log("calling getTasksByProjectForView");
		this.clickActions.setScrollToTodaysDate();
		this.clickActions.setNowTime();
		this.props.getTasksByProjectForView(this.props.view._id,{ projectsLimit: this.userGantSettings.pagination.projects, projectsSkip:0, startDate: this.userGantSettings.startDate.toDate(), endDate: this.userGantSettings.endDate.toDate() });
		console.log('this.props getTasksByProjectForView',this.props);
		
	}

	componentDidUpdate(prevProps, prevState) {
		console.log('component getTasksByProjectForView',this.props);
		return this.props.views && this.props.views[this.props.view._id];
	}
	

	clickActions = {
		toggleProjectExpand: async (project) => {
			console.log("project",project);
			await expandItem(this.props.view._id, "project", project._id, !project.isExpanded); //Persist the expanded

			if (!project.isExpanded){
				//TODO currently this loads all data, but we can tweak to load only data for the specific project
				//TODO current need to forceUpdate - it should automatically re-render - unsure as to why atm.
				// await this.props.getTasksByProjectForView(this.props.view._id,{}); 

				await this.props.getTasksByProjectForViewForProject(this.props.view._id,project._id, {});//TODO 

				this.forceUpdate();
			}else{
				project.isExpanded = !project.isExpanded;
				this.forceUpdate(); //No need to load data again, but re-render to close the expanded view.
			}
		},
		loadNextProjects: async () => {
			await this.props.getTasksByProjectForView(this.props.view._id,{ projectsLimit: this.userGantSettings.pagination.projects, projectsSkip: this.props.views[this.props.view._id].nextSkip });
			this.forceUpdate();
		},
		loadNextTasksForStory: async (story, releaseId, projectId) => {
			await this.props.getTasksByStoryForView(story._id, releaseId, projectId, this.props.view._id, {tasksLimit: this.userGantSettings.pagination.tasks, tasksSkip: story.nextSkip});
			this.forceUpdate();
		},
		setScrollToTodaysDate: () => {
			let today = moment().startOf("day");
			this.clickActions.setScrollToDate(today);
		},
		setScrollToDate: (date) => {
			console.log("date",date);
			date = moment(date);
			console.log("setScrollToDate", date.format("DD-MM-YYYY"));
			let slotsHolderOuter = document.getElementById("slotsHolderOuter");
			let scrollLeft = moment.duration(date.diff(this.userGantSettings.startDate)).as(this.userGantSettings.divisionUnit) * this.userGantSettings.divisionWidth;
			slotsHolderOuter.scrollLeft = scrollLeft - (this.userGantSettings.divisionWidth*2); //Offset it a bit for some context of previous days also.
		},
		setNowTime: () => {
			let nowTimeElement = document.getElementById("nowTime");
			if (!nowTimeElement){
				return false;
			}
			let date = moment();
			let left = moment.duration(date.diff(this.userGantSettings.startDate)).as(this.userGantSettings.divisionUnit) * this.userGantSettings.divisionWidth;
			left = left + this.userGantSettings.sideBarWidth;
			// console.log("nowTimeElement",nowTimeElement,nowTimeElement.style,nowTimeElement.style.left,left);
			nowTimeElement.style.left = left +"px";
			let nowTimeTextElement = document.getElementById("nowTimeText");
			nowTimeTextElement.innerHTML = date.format("HH:mm:ss");
			setTimeout(this.clickActions.setNowTime, 1000);
		},
		handleInViewOnChange: (inView, entry) =>{
			let target = entry.target;
			let taskId = target.parentNode.parentNode.parentNode.getAttribute("data-task-id");
			let taskElements = document.querySelectorAll('[data-task-id="' + taskId + '"]');

			console.log("taskElements",taskElements);
			taskElements.forEach((taskElement)=>{
				if (inView){
					// console.log(taskId, "is in view");
					taskElement.className = taskElement.className + " in-view";
					taskElement.className = taskElement.className.replace(" out-view-right","");
					taskElement.className = taskElement.className.replace(" out-view-left","");
				}else{
					taskElement.className = taskElement.className.replace(" in-view","");
					console.log(taskId,inView,entry);
					if (entry.rootBounds.x>entry.boundingClientRect.x){
						// console.log(taskId, "is to the left");
						taskElement.className = taskElement.className + " out-view-left";
					}else{
						// console.log(taskId,"is to the right");
						taskElement.className = taskElement.className + " out-view-right";
					}
				}
			});
		},
		slot: {
			updateStartDate: async (taskId, scheduleId, projectId, newStartDate) => {
				console.log("updateStartDate", taskId, newStartDate, "viewId", this.props.view._id, "projectId", projectId);
				await this.props.updateScheduleSlot(taskId, scheduleId, newStartDate);
				await this.props.getTasksByProjectForViewForProject(this.props.view._id, projectId,{startDate: this.ganttSettings.startDate, endDate: this.ganttSettings.endDate});
				this.forceUpdate();
			},
			handleOnStopDrag: (task, scheduleId, projectId, e, data) => {
				console.log("task", task, 	"e", e, "data", data);
				let currentLeft = data.node.offsetLeft;
				let translateLeft = data.x;
				console.log("currentLeft",currentLeft);
				console.log("translateLeft",translateLeft);
				let newStartDate = this.calculate.newStartDateFromNewXPos(task, currentLeft, translateLeft);
				this.clickActions.slot.updateStartDate(task._id, scheduleId, projectId, newStartDate);
				console.log("newStartDate", newStartDate);
			}
		}
	}

	renderProjects = (isSideBar) => {
		if (!this.props.views || !this.props.views[this.props.view._id]){
			return false;
		}
		let items = [];
		for (let projectId in this.props.views[this.props.view._id].projects){
			let project = this.props.views[this.props.view._id].projects[projectId];
			let scheduleStartDate = project.firstTaskScheduled.schedules[0].startDate;
			let scheduleEndDate = moment(project.lastTaskScheduled.schedules[0].startDate).add(this.userGantSettings.getMillisecondsAsWorkingDays(project.lastTaskScheduled.estimate), "days");
			// console.log("scheduleStartDate",scheduleStartDate);
			// console.log("scheduleEndDate",scheduleEndDate);
			let scheduleDuration = moment.duration(scheduleEndDate.diff(scheduleStartDate)).as(this.userGantSettings.divisionUnit);
			
			let scheduleStartDateOffsetPos = moment.duration(moment(scheduleStartDate).diff(this.userGantSettings.startDate)).as(this.userGantSettings.divisionUnit) * this.userGantSettings.divisionWidth;
			
			// console.log("JS1 scheduleDuration",scheduleDuration,this.userGantSettings.divisionUnit,scheduleStartDateOffsetPos);
			let scheduleStartEndStyles = { width: scheduleDuration * this.userGantSettings.divisionWidth, left: scheduleStartDateOffsetPos};//{width: project.totalDuration*this.userGantSettings.divisionWidth, left: completionProgressOffsetPos}

			let intervalStartEndStyles = {};
			let intervalStartDate;
			let intervalEndDate;
			if (project.firstInterval){
				intervalStartDate = project.firstInterval.startDateTime;
				intervalEndDate = project.lastInterval.endDateTime;
				let intervalDuration = moment.duration(moment(intervalEndDate).diff(intervalStartDate)).as(this.userGantSettings.divisionUnit);
				// console.log("JS1 intervalDuration", intervalDuration);
				let intervalStartDateOffsetPos = moment.duration(moment(intervalStartDate).diff(this.userGantSettings.startDate)).as(this.userGantSettings.divisionUnit) * this.userGantSettings.divisionWidth;
				intervalStartEndStyles = { width: intervalDuration * this.userGantSettings.divisionWidth, left: intervalStartDateOffsetPos};
			}
			
			items.push(
				<li>
					<span onClick={()=>this.clickActions.toggleProjectExpand(project)}>
						<span className={isSideBar && project.isExpanded && "glyphicon glyphicon-chevron-down" || isSideBar && "glyphicon glyphicon-chevron-right"}></span>
							&nbsp;{project.title} <span class="debug-only">({moment(scheduleStartDate).format('DD-MM-YYYY')} - {scheduleEndDate.format('DD-MM-YYYY')}) (Interval - {moment(intervalStartDate).format('DD-MM-YYYY')} - {moment(intervalEndDate).format('DD-MM-YYYY')}</span>
					</span>
					<div className="slot-task">
						<div className="schedule-start-end" style={scheduleStartEndStyles}></div>
						<div className="interval-start-end" style={project.firstInterval && intervalStartEndStyles}></div>
					</div>
					<ul class="releases">
						{project.isExpanded && this.renderReleasesForProject(project, isSideBar)}
					</ul>
				</li>
			);
		}
	items.push(<li>{this.props.views[this.props.view._id].max>this.props.views[this.props.view._id].nextSkip && <InView as="div" onChange={(inView, entry)=>{ inView && this.clickActions.loadNextProjects()}} ><span onClick={this.clickActions.loadNextProjects}>+ More Projects</span></InView>}</li>)
		
		return items;
	}

	renderReleasesForProject = (project, isSideBar) => {
		let items = [];
		for (let releaseId in project.releases){
			let release = project.releases[releaseId];
		items.push(<li>{release.title} <ul class="stories">{this.renderStoriesForRelease(release, project._id, isSideBar)}</ul></li>);
		}
		return items;
	}


	renderStoriesForRelease = (release, projectId, isSideBar) => {
		let items = [];
		for (let storyId in release.stories){
			let story = release.stories[storyId];
			items.push(<li>{story.title}<ul class="tasks">{this.renderTasksForStory(story, release._id, projectId, isSideBar)}</ul></li>);
		}
		return items;
	}

	calculate = {
		estimateOffsetPos: (taskScheduleStartDate) => {
			return moment.duration(moment(taskScheduleStartDate).diff(this.userGantSettings.startDate)).as(this.userGantSettings.divisionUnit) * this.userGantSettings.divisionWidth;
		},
		newStartDateFromNewXPos: (task, origLeftPos, additionalLeftPos) => {

			let newTotalLeftPos = origLeftPos + additionalLeftPos;

			let oldDivisions = origLeftPos / this.userGantSettings.divisionWidth;
			let newDivisions = newTotalLeftPos / this.userGantSettings.divisionWidth; 
			let differenceDivisions =  newDivisions - oldDivisions;
			let newStartDate = moment(task.schedules[0].startDate).add(moment.duration(differenceDivisions,this.userGantSettings.divisionUnit).as("milliseconds"),"milliseconds")
			console.log("oldDivisions",oldDivisions);
			console.log("newDivisions",newDivisions);
			console.log("differenceDivisions",differenceDivisions,newStartDate.format("DD/MM/YY HH:mm"));
			return newStartDate.toDate();
		},
		taskScheduleStartDate: (task) => {
			return task.schedules && task.schedules[0].startDate || task.intervals[0] && task.intervals[0].startDateTime || task.createdAt;
		}
	};

	renderTasksForStory = (story, releaseId, projectId, isSideBar) => {
		let items = [];
		// console.log("JS7",story.tasks.length);
		
		let taskIdsOrdered = Object.keys(story.tasks);

		for (let taskId in story.tasks){
			// console.log("JS7",taskId);
			let task = story.tasks[taskId];
			
			let estimateAsWorkingDays = this.userGantSettings.getMillisecondsAsWorkingDays(task.estimate);//moment.duration(task.estimate,"seconds").as(this.userGantSettings.divisionUnit);
			
			//1st preference, a schedule, 2nd preference an interval, last preference - when the task was created/
			// let taskScheduleStartDate = task.scheduleSlot && task.scheduleSlot.startDateTime || task.intervals[0] && task.intervals[0].startDateTime || task.createdAt;
			let taskScheduleStartDate = this.calculate.taskScheduleStartDate(task);
			// console.log("JS6 task",task, "taskScheduleStartDate",taskScheduleStartDate );
			// if(task.workspaceCounter===12){
				// console.log("totalTimeOffDuringScheduleSlot",totalTimeOffDuringTaskEstimate);
				// }
				
			let template;
			let taskSlot;
			if (!isSideBar){
				let currentEstimateStartOffsetPos = this.calculate.estimateOffsetPos(taskScheduleStartDate); //To be schedule slot once coming back.
					
				let intervalsForTask = this.renderIntervalsForTask(task);
				let totalTimeOffDuringTaskEstimate = this.userGantSettings.totalWorkingTimeOffBetween(moment(taskScheduleStartDate),moment(taskScheduleStartDate).add(moment.duration(task.estimate,"seconds")));
				let totalDaysIncludingTimeOff = totalTimeOffDuringTaskEstimate+estimateAsWorkingDays;
				let currentEstimateStyles = {width: totalDaysIncludingTimeOff, left: currentEstimateStartOffsetPos}; //this.userGantSettings.divisionWidth
				let numberOfDivisions = currentEstimateStyles.width / this.userGantSettings.divisionWidth;
				let originalEstimateStyles = {}; //Todo
				let completionProgressOffsetPos = currentEstimateStartOffsetPos;
				let completionProgressStyles = {width: intervalsForTask.totalDuration*this.userGantSettings.divisionWidth, left: completionProgressOffsetPos}
				
				// console.log("this.ganttSettings.totalWorkingTimeOffBetween",this.ganttSettings.totalWorkingTimeOffBetween);
				
				
				//Fake dependancy tasks to be a previous task only for now for easy testing.
				let simulatedEndDate = moment(taskScheduleStartDate).add(moment.duration(currentEstimateStyles.width / this.userGantSettings.divisionWidth, this.userGantSettings.divisionUnit,"days").as("milliseconds"),"milliseconds");
				let dependantTaskIds = [];
				let currentTaskIndex = taskIdsOrdered.indexOf(taskId);
				if (currentTaskIndex<taskIdsOrdered.length){
					let nextTaskId = taskIdsOrdered[currentTaskIndex+1];
					dependantTaskIds.push(nextTaskId);
				}
				
				task.simulatedEndDate = simulatedEndDate.toDate(); //needed?
				
				dependantTaskIds.forEach((taskId)=>{
					if (!taskId) return false;
					story.tasks[taskId].maxStartDate = task.simulatedEndDate;
					story.tasks[taskId].dependancyTaskIds = story.tasks[taskId].dependancyTaskIds || [];
					story.tasks[taskId].dependancyTaskIds.push(task._id);
				});
				
				// console.log("task", task.title,numberOfDivisions, this.userGantSettings.divisionUnit, "task.estimate", task.estimate, "start", moment(taskScheduleStartDate).format("DD/MM/YY HH:mm"), "end", simulatedEndDate.format("DD/MM/YY HH:mm"), "total", totalTimeOffDuringTaskEstimate+estimateAsWorkingDays);
				
				let originalPlaceStyles = {};
				originalPlaceStyles.left = this.calculate.estimateOffsetPos(taskScheduleStartDate);
				if (task.maxStartDate){
					if (moment(task.maxStartDate).isAfter(taskScheduleStartDate)){
						currentEstimateStyles.left = completionProgressStyles.left = this.calculate.estimateOffsetPos(task.maxStartDate);
					}
				}
				//end fake dependancy

				 taskSlot = (
					<div className="slot-task">
						<div className="completion-progress" style={completionProgressStyles}></div>
						<div className="original-estimate" style={originalEstimateStyles}></div>
						{/* onClick={()=>this.clickActions.slot.updateStartDate(task._id, task.schedules[0]._id, projectId, new Date())} */}
						<Draggable 
							axis="x"
							key={shortid.generate()}
							// onStart={(e, data)=>{data.node.style.transform = "none"}}
							onStop={(e, data)=>{this.clickActions.slot.handleOnStopDrag(task, task.schedules[0]._id, projectId, e, data)}}
							>
							<div className="current-estimate" style={currentEstimateStyles}><InView as="div" onChange={(inView, entry)=>{this.clickActions.handleInViewOnChange(inView, entry);}}></InView></div>
						</Draggable>
						{task.maxStartDate && <div className="original-place" style={originalPlaceStyles}></div>}
					</div>
					);
				template = <li data-task-id={taskId} class="task-row"><div> {task.title} (Start Date: {moment(taskScheduleStartDate).format("DD-MM-YY")} TTL of Intervals: {math.round(intervalsForTask.totalDuration,1)} Days / Estimation: {math.round(estimateAsWorkingDays,1)} Days ({moment.duration(task.estimate, "milliseconds").as("hours")}hours)) {intervalsForTask.items}</div>{taskSlot}</li>
			}else{
				let completionPercentage = math.round(((this.renderIntervalsForTask(task).totalDuration*10*3600)/task.estimate) * 100000,0);
				taskSlot = (
					<div className="slot-task">
						<div className="completion-progress"></div>
						<div className="original-estimate"></div>
						<div className="current-estimate"></div>
					</div>
					);
				template = 	<li data-task-id={taskId} class="task-row" onClick={()=>this.clickActions.setScrollToDate(task.schedules && task.schedules[0].startDate || task.intervals[0] && task.intervals[0].startDateTime || task.createdAt)}><div class="row"><div class="col-xs-1"><span class="task-no">({moment(taskScheduleStartDate).format('HH:mm')})#{task.workspaceCounter}</span></div> <div class="col-xs-7">{task.title} <span class="debug-only">(Start Date: {moment(taskScheduleStartDate).format("DD-MM-YY")} Estimation: {math.round(estimateAsWorkingDays,1)} Days ({moment.duration(task.estimate, "milliseconds").as("hours")}hours))</span></div><div class="col-xs-2"><span class="estimate">{moment.duration(task.estimate, "milliseconds").as("hours")}h</span></div><div class="col-xs-1"><span class="act">{completionPercentage}%</span></div><div class="col-xs-1"><span class="assignee">YY</span></div></div><span class="jump-btn"></span>{taskSlot}</li>
			}
			items.push(template);
		}
		// console.log("JS11 - story", story);
		items.push(<li>{story.max>story.nextSkip && <InView as="div" onChange={(inView, entry)=>{ inView && this.clickActions.loadNextTasksForStory(story, releaseId, projectId)}} ><span onClick={()=>this.clickActions.loadNextTasksForStory(story, releaseId, projectId)}>+ More Tasks</span></InView>}</li>)
		// console.log("JS7", items);
		return items;
	}

	renderIntervalsForTask = (task) =>{
		let items = [];
		let totalDuration = 0;
		task.intervals.forEach((interval)=>{
			let duration = moment.duration(moment(interval.endDateTime).diff(moment(interval.startDateTime))).as("seconds");//.as(this.userGantSettings.divisionUnit);
			
			// duration = Math.round(duration * 10) / 10;
			
			totalDuration += duration;

			let intervalWidth = this.userGantSettings.getSecondsAsWorkingDays(duration) * this.userGantSettings.divisionWidth;

			let intervalStartOffsetPos = moment.duration(moment(interval.startDateTime).diff(this.userGantSettings.startDate)).as(this.userGantSettings.divisionUnit) * this.userGantSettings.divisionWidth; 
			
			// console.log("intervalStartOffsetPos",intervalStartOffsetPos, moment(interval.startDateTime).format("DD-MM-YYYY"), this.userGantSettings.startDate.format("DD-MM-YYYY"));

			let styles = {
				width: intervalWidth,
				left:intervalStartOffsetPos
			}

			items.push(<div className="slot-interval" style={styles}>{duration} Days</div>);
		});

		totalDuration = this.userGantSettings.getSecondsAsWorkingDays(totalDuration);

		return {
			items: items,
			totalDuration: totalDuration
		}
	}

	renderHeader = () => {
		
		console.log("startdate", this.userGantSettings.startDate,'endDate',this.userGantSettings.endDate, 'noOfDivisions',this.userGantSettings.noOfDivisions);
		
		let items = [];
		let styles = {
			width: this.userGantSettings.divisionWidth,
			
		}
		let headerStartDate = moment(this.userGantSettings.startDate).subtract(1, this.userGantSettings.divisionUnit); //so the for loop starts at start
		for (let i=0; i<this.userGantSettings.noOfDivisions; i++){
			let dateKey = headerStartDate.add(1, this.userGantSettings.divisionUnit).format('ddd DD MMM');
			items.push(<li style={styles} className={!this.userGantSettings.isWorkingDay(dateKey) && "non-working-day"}>{dateKey}</li>)
		}
		return items;
	}

	setSlotsHolderInnerWidth = () =>{
		return { width: (this.userGantSettings.noOfDivisions * this.userGantSettings.divisionWidth) + this.userGantSettings.sideBarWidth };
	}

	renderNonWorkingTime = () =>{
		let stepStartDate = moment(this.userGantSettings.startDate).subtract(1, "days"); //for ease of looping
		let noOfDaysInView = moment.duration(this.userGantSettings.endDate.diff(stepStartDate)).as("days");
		let items = [];
		// console.log("JS noOfDaysInView",noOfDaysInView, this.userGantSettings);
		for (let i=0; i<noOfDaysInView; i++){
			stepStartDate.add(1, "days");
			// console.log("JS noOfDaysInView",stepStartDate.format("DD-MM-YYYY"));
			let styles = {width: this.userGantSettings.divisionWidth};
			let li = <li style={styles} className={!this.userGantSettings.isWorkingDay(stepStartDate.format("DD-MM-YYYY")) && "non-working-day"}></li>
			items.push(li)
		}
		return items;
	}

	renderDependancies = () => {
		if (!this.props.views || !this.props.views[this.props.view._id]){
			return false;
		}

		for (let projectId in this.props.views[this.props.view._id].projects){
			let project = this.props.views[this.props.view._id].projects[projectId];

			console.log("project", project)
			for (let releaseId in project.releases){
				let release = project.releases[releaseId];
				console.log("release", release);
				for (let storyId in release.stories){
					let story = release.stories[storyId];
					console.log("story", story);
					for (let taskId in story.tasks){
						let task = story.tasks[taskId];
						console.log("task", task);
						task.dependancyTaskIds && task.dependancyTaskIds.forEach((dependancyTaskId)=>{
							let dependancyLine = {
								fromTaskId: dependancyTaskId,
								toTaskId: taskId
							};
							let fromTaskEle = document.querySelectorAll('[data-task-id="' + dependancyLine.fromTaskId + '"]');
							console.log("fromTaskEle",fromTaskEle);
						});
					}
				}	
			}
		}
	}

	render() {
		return (
			<Fragment>
				<div className="gantt-holder-outer">
					<div className="gantt-holder-inner">

						{/* <div className="now-time">
							<div class="line"></div>
						</div> */}
						
                        <div className="side-bar-holder">
								<div class="side-bar-headers">
									<div class="row">
										<div class="col-xs-8"><h5>Projects</h5></div>
										<div class="col-xs-2">Est.</div>
										<div class="col-xs-1">Act.</div>
										<div class="col-xs-1"><span class="glyphicon glyphicon-user"></span></div>
									</div>
									<span className="jump-btn" onClick={()=>this.clickActions.setScrollToTodaysDate()}></span>
								</div>
                                <ul class="projects-list">
									{this.renderProjects(true)}
                                </ul>
                        </div>

                        <div id="slotsHolderOuter" className="slots-holder-outer">
                            <div className="slots-holder-inner" style={this.setSlotsHolderInnerWidth()}>
								<div className="now-time" id="nowTime">
									<div id="nowTimeText"></div>
									<div class="line"></div>
								</div>
								<div className="slots-header-holder-outer">
									<div className="slots-header-holder-inner">
										<ul>
											{this.renderHeader()}
										</ul>
									</div>
								</div>
								<div className="slots-content-holder">
									<ul class="non-working-time">
										{this.renderNonWorkingTime()}
									</ul>
									<ul class="task-slots">
										{this.renderProjects(false)}
									</ul>
									<div class="dependancies">
										{this.renderDependancies()}
									</div>
								</div>
                            </div>
                        </div>
                        
                    </div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	...state.gantt.tasksByProjectByViews
});

const mapDispatchToProps = {
	getTasksByProjectForView,
	getTasksByProjectForViewForProject,
	getTasksByStoryForView,
	updateScheduleSlot
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ExpGantt);

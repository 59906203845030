// import superagentPromise from "superagent-promise";
// import _superagent from "superagent";
import axios from 'axios';
import openSocket from 'socket.io-client';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import store from './store';

// const superagent = superagentPromise(_superagent, global.Promise);

console.log('process.env', process.env);

const TRKR_SERVER_BASE_URL = process.env.REACT_APP_TRKR_SERVER_BASE_URL;

if (!TRKR_SERVER_BASE_URL) {
	throw new Error('You must set TRKR_SERVER_BASE_URL within your Environment.');
} else {
	console.log('TRKR_SERVER_BASE_URL', TRKR_SERVER_BASE_URL);
}

const Socket = openSocket(TRKR_SERVER_BASE_URL);
const API_ROOT = TRKR_SERVER_BASE_URL + '/api';

console.log('process.env : ', process.env);

console.log('API_ROOT', API_ROOT);

const responseBody = res => {
	store.dispatch(hideLoading());
	return res.data;
};

const errorHandling = error => {
	store.dispatch(hideLoading());
	throw error;
};

axios.defaults.withCredentials = true;

const requests = {
	post: (url, body) => {
		store.dispatch(showLoading());
		return axios
			.post(`${API_ROOT}${url}`, body)
			.then(responseBody)
			.catch(errorHandling);
	},
	get: (url, query) => {
		store.dispatch(showLoading());
		return axios
			.get(`${API_ROOT}${url}`, {params: query})
			.then(responseBody)
			.catch(errorHandling);
	},
	put: (url, body) => {
		store.dispatch(showLoading());
		return axios
			.put(`${API_ROOT}${url}`, body)
			.then(responseBody)
			.catch(errorHandling);
	},
	delete: url => {
		store.dispatch(showLoading());
		return axios
			.delete(`${API_ROOT}${url}`)
			.then(responseBody)
			.catch(errorHandling);
	},
};

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const encode = encodeURIComponent;
const omitSlug = task => Object.assign({}, task, { slug: undefined });

const Tasks = {
	all: (filter, showDeleted) =>
		requests.get(
			`/tasks${filter ? '?filter=' + filter : ''}${
				showDeleted ? (filter ? '&' : '?') + 'showDeleted=' + showDeleted : ''
			}`
		),
	getByProject: project => requests.get(`/tasks/?project=${encode(project)}`),
	assignedTo: (userId, filter, showDeleted) =>
		requests.get(
			`/tasks?assignedTo=${encode(userId)}${filter ? '&filter=' + filter : ''}${
				showDeleted ? '&showDeleted=' + showDeleted : ''
			}`
		),
	getByStatus: (assignedTo, statusSlug) =>
		requests.get(`/tasks?assignedTo=${encode(assignedTo)}${statusSlug ? '&statusSlug=' + statusSlug : ''}`),
	get: id => requests.get(`/tasks/${id}`),
	update: task => requests.put(`/tasks/${task._id}`, { task: omitSlug(task) }),
	updateBatch: tasks => requests.put(`/tasks/`, { tasks: tasks }),
	create: task => requests.post('/tasks', { task }),
	getTasksByViews: viewIds => requests.get(`/tasks/by-views/?ids=${viewIds.join(',')}`),
	// getTasksByViewByProject - used by GANTT chart, do not amend unless discussed with DA / JS
	getTasksByViewByProject: viewId => requests.get(`/tasks/by-view/${viewId}/by-project`),
	getTasksByViewByStatus: (viewId, limit, skip) =>
		requests.get(`/tasks/by-view/${viewId}/by-status?limit=${limit}&skip=${skip}`),
	noEstimatesByView: viewId => requests.get(`/tasks/by-view/${viewId}/noEstimates`),
};

const Projects = {
	all: () => requests.get('/projects'),
	allWithTree: () => requests.get('/projects/tree'),
	get: id => requests.get(`/projects/${id}`),
	getTreeBySlug: slug => requests.get(`/projects/tree/bySlug/${slug}`),
	getBySlug: slug => requests.get(`/projects/bySlug/${slug}`),
	update: project => requests.put(`/projects/${project.id}`, { project: omitSlug(project) }),
	updateBySlug: project =>
		requests.put(`/projects/bySlug/${project.slug}`, {
			project: omitSlug(project),
		}),
	create: project => requests.post('/projects', { project }),
};

const Status = {
	all: showDeleted => requests.get(`/status${showDeleted ? '?showDeleted=' + showDeleted : ''}`),
	create: status => requests.post('/status', { status }),
};

const Categories = {
	all: () => requests.get('/categories'),
	create: category => requests.post('/categories', { category }),
};

const Commits = {
	all: () => requests.get('/wh-commits'),
	getByTask: taskId => requests.get(`/tasks/${taskId}/wh-commits`),
};

const Releases = {
	all: () => requests.get('/releases'),
	get: id => requests.get(`/releases/${id}`),
	getByProject: (projectSlug, projectId) =>
		requests.get(
			projectId ? `/releases/?projectId=${encode(projectId)}` : `/releases/?projectSlug=${encode(projectSlug)}`
		),
	// getByTask: taskSlug => requests.get(`/releases/?taskSlug=${encode(taskSlug)}`),
	getByTaskCounter: workspaceCounter => requests.get(`/releases/?taskCounter=${encode(workspaceCounter)}`),
	getByStoryCounter: workspaceCounter => requests.get(`/releases/?storyCounter=${encode(workspaceCounter)}`),
	create: release => requests.post('/releases', { release }),
	update: release => requests.put(`/releases/${release.id}`, { release: omitSlug(release) }),
};

const Clients = {
	all: () => requests.get('/clients'),
	create: client => requests.post('/clients', { client }),
};

const Teams = {
	all: () => requests.get('/teams'),
	create: team => requests.post('/teams', { team }),
};

const Priorities = {
	all: () => requests.get(`/priorities`),
	create: priority => requests.post('/priorities', { priority }),
};

const Resources = {
	all: () => requests.get('/resources'),
	create: resource => requests.post('/resources', { resource }),
};

// referenced in Resources model, shows when a user (or "resource") is unavailable, ie. for holidays / weekends etc
const Unavailabilities = {
	all: () => requests.get('/unavailabilities'),
	create: unavailability => requests.post('/unavailabilities', { unavailability }),
};

const ScheduleSlots = {
	all: () => requests.get('/scheduleSlots'),
	get: id => requests.get(`/scheduleSlots/${id}`),
	// NOTE: paginated by date - used for GANTT chart
	getScheduleSlotsByView: ({ viewId, startDate, endDate }) =>
		requests.get(`/scheduleSlots/by-view/${viewId}?startDate=${startDate}&endDate=${endDate}`),
	getByTask: id => requests.get(`/scheduleSlots/byTask/${id}`),
	create: scheduleSlot => requests.post('/scheduleSlots', { scheduleSlot }),
	update: scheduleSlot => requests.put(`/scheduleSlots/${scheduleSlot._id}`, { scheduleSlot }),
	updateBatch: scheduleSlots => requests.put(`/scheduleSlots/`, { scheduleSlots: scheduleSlots }),
};

// TODO: locate where endpoints are used;
// 1. project is not specified within story model
// 2.
const Stories = {
	all: (filter, showDeleted) =>
		requests.get(
			`/stories${filter ? '?filter=' + filter : ''}${
				showDeleted ? (filter ? '&' : '?') + 'showDeleted=' + showDeleted : ''
			}`
		),
	getByProject: (projectSlug, projectId) =>
		requests.get(
			projectId ? `/stories/?projectId=${encode(projectId)}` : `/stories/?projectSlug=${encode(projectSlug)}`
		),
	getByTaskCounter: workspaceCounter => requests.get(`/stories/?taskCounter=${encode(workspaceCounter)}`),
	get: id => requests.get(`/stories/${id}`),
	getByCounter: counter => requests.get(`/stories/byCounter/${counter}`),
	update: update => requests.put(`/stories/${update.id}`, update),
	updateByCounter: story =>
		requests.put(`/stories/byCounter/${story.workspaceCounter}`, {
			story: omitSlug(story),
		}),
	updateBatch: stories => requests.put(`/stories/`, { stories: stories }),
	create: story => requests.post('/stories', { story }),
};

const Tags = {
	all: () => requests.get('/tags'),
	create: tag => requests.post('/tags', { tag }),
};

const Activities = {
	getByTask: ({ taskId, startDate, endDate, userId }) =>
		requests.get(`/activities/byTask/${taskId}?startDate=${startDate}&endDate=${endDate}&setAsReadBy=${userId}`),
	getByStory: id => requests.get(`/activities/byStory/${id}`),
	getByView: ({ id, startDate, endDate }) =>
		requests.get(`/activities/byView/${id}?startDate=${startDate}&endDate=${endDate}`),
};

const Messages = {
	all: () => requests.get('/messages'),
	get: id => requests.get(`/messages/${id}`),
	getByTask: id => requests.get(`/messages/byTask/${id}`),
	getByStory: id => requests.get(`/messages/byStory/${id}`),
	create: message => requests.post('/messages', { message }),
	getUnreadMessagesForUser: (userId, skip, limit) =>
		requests.get(`/messages/unreadByUser/${userId}?limit=${limit}&skip=${skip}`),
};

const Intervals = {
	getUserIntervals: (skip, limit) => requests.get(`/intervals/by-user?limit=${limit}&skip=${skip}`),
	getTotalDurationForUser: query => requests.get('/intervals/duration-by-user'),
	get: id => requests.get(`/intervals/${id}`),
	getByTask: taskid => requests.get(`/intervals/byTask/${taskid}`),
	/*getByTaskSlug: taskSlug =>
		requests.get(`/intervals/byTaskSlug/${taskSlug}`),*/
	getByTaskCounter: workspaceCounter => requests.get(`/intervals/byTaskCounter/${workspaceCounter}`),
	update: interval => requests.put(`/intervals/${interval.id}`, { interval: interval }),
	current: () => requests.get('/intervals/current'),
	last: () => requests.get('/intervals/last'),
	stop: interval => requests.put(`/intervals/${interval.id}/stop`),
	stopAll: () => requests.post(`/intervals/stop`),
	create: task => requests.post('/intervals', { task }),
	getServerDate: () => requests.get('/intervals/date'),
	getRunningIntervalsByTask: () => requests.get('/intervals/running/by-task')
};

const Auth = {
	current: () => requests.get('/users/current'),
	login: (email, password) => requests.post('/users/login', { user: { email, password } }),
	logout: () => requests.get('/users/logout'),
	register: (email, password) => requests.post('/users', { user: { email, password } }),
	update: update => requests.put('/users/current', update),
	authenticate: (subscriptionId, token) => requests.post('/users/authenticate', { subscriptionId, token }),
	createNewEmail: (email, password) => requests.post('/users/createNewEmail', { email, password }),
	confirmEmailAddress: token => requests.post('/users/confirmEmailAddress', { token }),
	checkPassword: password => requests.post('/users/checkPassword', { password }),
	resetPassword: (email, reCaptcha) => requests.post('/users/resetPassword', { email, reCaptcha }),
	verifyToken: token => requests.get(`/users/verifyToken/${token}`),
	createNewPassword: (secret, password, passwordConfirmation, reCaptcha) =>
		requests.post('/users/createNewPassword', { secret, password, passwordConfirmation, reCaptcha }),
};

const Users = {
	all: query => requests.get('/users' + (query ? query : '')),
	checkEmailExists: email => requests.get(`/users/checkEmailExists/${email}`),
};

const Views = {
	all: () => requests.get('/views'),
	get: id => requests.get(`/views/${id}`),
	create: () => requests.post('/views', {}),
	delete: viewId => requests.delete(`/views/${viewId}`),
	update: (viewId, update) => requests.put(`/views/${viewId}`, update),
	updateBatch: views => requests.put(`/views/`, { views }),
};

const Subscriptions = {
	update: (token, subscriptionId) => requests.put('/subscriptions', { token, subscriptionId }),
	get: subscriptionId => requests.get(`/subscriptions/${subscriptionId}`),
	checkSubscriptionCompleted: subscriptionId =>
		requests.get(`/subscriptions/checkSubscriptionCompleted/${subscriptionId}`),
	getActiveSubscriptionsByUser: userId => requests.get(`/subscriptions/ActiveSubscriptionsByUser/${userId}`),
	getInvoicesForSubscription: subscriptionId =>
		requests.get(`/subscriptions/invoicesForSubscription/${subscriptionId}`),
	cancelSubscription: subscriptionId => requests.put(`/subscriptions/cancelSubscription/${subscriptionId}`),
};

const Workspaces = {
	update: (workspaceId, updateObject) => requests.put(`/workspaces/${workspaceId}`, updateObject),
	inviteUsers: (workspaceId, emails) => requests.post('/workspaces/inviteUsers', { workspaceId, emails }),
	get: workspaceId => requests.get(`/workspaces/${workspaceId}`),
	getByUser: userId => requests.get(`/workspaces/byUser/${userId}`),
};

const WorkspaceUsers = {
	getWorkspaceUsers: WorkspaceId => requests.get(`/workspaceUsers/byWorkspace/${WorkspaceId}`),
	getActiveWorkspaceUsers: (workspaceId, subscriptionId) =>
		requests.get(`/workspaceUsers/activeWorkspaceUsers?w=${workspaceId}&s=${subscriptionId}`),
	update: (workspaceId, update) => requests.put(`/workspaceUsers/${workspaceId}`, update),
	getByUser: userId => requests.get(`/workspaceUsers/byUser/${userId}`),
};

const ExpGantt = {
	getTasksByProjectForView: (viewId, query) => requests.get(`/gantt/tasks-by-project/${viewId}`, query),
	getTasksByProjectForViewForProject: (viewId, projectId, query) => requests.get(`/gantt/tasks-by-project/${viewId}/for-project/${projectId}`),
	getTasksByStoryForView: (storyId, viewId, query) => requests.get(`/gantt/tasks-by-story/${storyId}/for-view/${viewId}`, query),
	expandItem: (viewId, type, id, expand) => requests.get(`/gantt/by-view/${viewId}/expand/${type}/${id}/${expand}`), //TODO To be Put but moans about CORS current;ly?
	updateScheduleSlot: (taskId, scheduleId, newStartDate) => requests.put(`/gantt/update-schedule/${taskId}/${scheduleId}`, {startDate: newStartDate})
};

export default {
	API_ROOT,
	Activities,
	Auth,
	Categories,
	Clients,
	Commits,
	Intervals,
	Messages,
	Releases,
	Projects,
	Status,
	Tags,
	Tasks,
	Teams,
	Users,
	Resources,
	ScheduleSlots,
	Stories,
	Priorities,
	Unavailabilities,
	Views,
	Subscriptions,
	Workspaces,
	WorkspaceUsers,
	Socket,
	ExpGantt
};

// Makes sure that action and reducers use the same strings.
export const CREATE_VIEW_SUCCESS = 'CREATE_VIEW_SUCCESS';
export const CREATE_VIEW_ERROR = 'CREATE_VIEW_ERROR';
export const GET_VIEWS_SUCCESS = 'GET_VIEWS_SUCCESS';
export const GET_VIEWS_ERROR = 'GET_VIEWS_ERROR';
export const GET_VIEW_SUCCESS = 'GET_VIEW_SUCCESS';
export const GET_VIEW_ERROR = 'GET_VIEW_ERROR';
export const DELETE_VIEW_SUCCESS = 'DELETE_VIEW_SUCCESS';
export const DELETE_VIEW_ERROR = 'DELETE_VIEW_ERROR';
export const UPDATE_VIEW_SUCCESS = 'UPDATE_VIEW_SUCCESS';
export const UPDATE_VIEW_ERROR = 'UPDATE_VIEW_ERROR';
export const UPDATE_VIEWS_SUCCESS = 'UPDATE_VIEWS_SUCCESS';
export const UPDATE_VIEWS_ERROR = 'UPDATE_VIEWS_ERROR';

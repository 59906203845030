import React from 'react';

import { formatDate, formatDuration2, getIntervalsByDate, formatDuration20 } from '../../lib/Utils';

import IntervalCard from '../IntervalCard';

import './index.scss';

import moment from 'moment';

class RecentList extends React.Component {
	render() {
		if (!this.props.intervals) {
			return null;
		}
		const intervals = this.props.intervals.filter(interval => interval.endDateTime);
		const intervalsByDate = getIntervalsByDate(intervals);
		return (
			<div className={`recent-list ${this.props.className}`} onScroll={this.props.handleScroll}>
				<div className="row">
					<div className="col-xs-12 ">
						{intervalsByDate.map(group => {
							return (
								<ul className="intervals-list" key={group.date}>
									<li className="intervals-list-item intervals-list-item-first">
										<div>
											<h5 className="intervals-list-item-first-date">
												{/* {group} */}
												{formatDate(group.date, 'dddd, Do MMMM', true)}
											</h5>

											<h5 className="intervals-list-item-first-time">
												{formatDuration20(group.totalDuration)}
												{/* {moment.duration(100,'milliseconds')} */}
												{/* {group.totalDuration}
												<span>{formatDuration2(group.totalDuration, 'HH')}</span>
												<span className="font-weight-normal"> h </span>
												<span>{formatDuration2(group.totalDuration, 'mm')}</span>
												<span className="font-weight-normal"> m </span> */}
											</h5>
										</div>
									</li>
									{group.intervals &&
										group.intervals.length > 0 &&
										group.intervals.map((interval, i) => {
											return (
												<li className="intervals-list-item" key={i}>
													<IntervalCard
														interval={interval}
														style="timermenu"
														onStart={this.props.onCardClick}
														key={interval.id}
														match={this.props.match}
													/>
												</li>
											);
										})}
								</ul>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default RecentList;

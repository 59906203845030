import { RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR } from './constants';

const initialState = {
	resetPasswordEmailSent: false,
	errors: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				resetPasswordEmailSent: action.resetPasswordEmailSent,
			};
		case RESET_PASSWORD_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		default:
			return state;
	}
};

export default reducer;

import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

import agent from '../../../../../../agent';
import history from '../../../../../../lib/History';

import Column from './Column';

import { cloneArray } from '../../../../../../lib/Utils';

import { onShowModalNewTask, onShowModalExistingTask, onRedirect } from '../../../../../App/actions';
import { getTasksByViewByStatus, updateTasks } from '../../../../../Tasks/actions';
import { updateView, getViewById } from '../../../../../Views/actions';

import './index.scss';

// TODO:
// 1. utilise redux, in order to place tasks into store
// 2. map current intervals to tasks

// undo the view reloading once a tab is switched.

const Kanban = (props) => {
	let dragging = false;
	const [statuses, setStatuses] = useState([]);
	const [taskIdsByStatuses, setTaskIdsByStatuses] = useState();
	const [tasksByStatuses, setTasksByStatuses] = useState();

	useEffect(() => {
		//No limit and skip values will return default limit skip values as per server
		props.getTasksByViewByStatus(props.view._id, 0, true, props.view.filters, null);
	}, []);

	useEffect(() => {
		props.getTasksByViewByStatus(
			props.view._id,
			0,
			true,
			props.view.filters,
			null,
			props[props.view._id] ? props[props.view._id].nextSkip : undefined
		);
	}, [props.intervalCreated]);

	useEffect(() => {
		props.getTasksByViewByStatus(
			props.view._id,
			0,
			true,
			props.view.filters,
			null,
			props[props.view._id] ? props[props.view._id].nextSkip : undefined
		);
	}, [props.intervalUpdated]);

	// adding tasks to Kanban when each view is mounted or when a new task is created or when a view's filter is changed.
	useEffect(() => {
		if (props[props.view._id]) {
			setStatuses(props[props.view._id].statuses);
			setTaskIdsByStatuses(props[props.view._id].taskIdsByStatuses);
			setTasksByStatuses(props[props.view._id].tasksByStatuses);
		}
	}, [props[props.view._id]]);

	const loadNextTasks = (skip, filters, empty) => {
		if (typeof skip === 'undefined') {
			skip = props[props.view._id].nextSkip || 0;
		}
		const tasksByStatuses = {};
		for (let status in taskIdsByStatuses) {
			tasksByStatuses[status] = taskIdsByStatuses[status].map((taskId) => props.tasksById[taskId]);
		}
		props.getTasksByViewByStatus(props.view._id, skip, empty, filters, tasksByStatuses);
	};

	const handleScroll = (event) => {
		let isBottom =
			event.currentTarget.scrollTop >= event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
		if (isBottom) {
			// console.log('Reached bottom');
			loadNextTasks();
		}
	};

	const renderStatusColumns = () => {
		const views = cloneArray(props.views);
		const view = views.filter((view) => view._id === props.view._id)[0];
		let statusColumns = [];

		for (let statusKey in statuses) {
			let status = statuses[statusKey];
			let statusId = status._id;
			const columnSetting = view.columnSettings.filter((columnSetting) => columnSetting.status === statusId)[0];

			//Create column object until Column component is refactored to be more specific on props.
			let column = {
				title: status.name,
				name: status.name,
				_id: statusId,
			};

			// TODO: make the columns more unique, by including the view _id
			statusColumns.push(
				<Column
					key={`${props.view._id}-${status.name}`}
					viewId={props.view._id}
					taskIds={taskIdsByStatuses[status.name]}
					column={column}
					handleScroll={handleScroll}
					showAddButton={true}
					showTimer={true}
					updateView={updateView}
					columnSetting={columnSetting}
				/>
			);
		}
		return statusColumns;
	};

	const updateView = async (update) => {
		await props.updateView(props.view._id, update);
		props.getViewById(props.view._id);
	};

	// shows the TaskModal
	const cardClick = ({ _id }) => {
		// console.log('[cardClick] dragging:', dragging);

		// console.log('[cardClick] history', history, getBasePath());

		if (dragging !== true) {
			// console.log('a card was clicked', taskId, workspaceCounter);
			// go to task details
			// TODO: check correctly map the board index to prevent board re-render
			// console.clear();
			// console.log('cardClick', history.location.pathname);
			// history.push(`${history.location.pathname}/task/${workspaceCounter}`);
			props.onShowModalExistingTask({ _id });
		}
	};

	const onDragStart = () => {
		dragging = true;
	};

	// helper to swap positions of tasks within a single column
	// TODO: update to work with mulitple columns
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	// stopped draggin a task card
	// column information now coming from the view model,
	// this needs to be refactored to CRUD operations accordingly
	const onDragEnd = (result) => {
		const { destination, source, draggableId, type } = result;
		// console.log('onDragEnd', destination, source, draggableId, type);

		dragging = false;

		// null handling
		if (!result.destination) {
			return;
		}

		// TODO:
		// 1. FRONT-END reorder the cards as dictated by the drop position for the
		let status; // determined from the droppableId of the destination / source
		let orderedContent;
		let newTasksIdsByStatuses = { ...taskIdsByStatuses };
		let taskUpdates = [];
		let sourceStatus, destinationStatus;

		// console.log('onDragEnd status list', state.statuses);

		// TODO: split into separate functions?
		// if the target column is the same as the destination, only a singular re-order is required,
		// otherwise perform it on both the target and destination columns
		if (destination.droppableId === source.droppableId) {
			sourceStatus = statuses.filter((s) => {
				return String(s._id) === String(destination.droppableId);
			})[0].name;

			orderedContent = reorder(taskIdsByStatuses[sourceStatus], source.index, destination.index);

			newTasksIdsByStatuses[sourceStatus] = orderedContent;
		} else {
			// get status relevant status names, from column id's
			sourceStatus = statuses.filter((s) => {
				return String(s._id) === String(source.droppableId);
			})[0].name;

			destinationStatus = statuses.filter((s) => {
				return String(s._id) === String(destination.droppableId);
			})[0].name;

			// splice TaskCard from source list...
			const [removed] = newTasksIdsByStatuses[sourceStatus].splice(source.index, 1);

			// ...and add to destination list
			newTasksIdsByStatuses[destinationStatus].splice(destination.index, 0, removed);
		}

		// console.log('onDragEnd', newTasksIdsByStatuses);

		// update the state for the new positions to be set
		setTaskIdsByStatuses(newTasksIdsByStatuses);

		// TODO:
		// 2. BACK-END: ping the endpoint to update new task positions, shouldn't need to re-render

		for (let i = 0, cardId, len = newTasksIdsByStatuses[sourceStatus].length; i < len; i++) {
			cardId = newTasksIdsByStatuses[sourceStatus][i];
			const topTask = tasksByStatuses[sourceStatus].reduce((prev, current) =>
				prev.orderWeight > current.orderWeight ? prev : current
			);
			const orderWeightOfTopTask = topTask.orderWeight;
			let orderWeight = orderWeightOfTopTask - i;
			// TODO: need a better way to set orderWeights considering the pagination doesn't load all the tasks
			taskUpdates.push({ _id: cardId, statusId: source.droppableId, orderWeight: orderWeight });
		}

		if (destination.droppableId !== source.droppableId) {
			for (let i = 0, cardId, len = newTasksIdsByStatuses[destinationStatus].length; i < len; i++) {
				cardId = newTasksIdsByStatuses[destinationStatus][i];
				let orderWeightOfTopTask;
				if (tasksByStatuses[destinationStatus].length === 0) {
					orderWeightOfTopTask = 0;
				} else {
					const topTask = tasksByStatuses[destinationStatus].reduce((prev, current) =>
						prev.orderWeight > current.orderWeight ? prev : current
					);
					orderWeightOfTopTask = topTask.orderWeight;
				}
				let orderWeight = orderWeightOfTopTask - i + 1;
				taskUpdates.push({ _id: cardId, statusId: destination.droppableId, orderWeight: orderWeight });
			}
		}

		// 	i. if there is an issue with the update, then show an error toastr to the user

		// separate action for updating tasks only.
		props.updateTasks(taskUpdates, props.tasks);

		// 3. if the destination column is "in progress", and the task is assigned to the current user, then start an interval
	};

	return (
		<Fragment>
			<div className="kanban-wrapper">
				<DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
					<div className="kanban-container">{renderStatusColumns()}</div>
				</DragDropContext>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	...state.tasks,
	...state.intervals,
	views: state.views.views,
});

const mapDispatchToProps = {
	getTasksByViewByStatus,
	onRedirect,
	onShowModalNewTask,
	onShowModalExistingTask,
	updateTasks,
	updateView,
	getViewById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Kanban);

export const WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_SUCCESS = 'WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_SUCCESS';
export const WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_ERROR = 'WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_ERROR';
export const WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_RESET = 'WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_RESET';
export const WORKSPACE_USERS_GET_WORKSPACE_USERS_SUCCESS = 'WORKSPACE_USERS_GET_WORKSPACE_USERS_SUCCESS';
export const WORKSPACE_USERS_GET_WORKSPACE_USERS_ERROR = 'WORKSPACE_USERS_GET_WORKSPACE_USERS_ERROR';
export const WORKSPACE_USERS_UPDATE_WORKSPACE_USER_SUCCESS = 'WORKSPACE_USERS_UPDATE_WORKSPACE_USER_SUCCESS';
export const WORKSPACE_USERS_UPDATE_WORKSPACE_USER_ERROR = 'WORKSPACE_USERS_UPDATE_WORKSPACE_USER_ERROR';
export const WORKSPACE_USERS_UPDATE_WORKSPACE_USER_RESET = 'WORKSPACE_USERS_UPDATE_WORKSPACE_USER_RESET';
export const WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_SUCCESS =
	'WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_SUCCESS';
export const WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_ERROR = 'WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_ERROR';

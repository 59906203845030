import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import View from './components/View';

import { updateWorkspaceUser } from '../WorkspaceUsers/actions';

import { getCurrentUser } from '../Users/actions';

import { createView, getViews, deleteView, updateViews } from './actions';
import { debounce } from '../../lib/Utils';

import './index.scss';

const getTabStyle = (isDragging, draggableStyle, active) => ({
	// change background colour if dragging
	background: isDragging ? '#2A2E37' : active ? '#2A2E37' : '#353945',
	// styles we need to apply on draggables
	...draggableStyle,
});

const getTabsStyle = (isDraggingOver) => ({
	display: 'flex',
	overflow: 'auto',
});

const Views = (props) => {
	const [selectedView, setSelectedView] = useState('');
	const [views, setViews] = useState([]);

	useEffect(() => {
		props.getViews();
	}, []);

	useEffect(() => {
		if (isSelectedViewNotSet()) {
			const selectedView = props.currentUser?.workspaceUserForWorkspace?.selectedView ?? props.views[0]._id;
			setSelectedView(selectedView);
		}
	}, [props.currentUser]);

	useEffect(() => {
		const selectedView = props.currentUser?.workspaceUserForWorkspace?.selectedView;
		setSelectedView(selectedView);
	}, [props.currentUser]);

	useEffect(() => {
		setViews(props.views);
	}, [props.views]);

	const isSelectedViewNotSet = () => {
		return !selectedView && props.currentUser;
	};

	const addBoard = async () => {
		await props.createView();
		await props.getViews();
		props.getCurrentUser();
	};

	const isSelectedViewBeingDeleted = (viewId) => {
		return selectedView === viewId;
	};
	const removeBoard = async (viewId) => {
		//Ensure what was selected before remains selected now when tabs before the currently selected are removed.
		if (isSelectedViewBeingDeleted(viewId)) {
			const viewIds = views.map(function (x) {
				return x._id;
			});
			const indexOfSelectedView = viewIds.indexOf(selectedView);
			const newSelectedView = indexOfSelectedView
				? views[indexOfSelectedView - 1]
				: views[indexOfSelectedView + 1];
			setSelectedView(newSelectedView);
			await updateSelectedView(newSelectedView);
		}
		await props.deleteView(viewId);
		await props.getViews();
	};

	const selectBoard = async (viewId) => {
		setSelectedView(viewId);
		await updateSelectedView(viewId);
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const boardMoved = async (result) => {
		const { destination, source, draggableId, type } = result;
		if (source && destination) {
			const reOrderedViews = reorder(views, source.index, destination.index);
			const viewsUpdate = [];
			for (const [index, view] of reOrderedViews.entries()) {
				viewsUpdate.push({
					_id: view._id,
					orderWeight: index,
				});
				view.orderWeight = index;
			}
			setViews(reOrderedViews);
			setSelectedView(draggableId);
			await props.updateViews(viewsUpdate);
			await props.getViews();
			await updateSelectedView(draggableId);
		}
	};

	const updateSelectedView = async (viewId) => {
		await props.updateWorkspaceUser(props.currentUser.workspaceUserForWorkspace._id, {
			selectedView: viewId,
		});
		await props.getCurrentUser();
	};

	const renderTabs = () => {
		// const selectedBoardIndex = this.state.selectedBoardIndex;
		// <<<<<<< HEAD
		// 		// const boards = [];
		// 		// const boards = {};
		// 		for (let i = 0; i < views.length; i++) {
		// 			//Board Tabs
		// 			boardTabs.push(
		// 				<button
		// 					key={views[i]._id}
		// 					className={'trkr-btn trkr-btn__board-tab ' + ((selectedBoardIndex === i && 'active') || '')}>
		// 					<span onClick={() => this.selectBoardIndex(i)}>{views[i].title}&nbsp;</span>
		// 					{views.length > 1 && (
		// 						<span
		// 							onClick={() => {
		// 								this.removeBoard(views[i]._id);
		// 							}} className="glyphicon glyphicon-remove">

		// 						</span>
		// 					)}
		// 				</button>
		// 			);
		// 			// //Board Content
		// 			// boards.push(<div className={"board " +  (selectedBoardIndex===i && 'active' || '')}><View key={views[i]} viewId={views[i]._id}  /></div>);
		// 		}
		// 		//Add Board Button
		// 		boardTabs.push(
		// 			<button key={'key-add-board'} className="trkr-btn trkr-btn__add trkr-btn--gradient trkr-btn--shadow trkr-btn--small trkr--margin-left-5" onClick={this.addBoard}></button>
		// =======
		return (
			// <div className="board-tabs"><label className="trkr-label trkr-label_boards">Boards:</label> {boardTabs}</div>
			<div style={{ display: 'flex' }} className="board-tabs">
				<label className="trkr-label trkr-label_boards">Boards:</label>
				<DragDropContext onDragEnd={boardMoved}>
					<Droppable droppableId="views-tabs" direction="horizontal">
						{(provided, snapshot) => (
							<div
								className="board-tabs"
								ref={provided.innerRef}
								style={getTabsStyle(snapshot.isDraggingOver)}
								{...provided.droppableProps}>
								{views.map((view, index) => (
									<Draggable key={view._id} draggableId={view._id} index={index}>
										{(provided, snapshot) => (
											<div
												key={view._id}
												className="trkr-btn trkr-btn__board-tab"
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getTabStyle(
													snapshot.isDragging,
													provided.draggableProps.style,
													selectedView === view._id
												)}>
												<span onClick={() => selectBoard(view._id)}>{view.title}&nbsp;</span>
												{views.length > 1 && (
													<span
														className="glyphicon glyphicon-remove"
														onClick={() => {
															removeBoard(view._id);
														}}></span>
												)}
											</div>
										)}
									</Draggable>
								))}
								<div className="trkr-btn__add-board">
									<div
										key={'key-add-board'}
										className="trkr-btn trkr-btn__add trkr-btn--gradient trkr-btn--shadow trkr-btn--small"
										onClick={addBoard}></div>
								</div>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			// >>>>>>> 01fd751d48adf4e35d0bc00954539bf93e00aa56
		);
	};

	return (
		<div className="views">
			{renderTabs()}
			<div className="boards">
				{views.map(function (view) {
					return (
						<div key={view._id} className={'board ' + ((selectedView === view._id && 'active') || '')}>
							<View view={view} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

Views.defaultProps = {
	views: [],
};

// TODO:
// 1. seperate the form elements so this can be added seperately elsewhere if needed
const mapStateToProps = (state) => ({
	views: state.views.views,
	currentUser: state.users.currentUser,
});

export default connect(mapStateToProps, {
	createView,
	getViews,
	deleteView,
	updateViews,
	updateWorkspaceUser,
	getCurrentUser,
})(Views);

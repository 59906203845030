import React from 'react';

import { formatDuration20, formatDuration } from '../../lib/Utils';

import './index.scss';
import IntervalTime from '../IntervalTime';

// NOTE: utilised in TaskCard and ProjectCard

// TODO: insert tick method here from TimerMenu
class ProgressBar extends React.Component {
	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.estimate !== nextProps.estimate) {
			return true;
		}
		if (this.props.elapsedTime !== nextProps.elapsedTime) {
			return true;
		}
		if (this.props.runningTimer !== nextProps.runningTimer) {
			return true;
		}
		if (this.props.interval !== nextProps.interval) {
			return true;
		}
		if (this.props.currentRunningTime !== nextProps.currentRunningTime) {
			return true;
		}
		return false;
	}
	render() {
		let estimate = this.props.estimate || 0;
		let elapsedTime = this.props.elapsedTime || 0;
		elapsedTime = elapsedTime / 1000;
		let prog,
			overrun,
			overrunClass = '';
		const currentRunningTime = Math.floor(this.props.currentRunningTime / 1000);
		elapsedTime += currentRunningTime;

		prog = Math.floor((elapsedTime / estimate) * 100);
		if (prog > 100) {
			overrun = Math.floor((estimate / elapsedTime) * 100);
			overrunClass = 'overrun';
			prog = 100;
		}

		return (
			<div className={'progress-bar-container ' + overrunClass}>
				<div className="time-detail elapsed">
					{!this.props.running && <p>{formatDuration20(this.props.ttlElapsedMilliseconds)}</p>}
					{this.props.running && <IntervalTime running={this.props.running} taskId={this.props.taskId} ttlElapsedMilliseconds={this.props.ttlElapsedMilliseconds} showTotalElapsedTime={true}/>}
				</div>

				<div className="flex-grow bar-container">
					<div className="progress-bar-bg position-relative">
						<div
							className={'progress-indicator-overrun'}
							style={{ width: `${overrun ? 100 - overrun : 0}%` }}
						/>
						<div className={'progress-bar'} style={{ width: ((overrun ? overrun : prog) || 0) + '%' }} />
					</div>
				</div>

				<div className="time-detail elapsed">
					<p className="">{formatDuration20(estimate)}</p>
				</div>
			</div>
		);
	}
}

export default ProgressBar;

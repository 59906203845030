// 'use strict';

import { Link } from 'react-router-dom';
import React from 'react';

import PageView from '../components/PageView';


class NotFound extends React.Component {
  render() {
    return (
		<PageView token={this.props.token}>
			<div className="notfound-page">

				<div className="container page text-center">
					<h1>The page youre looking for cannot be found!</h1>

					<p className="trkr-margin-top-30">
						<Link className="trkr-margin-0"
				          to="/">
				          Back to safety
				        </Link>
					</p>

					<iframe className="trkr-margin-top-30 giphy-embed" src="https://giphy.com/embed/LGVQJ4cQGPs8o" width="480" height="352" frameBorder="0" allowFullScreen></iframe>
					<p><a href="https://giphy.com/gifs/spaceballs-we-aint-found-shit-LGVQJ4cQGPs8o">via GIPHY</a></p>
				</div>
			</div>
		</PageView>
    );
  }
}

export default NotFound;

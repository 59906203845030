import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Notifications, { notify } from 'react-notify-toast';
import parseDomain from 'parse-domain';

import { getCurrentUserReset } from '../components/Users/actions';
import history from './History';

class Axios extends Component {
	componentDidMount() {
		axios.defaults.withCredentials = true;
		axios.interceptors.response.use(
			response => {
				if (response.data && response.data.info) {
					notify.show(response.data.info, 'success', 5000);
				}
				if (response.data && response.data.msg) {
					notify.show(response.data.msg, 'success', 5000);
				}

				// handle redirects from the server, as ajax requests seem to prevent this occurring
				if (response.data.redirectTo) {
					console.warn('REDIRECT REQUIRED', response.data.redirectTo);

					window.location.replace(response.data.redirectTo);
				}

				return response;
			},
			error => {
				// Do something with response error
				if (error && error.response && error.response.status === 401) {
					notify.show('Incorrect username or password.', 'error', 5000);
				}
				if (error && error.response && error.response.status === 403) {
					this.getCurrentUserReset();
					history.push('/login');
				}
				if (
					error &&
					error.response &&
					error.response.status === 500 &&
					error.response.data &&
					error.response.data.msg
				) {
					notify.show(error.response.data.msg, 'error', 5000);
				}
			}
		);

		// TODO: understand why this is needed?

		/*const parsedDomain = parseDomain(window.location.href);
		const domain = parsedDomain.domain;
		const tld = parsedDomain.tld;
		const subDomain = parsedDomain.subdomain;

		console.log('this.props.currentUser', this.props.currentUser);
		if (this.props.currentUser && subDomain !== this.props.currentUser.selectedWorkspace.slug) {
			const allowedUrl = `${window.location.protocol}//${
				this.props.currentUser.selectedWorkspace.slug
			}.${domain}.${tld}`;
			window.location.replace(allowedUrl);
		}*/
	}
	getCurrentUserReset = () => {
		this.props.getCurrentUserReset();
	};
	render() {
		return <Notifications />;
	}
}

const mapStateToProps = state => {
	return {};
};

export default connect(
	mapStateToProps,
	{
		getCurrentUserReset,
	}
)(Axios);

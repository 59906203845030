import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';
import { notify } from 'react-notify-toast';

import Form from '../Form';
import Chat from '../Chat';
import Modal from '../Modal';
import { Option, SingleValue } from '../ReactSelect';

import { formatOption, mapPriorityOptions, mapProjectOptions, mapReleaseOptions } from '../../lib/Utils';

import { addProject } from '../Projects/actions';

import { getProjectReleases, createRelease } from '../Releases/actions';

import { addPriority } from '../Priorities/actions';

import { addStory, getStory, updateStory } from '../Stories/actions';

import { onHideModal } from '../App/actions';

class ExistingStoryModal extends Component {
	state = {
		title: '',
		project: null,
		release: null,
		description: '',
		priority: null,
		releaseOptions: [],
	};
	constructor() {
		super();
		this.showToastr = notify.createShowQueue();
	}
	componentWillMount() {
		this.changeContentView('chat');
	}
	componentDidMount() {
		this.props.getStory(this.props._id);
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.newProjectAddedToStore(prevProps)) {
			this.setState(state => {
				return {
					...state,
					project: formatOption(this.props.newProject, 'project'),
				};
			});
			this.props.getProjectReleases(this.props.newProject.id);
		}
		if (this.projectReleasesAddedToStore(prevProps)) {
			this.setState(state => {
				const newState = {
					...state,
					releaseOptions: mapReleaseOptions(this.props[(this.state.project?.value)]?.releases),
				};
				const defaultRelease = this.props[(this.state.project?.value)]?.releases.filter(
					release => release.isDefault
				)[0];
				newState.release = formatOption(defaultRelease, 'release');
				return newState;
			});
		}
		if (this.newReleaseAddedToStore(prevProps)) {
			this.setState(state => {
				return {
					...state,
					release: formatOption(this.props.newRelease, 'release'),
				};
			});
		}
		if (this.newPriorityAddedToStore(prevProps)) {
			this.setState(state => {
				return {
					...state,
					priority: formatOption(this.props.newPriority, 'priority'),
				};
			});
		}
		if (this.StoryAddedToStore(prevProps)) {
			this.setState(state => {
				return {
					...state,
					title: this.props.story.title,
					project: formatOption(this.props.story.project, 'project'),
					release: formatOption(this.props.story.release, 'release'),
					description: this.props.story.description,
					priority: formatOption(this.props.story.priority, 'priority'),
					releaseOptions: mapReleaseOptions(this.props.story.releases),
				};
			});
		}
	}
	newProjectAddedToStore = prevProps => {
		return prevProps.newProject !== this.props.newProject;
	};
	projectReleasesAddedToStore = prevProps => {
		return (
			this.props[(this.state.project?.value)] &&
			prevProps[(this.state.project?.value)] !== this.props[(this.state.project?.value)]
		);
	};
	newReleaseAddedToStore = prevProps => {
		return prevProps.newRelease !== this.props.newRelease;
	};
	newPriorityAddedToStore = prevProps => {
		return prevProps.newPriority !== this.props.newPriority;
	};
	StoryAddedToStore = prevProps => {
		return prevProps.story !== this.props.story;
	};
	changeTitle = ev => {
		const title = ev.target.value;
		this.setState(state => {
			return {
				...state,
				title,
			};
		});
	};
	changeDescription = ev => {
		const description = ev.target.value;
		this.setState(state => {
			return {
				...state,
				description,
			};
		});
	};
	submitForm = async () => {
		// check all is valid
		if (!this.state.title || (!this.state.project || !this.state.project.value)) {
			console.log('StoryModal.submitForm', this.state.title, this.state.project);
			this.showToastr('Please provide required information', 'error');
			return;
		}
		const story = {
			id: this.props.story.id,
			title: this.state.title,
			description: this.state.description,
			project: this.state.project.value,
			release: this.state.release ? this.state.release.value : null,
			priority: this.state.priority ? this.state.priority.value : null,
		};
		await this.props.updateStory(story);
		this.props.getStory(this.props._id);
	};
	handleReleaseChange = newValue => {
		this.setState(state => {
			return {
				...state,
				release: newValue,
			};
		});
	};
	handlePriorityChange = newValue => {
		this.setState(state => {
			return {
				...state,
				priority: newValue,
			};
		});
	};
	addPriority = str => {
		this.props.addPriority(str);
	};
	handleProjectChange = newValue => {
		if (!newValue.value) {
			return;
		}
		this.setState(state => {
			return {
				...state,
				project: newValue,
			};
		});
		this.props.getProjectReleases(newValue.value);
	};
	addProject = str => {
		this.props.addProject(str);
	};
	changeContentView = target => {
		this.setState({ activeView: target });
	};
	toggleMobilePanel = () => {
		let show = !this.state.showMobilePanel || false;
		this.setState({ showMobilePanel: show });
	};
	onClose = () => {
		this.props.onHideModal();
	};
	renderForm = () => {
		return (
			<Form submit={this.submitForm}>
				<div className="form-group">
					<label htmlFor={'title'}>Title*</label>
					<input
						className="form-control"
						type="text"
						name={'title'}
						required={true}
						placeholder="Story Title"
						value={this.state.title}
						onChange={this.changeTitle}
					/>
					<div className="invalid-feedback" />
				</div>

				<div className="form-group">
					<label htmlFor={'project'}>Project*</label>
					<CreatableSelect
						className="react-select-custom"
						classNamePrefix="react-select-custom"
						components={{ Option, SingleValue }}
						name={'project'}
						required={true}
						onChange={this.handleProjectChange}
						value={this.state.project}
						options={this.props.projectOptions}
						onCreateOption={this.addProject}
						placeholder="Select a project"
					/>
					<div className="invalid-feedback" />
				</div>

				{this.state.project && (
					<div className="form-group">
						<label htmlFor={'release'}>Release</label>
						<CreatableSelect
							className="react-select-custom"
							classNamePrefix="react-select-custom"
							components={{ Option, SingleValue }}
							name={'release'}
							onChange={this.handleReleaseChange}
							value={this.state.release}
							options={this.state.releaseOptions}
							onCreateOption={str =>
								this.props.createRelease({ name: str, project: this.state.project.value })
							}
							placeholder="Select a release"
						/>
						<div className="invalid-feedback" />
					</div>
				)}

				<div className="form-group">
					<label htmlFor={'description'}>Description</label>
					<textarea
						className="form-control"
						rows="5"
						name={'description'}
						placeholder="As a user ..."
						value={this.state.description}
						onChange={this.changeDescription}
					/>
					<div className="invalid-feedback" />
				</div>

				<div className="form-group">
					<label htmlFor={'priority'}>Priority</label>

					<CreatableSelect
						className="react-select-custom"
						classNamePrefix="react-select-custom"
						components={{ Option, SingleValue }}
						name={'priority'}
						onChange={this.handlePriorityChange}
						value={this.state.priority}
						options={this.props.allPriorities}
						onCreateOption={this.addPriority}
						placeholder="Select a priority"
					/>
					<div className="invalid-feedback" />
				</div>
			</Form>
		);
	};
	render() {
		return (
			<Modal>
				<div
					className={
						'flex-container trkr-padding-lr-60 xs-trkr-padding-lr-30 md-display-flex' +
						(this.props.story ? ' col-md-7' : ' col-md-12') +
						(this.state.showMobilePanel ? ' hide' : '')
					}>
					<div className="modal-header flex-header md-trkr-padding-tb-15">
						{this.props.story && (
							<div className="row md-hide trkr-padding-tb-10">
								<div className="col-xs-6">
									<button
										className="trkr-icon-close position-fixed trkr-btn-close"
										onClick={this.onClose}
									/>
								</div>
								<div className="col-xs-6 text-right">
									<a
										className="font-color-white"
										onClick={() => {
											this.toggleMobilePanel();
										}}>
										Show panel
									</a>
								</div>
							</div>
						)}
						<div className="row vertical-align-middle">
							<div className="col-xs-12">
								<h5
									className={
										'header-title trkr-margin-top-0' + (!this.props.story ? ' text-center' : '')
									}>
									{this.props.story.title
										? `#${this.props.story.workspaceCounter} ${this.props.story.title}`
										: 'New Story'}

									{!this.props.story && (
										<button
											className="trkr-icon-close trkr-btn-close trkr-float-right"
											onClick={this.onClose}
										/>
									)}
								</h5>
								{/* breadcrumbs - removing default releases from view */}
								{this.props.story && this.props.story.release && (
									<p className="text-small trkr-margin-top-10">
										<Link
											to={`${this.props.baseUrl}/project/${String(
												this.props.story.release.project.slug
											).toLowerCase()}`}
											className="font-color-white">
											{this.props.story.release.project.title}
										</Link>
										{this.props.story.release && !this.props.story.release.isDefault && (
											<span>
												{' '}
												/{' '}
												<Link
													to={`${this.props.baseUrl}/release/${String(
														this.props.story.release._id
													).toLowerCase()}`}
													className="font-color-white">
													{this.props.story.release.name}
												</Link>
											</span>
										)}
									</p>
								)}
							</div>
						</div>
					</div>
					<div className={'flex-body'}>
						<div className="scrollable-content trkr-full-width trkr-margin-top-30">{this.renderForm()}</div>
					</div>
					<div className="flex-footer">
						<div className="trkr-full-width trkr-padding-tb-10 bg-grey-2" style={{ zIndex: 1 }}>
							<div className="row vertical-align-middle">
								<div className="col-xs-12 text-right">
									<button
										className="trkr-btn"
										type="submit"
										disabled={this.props.inProgress}
										onClick={() => {
											this.submitForm();
										}}>
										Update Story
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.props.story.workspaceCounter && (
					<div className="col-md-5 flex-container bg-color-dark activity-panel xs-trkr-padding-lr-30">
						<div className="modal-header flex-header">
							<div className="row md-hide trkr-padding-tb-10">
								<div className="col-xs-6">
									<button
										className="trkr-icon-close position-fixed trkr-btn-close"
										onClick={this.onClose}
									/>
								</div>
								<div className="col-xs-6 text-right">
									<a
										className="font-color-white"
										onClick={() => {
											this.toggleMobilePanel();
										}}>
										Show details
									</a>
								</div>
							</div>
							<div className="text-center trkr-full-width">
								<ul className="pill-nav-list">
									<li
										className={
											'nav-link ' +
											(!this.state.activeView || this.state.activeView === 'chat' ? 'active' : '')
										}
										onClick={() => {
											this.changeContentView('chat');
										}}>
										<a>Feed</a>
									</li>
								</ul>
							</div>
							<button
								className="trkr-icon-close trkr-btn-close position-absolute trkr-display-none md-show"
								onClick={this.onClose}
							/>
						</div>
						<div className="flex-body">
							{this.props.chatId && (
								<Chat
									forTask={this.props.chatId}
									className={'' + (this.state.activeView !== 'chat' ? 'hide' : '')}
								/>
							)}
							<div> TEST</div>
						</div>
					</div>
				)}
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		...state.storyEditor,
		...state.releases,
		allPriorities: mapPriorityOptions(state.app.allPriorities),
		projectOptions: mapProjectOptions(state.projects.allProjects),
		newProject: state.projects.newProject,
		newPriority: state.priorities.newPriority,
		story: state.stories.story,
	};
};

const mapDispatchToProps = {
	addProject,
	getProjectReleases,
	createRelease,
	addPriority,
	addStory,
	getStory,
	updateStory,
	onHideModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ExistingStoryModal);

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import ListErrors from '../../../ListErrors';
import Chat from '../../../Chat';
import IntervalList from '../../../IntervalList';
import FileThumbnails from '../../../FileThumbnails';
import Modal from '../../../Modal';

import TaskForm from '../TaskForm';
import Avatar from '../../../Icons/Avatar';
import ProgressBar from '../../../ProgressBar';

import history from '../../../../lib/History';
import { formatDate, changeEstimateToMilliseconds, milliSecondsToHoursAndMinutes } from '../../../../lib/Utils';

import { getTask, updateTask, getTaskReset } from '../../../Tasks/actions';
import { onHideModal, onShowModalExistingStory } from '../../../App/actions';

const ExistingTaskModal = (props) => {
	const [hasRunningTimer, setHasRunningTimer] = useState(false);
	const [defaultStatus, setDefaultStatus] = useState('backlog');
	const [defaultCategory, setDefaultCategory] = useState('task');
	const [defaultPriority, setDefaultPriority] = useState('medium');
	const [task, setTask] = useState({});
	const [activeView, setActiveView] = useState();
	const [showMobilePanel, setShowMobilePanel] = useState(false);

	useEffect(() => {
		if (props._id) {
			props.getTask(props._id);
		}
		changeContentView('chat');
	}, []);

	// need this logic for a running progressbar as in TaskCard
	useEffect(() => {
		if (props.task && !props?.intervals[0]?.endDateTime && !hasRunningTimer) {
			setHasRunningTimer(true);
		}
	}, [props.task]);

	useEffect(() => {
		if (props.taskUpdated) {
			setTimeout(() => {
				props.getTask(props._id);
			}, 500);
		}
	}, [props.taskUpdated]);

	// setting initial task state so that files can be updated
	useEffect(() => {
		if (props.task && Object.entries(task).length === 0) {
			const task = props.task;
			const modifiedTask = props.modifiedTask;
			const combinedTask = {
				_id: task._id,
				workspaceCounter: task.workspaceCounter,
				title: task.title,
				description: task.description,
				flagNotes: task.flagNotes,
				estimate: milliSecondsToHoursAndMinutes(task.estimate),
				managementEstimate: milliSecondsToHoursAndMinutes(task.managementEstimate),
				originalEstimate: task.originalEstimate,
				status: modifiedTask.status ? modifiedTask.status : null,
				category: modifiedTask.category ? modifiedTask.category : null,
				project: modifiedTask.project ? modifiedTask.project : null,
				story: modifiedTask.story ? modifiedTask.story : null,
				taskDependencies: modifiedTask.taskDependencies
					? modifiedTask.taskDependencies.map((task) => task.value)
					: null,
				priority: modifiedTask.priority ? modifiedTask.priority : null,
				assignedTo: modifiedTask.assignedTo ? modifiedTask.assignedTo : null,
			};
			setTask(combinedTask);
		}
	}, [props.task]);

	useEffect(() => {
		return () => {
			setTask({});
			props.getTaskReset();
		};
	}, []);

	const changeContentView = (target) => {
		setActiveView(target);
	};

	const toggleMobilePanel = () => {
		let show = !showMobilePanel || false;
		setShowMobilePanel(show);
	};

	const onClose = () => {
		props.onHideModal();
	};
	// used to switch to legacy modals via url
	const navigateTo = (url) => {
		// find base url (boards index etc) before pushing
		props.onHideModal();
		history.push(`${history.location.pathname}${url}`);
	};

	const getTaskReadyForServer = (task) => {
		const taskReadyForServer = {
			_id: task._id,
			title: task.title,
			description: task.description,
			flagNotes: task.flagNotes,
			estimate: changeEstimateToMilliseconds(task.estimate),
			managementEstimate: changeEstimateToMilliseconds(task.managementEstimate),
			status: task.status ? task.status.value : null,
			category: task.category ? task.category.value : null,
			project: task.project ? task.project.value : null,
			story: task.story ? task.story.value : null,
			taskDependencies: task.taskDependencies
				? task.taskDependencies.map((task) => task.value)
				: null,
			priority: task.priority ? task.priority.value : null,
			assignedTo: task.assignedTo ? task.assignedTo.value : null,
		};
		return taskReadyForServer;
	}

	const updateTaskInTaskModal = (taskFromTaskForm) => {
		setTask((prevTask) => {
			return {
				...prevTask,
				...taskFromTaskForm
			};
		});
	}

	const submitForm = () => {
		console.log('submitForm task: ', getTaskReadyForServer(task));
		props.updateTask(getTaskReadyForServer(task));
	};

	const updateTaskFiles = (files) => {
		props.updateTask({
			...getTaskReadyForServer(task),
			files: files.map((file) => file._id),
		});
	};
	// sets the status of the task to "Deleted" - filtered out of the kanban by default
	const deleteTask = () => {
		props.updateTask({
			...getTaskReadyForServer(task),
			status: '5bf299d3a46dcb509cd0cf75', // _id for "deleted"
		});
		props.onHideModal();
	};

	if (!props.task) {
		return null;
	}
	return (
		<Modal>
			<div
				className={
					'flex-container trkr-padding-lr-60 xs-trkr-padding-lr-30 md-display-flex col-md-7' +
					(showMobilePanel ? ' hide' : '')
				}>
				<div className="modal-header flex-header md-trkr-padding-tb-15">
					<div className="row md-hide trkr-padding-tb-10">
						<div className="col-xs-6">
							<button className={'trkr-icon-close position-fixed trkr-btn-close'} onClick={onClose} />
						</div>
						{props.task.workspaceCounter && (
							<div className="col-xs-6 text-right">
								<a
									className="font-color-white"
									onClick={() => {
										toggleMobilePanel();
									}}>
									Show panel
								</a>
							</div>
						)}
					</div>
					<div className="row vertical-align-middle">
						<div className={'col-xs-2 text-right'}>
							<h3>{`#${props.task.workspaceCounter}`}</h3>
						</div>
						<div className={'col-xs-8'}>
							<h5 className={'header-title trkr-margin-top-0'}>{props.task.title}</h5>
							{props.task && props.task.project && (
								<p className="text-small trkr-margin-top-10">
									<a
										onClick={() => {
											navigateTo(`/project/${String(props.task.project.slug).toLowerCase()}`);
										}}
										className="font-color-white pointer">
										{props.task.project.title}
									</a>
									{props.task.story.release && !props.task.story.release.isDefault && (
										<a
											onClick={() => {
												navigateTo(
													`/release/${String(props.task.story.release.id).toLowerCase()}`
												);
											}}
											className="font-color-white pointer">
											{props.task.release.name}
										</a>
									)}
									{props.task.story && !props.task.story.isDefault && (
										<span>
											{' '}
											/{' '}
											<a
												onClick={() => {
													props.onShowModalExistingStory({
														_id: props.task.story._id,
													});
												}}
												className="font-color-white pointer">
												{props.task.story.title}
											</a>
										</span>
									)}
								</p>
							)}
						</div>
						<div className={'col-xs-2'}>
							<Avatar
								className="small inline trkr-float-right"
								user={props.task ? props.task.assignedTo : null}
							/>
						</div>
					</div>
					{props.task && (
						<div className="row vertical-align-middle">
							{/*<div className="col-xs-9 col-lg-7 col-lg-offset-2">*/}
							<div className="col-xs-12 col-lg-10 col-lg-offset-2">
								<ProgressBar
									estimate={props.task.estimate}
									actualTime={props.task.actualTime}
									hasRunningTimer={hasRunningTimer}
									ttlElapsedMilliseconds={props.task.ttlElapsedMilliseconds}
								/>
							</div>
						</div>
					)}
				</div>
				<div className="flex-body">
					{/* Should this be within the form? */}
					<ListErrors errors={props.errors} />
					<div className="scrollable-content trkr-full-width trkr-margin-top-30">
						{/* all form functionality now in a sub-component, which can now be included in other areas as necessary */}
						{task.title ? (
							<TaskForm
								workspaceCounter={task.workspaceCounter}
								updateTaskInTaskModal={updateTaskInTaskModal}
								hideSubmitBtn={true}
								defaultStatus={defaultStatus}
								defaultCategory={'task'}
								defaultPriority={'medium'}
								defaultAssignedTo={props.currentUser._id}
								task={task}
							/>
						) : null}
					</div>
				</div>
				<div className="flex-footer">
					<div className="trkr-full-width trkr-padding-tb-10 bg-grey-2" style={{ zIndex: 1 }}>
						<div className="row vertical-align-middle">
							<div className="col-xs-2">
								<h4>
									<i
										className="trkr-icon-delete font-color-red"
										onClick={() => {
											deleteTask();
										}}
									/>
								</h4>
							</div>
							<div className="col-xs-6 text-center">
								{props.task && (
									<p className="text-light text-small test">
										Last updated {formatDate(props.task.updatedAt)}
									</p>
								)}
							</div>
							<div className="col-xs-4 text-right">
								<button
									className="trkr-btn"
									type="submit"
									disabled={props.isLoading == true}
									onClick={() => {
										submitForm();
									}}>
									Update Task
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-5 flex-container bg-color-dark activity-panel xs-trkr-padding-lr-30">
				<div className="modal-header flex-header">
					<div className="row md-hide trkr-padding-tb-10">
						<div className="col-xs-6">
							<button className="trkr-icon-close position-fixed trkr-btn-close" onClick={onClose} />
						</div>
						<div className="col-xs-6 text-right">
							<a
								className="font-color-white"
								onClick={() => {
									toggleMobilePanel();
								}}>
								Show details
							</a>
						</div>
					</div>
					<div className="text-center trkr-full-width">
						<ul className="pill-nav-list">
							<li
								className={'nav-link ' + (!activeView || activeView === 'chat' ? 'active' : '')}
								onClick={() => {
									changeContentView('chat');
								}}>
								<a>Feed</a>
							</li>
							<li
								className={'nav-link ' + (activeView === 'timesheet' ? 'active' : '')}
								onClick={() => {
									changeContentView('timesheet');
								}}>
								<a>Timesheet</a>
							</li>
							<li
								className={'nav-link ' + (activeView === 'files' ? 'active' : '')}
								onClick={() => {
									changeContentView('files');
								}}>
								<a>
									Files{' '}
									{props.task.files && props.task.files.length > 0 && (
										<span>{props.task.files.length}</span>
									)}
								</a>
							</li>
						</ul>
					</div>
					<button
						className="trkr-icon-close trkr-btn-close position-absolute trkr-display-none md-show"
						onClick={onClose}
					/>
				</div>
				<div className="flex-body">
					{props.task._id && (
						<Chat forTask={props.task._id} className={'' + (activeView !== 'chat' ? 'hide' : '')} />
					)}
					<IntervalList
						className={'' + (activeView !== 'timesheet' ? 'hide' : '')}
						task={props.task}
						estimate={props.task.estimate}
						actualTime={props.task.actualTime}
						match={props.match}
					/>
					<FileThumbnails
						className={'' + (activeView !== 'files' ? 'hide' : '')}
						files={props.task.files ? props.task.files : []}
						match={props.match}
						updateTaskFiles={updateTaskFiles}
					/>
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	task: state.tasks.task,
	modifiedTask: state.tasks.modifiedTask,
	taskUpdated: state.tasks.taskUpdated,
	currentUser: state.users.currentUser,
	intervals: state.intervals.intervals,
});

export default connect(mapStateToProps, {
	getTask,
	updateTask,
	getTaskReset,
	onHideModal,
	onShowModalExistingStory,
})(ExistingTaskModal);

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { notify } from 'react-notify-toast';

import PageView from '../../../components/PageView';
import Form from '../../../components/Form';
import ResetPassword from '../../../components/ResetPassword';
import queryString from 'query-string';

import {
	getCurrentUser,
	loginUser,
	loginUserReset,
	confirmEmailAddress,
	confirmEmailAddressReset,
} from '../../../components/Users/actions';

import './index.scss';

class LoginPage extends Component {
	state = {
		email: '',
		password: '',
		showPasswordReset: false,
	};

	componentDidMount() {
		let params = queryString.parse(this.props.location.search);
		const token = params.t;
		if (token) {
			this.props.confirmEmailAddress(token);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.emailConfirmed && !prevProps.emailConfirmed) {
			notify.show('Your new email address has been confirmed and you can log in with it.', 'success', 5000);
			this.props.confirmEmailAddressReset();
			this.props.history.push('/login');
		}
		if (this.props.userLoggedIn && !prevProps.userLoggedIn) {
			this.props.getCurrentUser();
			this.props.loginUserReset();
		}
		if (this.props.currentUser) {
			this.props.history.push('/');
		}
	}

	changeEmail = event => {
		const email = event.target.value;
		this.setState({ email });
	};

	changePassword = event => {
		const password = event.target.value;
		this.setState({ password });
	};

	submitForm = () => {
		this.props.loginUser(this.state.email, this.state.password);
	};

	toggleResetScreen = v => {
		this.setState({ ...this.state, showPasswordReset: v });
	};

	render() {
		return (
			<div className="auth-page">
				<div className="container page">
					<div className="row">
						<div className="col-md-6 col-xs-12 position-absolute hv-center trkr-bg-grey auth-form-wrapper">
							{!this.state.showPasswordReset ? (
								<Fragment>
									<h2 className="text-center font-bold">Sign In</h2>

									<Form className="trkr-margin-top-30" submit={this.submitForm}>
										<div className={'form-group'}>
											<label htmlFor={'email'}>Email</label>
											<input
												id={'email'}
												className={'form-control'}
												required={true}
												name={'email'}
												type={'email'}
												onChange={this.changeEmail}
											/>
											<div className="invalid-feedback" />
										</div>
										<div className={'form-group'}>
											<label htmlFor={'password'}>Password</label>
											<input
												id={'password'}
												className={'form-control'}
												required={true}
												name={'password'}
												type={'password'}
												onChange={this.changePassword}
											/>
											<div className="invalid-feedback" />
										</div>

										<div className="row">
											<div className="col-xs-12">
												<button type={'submit'} className="trkr-btn btn-primary">
													<h4>Sign in</h4>
												</button>
											</div>
										</div>

										<div className="row trkr-margin-top-15">
											<div className="col-xs-6">
												<a
													className="trkr-display-inline-block font-color-blue-active"
													href="/register">
													Not got an account?
												</a>
											</div>
											<div className="col-xs-6 text-right">
												<p
													className="trkr-display-inline-block font-color-blue-active link"
													onClick={() => {
														this.toggleResetScreen(true);
													}}>
													Forgotten your password?
												</p>
											</div>
										</div>
									</Form>
								</Fragment>
							) : (
								<ResetPassword
									onClose={() => {
										this.toggleResetScreen(false);
									}}
								/>
							)}

							{/**/}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	userLoggedIn: state.users.userLoggedIn,
	emailConfirmed: state.users.emailConfirmed,
	currentUser: state.users.currentUser,
});

export default connect(
	mapStateToProps,
	{ getCurrentUser, loginUser, loginUserReset, confirmEmailAddress, confirmEmailAddressReset }
)(LoginPage);

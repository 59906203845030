import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Notifications, { notify } from 'react-notify-toast';

import PageView from '../PageView';
import ListErrors from '../ListErrors';
import agent from '../../agent';
import Form from '../Form';
import { Option, SingleValue } from '../ReactSelect';
import { formatDuration, formatDate } from '../../lib/Utils';

const mapStateToProps = (state) => ({
	...state.intervalEditor,
});

// TODO:
// 1. seperate the form elements so this can be added seperately elsewhere if needed
// testing

/**
 * `mapDispatchToProps()` needs separate actions for adding/removing
 * tags, submitting an task, updating individual fields, and cleaning
 * up after navigating away from the page.
 */
const mapDispatchToProps = (dispatch) => ({
	onLoad: (payload) => dispatch({ type: 'INTERVAL_EDITOR_PAGE_LOADED', payload }),
	onSubmit: (payload) => dispatch({ type: 'INTERVAL_SUBMITTED', payload }),
	onUnload: (payload) => dispatch({ type: 'INTERVAL_EDITOR_PAGE_UNLOADED' }),
	onUpdateField: (key, value) => dispatch({ type: 'INTERVAL_UPDATE_FIELD', key, value }),
	onRedirect: () => dispatch({ type: 'REDIRECT' }),
});

class IntervalModal extends Component {
	// default properties (overwritten by props passed by parent component)
	static defaultProps = {};

	constructor() {
		super();

		const updateFieldEvent = (key) => (ev) => this.props.onUpdateField(key, ev.target.value);

		// TODO: regex to calculate h:m:s
		this.changeDuration = updateFieldEvent('duration');

		this.showToastr = notify.createShowQueue();

		// default state (private to this component)
		this.state = {
			inProgress: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		console.log('[IntervalModal.componentWillReceiveProps]', this.props, nextProps);

		/*if(nextProps.updateDuration){
		  this.updateDuration();
	  }*/

		if (nextProps.redirectTo) {
			// this.context.router.replace(nextProps.redirectTo);
			console.log('redirect!', nextProps.redirectTo);
			// TODO: go to previous screen if there is history, else return to kanban

			if (this.props.history.length && this.props.history.length > 0) {
				this.props.history.goBack();
			} else {
				// TODO: go to previous screen
				this.props.history.push('kanban');
			}

			this.props.onRedirect();
		} /*else if (this.props.match.params.slug !== nextProps.match.params.slug) {

		console.log('')

      	if (nextProps.match.params.slug) {
	        this.props.onUnload();
	        // return this.props.onLoad(agent.Projects.getBySlug(this.props.match.params.slug));
	        // return this.props.onLoad(agent.Projects.getBySlug(nextProps.match.params.slug));
	        return this.props.onLoad(Promise.all([agent.Projects.getBySlug(nextProps.match.params.slug)]));
		}


      this.props.onLoad(null);
    }*/
	}

	componentDidMount() {
		if (this.props.intervalId) {
			console.log('loading slug', this.props.match.params.slug);
			return this.props.onLoad(Promise.all([agent.Intervals.get(this.props.intervalId), agent.Tasks.all()]));
		}
	}

	componentWillUnmount() {
		this.props.onUnload();
	}

	submitForm = () => {
		// ev.preventDefault();

		if (!this.props.task.value) {
			// alert('Please provide a task for this interval');
			this.showToastr('Please provide required information', 'error');
			return;
		}
		// console.log('submitForm', this.props.task.value);
		// return

		const interval = {
			id: this.props.intervalId,
			title: this.props.title,
			task: this.props.task.value ? this.props.task.value : undefined,
			startDateTime: this.props.startDateTime,
			endDateTime: this.props.endDateTime,
			// duration: this.props.duration
		};

		console.log('submitting form', interval);

		this.props.onSubmit(agent.Intervals.update(interval));
	};

	handleTaskChange = (newValue: any, actionMeta: any) => {
		this.props.onUpdateField('task', newValue);
	};
	handleProjectChange = (newValue: any, actionMeta: any) => {
		this.props.onUpdateField('project', newValue);
	};

	updateDuration = () => {
		var start = new Date(this.props.startDateTime);
		var end = new Date(this.props.endDateTime);
		var newDur = Math.abs(end.getTime() - start.getTime());

		console.log('updateDuration', start, end, newDur);

		this.props.onUpdateField('duration', newDur);
	};

	handleStartChange = (date) => {
		this.props.onUpdateField('startDateTime', date);
		// this.updateDuration();
	};

	handleEndChange = (date) => {
		this.props.onUpdateField('endDateTime', date);
	};

	renderForm = () => {
		return (
			<Form submit={this.submitForm}>
				<div className="form-group">
					<label htmlFor={'task'}>Task*</label>
					<Select
						className="react-select-custom"
						classNamePrefix="react-select-custom"
						components={{ Option, SingleValue }}
						name={'task'}
						required={true}
						onChange={this.handleTaskChange}
						onInputChange={this.handleTaskInputChange}
						value={this.props.task}
						options={this.props.allTasks}
						placeholder="Select a task"
					/>
					<div className="invalid-feedback" />
				</div>

				<div className="form-group">
					<label htmlFor={'duration'}>Duration</label>
					<input
						className="form-control"
						name="duration"
						type="Text"
						placeholder="Duration"
						disabled
						value={this.props.durationString}
						onChange={this.changeDuration}
					/>
					<div className="invalid-feedback" />
				</div>

				<div className="form-group">
					<label htmlFor={'startDate'}>Start</label>
					<DatePicker
						className="form-control"
						selected={moment(this.props.startDateTime)}
						onChange={this.handleStartChange}
						showTimeSelect
						name="startDate"
						timeFormat="HH:mm"
						timeIntervals={1}
						dateFormat="LLL"
						locale="en-gb"
					/>
					<div className="invalid-feedback" />
				</div>

				<div className="form-group">
					<label htmlFor={'endDate'}>End</label>
					<DatePicker
						className="form-control"
						selected={moment(this.props.endDateTime)}
						onChange={this.handleEndChange}
						showTimeSelect
						name="endDate"
						timeFormat="HH:mm"
						timeIntervals={1}
						dateFormat="LLL"
						locale="en-gb"
					/>
					<div className="invalid-feedback" />
				</div>
			</Form>
		);
	};

	render() {
		// console.log('[IntervalModal.render] props', this.props);
		return (
			<div className="row modal-content">
				<div className="flex-container trkr-padding-lr-60 xs-trkr-padding-lr-30 md-display-flex">
					<div className="modal-header flex-header md-trkr-padding-tb-15">
						<div className="row vertical-align-middle">
							<div className="col-xs-10">
								<h4 className="header-title trkr-margin-top-0">
									<span>Edit Interval</span>
								</h4>
							</div>
							<div className="col-xs-2 text-right">
								<button className="trkr-icon-close trkr-btn-close" onClick={this.props.onClose} />
							</div>
						</div>
					</div>

					<div className="flex-body">
						<ListErrors errors={this.props.errors} />
						<div className="scrollable-content trkr-full-width trkr-margin-top-30">{this.renderForm()}</div>
					</div>

					<div className="flex-footer">
						<div className="trkr-full-width trkr-padding-tb-10 bg-grey-2" style={{ zIndex: 1 }}>
							<div className="row vertical-align-middle">
								<div className="col-xs-6">
									{this.props.interval && (
										<p className="text-light text-small test">
											Last updated {formatDate(this.props.interval.updatedAt)}
										</p>
									)}
								</div>

								<div className="col-xs-6 text-right">
									<button
										className="trkr-btn"
										type="submit"
										disabled={this.props.inProgress}
										onClick={() => {
											this.submitForm();
										}}>
										Update interval
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(IntervalModal);

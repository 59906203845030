import agent from '../../agent';
import {
	WORKSPACES_INVITE_USERS_SUCCESS,
	WORKSPACES_INVITE_USERS_ERROR,
	WORKSPACES_INVITE_USERS_RESET,
} from './constants';

export const inviteUsers = async (workspaceId, emails) => {
	try {
		const response = await agent.Workspaces.inviteUsers(workspaceId, emails);
		const sentEmails = response.sentEmails;
		return { type: WORKSPACES_INVITE_USERS_SUCCESS, sentEmails };
	} catch (error) {
		return { type: WORKSPACES_INVITE_USERS_ERROR, error };
	}
};

export const inviteUsersReset = async () => {
	return { type: WORKSPACES_INVITE_USERS_RESET };
};

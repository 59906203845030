import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { formatDuration20, tick } from '../../lib/Utils';
import './index.scss';

class IntervalTime extends Component {

	constructor() {
		super();
	}

	componentDidMount() {
	}

	componentDidUpdate(){
	}

	renderTime = () => {
		let milliseconds = 0;
		if (this.props.showTotalElapsedTime){
			milliseconds = this.props.ttlElapsedMilliseconds + this.props.runningElapsedMillisecondsOfInterval
		}else{
			milliseconds= this.props.runningElapsedMillisecondsOfInterval;
		}
		return formatDuration20(milliseconds, true);
	}

	render() {
		return <span> {this.renderTime()}</span>
	}
}

const mapStateToProps = (state, ownProps) => {
	let runningElapsedMillisecondsOfInterval = ownProps.running && state.intervals.runningIntervalsByTask[ownProps.taskId] && state.intervals.runningIntervalsByTask[ownProps.taskId].elapsedMilliseconds
	return {
		runningElapsedMillisecondsOfInterval: runningElapsedMillisecondsOfInterval
	};
};

export default connect(
	mapStateToProps
)(IntervalTime);

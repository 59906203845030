import {
	STORIES_GET_STORIES_BY_PROJECT_SUCCESS,
	STORIES_GET_STORIES_BY_PROJECT_ERROR,
	STORIES_ADD_STORY_SUCCESS,
	STORIES_ADD_STORY_ERROR,
	STORIES_GET_STORIES_BY_TASK_COUNTER_SUCCESS,
	STORIES_GET_STORIES_BY_TASK_COUNTER_ERROR,
	STORIES_GET_STORY_SUCCESS,
	STORIES_GET_STORY_ERROR,
	STORIES_UPDATE_STORY_SUCCESS,
	STORIES_UPDATE_STORY_ERROR,
} from './constants';

const initialState = {
	errors: [],
	allStories: [],
	story: {},
	newStory: {},
};

// This function is called to update the redux store when any action is received.
export default (state = initialState, action) => {
	switch (action.type) {
		case STORIES_GET_STORIES_BY_PROJECT_SUCCESS:
			return {
				...state,
				allStories: action.allStories,
				story: action.story,
			};
		case STORIES_GET_STORIES_BY_PROJECT_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case STORIES_ADD_STORY_SUCCESS:
			return {
				...state,
				allStories: [...state.allStories, action.newStory],
				newStory: action.newStory,
			};
		case STORIES_ADD_STORY_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case STORIES_GET_STORIES_BY_TASK_COUNTER_SUCCESS:
			return {
				...state,
				allStories: action.allStories,
			};
		case STORIES_GET_STORIES_BY_TASK_COUNTER_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case STORIES_GET_STORY_SUCCESS:
			return {
				...state,
				allStories: [...state.allStories.filter(story => story._id !== action.story._id), action.story],
				story: action.story,
			};
		case STORIES_GET_STORY_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case STORIES_UPDATE_STORY_SUCCESS:
			return {
				...state,
			};
		case STORIES_UPDATE_STORY_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
	}
	return state;
};

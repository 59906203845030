import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Avatar from '../../../../../../Icons/Avatar';
import { formatDate } from '../../../../../../../lib/Utils';

import { getUnreadMessagesForUser } from '../../../../../../Messages/actions';

import './index.scss';

class UnreadMessages extends Component {
	componentDidMount() {
		this.props.getUnreadMessagesForUser(this.props.currentUser._id, 0, 5);
	}
	handleScroll = event => {
		let isBottom =
			event.currentTarget.scrollTop + 1 >= event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
		if (isBottom) {
			const nextSkip = this.props[this.props.currentUser._id]?.nextSkip;
			this.props.getUnreadMessagesForUser(this.props.currentUser._id, nextSkip, 5);
		}
	};
	render() {
		const messages = this.props[this.props.currentUser._id]?.messages;
		if (!messages) {
			return null;
		}
		return (
			<DragDropContext onDragEnd={() => {}}>
				<div className={'messages-column-container'}>
					<div className="messages-column-container-header">
						<div className="row vertical-align-middle">
							<div className="col-xs-8">
								<h4 className="font-color-white-1">{`Unread (${messages.length})`}</h4>
							</div>
						</div>
					</div>
					<Droppable droppableId="unread-messages-column" type="message">
						{(provided, snapshot) => (
							<div
								onScroll={this.handleScroll}
								className={`messages-list ${
									snapshot.isDraggingOver
										? 'messages-list-dragging-over-background'
										: Boolean(snapshot.draggingFromThisWith)
										? 'messages-list-dragging-from-background'
										: 'messages-list-normal-background'
								}`}
								ref={provided.innerRef}
								{...provided.droppableProps}>
								{messages.map((message, index) => (
									<div
										key={`${message._id}-${index}`}
										className={`message-card`}
										style={{ borderColor: '#000000' }}>
										<div className="row">
											<div className="col-xs-2">
												<Avatar
													className="user-avatar-small inline font-color-white-2 font-light"
													user={message.author}
												/>
											</div>
											<div className="col-xs-8">
												<div>
													<p className="font-bold-extra wrap-text font-color-white-2">
														{formatDate(message.createdAt)}
													</p>
												</div>
												<div>
													<p className="font-bold-extra wrap-text font-color-white-2">
														{message.content}
													</p>
												</div>
											</div>
											<div className="col-xs-2">
												<p className="font-color-white-2">#{message?.task?.workspaceCounter}</p>
											</div>
										</div>
									</div>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</div>
			</DragDropContext>
		);
	}
}

const mapStateToProps = state => ({
	...state.messages,
	currentUser: state.users.currentUser,
});

const mapDispatchToProps = {
	getUnreadMessagesForUser,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UnreadMessages);

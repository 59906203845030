// import { formatDuration, dateDiff } from '../../lib/Utils';

export default (state = {}, action) => {
	switch (action.type) {
		case 'RELEASE_EDITOR_PAGE_LOADED':
			console.log('[RELEASE_EDITOR_PAGE_LOADED]', action);

			var obj = {
				...state,
				isLoading: false,
			};

			function numberPad(n) {
				if (n < 10) n = '0' + n;
				return n;
			}

			if (action.payload) {
				if (action.payload[0]) {
					var release = action.payload[0].release;

					obj.release = release;
					obj.name = release.name;
					obj.description = release.description;
					obj.dueDate = release.dueDate;

					// obj.project = release.project ? {value:release.project.id, label:release.project.title} : undefined;
				} else {
					// use 'initialState' pattern
					/*obj.title = '';
	  		        obj.description = '';
					obj.project = undefined;
	  		        obj.dueDate = undefined;*/
				}

				if (action.payload[1]) {
					obj.allProjects = action.payload[1].projects.map(project => {
						return { value: project.id, label: project.title };
					});
				} else {
					obj.allProjects = [];
				}
			} else {
				obj = {
					...state,
					isLoading: false,
					title: '',
					description: '',
					allProjects: [],
				};
			}

			return obj;

		case 'RELEASE_EDITOR_PAGE_UNLOADED':
			return {};

		case 'RELEASE_SUBMITTED':
			console.log('[RELEASE_SUBMITTED]', action);
			return {
				...state,
				inProgress: null,
				errors: action.error ? action.payload.errors : null,
				redirectTo: action.error ? null : '/boards',
			};

		case 'RELEASE_UPDATE_FIELD':
			// console.log('releaseEditor.UPDATE_FIELD_EDITOR', action, state)
			var obj = {
				...state,
			};

			obj[action.key] = action.value;

			return obj;

		case 'REDIRECT':
			return { ...state, redirectTo: null };
	}

	return state;
};

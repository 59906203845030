import React, { Component } from 'react';

import './index.scss';
import agent from '../../agent';

class Reports extends Component {
	render() {
		return (
			<div className="row report">
				<div className="row vertical-align-middle">
					<h2 className="text-center">Reports</h2>
				</div>
				<div className="flex-body">
					<div className="report-body">
						<h3>Downloads</h3>
						<hr />
						<h4>Timer Intervals</h4>
						<a href={`${agent.API_ROOT}/reports/intervals`}>Download</a>
						<hr />
						<h4>Tasks</h4>
						<a href={`${agent.API_ROOT}/reports/tasks`}>Download</a>
						<hr />
					</div>
				</div>
			</div>
		);
	}
}

export default Reports;

import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';

export const numberPad = (number) => {
	return number < 10 ? '0' + number : number;
};

export const numberFract = (number, total) => {
	return (number / total) * 100;
};

export const addTime = (existingDate, value, format) => {
	return moment(existingDate).add(value, format).toDate();
};

export const formatDuration20 = (milliSeconds, showSeconds) => {
	var h = Math.floor(milliSeconds / (1000 * 60 * 60));
	var m = Math.floor((milliSeconds / (1000 * 60)) % 60);
	var durationString = "";
	var showHours = milliSeconds>=3600000;//moment.duration(1,'hour').asMilliseconds();
	var showMinutes = m>0;

	if (showHours){
		durationString = durationString + " " + h + "h";
	}

	if (showMinutes){
		durationString = durationString + " " + m + "m";
	}

	if (!showHours && !showMinutes || showSeconds){
		var s = Math.floor((milliSeconds / 1000) % 60);
		durationString = durationString + " " + s + "s";
	}
	
	durationString = durationString.trim();

	return durationString;
}

// TODO: different format types e.g. 4.5h, 04:00:00
// TODO: replace internals with moment formatting
export const formatDuration = (ms, format) => {
	if (!ms) {
		if (format === 'hours') return '0h';
		return '00:00';
	}

	// modulus used to calculate remainder
	var sec = Math.floor((ms / 1000) % 60);
	var min = Math.floor((ms / (1000 * 60)) % 60);
	var hour = Math.floor(ms / (1000 * 60 * 60));

	if (format === 'hours') {
		return String(hour + (min > 0 ? '.' + String(Math.floor(numberFract(min, 60))).replace(/0$/g, '') : '') + 'h');
	} else if (format === 'hhmm') {
		// strip hours if they don't exist
		hour = hour > 0 ? `${numberPad(hour)}h ` : '';
		min = min > 0 ? `${numberPad(min)}m` : '';

		return String(hour + min + (min === '' ? `${numberPad(sec)}s` : ''));
	} else if (format === 'hhmmss') {
		return String(numberPad(hour) + ':' + numberPad(min) + ':' + numberPad(sec));
	} else {
		// strip hours if they don't exist
		hour = hour > 0 ? `${numberPad(hour)}:` : '';

		return String(hour + numberPad(min) + ':' + numberPad(sec));
	}
};

// TODO: replace the above?
export const formatDuration2 = (ms, format) => {
	// return parseInt(moment.utc(ms).format('DDD')) - 1 + 'days ' + moment.utc(ms).format('HH:mm:ss.SSS');
	if (format === 'DDD') {
		return parseInt(moment.utc(ms).format('DDD')) - 1;
	}
	return moment.utc(ms).format(format || 'HH:mm:ss');
};

export const combineDateAndTime = (date, timeString) => {
	// timeString = time.getHours() + ':' + time.getMinutes() + ':00';

	var year = date.getFullYear();
	var month = date.getMonth() + 1; // Jan is 0, dec is 11
	var day = date.getDate();
	var dateString = '' + year + '-' + month + '-' + day;
	var combined = new Date(dateString + ' ' + timeString);

	return combined;
};

// useCalendar to reflect "today" / "yesterday" / "tomorrow"
export const formatDate = (date, momentFormat = 'MMM Do YYYY HH:mm:ss', useCalendar = false) => {
	var d = Date(date).toLocaleString();

	// Check for the day difference from now, to reflect "tomorrow" / "today" / "yesterday"
	if (useCalendar) {
		var dateText = moment(date).from(new Date());
		var startOfToday = moment().startOf('day');
		var startOfDate = moment(date).startOf('day');
		// var startOfDate = moment(date).add(1,'days').startOf('day'); // simulate 'tomorrow'
		// var startOfDate = moment(date).subtract(1,'days').startOf('day'); // simulate 'yesterday'
		var daysDiff = startOfDate.diff(startOfToday, 'days');
		var days = {
			'0': 'Today',
			'-1': 'Yesterday',
			'1': 'Tomorrow',
		};

		if (Math.abs(daysDiff) <= 1) {
			// console.log('dateText', dateText);
			return (dateText = days[daysDiff]);
		}

		return moment(date).format(momentFormat);
	}

	return moment(date).format(momentFormat);
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait, immediate) => {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

// returns the value of the specified url param
export const getQuery = (url, query) => {
	// console.log('[this.getQuery]', url, query);

	// var pattern = query+'=(\\w*)(&|$)'
	var pattern = query + '=([\\w\\s]+)';
	var reg = new RegExp(pattern, 'gi');
	var matchGroups = reg.exec(url.replace(/%20/g, ' ')); // replace all encoded white space characters

	if (matchGroups && matchGroups.length > 0) {
		// console.log('[this.getQuery] pattern:', pattern, ' matchGroups:', matchGroups);

		return matchGroups[1];
	} else {
		return false;
	}
};

// returns the difference (in ms) between 2 dates
// a: startDateTimeInSeconds
// b: endDateTimeInSeconds
export const dateDiff = (a, b) => {
	return moment.duration((b - a) * 1000);
};

// returns the difference (in ms) between 2 dates
export const dateDiff2 = (endDateTime, startDateTime) => {
	const diff = moment
		.duration(moment(endDateTime).startOf('second').diff(moment(startDateTime).startOf('second')))
		.as('milliseconds');
	return diff;
};

export const sortTaskByProject = (a, b) => {
	// TODO: issue if project is undefined

	var aProject = a.project ? a.project._id : 0;
	var bProject = b.project ? b.project._id : 0;

	return aProject > bProject ? 1 : bProject > aProject ? -1 : 0;
};

export function sortByDate(propertyRef = 'createdAt') {
	// Turn your strings into dates, and then subtract them
	// to get a value that is either negative, positive, or zero.
	return function (a, b) {
		return new Date(a[propertyRef]) - new Date(b[propertyRef]);
	};
}

export const SortByWeight = (a, b) => {
	var aWeight = a.orderWeight;
	var bWeight = b.orderWeight;
	return aWeight < bWeight ? -1 : aWeight > bWeight ? 1 : 0;
};

// convert milliseconds to working days
export const msToDays = (ms) => {
	// console.log('utils.msToDays', ms);

	var workingDayLength = 7.5;

	return Math.floor(ms / (1000 * 60 * 60 * workingDayLength)); // e.g. 14400000 / 27000000 = 0.533333
};

export const areArraysEqual = (value, other) => {
	// Get the value type
	var type = Object.prototype.toString.call(value);

	// If the two objects are not the same type, return false
	if (type !== Object.prototype.toString.call(other)) return false;

	// If items are not an object or array, return false
	if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

	// Compare the length of the length of the two items
	var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
	var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
	if (valueLen !== otherLen) return false;

	// Compare two items
	var compare = function (item1, item2) {
		// Get the object type
		var itemType = Object.prototype.toString.call(item1);

		// If an object or array, compare recursively
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!areArraysEqual(item1, item2)) return false;
		}

		// Otherwise, do a simple comparison
		else {
			// If the two items are not the same type, return false
			if (itemType !== Object.prototype.toString.call(item2)) return false;

			// Else if it's a function, convert to a string and compare
			// Otherwise, just compare
			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) return false;
			} else {
				if (item1 !== item2) return false;
			}
		}
	};

	// Compare properties
	if (type === '[object Array]') {
		for (var i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) return false;
		}
	} else {
		for (var key in value) {
			if (value.hasOwnProperty(key)) {
				if (compare(value[key], other[key]) === false) return false;
			}
		}
	}

	// If nothing failed, return true
	return true;
};

export const tick = (interval, serverOffset) => {
	let newDate = new Date();
	newDate.setMilliseconds(newDate.getMilliseconds() + serverOffset);
	const eventTime = interval?.startDateTime || newDate;
	const diff = dateDiff2(newDate, eventTime);
	return diff;
};

export const getIntervalsByDate = intervals => {

	var intervalsByDateNew = [];
	var intervalsGroupedByDate = _.groupBy(intervals, (interval)=>{
		return moment(interval.startDateTime).startOf("day");
	});

	for (var date in intervalsGroupedByDate){
		var intervalsForDate = intervalsGroupedByDate[date];
		var totalDurationForDateInMS = 0;
		intervalsForDate.forEach((intervalForDate)=>{
			if (intervalForDate.totalDurationInMS){
				totalDurationForDateInMS+=intervalForDate.totalDurationInMS;
			}else{
				console.log("JS Interval",intervalForDate,"does not have totalDurationInMS",intervalForDate.totalDurationInMS);
			}
		});
		intervalsByDateNew.push(
			{
				date: date,
				intervals: intervalsForDate,
				totalDuration: totalDurationForDateInMS
			}
		)
	}

	return intervalsByDateNew;
};

export const changeEstimateToMilliseconds = (estimate) => {
	const minuteValue = 60000;
	const hourValue = minuteValue * 60;
	let nonAlphaMatch = /^(\d+)(([\.|:])?(\d+){1,2})?$/.exec(estimate);
	let dayMatch = 0;
	let hourMatch = 0;
	let minMatch = 0;
	if (nonAlphaMatch) {
		// if ":" is used then covert to decimal. e.g. "2:30" needs to be 2.5 hours
		if (nonAlphaMatch.length >= 3 && nonAlphaMatch[3] === ':') {
			// pad the number if (ie if 2:3 >> 2:30)
			let mins = nonAlphaMatch[4].length !== 1 ? nonAlphaMatch[4] : parseInt(nonAlphaMatch[4] + '0');
			hourMatch = parseInt(nonAlphaMatch[1]) + mins / 60;
			// console.log('padded number = ', mins);
		} else {
			// take the number as is (assuming its a decimal)
			hourMatch = nonAlphaMatch[0] || 0;
		}
	} else {
		dayMatch = /(([\d\.]+)\s*?(?:d(\w+)?))/i.exec(estimate) || [];
		hourMatch = /(([\d\.]+)\s*?(?:h(\w+)?))/i.exec(estimate) || [];
		minMatch = /(([\d\.]+)\s*?(?:m(\w+)?))/i.exec(estimate) || [];
		dayMatch = dayMatch[2] || 0;
		hourMatch = hourMatch[2] || 0;
		minMatch = minMatch[2] || 0;
	}
	let daysAsMs = parseFloat(dayMatch) * (hourValue * 7.5);
	let hoursAsMs = parseFloat(hourMatch) * hourValue;
	let minsAsMs = parseFloat(minMatch) * minuteValue;
	const estimateInMilliseconds = daysAsMs + hoursAsMs + minsAsMs;
	return estimateInMilliseconds;
};

export const milliSecondsToHoursAndMinutes = (milliSeconds) => {
	let minutes = parseInt((milliSeconds / (1000 * 60)) % 60),
		hours = parseInt(milliSeconds / (1000 * 60 * 60));
	hours = hours < 10 ? '0' + hours : hours;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	return `${hours}:${minutes}`;
};

export const cloneArray = (items) =>
	items.map((item) =>
		Array.isArray(item) ? cloneArray(item) : typeof item === 'object' && item !== null ? { ...item } : item
	);

export const formatOption = (object, type) => {
	let option = {
		type,
		objectData: object,
		value: object._id || object.id,
	};
	switch (type) {
		case 'project':
			option = {
				...option,
				label: object.title,
			};
			break;
		case 'priority':
			option = {
				...option,
				label: object.name,
			};
			break;
		case 'story':
			option = {
				...option,
				label: object.title,
			};
			break;
		default:
			option.label = object.name;
			break;
	}
	return option;
};

export const mapPriorityOptions = (priorities) => {
	return priorities ? priorities.map((priority) => formatOption(priority, 'priority')) : [];
};
export const mapProjectOptions = (projects) => {
	return projects ? projects.map((project) => formatOption(project, 'project')) : [];
};
export const mapReleaseOptions = (releases) => {
	return releases ? releases.map((release) => formatOption(release, 'release')) : [];
};

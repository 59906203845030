import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';
import { notify } from 'react-notify-toast';

import Form from '../Form';
import Modal from '../Modal';
import { Option, SingleValue } from '../ReactSelect';

import { formatDate, formatOption, mapPriorityOptions, mapProjectOptions, mapReleaseOptions } from '../../lib/Utils';

import { addProject } from '../Projects/actions';

import { getProjectReleases, createRelease } from '../Releases/actions';

import { addPriority } from '../Priorities/actions';

import { addStory } from '../Stories/actions';

import { onHideModal, onShowModalExistingStory } from '../App/actions';

class NewStoryModal extends Component {
	state = {
		title: '',
		project: null,
		release: null,
		description: '',
		priority: null,
		releaseOptions: [],
	};
	constructor() {
		super();
		this.showToastr = notify.createShowQueue();
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.newProjectAddedToStore(prevProps)) {
			this.setState(state => {
				return {
					...state,
					project: formatOption(this.props.newProject, 'project'),
				};
			});
			this.props.getProjectReleases(this.props.newProject.id);
		}
		if (this.projectReleasesAddedToStore(prevProps)) {
			this.setState(state => {
				const newState = {
					...state,
					releaseOptions: mapReleaseOptions(this.props[(this.state.project?.value)]?.releases),
				};
				const defaultRelease = this.props[(this.state.project?.value)]?.releases.filter(
					release => release.isDefault
				)[0];
				newState.release = formatOption(defaultRelease, 'release');
				return newState;
			});
		}
		if (this.newReleaseAddedToStore(prevProps)) {
			this.setState(state => {
				return {
					...state,
					release: formatOption(this.props.newRelease, 'release'),
				};
			});
		}
		if (this.newPriorityAddedToStore(prevProps)) {
			this.setState(state => {
				return {
					...state,
					priority: formatOption(this.props.newPriority, 'priority'),
				};
			});
		}
	}
	newProjectAddedToStore = prevProps => {
		return prevProps.newProject !== this.props.newProject;
	};
	projectReleasesAddedToStore = prevProps => {
		return (
			this.props[(this.state.project?.value)] &&
			prevProps[(this.state.project?.value)] !== this.props[(this.state.project?.value)]
		);
	};
	newReleaseAddedToStore = prevProps => {
		return prevProps.newRelease !== this.props.newRelease;
	};
	newPriorityAddedToStore = prevProps => {
		return prevProps.newPriority !== this.props.newPriority;
	};
	changeTitle = ev => {
		const title = ev.target.value;
		this.setState(state => {
			return {
				...state,
				title,
			};
		});
	};
	changeDescription = ev => {
		const description = ev.target.value;
		this.setState(state => {
			return {
				...state,
				description,
			};
		});
	};
	submitForm = async () => {
		// check all is valid
		if (!this.state.title || (!this.state.project || !this.state.project.value)) {
			console.log('StoryModal.submitForm', this.state.title, this.state.project);
			this.showToastr('Please provide required information', 'error');
			return;
		}
		const story = {
			title: this.state.title,
			description: this.state.description,
			project: this.state.project.value,
			release: this.state.release ? this.state.release.value : null,
			priority: this.state.priority ? this.state.priority.value : null,
		};
		await this.props.addStory(story);
		this.props?.newStory?.id && this.props.onShowModalExistingStory({ _id: this.props.newStory.id });
	};
	handleReleaseChange = newValue => {
		this.setState(state => {
			return {
				...state,
				release: newValue,
			};
		});
	};
	handlePriorityChange = newValue => {
		this.setState(state => {
			return {
				...state,
				priority: newValue,
			};
		});
	};
	addPriority = str => {
		this.props.addPriority(str);
	};
	handleProjectChange = newValue => {
		if (!newValue.value) {
			return;
		}
		this.setState(state => {
			return {
				...state,
				project: newValue,
			};
		});
		this.props.getProjectReleases(newValue.value);
	};
	addProject = str => {
		this.props.addProject(str);
	};
	onClose = () => {
		this.props.onHideModal();
	};
	renderForm = () => {
		return (
			<Form submit={this.submitForm}>
				<div className="form-group">
					<label htmlFor={'title'}>Title*</label>
					<input
						className="form-control"
						type="text"
						name={'title'}
						required={true}
						placeholder="Story Title"
						value={this.state.title}
						onChange={this.changeTitle}
					/>
					<div className="invalid-feedback" />
				</div>
				<div className="form-group">
					<label htmlFor={'project'}>Project*</label>
					<CreatableSelect
						className="react-select-custom"
						classNamePrefix="react-select-custom"
						components={{ Option, SingleValue }}
						name={'project'}
						required={true}
						onChange={this.handleProjectChange}
						value={this.state.project}
						options={this.props.projectOptions}
						onCreateOption={this.addProject}
						placeholder="Select a project"
					/>
					<div className="invalid-feedback" />
				</div>
				{this.state.project && (
					<div className="form-group">
						<label htmlFor={'release'}>Release</label>
						<CreatableSelect
							className="react-select-custom"
							classNamePrefix="react-select-custom"
							components={{ Option, SingleValue }}
							name={'release'}
							onChange={this.handleReleaseChange}
							value={this.state.release}
							options={this.state.releaseOptions}
							onCreateOption={str =>
								this.props.createRelease({ name: str, project: this.state.project.value })
							}
							placeholder="Select a release"
						/>
						<div className="invalid-feedback" />
					</div>
				)}
				<div className="form-group">
					<label htmlFor={'description'}>Description</label>
					<textarea
						className="form-control"
						rows="5"
						name={'description'}
						placeholder="As a user ..."
						value={this.state.description}
						onChange={this.changeDescription}
					/>
					<div className="invalid-feedback" />
				</div>
				<div className="form-group">
					<label htmlFor={'priority'}>Priority</label>
					<CreatableSelect
						className="react-select-custom"
						classNamePrefix="react-select-custom"
						components={{ Option, SingleValue }}
						name={'priority'}
						onChange={this.handlePriorityChange}
						value={this.state.priority}
						options={this.props.allPriorities}
						onCreateOption={this.addPriority}
						placeholder="Select a priority"
					/>
					<div className="invalid-feedback" />
				</div>
			</Form>
		);
	};
	render() {
		return (
			<Modal>
				<div
					className={
						'flex-container trkr-padding-lr-60 xs-trkr-padding-lr-30 md-display-flex col-md-12' +
						(this.state.showMobilePanel ? ' hide' : '')
					}>
					<div className="modal-header flex-header md-trkr-padding-tb-15">
						<div className="row vertical-align-middle">
							<div className="col-xs-12">
								<h5 className="header-title trkr-margin-top-0 text-center">
									New Story
									<button
										className="trkr-icon-close trkr-btn-close trkr-float-right"
										onClick={this.onClose}
									/>
								</h5>
							</div>
						</div>
					</div>
					<div className={'flex-body'}>
						<div className="scrollable-content trkr-full-width trkr-margin-top-30">{this.renderForm()}</div>
					</div>
					<div className="flex-footer">
						<div className="trkr-full-width trkr-padding-tb-10 bg-grey-2" style={{ zIndex: 1 }}>
							<div className="row vertical-align-middle">
								<div className="col-xs-12 text-right">
									<button
										className="trkr-btn"
										type="submit"
										disabled={this.props.inProgress}
										onClick={() => {
											this.submitForm();
										}}>
										Create Story
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		...state.storyEditor,
		...state.releases,
		allPriorities: mapPriorityOptions(state.app.allPriorities),
		projectOptions: mapProjectOptions(state.projects.allProjects),
		newProject: state.projects.newProject,
		newPriority: state.priorities.newPriority,
		newStory: state.stories.newStory,
	};
};

const mapDispatchToProps = {
	addProject,
	getProjectReleases,
	createRelease,
	addPriority,
	addStory,
	onHideModal,
	onShowModalExistingStory,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NewStoryModal);

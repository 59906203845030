import agent from '../../agent';

import {
	REDIRECT,
	SHOW_TIMER_MENU,
	HIDE_TIMER_MENU,
	SHOW_MODAL_NEW_TASK,
	SHOW_MODAL_EXISTING_TASK,
	SHOW_MODAL_NEW_STORY,
	SHOW_MODAL_EXISTING_STORY,
	SHOW_MODAL_NEW_RELEASE,
	HIDE_MODAL,
	GET_ALL_LISTED_DATA_SUCCESS,
	GET_ALL_LISTED_DATA_ERROR,
} from './constants';

export const onRedirect = () => {
	return { type: REDIRECT };
};

export const onShowTimerMenu = () => {
	return { type: SHOW_TIMER_MENU, show: true };
};

export const onHideTimerMenu = () => {
	return { type: HIDE_TIMER_MENU, show: false };
};

// TODO: map lists to additional options schema
export const loadAllDataLists = async () => {
	try {
		const { categories } = await agent.Categories.all();
		const { priorities } = await agent.Priorities.all();
		const { status } = await agent.Status.all();
		const { users } = await agent.Users.all();

		let categoryOptions = categories
			? categories.map(category => {
					return {
						value: category._id,
						key: category._id,
						label: category.name,
					};
			  })
			: [];

		let priorityOptions = priorities
			? priorities.map(priority => {
					return {
						value: priority._id,
						key: priority._id,
						label: priority.name,
						type: 'priority',
						objectData: priority,
					};
			  })
			: [];

		let statusOptions = status
			? status.map(status => {
					return {
						value: status._id,
						key: status._id,
						label: status.name,
					};
			  })
			: [];

		let userOptions = users
			? users.map(user => {
					return { value: user._id, key: user._id, label: user.fullName, type: 'user', objectData: user };
			  })
			: [];

		return {
			type: GET_ALL_LISTED_DATA_SUCCESS,
			payload: {
				categories,
				categoryOptions,
				priorities,
				priorityOptions,
				status,
				statusOptions,
				users,
				userOptions,
			},
		};
	} catch (error) {
		return { type: GET_ALL_LISTED_DATA_ERROR, error };
	}
};

export const onShowModalNewTask = ({ defaultStatus, defaultPriority, defaultAssigned }) => {
	return { type: SHOW_MODAL_NEW_TASK, defaultStatus, defaultPriority, defaultAssigned };
};

// TODO: should the loading of data occur here?
export const onShowModalExistingTask = ({ _id }) => {
	return { type: SHOW_MODAL_EXISTING_TASK, _id };
};

export const onShowModalNewStory = () => {
	return { type: SHOW_MODAL_NEW_STORY };
};

export const onShowModalExistingStory = ({ _id }) => {
	return { type: SHOW_MODAL_EXISTING_STORY, _id };
};

export const onShowModalNewRelease = () => {
	return { type: SHOW_MODAL_NEW_RELEASE };
};

export const onHideModal = () => {
	return { type: HIDE_MODAL };
};

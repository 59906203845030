import React from 'react';

import './avatar.scss';
import agent from '../../agent';

// const Avatar = props => {
const Avatar = ({ user, username, fullName, image, className, hideName, showWorskpace }) => {
	// console.log('[Avatar]',props);

	user = user || {};

	// deprecation polyfill
	fullName = fullName || user.fullName;
	image = image || user.image;

	let initials, colour;
	let workspace = user.selectedWorkspace ? user.selectedWorkspace.name : null;

	// get username for avatar image
	/*if (username) {
		username = username;
	} else if (user.username) {
		username = user.username;
	} else {
		var pattern = '(.*)@';
		var reg = new RegExp(pattern, 'gi');
		var matchGroups = reg.exec(user.email);

		// console.log('register email match', matchGroups);

		if (matchGroups && matchGroups.length > 0) {
			// TODO: strip special characters
			username = matchGroups[1].replace(/(_|\.|-)/, '');
		}
	}*/
	if (user.fullName && !user.image) {
		initials = user.fullName.split(' ').map(name => name[0]);
		const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
		const firstInitialIndex = alphabet.indexOf(initials[0].toLowerCase());
		const lastInitialIndex = alphabet.indexOf(initials[initials.length - 1].toLowerCase());
		const colourIndex = Math.round((firstInitialIndex + lastInitialIndex) / 2);
		const colours = [
			'#1ABC9C',
			'#2ECC71',
			'#3498DB',
			'#9B59B6',
			'#F1C40F',
			'#E74C3C',
			'#BDC581',
			'#FD79A8',
			'#A29BFE',
			'#B8E994',
			'#B53471',
			'#F8C291',
			'#3C6382',
			'#16A085',
			'#27AE60',
			'#2980B9',
			'#8E44AD',
			'#F39C12',
			'#C0392B',
			'#3B3B98',
			'#E84393',
			'#6C5CE7',
			'#FDCB6E',
			'#833471',
			'#B71540',
			'#4A69BD',
		];
		colour = colours[colourIndex];
	}
	return (
		<div className={`avatar-container ${className ? className : ''}`}>
			<div className="avatar-image">
				<div
					className={`user-avatar`}
					style={{
						backgroundColor: !image ? colour : undefined,
						backgroundImage: image
							? `url(${agent.API_ROOT}/attachments/${image.split('.')[0]}-120.jpg)`
							: undefined,
					}}
					title={`${fullName}`}>
					<div className="user-avatar-initials">{initials ? initials.join('') : null}</div>
				</div>
			</div>
			<div className={'avatar-username-wrapper ' + (hideName ? 'hide' : '')}>
				<p className="username trim-text-1">{fullName && fullName.split(/\s/)[0]}</p>
				{showWorskpace && <p className="selected-workspace trim-text-1">{workspace}</p>}
			</div>
		</div>
	);
};

export default Avatar;

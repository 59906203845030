export const INTERVALS_GET_USERS_INTERVALS_SUCCESS = 'INTERVALS_GET_ALL_USERS_INTERVALS_SUCCESS';
export const INTERVALS_GET_USERS_INTERVALS_ERROR = 'INTERVALS_GET_ALL_USERS_INTERVALS_ERROR';
export const INTERVALS_GET_USER_INTERVALS_DURATION_SUCCESS = 'INTERVALS_GET_USER_INTERVALS_DURATION_SUCCESS';
export const INTERVALS_GET_USER_INTERVALS_DURATION_ERROR = 'INTERVALS_GET_USER_INTERVALS_DURATION_ERROR';
export const INTERVALS_CREATE_INTERVAL_SUCCESS = 'INTERVALS_CREATE_INTERVAL_SUCCESS';
export const INTERVALS_CREATE_INTERVAL_ERROR = 'INTERVALS_CREATE_INTERVAL_ERROR';
export const INTERVALS_UPDATE_INTERVAL_SUCCESS = 'INTERVALS_UPDATE_INTERVAL_SUCCESS';
export const INTERVALS_UPDATE_INTERVAL_ERROR = 'INTERVALS_UPDATE_INTERVAL_ERROR';
export const INTERVALS_GET_SERVER_OFFSET_SUCCESS = 'INTERVALS_GET_SERVER_OFFSET_SUCCESS';
export const INTERVALS_GET_SERVER_OFFSET_ERROR = 'INTERVALS_GET_SERVER_OFFSET_ERROR';
export const INTERVALS_GET_RUNNING_BY_TASK = 'INTERVALS_GET_RUNNING_BY_TASK';
export const INTERVALS_TICK_RUNNING_INTERVAL = 'INTERVALS_TICK_RUNNING_INTERVAL';

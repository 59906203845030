// Makes sure that action and reducers use the same strings.
import { VERIFY_TOKEN_SUCCESS, VERIFY_TOKEN_ERROR, CREATE_PASSWORD_SUCCESS, CREATE_PASSWORD_ERROR } from './constants';

// The data looks like below before any action is received.
const initialState = {
	secret: '',
	passwordCreated: false,
	errors: [],
};

// This function is called to update the redux store when any action is received.
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case VERIFY_TOKEN_SUCCESS:
			return {
				...state,
				secret: action.secret,
			};
		case VERIFY_TOKEN_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case CREATE_PASSWORD_SUCCESS:
			return {
				...state,
				passwordCreated: action.passwordCreated,
			};
		case CREATE_PASSWORD_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		default:
			return state;
	}
};

export default reducer;

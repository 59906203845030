import React from 'react';

import agent from '../../agent';
import Avatar from '../Icons/Avatar';
import FileUploader from '../FileUploader';
import { formatDate } from '../../lib/Utils';

import './index.scss';

class FileThumbnails extends React.Component {
	static defaultProps = {
		files: [],
		updateTaskFiles: () => {
			console.warn('no submit function parsed to TaskForm');
		},
	};
	returnedFiles = files => {
		const newFileList = this.props.files.concat(files);
		this.props.updateTaskFiles(newFileList);
	};
	removeFile = deletedFile => {
		var result = window.confirm('Are you sure you want to delete?');
		if (result) {
			let file;
			let newFileList = [];
			for (let i = 0; i < this.props.files.length; i++) {
				file = this.props.files[i];
				if (String(file._id) !== String(deletedFile._id)) {
					newFileList.push(file);
				}
			}
			this.props.updateTaskFiles(newFileList);
		}
	};
	render() {
		return (
			<div className={`flex-container trkr-full-width ${this.props.className}`}>
				<div className="flex-header time-overview">
					<FileUploader
						returnedFiles={filename => this.returnedFiles(filename)}
						files={this.props.files ? this.props.files : undefined}
						multiple={true}
					/>
				</div>
				{this.props.files && this.props.files.length > 0 && (
					<div className="flex-body scrollable-content trkr-padding-15 file-thumbnail-list">
						{this.props.files.map(file => (
							<div key={`file-card-${file._id}`} className="file-card">
								<div className="row">
									<div className="col-xs-2">
										<Avatar user={file.author} className="small" />
									</div>
									<div className="col-xs-10">
										<p className="text-small time-stamp">
											{formatDate(file.createdAt, 'LLLL').toLowerCase()}
										</p>
										<div className="bubble">
											<div className="file-image-wrapper">
												<img
													src={`${agent.API_ROOT}/attachments/${file.thumbnail}`}
													alt="image"
													className="modal-image"
												/>
												<div className="image-options">
													<div className="bg-color-dark" />
													<div className="menu-options">
														<a
															href={`${agent.API_ROOT}/attachments/${file.thumbnail}`}
															target="_blank"
															download
															className="trkr-margin-right-30">
															<i className="ion-ios-download-outline" />
														</a>
														<a
															href={`${agent.API_ROOT}/attachments/${file.thumbnail}`}
															target="_blank"
															className="trkr-margin-right-30">
															Open
														</a>
														<i
															className="glyphicon glyphicon-trash"
															onClick={() => this.removeFile(file)}
														/>
													</div>
												</div>
											</div>
											<p className="text-small text-center trkr-margin-top-10">{file.filename}</p>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
}

export default FileThumbnails;

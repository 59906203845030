import React from 'react';
import { connect } from 'react-redux';

import GA from '@letscreateltd/lc-google-analytics';

import { formatDuration2 } from '../../lib/Utils';

// generic app actions
import { onHideTimerMenu } from '../App/actions';

// interval specific actions
import { getUserIntervals, getTotalDurationForUser } from '../Intervals/actions';

import TaskCard from '../TaskCard';

import RecentList from './RecentList';

import './index.scss';

class TimerMenu extends React.Component {
	state = {
		showOverlay: false,
	};

	componentDidMount() {
		if (this.props.currentUser) {
			this.props.getUserIntervals(this.props?.nextSkip ?? 0, 25);
			this.props.getTotalDurationForUser();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.showTimerMenu) {
			GA.ReactGA.pageview('timer');
		}
		if (this.props.intervalUpdated || this.props.intervalCreated) {
			this.props.getUserIntervals(0, 25, true);
		}
	}

	handleScroll = event => {
		let isBottom =
			event.currentTarget.scrollTop + 1 >= event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
		if (isBottom) {
			this.props.getUserIntervals(this.props?.nextSkip ?? 0, 25);
		}
	};

	// reflect the last running interval, or one that is currently running for the user
	renderRecentTask = () => {
		return <span></span>;
		if (this.props?.intervals[0] && this.props.tasksById) {
			const latestInterval = this.props?.intervals[0];
			const recentTask = this.props.tasksById[latestInterval.task._id];
			if (recentTask) {
				return <TaskCard style="no-progress-bar" taskId={recentTask._id} />;
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	render() {
		if (!this.state) {
			return null;
		}

		return (
			<div className={`timer-sidebar-container ${this.props.showTimerMenu ? 'show' : 'hide'}`}>
				<div className="timer-sidebar-inner-wrapper">
					<div
						className="overlay"
						onClick={e => {
							e.stopPropagation();
							this.props.onHideTimerMenu();
						}}
					/>

					<div className="timer-side-bar flex-container">
						<div className="timer-side-bar__header flex-header">
							<div className="title trkr-display-inline-block trkr-full-width">
								<h5 className="trkr-display-inline-block">
									<i className="trkr-icon-timesheet-menu" style={{ fontSize: '20px' }} /> Timesheet
								</h5>

								<button className="float-right font-color-white trkr-padding-0 trkr-btn-transparent">
									<i
										className="trkr-icon-close"
										onClick={e => {
											e.stopPropagation();
											this.props.onHideTimerMenu();
										}}
									/>
								</button>
							</div>
							<hr />

							<div className="overview">
								{this.renderRecentTask()}

								{/* filters and total duration */}
								{/* <div className="row trkr-margin-top-10">
									<div className="col-xs-4">
										 <p className="font-light font-color-white">
											<span>All </span> <i className="ion-chevron-down" />
										</p> 
									</div>
									<div className="col-xs-8 text-right">
										<h5 className="font-color-white">
											<span className="font-weight-normal">Total: </span>{' '}
											<span>{formatDuration2(this.props.intervalsDuration, 'DDD')}</span>
											<span className="font-weight-normal"> days </span>
											<span>{formatDuration2(this.props.intervalsDuration, 'HH')}</span>
											<span className="font-weight-normal"> h </span>
											<span>{formatDuration2(this.props.intervalsDuration, 'mm')}</span>
											<span className="font-weight-normal"> m</span>
										</h5>
									</div>
								</div> */}
								<hr className="trkr-margin-top-10" />
							</div>
						</div>

						<div className="flex-body">
							<RecentList
								handleScroll={this.handleScroll}
								className="scrollable-content"
								onCardClick={this.onCardClick}
								intervals={this.props.intervals}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.intervals,
		tasksById: state.tasks.tasksById,
		currentUser: state.users.currentUser,
	};
};

const mapDispatchToProps = {
	getUserIntervals,
	getTotalDurationForUser,
	onHideTimerMenu,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TimerMenu);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';

import Form from '../../components/Form';

import { getQuery } from '../../lib/Utils';
import { getSubscription, updateWorkspace } from './actions';

import logo from '../../assets/images/logo-bw.png';
import './index.scss';

class WorkspaceRegistrationPage extends Component {
	state = {
		workspaceName: '',
		slug: '',
	};
	async componentDidMount() {
		const subscriptionId = getQuery(this.props.location.search, 's');
		this.props.getSubscription(subscriptionId);
	}
	componentDidUpdate(prevProps) {
		if (this.props.subscription !== prevProps.subscription) {
			let workspaceName = `${this.props.subscription.user.fullName.replace(/\s/g, '')}-${shortid.generate()}`;
			let slug = this.slugifyWorkspaceName(workspaceName);

			this.setState({
				workspaceName,
				slug,
			});
		}
		if (this.props.workspace !== prevProps.workspace) {
			this.props.history.push(`/team-invites?w=${this.props.workspace._id}`);
		}
	}
	updateWorkspaceName = event => {
		this.setState({ workspaceName: event.target.value, slug: this.slugifyWorkspaceName(event.target.value) });
	};

	// this should match the slug "rules" from the back end
	// special characters should be omitted as it's part of the url
	slugifyWorkspaceName = name => {
		return name.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
	};

	submitForm = () => {
		console.log('submitting workspace name', this.state.workspaceName);

		const { workspaceName, slug } = this.state;

		// this.props.updateWorkspace(this.props.subscription.workspace, this.state.workspaceName);
		let updateObject = { name: workspaceName, slug };
		this.props.updateWorkspace(this.props.subscription.workspace, updateObject);
	};

	render() {
		return (
			<div className="row full-height">
				<div className="col-md-5 col-xs-5 workspace-form-column full-height">
					<div className="vertical-center full-height">
						<img className="workspace-form-column-logo" src={logo} />

						<Form className="form-horizontal workspace-form" submit={this.submitForm}>
							<p className="workspace-form-step">STEP 2/4</p>
							<h2>Create a</h2>
							<h2>workspace</h2>
							<div className="form-group workspace-form-group">
								<label
									className="col-sm-4 col-sm-offset-2 control-label workspace-form-group-label"
									htmlFor={'workspace'}>
									Workspace name:{' '}
								</label>
								<div className="col-sm-6">
									<input
										className="form-control workspace-form-group-input"
										name="workspace"
										type="text"
										required={true}
										maxLength="15"
										minLength="5"
										pattern="^\w.*"
										placeholder="workspace"
										value={this.state.workspaceName}
										onChange={this.updateWorkspaceName}
										validationmessage={`Please enter minimum 5 characters`}
									/>
									<div className="invalid-feedback" />
								</div>
							</div>
							<p className="workspace-form-name">{'Your URL will be'}</p>
							<p className="workspace-form-name-url">{`${this.state.slug}.trkr.space*`}</p>
							<button
								className="btn btn-primary workspace-form-button"
								type="submit"
								disabled={this.props.inProgress}>
								NEXT
							</button>
							<p className="workspace-form-settings">
								*This can be changed in the settings after registration
							</p>
						</Form>
					</div>
				</div>
				<div className="col-md-7 col-xs-7 workspace-image-column">
					<div className="workspace-image-column-navbar">
						<div className="workspace-image-column-navbar-tab-1">
							<div className="workspace-image-column-navbar-tab-1-title" />
						</div>
						<div className="workspace-image-column-navbar-tab-2">
							<div className="workspace-image-column-navbar-tab-2-title" />
						</div>
						<div className="workspace-image-column-navbar-name">
							<p>{this.props.subscription.user ? this.props.subscription.user.fullName : ''}</p>
							<p>{this.state.workspaceName}</p>
						</div>
						<div className="workspace-image-column-navbar-avatar" />
					</div>
					<div className="workspace-image-column-board">
						<div className="workspace-image-column-board-section-1">
							<div className="workspace-image-column-board-section-1-drop-down" />
							<div className="workspace-image-column-board-section-1-search-bar" />
						</div>
						<div className="workspace-image-column-board-section-2">
							<div className="workspace-image-column-board-section-2-task-card-1">
								<div className="workspace-image-column-board-section-2-task-card-1-title" />
								<div className="workspace-image-column-board-section-2-task-card-1-details">
									<div className="workspace-image-column-board-section-2-task-card-1-details-item-1" />
									<div className="workspace-image-column-board-section-2-task-card-1-details-item-2" />
									<div className="workspace-image-column-board-section-2-task-card-1-details-item-3" />
									<div className="workspace-image-column-board-section-2-task-card-1-details-item-4" />
								</div>
							</div>
							<div className="workspace-image-column-board-section-2-task-card-2">
								<div className="workspace-image-column-board-section-2-task-card-2-title" />
								<div className="workspace-image-column-board-section-2-task-card-2-details">
									<div className="workspace-image-column-board-section-2-task-card-2-details-item-1" />
									<div className="workspace-image-column-board-section-2-task-card-2-details-item-2" />
									<div className="workspace-image-column-board-section-2-task-card-2-details-item-3" />
									<div className="workspace-image-column-board-section-2-task-card-2-details-item-4" />
								</div>
							</div>
							<div className="workspace-image-column-board-section-2-task-card-3">
								<div className="workspace-image-column-board-section-2-task-card-3-title" />
								<div className="workspace-image-column-board-section-2-task-card-3-details">
									<div className="workspace-image-column-board-section-2-task-card-3-details-item-1" />
									<div className="workspace-image-column-board-section-2-task-card-3-details-item-2" />
									<div className="workspace-image-column-board-section-2-task-card-3-details-item-3" />
									<div className="workspace-image-column-board-section-2-task-card-3-details-item-4" />
								</div>
							</div>
							<div className="workspace-image-column-board-section-2-task-card-4">
								<div className="workspace-image-column-board-section-2-task-card-4-title" />
								<div className="workspace-image-column-board-section-2-task-card-4-details">
									<div className="workspace-image-column-board-section-2-task-card-4-details-item-1" />
									<div className="workspace-image-column-board-section-2-task-card-4-details-item-2" />
									<div className="workspace-image-column-board-section-2-task-card-4-details-item-3" />
									<div className="workspace-image-column-board-section-2-task-card-4-details-item-4" />
								</div>
							</div>
						</div>
						<div className="workspace-image-column-board-section-3">
							<div className="workspace-image-column-board-section-3-task-card-5">
								<div className="workspace-image-column-board-section-3-task-card-5-details">
									<div className="workspace-image-column-board-section-3-task-card-5-details-item-1" />
									<div className="workspace-image-column-board-section-3-task-card-5-details-item-2" />
									<div className="workspace-image-column-board-section-3-task-card-5-details-item-3" />
									<div className="workspace-image-column-board-section-3-task-card-5-details-item-4" />
								</div>
							</div>
							<div className="workspace-image-column-board-section-3-task-card-6">
								<div className="workspace-image-column-board-section-3-task-card-6-details">
									<div className="workspace-image-column-board-section-3-task-card-6-details-item-1" />
									<div className="workspace-image-column-board-section-3-task-card-6-details-item-2" />
									<div className="workspace-image-column-board-section-3-task-card-6-details-item-3" />
									<div className="workspace-image-column-board-section-3-task-card-6-details-item-4" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state.workspaceRegistrationPage,
});

export default connect(
	mapStateToProps,
	{
		getSubscription,
		updateWorkspace,
	}
)(WorkspaceRegistrationPage);

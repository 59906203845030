import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Notifications, { notify } from 'react-notify-toast';
import ReCAPTCHA from 'react-google-recaptcha';

import { getQuery } from '../../lib/Utils';
import { secretGet, createPassword } from './actions';

class PasswordFormPage extends Component {
	state = {
		password: '',
		passwordConfirmation: '',
		reCaptcha: '',
	};
	componentDidMount() {
		const token = getQuery(this.props.location.search, 't');
		if (token) {
			this.props.secretGet(token);
		} else {
			console.log('token is not provided.');
		}
	}
	componentDidUpdate(prevProps) {
		if (this.props.passwordCreated !== prevProps.passwordCreated) {
			this.props.history.push('/login');
		}
	}
	submit = event => {
		event.preventDefault();
		if (!this.state.reCaptcha) {
			notify.show('Please do the captcha.', 'error', 5000);
		}
		if (this.props.secret && this.state.password && this.state.passwordConfirmation && this.state.reCaptcha) {
			this.props.createPassword(
				this.props.secret,
				this.state.password,
				this.state.passwordConfirmation,
				this.state.reCaptcha
			);
		}
	};
	changePassword = event => {
		const password = event.target.value;
		this.setState({ password });
	};
	changePasswordConfirmation = event => {
		const passwordConfirmation = event.target.value;
		this.setState({ passwordConfirmation });
	};
	changeReCaptcha = value => {
		const reCaptcha = value;
		this.setState({ reCaptcha });
	};
	render() {
		return (
			<Fragment>
				<Notifications options={{ zIndex: 3000 }} />
				<div className="container">
					<div className="row">
						<div className="col-xs-10 col-xs-offset-1 col-md-6 col-md-offset-3">
							<div className="page">
								<h1>Reset Password</h1>
								<hr />
								<p>Please ensure that your password contains all of the following:</p>
								<ul>
									<li>At least 8 characters</li>
									<li>1 lower case and 1 uppercase letter</li>
									<li>1 number</li>
									<li>1 symbol such as &amp; ! or %</li>
								</ul>
								<hr />
								<form onSubmit={this.submit}>
									<div>
										<label htmlFor="password">Password</label>
										<input
											className="form-control"
											type="password"
											name={'password'}
											required={true}
											value={this.state.password}
											onChange={this.changePassword}
										/>
									</div>
									<div>
										<label htmlFor="passwordConfirmation">Password</label>
										<input
											className="form-control"
											type="password"
											name={'passwordConfirmation'}
											required={true}
											value={this.state.passwordConfirmation}
											onChange={this.changePasswordConfirmation}
										/>
									</div>
									<br />
									<ReCAPTCHA
										ref="g-recaptcha"
										sitekey="6LfjnqwUAAAAANojlJPhnnawwMdB9prOlpoYXQ5U"
										onChange={this.changeReCaptcha}
									/>
									<div className="text-right">
										<button className="btn btn-primary" type="submit">
											Submit
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	...state.passwordFormPage,
});

export default connect(
	mapStateToProps,
	{ secretGet, createPassword }
)(PasswordFormPage);

// Makes sure that action and reducers use the same strings.
import {
	PROJECT_GET_WORKSPACE_SUCCESS,
	PROJECT_GET_WORKSPACE_ERROR,
	CREATE_PROJECT_SUCCESS,
	CREATE_PROJECT_ERROR,
} from './constants';

// The data looks like below before any action is received.
const initialState = {
	workspace: {},
	project: {},
	errors: [],
};

// This function is called to update the redux store when any action is received.
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case PROJECT_GET_WORKSPACE_SUCCESS:
			return {
				...state,
				workspace: action.workspace,
			};
		case PROJECT_GET_WORKSPACE_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case CREATE_PROJECT_SUCCESS:
			return {
				...state,
				project: action.project,
			};
		case CREATE_PROJECT_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		default:
			return state;
	}
};

export default reducer;

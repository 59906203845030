import { GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_SUCCESS, GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_ERROR, GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_FOR_PROJECT_SUCCESS, GANTT_GET_TASKS_BY_STORY_FOR_VIEW_SUCCESS, GANTT_GET_PROJECT_FOR_VIEW, GANTT_UPDATE_SCHEDULE_SLOT } from './constants';

const INITIAL_STATE = {
	tasksByProjectByViews: {}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_SUCCESS:
			// console.log(`${action.type}`, action);
		console.log('reducer getTasksByProjectForView action', action);
			
			let tasksByProjectByViews = state.tasksByProjectByViews;
			tasksByProjectByViews.views = tasksByProjectByViews.views || {};
			tasksByProjectByViews.views[action.viewId] = tasksByProjectByViews.views[action.viewId] || {};//action.tasksByProjectForView;
			tasksByProjectByViews.views[action.viewId].projects = tasksByProjectByViews.views[action.viewId].projects || {};

			for (let projectId in action.tasksByProjectForView.projects){
				tasksByProjectByViews.views[action.viewId].projects[projectId] = action.tasksByProjectForView.projects[projectId];
			}

			tasksByProjectByViews.views[action.viewId].nextSkip = action.tasksByProjectForView.nextSkip;
			tasksByProjectByViews.views[action.viewId].max = action.tasksByProjectForView.max;


			console.log('reducer getTasksByProjectForView tasksByProjectByViews',tasksByProjectByViews);
			let newState = {
				...state,
				tasksByProjectByViews
			};
			console.log('newState getTasksByProjectForView',newState);
			return newState;
		case GANTT_GET_PROJECT_FOR_VIEW:
			tasksByProjectByViews = state.tasksByProjectByViews;
			tasksByProjectByViews.views = tasksByProjectByViews.views || {};
			tasksByProjectByViews.views[action.viewId] = tasksByProjectByViews.views[action.viewId] || {};//action.tasksByProjectForView;
			tasksByProjectByViews.views[action.viewId].projects = tasksByProjectByViews.views[action.viewId].projects || {};
			tasksByProjectByViews.views[action.viewId].projects[action.project._id] = action.project;
			console.log("action project", action.project);
			newState = {
				...state,
				tasksByProjectByViews
			};
			return newState;
		case GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_FOR_PROJECT_SUCCESS: 
			console.log("action",action);
			tasksByProjectByViews = state.tasksByProjectByViews;
			if (!tasksByProjectByViews || !tasksByProjectByViews.views){
				return alert("its not handled to call this before initial all projects/views been loaded first");
			}
			tasksByProjectByViews.views[action.viewId].projects[action.projectId] = action.tasksByProjectForViewForProject.projects[action.projectId];
			console.log('reducer getTasksByProjectForViewForProject',tasksByProjectByViews,action);
			newState = {
				...state,
				tasksByProjectByViews
			};
			console.log('newState getTasksByProjectForView',newState);
			return newState;
		case GANTT_UPDATE_SCHEDULE_SLOT:
			console.log("GANTT_UPDATE_SCHEDULE_SLOT",action);
			return state;
		case GANTT_GET_TASKS_BY_STORY_FOR_VIEW_SUCCESS:
				
				console.log('JS11 reducer getTasksByProjectForView action', action);
					
				tasksByProjectByViews = state.tasksByProjectByViews;
				

				if (!tasksByProjectByViews.views){
					return alert("its not handled to call this before initial all projects/views been loaded first");
				}
				for (let taskId in action.tasksByStoryForView[action.storyId].tasks){
					console.log('JS11 ',action.tasksByStoryForView[action.storyId].tasks,taskId);
					tasksByProjectByViews.views[action.viewId].projects[action.projectId].releases[action.releaseId].stories[action.storyId].tasks[taskId] = action.tasksByStoryForView[action.storyId].tasks[taskId];
				}

				tasksByProjectByViews.views[action.viewId].projects[action.projectId].releases[action.releaseId].stories[action.storyId].nextSkip = action.tasksByStoryForView[action.storyId].nextSkip;


				console.log('reducer getTasksByProjectForView tasksByProjectByViews',tasksByProjectByViews);
				console.log('JS11 final',tasksByProjectByViews.views[action.viewId].projects[action.projectId].releases[action.releaseId].stories[action.storyId]);
				newState = {
					...state,
					tasksByProjectByViews
				};
				console.log('newState getTasksByProjectForView',newState);
				return newState;
		case GANTT_GET_TASKS_BY_PROJECT_BY_VIEW_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		default:
			return state;
	}
};

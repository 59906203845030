import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import WarningIcon from '../../../../../../assets/images/icons/warning.png';

import { getTasksWithNoEstimatesByView } from '../../../../../Tasks/actions';

import './index.scss';

const EstimatePrompt = props => {
	const { viewId, view, tasksById } = props;
	const [unestimatedTasks, setUnestimatedTasks] = useState([]);
	// let unestimatedTasks = [];

	useEffect(
		() => {
			// console.log('[EstimatePrompt] mounted / updated');
			console.log('VIEW CHANGES', view);

			// is this needed?
			props.getTasksWithNoEstimatesByView(viewId);
		},
		[view]
	);

	// update unestimatedTask list when any of the view data changes
	// TODO: listen for changes to the
	useEffect(
		() => {
			if (!view.unestimatedTasks || view.unestimatedTasks.length === 0) {
				setUnestimatedTasks([]);
			} else {
				// TODO: map to store objects

				const mappedTasks = Object.values(tasksById).filter(
					task => view.unestimatedTasks.includes(String(task._id)) && !task.estimate
				);

				// console.log('mappedTasks', mappedTasks);

				setUnestimatedTasks(mappedTasks);
			}
		},
		[tasksById, unestimatedTasks]
	);

	// TODO: watch for changes on the mapped tasks, if the task has an estimate provided, then the count needs to change

	const actionTasks = () => {
		alert('Jacopo to design what happens next');
	};

	// don't render component if there are no relevant tasks
	if (!unestimatedTasks || unestimatedTasks.length === 0) return null;

	return (
		<div className="estimate-prompt">
			<p>
				<span className="trkr-margin-right-10">
					<img className="icon warning" src={WarningIcon} />
				</span>
				<span className="link" onClick={actionTasks}>
					{unestimatedTasks.length} task(s)
				</span>{' '}
				needs estimating
			</p>
		</div>
	);
};

// NOTE: passed view from HOC was incorrect, unsure why without spending more time investigating.
// Here why are mapping directly with the store by the given viewId (which is still passed by HOC)
// TODO: move view mapping up to Dashboard
const mapStateToProps = (state, ownProps) => {
	function mapView() {
		return state.views.views ? state.views.views.filter(view => view._id === ownProps.viewId)[0] : null;
	}

	let props = {
		view: mapView(),
		views: state.views,
		tasksById: state.tasks.tasksById,
	};

	return props;
};

const mapDispatchToProps = {
	getTasksWithNoEstimatesByView,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EstimatePrompt);

import _ from 'lodash';
import { SortByWeight } from '../../lib/Utils';

// Makes sure that action and reducers use the same strings.
import {
	CREATE_VIEW_SUCCESS,
	CREATE_VIEW_ERROR,
	GET_VIEWS_SUCCESS,
	GET_VIEWS_ERROR,
	GET_VIEW_SUCCESS,
	DELETE_VIEW_SUCCESS,
	DELETE_VIEW_ERROR,
	UPDATE_VIEW_SUCCESS,
	UPDATE_VIEW_ERROR,
	UPDATE_VIEWS_SUCCESS,
	UPDATE_VIEWS_ERROR,
} from './constants';

import { TASKS_GET_TASK_NO_ESTIMATES } from '../Tasks/constants';

// The data looks like below before any action is received.
const initialState = {
	views: [],
	tasks: [],
	projects: [],
	boardData: [],
	errors: [],
	selectedIndex: 0,
};

// This function is called to update the redux store when any action is received.
const reducer = function boardReducer(state = initialState, action) {
	switch (action.type) {
		// get the tasks with no estimates for the
		case TASKS_GET_TASK_NO_ESTIMATES:
			const { viewId, unestimatedTasks } = action;
			console.log(`${action.type}`, viewId, unestimatedTasks, state.views);

			let views = state.views;

			// loop through all views in state to find the relevant one
			for (var key in views) {
				// console.log('looping views', key, views[key]);

				if (views[key]._id === viewId) {
					views[key].unestimatedTasks = unestimatedTasks;
				}
			}

			console.log(`${action.type}`, state, views);

			return {
				...state,
				views: [...views],
			};
			return state;
		case CREATE_VIEW_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};

		case DELETE_VIEW_SUCCESS:
			// console.clear();
			console.log('DELETE_VIEW_SUCCESS');

			return {
				...state,
			};

		case DELETE_VIEW_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};

		// GET initial boards
		case GET_VIEWS_SUCCESS:
			console.log('GET_VIEWS_SUCCESS yo', action, state);

			// // map filter object to match the same format as the filter bar {label:user.username, value:user._id, type:'user'}
			// if (action.views && action.views.length > 0) {
			// 	var newState = {
			// 		...state,
			// 	};

			// 	// sort views by orderWeight
			// 	action.views = action.views.sort(SortByWeight);

			// 	let views = action.views.map(view => {
			// 		return {
			// 			...view,
			// 			filters: [],
			// 		};
			// 	});

			// 	newState.views = views;

			// 	return newState;
			// }
			// return state;
			return {
				...state,
				views: action.views,
			};
		case GET_VIEW_SUCCESS:
			let indexOfView = state.views
				.map(function(x) {
					return x._id;
				})
				.indexOf(action.view._id);
			let nViews = state.views.map(view => {
				return view;
			});

			nViews[indexOfView] = { ...nViews[indexOfView], ...action.view };

			return {
				...state,
				views: nViews,
			};

		case GET_VIEWS_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error ? action.error.toString() : action.error,
						time: new Date(),
					},
				]),
			};
		case UPDATE_VIEW_SUCCESS:
			// console.log('UPDATE_VIEW_SUCCESS', action);

			// let views = state.views;

			// // TODO: map get the index of the action.viewId (parsed via actions.js)
			// if (state.views && Object.keys(state.views).length > 0) {
			// 	let { view } = action;

			// 	let relatedViewIndex;

			// 	for (var key in state.views) {
			// 		if (state.views.hasOwnProperty(key)) {
			// 			if (state.views[key]._id === view._id) {
			// 				relatedViewIndex = key;
			// 				break;
			// 			}
			// 		}
			// 	}

			// 	// TODO: add data to view object with state (to avoid the initial call every tab click)
			// 	// update view type as needed
			// 	views[relatedViewIndex].type = view.type;
			// 	views[relatedViewIndex].title = view.title;
			// 	views[relatedViewIndex].filters = view.filters;
			// }

			return {
				...state,
				// updatedView: action.view,
				// isLoadingTasks: false,
				// views: views,
			};
		case UPDATE_VIEW_ERROR:
			return {
				...state,
				isLoadingTasks: false,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case UPDATE_VIEWS_SUCCESS:
			return {
				...state,
			};
		case UPDATE_VIEWS_ERROR:
			return {
				...state,
				isLoadingTasks: false,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
	}

	return state;
};

export default reducer;

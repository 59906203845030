// Makes sure that action and reducers use the same strings.
import {
	GET_SUBSCRIPTION_SUCCESS,
	GET_SUBSCRIPTION_ERROR,
	UPDATE_WORKSPACE_SUCCESS,
	UPDATE_WORKSPACE_ERROR,
} from './constants';

// The data looks like below before any action is received.
const initialState = {
	subscription: {},
	workspace: {},
	errors: [],
};

// This function is called to update the redux store when any action is received.
const reducer = function workspaceRegistrationReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				subscription: action.subscription,
			};
		case GET_SUBSCRIPTION_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case UPDATE_WORKSPACE_SUCCESS:
			return {
				...state,
				workspace: action.workspace,
			};
		case UPDATE_WORKSPACE_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		default:
			return state;
	}
};

export default reducer;

import React, { Component, Fragment } from 'react';
import agent from '../../agent';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import history from '../../lib/History';

import Select from 'react-select';

import { formatDuration, formatDate, numberPad, dateDiff, formatDuration20 } from '../../lib/Utils';

import Avatar from '../Icons/Avatar';
import ProjectTag from '../Icons/ProjectTag';

import './index.scss';

import { onHideModal } from '../App/actions';
import { createInterval } from '../Intervals/actions';
import moment from 'moment';
import BtnTimer from '../BtnTimer';

class IntervalCard extends Component {
	// default properties (overwritten by props passed by parent component)
	static defaultProps = {
		onStop: () => {
			console.warn('no onStop function available in Interval component');
		},
		onStart: () => {
			console.warn('no onStart function available in Interval component');
		},
	};

	// default state (private to this component)
	state = {};

	constructor() {
		super();
	}

	componentDidMount() {
		// if(this.props.task.status)
		// console.log('[IntervalCard.componentDidMount]', this.props.intervalDuration);
	}

	// componentWillReceiveProps(nextProps) {}

	handleTaskChange = (newValue: any, actionMeta: any) => {
		console.log('[handleTaskChange]', newValue);
	};
	handleTaskInputChange = (inputValue: any, actionMeta: any) => {};

	onOpenInterval = () => {
		console.warn('redirect please', history);

		// hide task modal if open - quick fix
		this.props.onHideModal();
		history.push(`${history.location.pathname}/interval/${this.props.interval.id || this.props.interval._id}`);
	};

	createInterval = (e) => {
		e.stopPropagation();

		console.warn('start new interval', this.props.interval, this.props.task);

		this.props.createInterval(this.props.task);
	};

	render() {
		if (!this.props.style || !this.props.interval) return null;

		// console.log('[IntervalCard.render]', this.props);

		if (this.props.style === 'timesheet') {
			return (
				<div className={'interval-card style-timesheet ' + (this.props.className ? this.props.className : '')}>
					<div className="row trkr-align-middle">
						<div className="col-xs-2 trkr-align-top text-center">
							<Avatar className="user-avatar small trkr-full-width" user={this.props.interval.author} />
						</div>

						<div className="col-xs-10 trkr-padding-left-0">
							<p className="text-small time-stamp">
								<span>{formatDate(this.props.intervalStart, 'LT').toLowerCase()}</span>
								<span className="text-light">
									{' '}
									> {formatDate(this.props.intervalEnd, 'LT').toLowerCase()}
								</span>
							</p>
							<div className="wrapper text-right">
								<button className="btn trkr-btn trkr-btn-default" onClick={this.onOpenInterval}>
									<i className="ion-edit trkr-margin-right-5" />{' '}
									{formatDuration20(this.props.intervalDuration, 'hhmmss')}
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		}

		if (this.props.style === 'timermenu') {
			let hasRunningTimer = false;
			return (
				<div
					className={'interval-card style-timermenu'}
					title={
						(this.props.task ? `#${this.props.task.title}` : `${this.props.interval.title}`) +
						` : ${formatDuration(this.props.intervalDuration)}`
					}
					onClick={this.onOpenInterval}>
					{/* overlay to reflect if the card is complete */}
					{/* {this.props.task.status === '5b90ff551c1099db2218ec4f' && <div className="task-complete-overlay" />} */}

					<div className="interval-card-body">
						<div className="row vertical-align-middle">
							<div className="col-xs-3">
								{this.props.task && (
									<p className="task-id font-color-white">#{this.props.task.workspaceCounter}</p>
								)}
								<p className="time-stamp font-light">
									{moment(this.props.interval.startDateTime).format('h:mma')}
								</p>
							</div>
							<div className="col-xs-5 trkr-padding-left-0">
								<p className="interval-title font-color-white" style={{ marginBottom: '3px' }}>
									{this.props.interval.task
										? `${this.props.interval.task.title}`
										: `${this.props.interval.title}`}
								</p>
								{this.props.project && (
									<ProjectTag
										projectTitle={this.props.project.title}
										projectColour={this.props.project.tagColour}
									/>
								)}
							</div>

							<div className="col-xs-4 text-right">
								<BtnTimer taskId={this.props.interval.task._id} showStartLabelAs={formatDuration20(this.props.interval.totalDurationInMS)}/>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = (state, ownProps) => {
	let props = {
		task: {
			...ownProps.interval.task,
		},
		project: ownProps.interval.task && ownProps.interval.task.project,
		intervalStart: ownProps.interval.startDateTime,
		intervalEnd: ownProps.interval.endDateTime,
	};

	props.intervalDuration = ownProps.interval.totalDurationInMS;  //dateDiff(props.intervalStart, props.intervalEnd);

	return props;
};

// TODO: utilise redux for redirection

const mapDispatchToProps = {
	createInterval,
	onHideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntervalCard);

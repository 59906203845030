import React, { Fragment } from 'react';
import Diff from 'react-stylable-diff';
import { Value } from 'slate';
import Plain from 'slate-plain-serializer';

import agent from '../../../../../../../agent';
import history from '../../../../../../../lib/History';
import { formatDate, formatDuration } from '../../../../../../../lib/Utils';

import ActivityLinks from './ActivityLinks';

function tryParse(str) {
	try {
		return { value: JSON.parse(str), isValid: str !== null };
	} catch (e) {
		return { value: str, isValid: false };
	}
}

// TODO: need to add links for tasks
const ActivityEntry = props => {
	const { action, afterValue, author, beforeValue, task, story } = props;

	const renderEntryContent = props => {
		switch (action) {
			case 'task-created':
				return (
					<p>
						{author.fullName} created a task {ActivityLinks(props)}
					</p>
				);
			case 'story-created':
				return (
					<p>
						{author ? author.fullName + ' created a story ' : 'A story was created'} {ActivityLinks(props)}
					</p>
				);
			case 'task-interval-started':
				return (
					<p>
						{author.fullName} started working on {ActivityLinks(props)}
					</p>
				);
			case 'task-estimate-change':
				return (
					<p>
						{author.fullName} updated the estimate for {ActivityLinks(props)}
						<span className="value value-before">{beforeValue ? formatDuration(beforeValue) : 'X'}</span>
						<span className="value value-after">{formatDuration(afterValue)}</span>
					</p>
				);
			case 'task-description-change':
			case 'story-description-change':
				return (
					<Fragment>
						<p>
							{author.fullName} has updated the description for {ActivityLinks(props)}
						</p>
						<Diff
							inputA={
								tryParse(props.beforeValue).isValid
									? Plain.serialize(Value.fromJSON(JSON.parse(props.beforeValue)))
									: props.beforeValue
							}
							inputB={
								tryParse(props.afterValue).isValid
									? Plain.serialize(Value.fromJSON(JSON.parse(props.afterValue)))
									: props.afterValue
							}
							type="chars"
						/>
					</Fragment>
				);
			case 'task-flag-notes-change':
				return (
					<Fragment>
						<p>
							{author.fullName} has updated the flag notes for {ActivityLinks(props)}
						</p>
						<Diff
							inputA={
								tryParse(props.beforeValue).isValid
									? Plain.serialize(Value.fromJSON(JSON.parse(props.beforeValue)))
									: props.beforeValue
							}
							inputB={
								tryParse(props.afterValue).isValid
									? Plain.serialize(Value.fromJSON(JSON.parse(props.afterValue)))
									: props.afterValue
							}
							type="chars"
						/>
					</Fragment>
				);
			case 'file-added':
				return (
					<Fragment>
						<p>
							{author.fullName} added an attachment to {ActivityLinks(props)}
						</p>
						<p>
							<a href={`${agent.API_ROOT}/attachments/${afterValue}`} target="_blank">
								{afterValue}
							</a>
						</p>
					</Fragment>
				);
			case 'file-removed':
				return (
					<Fragment>
						<p>
							{author.fullName} removed an attachment from {ActivityLinks(props)}
						</p>
						<p>
							<a href={`${agent.API_ROOT}/attachments/${beforeValue}`} target="_blank">
								{beforeValue}
							</a>
						</p>
					</Fragment>
				);
			default:
				if (!beforeValue && !afterValue) {
					return <p>{action}</p>;
				} else {
					return (
						<p>
							{author.fullName} has updated the task {ActivityLinks(props)}
							<span className="value value-before">{beforeValue || 'X'}</span>
							<span className="value value-after">{afterValue}</span>
						</p>
					);
				}
		}
	};

	if (!author) {
		return null;
	}

	return (
		<div className="activity-feed-entry row">
			<div className="col-xs-12">
				<div className="content">{renderEntryContent(props)}</div>
			</div>
		</div>
	);
};

export default ActivityEntry;

// Makes sure that action and reducers use the same strings.
import agent from '../../agent';
import {
	GET_WORKSPACE_USERS_SUCCESS,
	GET_WORKSPACE_USERS_ERROR,
	GET_WORKSPACE_SUCCESS,
	GET_WORKSPACE_ERROR,
	INVITE_USERS_SUCCESS,
	INVITE_USERS_ERROR,
} from './constants';

export const getWorkspaceUsers = async workspaceId => {
	try {
		const response = await agent.WorkspaceUsers.getWorkspaceUsers(workspaceId);
		const workspaceUsers = response.workspaceUsers;
		return { type: GET_WORKSPACE_USERS_SUCCESS, workspaceUsers };
	} catch (error) {
		return { type: GET_WORKSPACE_USERS_ERROR, error };
	}
};

export const getWorkspace = async workspaceId => {
	try {
		const response = await agent.Workspaces.get(workspaceId);
		const workspace = response.workspace;
		return { type: GET_WORKSPACE_SUCCESS, workspace };
	} catch (error) {
		return { type: GET_WORKSPACE_ERROR, error };
	}
};

export const inviteUsers = async (workspaceId, emails) => {
	try {
		const response = await agent.Workspaces.inviteUsers(workspaceId, emails);
		const sentEmails = response.sentEmails;
		return { type: INVITE_USERS_SUCCESS, sentEmails };
	} catch (error) {
		return { type: INVITE_USERS_ERROR, error };
	}
};

import React from 'react';

import Avatar from '../Icons/Avatar';
import { formatDate } from '../../lib/Utils';

class CommitMessage extends React.Component {
	/*constructor() {
      	super();
  	}*/

	componentWillMount() {}

	render() {
		var commit = this.props.message;

		return (
			<div className="commit-entry row">
				<div className="col-xs-2 trkr-align-top">
					<Avatar username={commit.userDisplayName} className="small trkr-full-width" />
				</div>

				<div className="col-xs-10 trkr-padding-left-0">
					<p className="text-small time-stamp">{formatDate(commit.createdAt, 'LT').toLowerCase()}</p>

					<div className={'message-bubble'}>
						<p className="author trkr-full-width">
							<span>Push to "{commit.branch}"</span>
						</p>
						<p className="trkr-margin-0 content">{commit.msg}</p>
					</div>
				</div>
			</div>
		);
	}
}

export default CommitMessage;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Notifications, { notify } from 'react-notify-toast';

import ListErrors from '../../../ListErrors';
import Modal from '../../../Modal';
import TaskForm from '../TaskForm';

import { changeEstimateToMilliseconds } from '../../../../lib/Utils';

import { onShowModalExistingTask, onHideModal } from '../../../App/actions';
import { createTask, getTasksByViewByStatus } from '../../../Tasks/actions';

const NewTaskModal = (props) => {
	const [newTask, setNewTask] = useState({});
	let showToastr;

	if (!showToastr) {
		showToastr = notify.createShowQueue();
	}

	useEffect(() => {
		if (props.taskCreated) {
			for (const view of props.views) {
				props.getTasksByViewByStatus(view._id, 0, true, null, null, props[view._id]?.nextSkip ?? 5);
			}
			props.onShowModalExistingTask({ _id: props.newTaskId });
		}
	}, [props.taskCreated]);

	useEffect(() => {
		return () => {
			setNewTask({});
		};
	}, []);

	const onClose = () => {
		props.onHideModal();
	};

	const getTaskReadyForServer = (task) => {
		const taskReadyForServer = {
			title: task.title,
			description: task.description,
			flagNotes: task.flagNotes,
			estimate: changeEstimateToMilliseconds(task.estimate),
			managementEstimate: changeEstimateToMilliseconds(task.managementEstimate),
			status: task.status ? task.status.value : null,
			category: task.category ? task.category.value : null,
			project: task.project ? task.project.value : null,
			story: task.story ? task.story.value : null,
			taskDependencies: task.taskDependencies
				? task.taskDependencies.map((task) => task.value)
				: null,
			priority: task.priority ? task.priority.value : null,
			assignedTo: task.assignedTo ? task.assignedTo.value : null,
		};
		return taskReadyForServer;
	}

	const updateTaskInTaskModal = (taskFromTaskForm) => {
		setNewTask((prevTask) => {
			return {
				...prevTask,
				...taskFromTaskForm
			};
		});
	}

	const submitForm = () => {
		const newTaskObject = getTaskReadyForServer(newTask);
		if (!newTaskObject.title || !newTaskObject.project || !newTaskObject.category || !newTaskObject.status) {
			console.log(
				'[NewTaskModal.submitForm] form information missing',
				newTaskObject.title,
				newTaskObject.project,
				newTaskObject.category,
				newTaskObject.status
			);
			showToastr('Please provide required information', 'error');
			return;
		}
		props.createTask(newTaskObject);
	};

	return (
		<Modal>
			<div className={'flex-container trkr-padding-lr-60 xs-trkr-padding-lr-30 md-display-flex col-md-12'}>
				<div className="modal-header flex-header md-trkr-padding-tb-15">
					<div className="row md-hide trkr-padding-tb-10">
						<div className="col-xs-6">
							<button className={'trkr-icon-close position-fixed trkr-btn-close'} onClick={onClose} />
						</div>
					</div>
					<div className="row vertical-align-middle">
						<div className={'col-xs-12 text-center'}>
							<h5 className={'header-title trkr-margin-top-0'}>
								New task
								<button className="trkr-icon-close trkr-btn-close trkr-float-right" onClick={onClose} />
							</h5>
						</div>
					</div>
				</div>
				<div className="flex-body">
					{/* Should this be within the form? */}
					<Notifications options={{ zIndex: 3000 }} />
					<ListErrors errors={props.errors} />
					<div className="scrollable-content trkr-full-width trkr-margin-top-30">
						{/* all form functionality now in a sub-component, which can now be included in other areas as necessary */}
						<TaskForm
							updateTaskInTaskModal={updateTaskInTaskModal}
							hideSubmitBtn={true}
							defaultStatus={props.defaultStatus}
							defaultCategory={props.defaultCategory}
							defaultPriority={props.defaultPriority}
							defaultAssignedTo={props.currentUser._id}
						/>
					</div>
				</div>
				<div className="flex-footer">
					<div className="trkr-full-width trkr-padding-tb-10 bg-grey-2" style={{ zIndex: 1 }}>
						<div className="row vertical-align-middle">
							<div className="col-xs-4 col-xs-offset-8 text-right">
								<button
									className="trkr-btn"
									type="submit"
									disabled={props.isLoading == true}
									onClick={() => {
										submitForm();
									}}>
									Create Task
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

NewTaskModal.defaultProps = {
	defaultStatus: 'backlog',
	defaultCategory: 'task',
	defaultPriority: 'medium',
};

const mapStateToProps = (state) => ({
	taskCreated: state.tasks.taskCreated,
	newTaskId: state.tasks.newTaskId,
	currentUser: state.users.currentUser,
	views: state.views.views,
	...state.tasks,
});

export default connect(mapStateToProps, {
	createTask,
	onShowModalExistingTask,
	onHideModal,
	getTasksByViewByStatus,
})(NewTaskModal);

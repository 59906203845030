import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getQuery } from '../../lib/Utils';
import { getWorkspace, createProject } from './actions';
import './index.scss';
import logo from '../../assets/images/logo-bw.png';

import { getAllProjects } from '../../components/Projects/actions';

class ProjectCreationPage extends Component {
	state = {
		project: '',
	};
	componentDidMount() {
		const workspaceId = getQuery(this.props.location.search, 'w');
		if (workspaceId) {
			this.props.getWorkspace(workspaceId);
		} else {
			console.log('workspaceId is not provided.');
		}
	}
	componentDidUpdate(prevProps) {
		if (this.props.project !== prevProps.project) {
			this.props.getAllProjects();
			this.props.history.push('/');
		}
	}
	changeProject = event => {
		this.setState({ project: event.target.value });
	};
	submitForm = event => {
		event.preventDefault();
		const workspaceId = getQuery(this.props.location.search, 'w');
		if (workspaceId) {
			this.props.createProject(workspaceId, this.state.project);
		} else {
			console.log('workspaceId is not provided.');
		}
	};
	skip = () => {
		this.props.history.push('/');
	};
	goBack = () => {
		this.props.history.goBack();
	};
	render() {
		return (
			<div className="row full-height">
				<div className="col-md-5 col-xs-5 project-creation-form-column full-height">
					<div className="vertical-center full-height">
						<img className="project-creation-form-column-logo" src={logo} />
						<form className="form-horizontal project-creation-form" onSubmit={this.submitForm}>
							<p className="project-creation-form-step">STEP 4/4</p>
							<h2 className="project-creation-form-title">Create your first</h2>
							<h2 className="project-creation-form-title">project</h2>
							<div className="form-group project-creation-form-group">
								<label
									className="col-sm-3 col-sm-offset-4 control-label project-creation-form-group-label"
									htmlFor={'project'}>
									Project title:
								</label>
								<div className="col-sm-5 project-creation-form-group-input-container">
									<input
										className="form-control project-creation-form-group-input"
										name="project"
										type="text"
										required={true}
										placeholder="Landing on the moon"
										value={this.state.project}
										onChange={this.changeProject}
									/>
								</div>
							</div>
							<div>
								<button
									className="btn btn-primary project-creation-form-back-button"
									type="button"
									onClick={this.goBack}>
									BACK
								</button>
								<button className="btn btn-primary project-creation-form-next-button" type="submit">
									NEXT
								</button>
							</div>
							<p className="project-creation-form-skip-button-message">
								or{' '}
								<button className="project-creation-form-skip-button" type="button" onClick={this.skip}>
									skip for now
								</button>
							</p>
						</form>
					</div>
				</div>
				<div className="col-md-7 col-xs-7 project-creation-image-column">
					<div className="project-creation-image-column-navbar">
						<div className="project-creation-image-column-navbar-tab-1">
							<div className="project-creation-image-column-navbar-tab-1-title" />
						</div>
						<div className="project-creation-image-column-navbar-tab-2">
							<div className="project-creation-image-column-navbar-tab-2-title">{this.state.project}</div>
						</div>
						<div className="project-creation-image-column-navbar-name">
							<p>{this.props.currentUser ? this.props.currentUser.fullName : ''}</p>
							<p>{this.props.workspace ? this.props.workspace.name : ''}</p>
						</div>
						<div className="project-creation-image-column-navbar-avatar" />
					</div>
					<div className="project-creation-image-column-board">
						<div className="project-creation-image-column-board-section-1">
							<div className="project-creation-image-column-board-section-1-drop-down" />
							<div className="project-creation-image-column-board-section-1-search-bar" />
						</div>
						<div className="project-creation-image-column-board-section-2">
							<div className="project-creation-image-column-board-section-2-task-card-1">
								<div className="project-creation-image-column-board-section-2-task-card-1-title" />
								<div className="project-creation-image-column-board-section-2-task-card-1-details">
									<div className="project-creation-image-column-board-section-2-task-card-1-details-item-1" />
									<div className="project-creation-image-column-board-section-2-task-card-1-details-item-2" />
									<div className="project-creation-image-column-board-section-2-task-card-1-details-item-3" />
									<div className="project-creation-image-column-board-section-2-task-card-1-details-item-4" />
								</div>
							</div>
							<div className="project-creation-image-column-board-section-2-task-card-2">
								<div className="project-creation-image-column-board-section-2-task-card-2-title" />
								<div className="project-creation-image-column-board-section-2-task-card-2-details">
									<div className="project-creation-image-column-board-section-2-task-card-2-details-item-1" />
									<div className="project-creation-image-column-board-section-2-task-card-2-details-item-2" />
									<div className="project-creation-image-column-board-section-2-task-card-2-details-item-3" />
									<div className="project-creation-image-column-board-section-2-task-card-2-details-item-4" />
								</div>
							</div>
							<div className="project-creation-image-column-board-section-2-task-card-3">
								<div className="project-creation-image-column-board-section-2-task-card-3-title" />
								<div className="project-creation-image-column-board-section-2-task-card-3-details">
									<div className="project-creation-image-column-board-section-2-task-card-3-details-item-1" />
									<div className="project-creation-image-column-board-section-2-task-card-3-details-item-2" />
									<div className="project-creation-image-column-board-section-2-task-card-3-details-item-3" />
									<div className="project-creation-image-column-board-section-2-task-card-3-details-item-4" />
								</div>
							</div>
							<div className="project-creation-image-column-board-section-2-task-card-4">
								<div className="project-creation-image-column-board-section-2-task-card-4-title" />
								<div className="project-creation-image-column-board-section-2-task-card-4-details">
									<div className="project-creation-image-column-board-section-2-task-card-4-details-item-1" />
									<div className="project-creation-image-column-board-section-2-task-card-4-details-item-2" />
									<div className="project-creation-image-column-board-section-2-task-card-4-details-item-3" />
									<div className="project-creation-image-column-board-section-2-task-card-4-details-item-4" />
								</div>
							</div>
						</div>
						<div className="project-creation-image-column-board-section-3">
							<div className="project-creation-image-column-board-section-3-task-card-5">
								<div className="project-creation-image-column-board-section-3-task-card-5-details">
									<div className="project-creation-image-column-board-section-3-task-card-5-details-item-1" />
									<div className="project-creation-image-column-board-section-3-task-card-5-details-item-2" />
									<div className="project-creation-image-column-board-section-3-task-card-5-details-item-3" />
									<div className="project-creation-image-column-board-section-3-task-card-5-details-item-4" />
								</div>
							</div>
							<div className="project-creation-image-column-board-section-3-task-card-6">
								<div className="project-creation-image-column-board-section-3-task-card-6-details">
									<div className="project-creation-image-column-board-section-3-task-card-6-details-item-1" />
									<div className="project-creation-image-column-board-section-3-task-card-6-details-item-2" />
									<div className="project-creation-image-column-board-section-3-task-card-6-details-item-3" />
									<div className="project-creation-image-column-board-section-3-task-card-6-details-item-4" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state.projectCreationPage,
	currentUser: state.users.currentUser,
});

export default connect(
	mapStateToProps,
	{
		getWorkspace,
		createProject,
		getAllProjects,
	}
)(ProjectCreationPage);

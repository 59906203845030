import _ from 'lodash';
import agent from '../../agent';

import {
	ACTIVITIES_GET_BY_VIEW_SUCCESS,
	ACTIVITIES_GET_BY_VIEW_ERROR,
	ACTIVITIES_GET_ACTIVITIES_SUCCESS,
	ACTIVITIES_GET_ACTIVITIES_ERROR,
	ACTIVITIES_GET_ACTIVITIES_RESET,
} from './constants';

export const getChatsByTask = async (taskId, paginationQuery, userId) => {
	try {
		const { participants, feed, feedByDate } = await agent.Activities.getByTask({
			taskId,
			...paginationQuery,
			userId,
		});
		return { type: ACTIVITIES_GET_ACTIVITIES_SUCCESS, taskId, participants, feed, feedByDate };
	} catch (error) {
		return { type: ACTIVITIES_GET_ACTIVITIES_ERROR, error };
	}
};

export const getChatsByTaskReset = () => {
	return { type: ACTIVITIES_GET_ACTIVITIES_RESET };
};

export const getChatsByStory = async Id => {
	try {
		const { participants, feed, feedByDate } = await agent.Activities.getByStory(Id);
		return { type: ACTIVITIES_GET_ACTIVITIES_SUCCESS, participants, feed, feedByDate };
	} catch (error) {
		return { type: ACTIVITIES_GET_ACTIVITIES_ERROR, error };
	}
};

export const getActivitiesByView = async (viewId, paginationQuery, dumpPreviousData) => {
	console.log('getActivitiesByView', viewId, paginationQuery);

	try {
		const {
			feed,
			feedByDate,
			participants,
			success,
			error,
			totalCount,
			currentCount,
		} = await agent.Activities.getByView({
			id: viewId,
			...paginationQuery,
		});

		const activitiesById = _.keyBy(feed, '_id');
		const viewActivities = {};

		// data should be reference keys only

		viewActivities[viewId] = {
			participants,
			feed,
			feedByDate,
			totalCount,
			currentCount,
		};

		return {
			type: ACTIVITIES_GET_BY_VIEW_SUCCESS,
			activitiesById,
			viewActivities,
			viewId,
			dumpPreviousData,
		};
	} catch (error) {
		return { type: ACTIVITIES_GET_BY_VIEW_ERROR, error };
	}
};

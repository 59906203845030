export const STORIES_GET_STORIES_BY_PROJECT_SUCCESS = 'STORIES_GET_STORIES_BY_PROJECT_SUCCESS';
export const STORIES_GET_STORIES_BY_PROJECT_ERROR = 'STORIES_GET_STORIES_BY_PROJECT_ERROR';
export const STORIES_ADD_STORY_SUCCESS = 'STORIES_ADD_STORY_SUCCESS';
export const STORIES_ADD_STORY_ERROR = 'STORIES_ADD_STORY_ERROR';
export const STORIES_GET_STORIES_BY_TASK_COUNTER_SUCCESS = 'STORIES_GET_STORIES_BY_TASK_COUNTER_SUCCESS';
export const STORIES_GET_STORIES_BY_TASK_COUNTER_ERROR = 'STORIES_GET_STORIES_BY_TASK_COUNTER_ERROR';
export const STORIES_GET_STORY_SUCCESS = 'STORIES_GET_STORY_SUCCESS';
export const STORIES_GET_STORY_ERROR = 'STORIES_GET_STORY_ERROR';
export const STORIES_UPDATE_STORY_SUCCESS = 'STORIES_UPDATE_STORY_SUCCESS';
export const STORIES_UPDATE_STORY_ERROR = 'STORIES_UPDATE_STORY_ERROR';

import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

import {
	DropdownIndicator,
	MultiValueContainer,
	MultiValueLabel,
	Option,
	Group,
	MenuList,
	MultiValueRemove,
} from '../../../../../ReactSelect';

import { mapProjectOptions } from '../../../../../../lib/Utils';

import { getViewById, updateView } from '../../../../../Views/actions';

import { getTasksByViewByStatus } from '../../../../../Tasks/actions';

import agent from '../../../../../../agent';

import './index.scss';

const CreateLabelWrapper = inputValue => {
	return (
		<div>
			<p className="text-small trkr-margin-bot-5">
				<strong>Hint:</strong> Find specific tasks by typing '#'. E.g. '#123'
			</p>
			Search for "{inputValue}"
		</div>
	);
};

// NOTE: creatable-select has issues (utilising for text search), removing this feature for now
// considering user and task tags only atm
class FilterBar extends React.Component {
	static defaultProps = {
		viewId: null,
	};
	state = {
		currentFilter: [],
	};
	componentDidMount() {
		console.log('FILTERBAR props', this.props.view._id);
		if (this.props.view.filters) {
			this.addCurrentFilterToState();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//Only set the currentFilter when initially receiving the view object from parent if filters exist
		//Otherwise it is handled manually by setstate - better to refactor to use state on views later on.
		if (prevProps.view._id !== this.props.view._id && this.props.view._id === this.props.view._id) {
			if (this.props.view.filters) {
				this.addCurrentFilterToState();
			}
		}

		// when a new profile image is uploaded, the filters are reloaded to reflect the new image.
		if (prevProps.currentUser.image !== this.props.currentUser.image) {
			this.addCurrentFilterToState();
		}
	}

	addCurrentFilterToState = () => {
		//Matrix of what to use as the label for the filter tags.
		const labelKeys = {
			user: 'fullName',
			project: 'title', //ASSUMED!
			priority: 'title', //ASSUMED!
		};

		let newCurrentFilter = this.props.view.filters.map(filter => {
			// console.log('JS->', filter.item, filter);

			// TODO: better null handling needed

			return {
				key: filter.item ? filter.item._id : filter.value,
				type: filter.type,
				value: filter.value,
				label:
					filter.type === 'searchString' ? filter.value : filter.item[labelKeys[filter.type.toLowerCase()]],
				objectData: {
					...filter.item,
					image:
						this.props.currentUser && filter.item
							? this.props.currentUser._id === filter.item._id
								? this.props.currentUser.image
								: filter.item.image
							: null,
				},
			};
		});
		this.setState({
			...this.state,
			currentFilter: newCurrentFilter,
		});
	};

	onUpdateFilter = async (value, actionMeta) => {
		console.log(`[FilterBar.updateFilter] ${this.props.view._id}`, value);

		//Show the filter applied instantly
		this.setState({ currentFilter: value });
		//Structure filters to save on backend, send update then reload the view for confirmation
		let filters = value.map(filter => {
			return {
				item: filter.type === 'searchString' ? null : filter.value, //e.g. ID of a Model - 12345676545676545sdfsdfsdf or null for search string - not needed
				type: filter.type, //e.g. user
				value: filter.value,
			};
		});

		// TODO: amend actions / reducer to update the view store with the new document
		await this.props.updateView(this.props.view._id, { filters: filters });

		//Get the view again to trigger a re-render of the board tog et tasks
		this.props.getViewById(this.props.view._id);

		// load kanban tasks with new filters
		this.props.getTasksByViewByStatus(this.props.view._id, 0, true, filters);
	};
	// TODO: resolve create-select with grouped options, currently it doesn't seem to handle a new option being added
	onAddTextFilter = async (inputValue: any, actionMeta: any) => {
		console.log('[FilterBar.onAddTextFilter]', inputValue);

		// setup new searchString filter object
		let newSearchFilter = {
			value: inputValue,
			label: inputValue,
			type: 'searchString',
		};

		// add new filter to the state search group (populated for select component)
		this.setState({ currentFilter: [...this.state.currentFilter, newSearchFilter] });

		// push amended filter list to the view object
		await this.props.updateView(this.props.view._id, { filters: [...this.props.view.filters, newSearchFilter] });

		//Get the view again to trigger a re-render of the board tog et tasks
		this.props.getViewById(this.props.view._id);

		// load kanban tasks with new filters
		this.props.getTasksByViewByStatus(this.props.view._id, 0, true, [...this.props.view.filters, newSearchFilter]);
	};

	render() {
		if (!this.state) {
			return null;
		}
		return (
			<div className="filter-bar">
				{!this.props.disableFilter && (
					<CreatableSelect
						className="react-select-custom react-select-search"
						classNamePrefix="react-select-custom"
						onChange={this.onUpdateFilter}
						value={this.state.currentFilter}
						options={this.props.filterOptions}
						placeholder="Search Tasks, Assigned, Priority and more"
						isClearable={true}
						isMulti
						formatCreateLabel={CreateLabelWrapper}
						closeMenuOnSelect={false}
						onCreateOption={this.onAddTextFilter}
						components={{
							DropdownIndicator,
							MultiValueContainer,
							MultiValueLabel,
							Option,
							Group,
							MenuList,
							MultiValueRemove,
						}}
					/>
				)}
			</div>
		);
	}
}

// TODO: do this with data set loading in App/actions
const mapStateToProps = (state, ownProps) => {
	function mapFilterOptions() {
		let groupedOptions = [
			{
				label: 'Assigned to',
				options: state.app.userOptions,
			},
			{
				label: 'Priorities',
				options: state.app.priorityOptions,
			},
			{
				label: 'Projects',
				options: mapProjectOptions(state.projects.allProjects),
			},
		];
		return groupedOptions;
	}
	return {
		...state.filterBar,
		filterOptions: mapFilterOptions(),
		currentUser: state.users.currentUser,
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		{
			getViewById,
			updateView,
			getTasksByViewByStatus,
		}
	)(FilterBar)
);

import {
	RELEASES_GET_PROJECT_RELEASES_SUCCESS,
	RELEASES_GET_PROJECT_RELEASES_ERROR,
	RELEASES_CREATE_RELEASE_SUCCESS,
	RELEASES_CREATE_RELEASE_ERROR,
	RELEASES_GET_RELEASE_SUCCESS,
	RELEASES_GET_RELEASE_ERROR,
} from './constants';

const initialState = {
	errors: [],
	newRelease: {},
	release: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case RELEASES_GET_PROJECT_RELEASES_SUCCESS:
			return {
				...state,
				...action,
				type: undefined,
			};
		case RELEASES_GET_PROJECT_RELEASES_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case RELEASES_CREATE_RELEASE_SUCCESS:
			return {
				...state,
				newRelease: action.newRelease,
			};
		case RELEASES_CREATE_RELEASE_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case RELEASES_GET_RELEASE_SUCCESS:
			return {
				...state,
				release: action.release,
			};
		case RELEASES_GET_RELEASE_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
	}
	return state;
};

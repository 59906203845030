import moment from 'moment';

import {
	ACTIVITIES_GET_BY_VIEW_SUCCESS,
	ACTIVITIES_GET_BY_VIEW_ERROR,
	ACTIVITIES_GET_ACTIVITIES_SUCCESS,
	ACTIVITIES_GET_ACTIVITIES_ERROR,
	ACTIVITIES_GET_ACTIVITIES_RESET,
} from './constants';

const initialState = {
	activitiesById: {},
	activitiesByViewId: {},
	errors: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIVITIES_GET_BY_VIEW_SUCCESS:
			console.log(`${action.type}`, action);

			// don't override the object, for pagination

			const originalViewContent = state.activitiesByViewId[action.viewId];
			const newViewContent = action.viewActivities[action.viewId];
			let updatedContent = {};
			let mergedViewContent = state.activitiesByViewId;

			// console.clear();
			// TODO: need to override, if the fetch was a

			if (!originalViewContent || !originalViewContent.feed || action.dumpPreviousData) {
				updatedContent = newViewContent;
			} else {
				// prevent shallow copying;
				updatedContent.feed = [...originalViewContent.feed, ...newViewContent.feed];
				updatedContent.feedByDate = { ...originalViewContent.feedByDate, ...newViewContent.feedByDate };
				updatedContent.participants = [...originalViewContent.participants, ...newViewContent.participants];
				updatedContent.currentCount = newViewContent.currentCount;
				updatedContent.totalCount = newViewContent.totalCount;
			}

			// sort the date feed as an array
			updatedContent.feedByDateSorted = sortByDateKeys(updatedContent.feedByDate);

			mergedViewContent[action.viewId] = updatedContent;

			console.log('original date feed', state.activitiesByViewId);
			console.log('updated date feed', mergedViewContent);

			// TODO: data sorting here
			// - create a new object to reference

			return {
				...state,
				activitiesById: {
					...state.activitiesById,
					...action.activitiesById,
				},
				activitiesByViewId: mergedViewContent,
			};

		case ACTIVITIES_GET_ACTIVITIES_SUCCESS:
			const { taskId, feed, feedByDate, participants } = action;
			const newState = { ...state };
			let newFeedByDateArray = [];
			for (const [date, feeds] of Object.entries(feedByDate)) {
				newFeedByDateArray.push({
					date: moment(date).toISOString(),
					feeds,
				});
			}
			if (state[taskId]) {
				const uniqueParticipants = Object.values(
					[...state[taskId].participants, ...participants].reduce(
						(acc, cur) => Object.assign(acc, { [cur.id]: cur }),
						{}
					)
				);
				let combinedFeedByDateArray = [
					...state[taskId].feedByDateArray.filter(oldDate => {
						for (const newDate of newFeedByDateArray) {
							if (oldDate.date === newDate.date) {
								return false;
							}
						}
						return true;
					}),
					...newFeedByDateArray,
				];
				combinedFeedByDateArray = combinedFeedByDateArray.sort(function(a, b) {
					return moment(a.date) - moment(b.date);
				});
				newState[taskId] = {
					feedByDateArray: combinedFeedByDateArray,
					feed: [...state[taskId].feed, ...feed],
					participants: uniqueParticipants,
				};
			} else {
				newState[taskId] = {
					feedByDateArray: newFeedByDateArray,
					feed,
					participants,
				};
			}
			return newState;
		case ACTIVITIES_GET_ACTIVITIES_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case ACTIVITIES_GET_ACTIVITIES_RESET:
			return {
				...initialState,
			};
	}
	return state;
};

// sort the keys only, then push the data in the correct order by the key name
const sortByDateKeys = obj => {
	if (!obj) return [];

	const sortedKeys = Object.keys(obj).sort((a, b) => new Date(b) - new Date(a));
	const sortedData = [];

	for (let key of sortedKeys) {
		sortedData.push({ date: key, messages: obj[key] });
	}

	return sortedData;
};

import {
	WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_SUCCESS,
	WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_ERROR,
	WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_RESET,
	WORKSPACE_USERS_GET_WORKSPACE_USERS_SUCCESS,
	WORKSPACE_USERS_GET_WORKSPACE_USERS_ERROR,
	WORKSPACE_USERS_UPDATE_WORKSPACE_USER_SUCCESS,
	WORKSPACE_USERS_UPDATE_WORKSPACE_USER_ERROR,
	WORKSPACE_USERS_UPDATE_WORKSPACE_USER_RESET,
	WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_SUCCESS,
	WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_ERROR,
} from './constants';

// The data looks like below before any action is received.
const initialState = {
	activeUsers: [],
	startOfBillingPeriod: null,
	workspaceUsers: [],
	updatedWorkspaceUser: false,
	workspaceUsersForUser: [],
	errors: [],
};

// This function is called to update the redux store when any action is received.
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_SUCCESS:
			return {
				...state,
				activeUsers: action.activeUsers,
				startOfBillingPeriod: action.startOfBillingPeriod,
			};
		case WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_RESET:
			return {
				...state,
				activeUsers: [],
				startOfBillingPeriod: null,
			};
		case WORKSPACE_USERS_GET_WORKSPACE_USERS_SUCCESS:
			const { workspaceUsers, invitesPending } = action;

			return {
				...state,
				workspaceUsers,
				invitesPending,
			};
		case WORKSPACE_USERS_GET_WORKSPACE_USERS_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case WORKSPACE_USERS_UPDATE_WORKSPACE_USER_SUCCESS:
			return {
				...state,
				updatedWorkspaceUser: action.updatedWorkspaceUser,
			};
		case WORKSPACE_USERS_UPDATE_WORKSPACE_USER_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case WORKSPACE_USERS_UPDATE_WORKSPACE_USER_RESET:
			return {
				...state,
				updatedWorkspaceUser: false,
			};
		case WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_SUCCESS:
			return {
				...state,
				workspaceUsersForUser: action.workspaceUsersForUser,
			};
		case WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
	}
	return state;
};

export default reducer;

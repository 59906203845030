import React from 'react';

import { formatDate } from '../../../../../../../lib/Utils';

class CommitMessage extends React.Component {
	componentWillMount() {}

	render() {
		var commit = this.props.message;

		return (
			<div className="commit-entry row">
				<div className="col-xs-12">
					<p className="author trkr-full-width">
						<span>Push to "{commit.branch}"</span>
					</p>
					<p className="trkr-margin-0 content">{commit.msg}</p>
				</div>
			</div>
		);
	}
}

export default CommitMessage;

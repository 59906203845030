import agent from '../../agent';
import { RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR } from './constants';

export const resetPassword = async (email, reCaptcha) => {
	try {
		const response = await agent.Auth.resetPassword(email, reCaptcha);
		const resetPasswordEmailSent = response.success;
		return { type: RESET_PASSWORD_SUCCESS, resetPasswordEmailSent };
	} catch (error) {
		return { type: RESET_PASSWORD_ERROR, error };
	}
};

// Makes sure that action and reducers use the same strings.
import {
	USERS_GET_CURRENT_USER_SUCCESS,
	USERS_GET_CURRENT_USER_ERROR,
	USERS_GET_CURRENT_USER_RESET,
	USERS_LOGOUT_USER_SUCCESS,
	USERS_LOGOUT_USER_ERROR,
	USERS_CHECK_EMAIL_EXISTS_SUCCESS,
	USERS_CHECK_EMAIL_EXISTS_ERROR,
	USERS_CHECK_EMAIL_EXISTS_RESET,
	USERS_CREATE_PROVISIONAL_EMAIL_SUCCESS,
	USERS_CREATE_PROVISIONAL_EMAIL_ERROR,
	USERS_CREATE_PROVISIONAL_EMAIL_RESET,
	USERS_CHECK_PASSWORD_SUCCESS,
	USERS_CHECK_PASSWORD_ERROR,
	USERS_CHECK_PASSWORD_RESET,
	USERS_CREATE_NEW_PASSWORD_SUCCESS,
	USERS_CREATE_NEW_PASSWORD_ERROR,
	USERS_CREATE_NEW_PASSWORD_RESET,
	USERS_ADD_NEW_IMAGE_SUCCESS,
	USERS_ADD_NEW_IMAGE_ERROR,
	USERS_ADD_NEW_IMAGE_RESET,
	USERS_ADD_NEW_NAME_SUCCESS,
	USERS_ADD_NEW_NAME_ERROR,
	USERS_ADD_NEW_NAME_RESET,
	USERS_ADD_EMAIL_NOTIFICATIONS_SUCCESS,
	USERS_ADD_EMAIL_NOTIFICATIONS_ERROR,
	USERS_ADD_EMAIL_NOTIFICATIONS_RESET,
	USERS_LOGIN_USER_SUCCESS,
	USERS_LOGIN_USER_ERROR,
	USERS_LOGIN_USER_RESET,
	USERS_EMAIL_CONFIRMATION_SUCCESS,
	USERS_EMAIL_CONFIRMATION_ERROR,
	USERS_EMAIL_CONFIRMATION_RESET,
	USERS_AUTHENTICATE_USER_SUCCESS,
	USERS_AUTHENTICATE_USER_ERROR,
} from './constants';

// The data looks like below before any action is received.
const initialState = {
	emailExists: false,
	currentUser: null,
	userLoggedOut: false,
	provisionalEmailCreated: false,
	passwordCorrect: false,
	passwordCreated: false,
	imageAdded: false,
	nameAdded: false,
	addedEmailNotification: false,
	userLoggedIn: false,
	emailConfirmed: false,
	userAuthenticated: null,
	errors: [],
};

// This function is called to update the redux store when any action is received.
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case USERS_GET_CURRENT_USER_SUCCESS:
			return {
				...state,
				currentUser: action.currentUser,
				userLoggedOut: false,
			};
		case USERS_GET_CURRENT_USER_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_GET_CURRENT_USER_RESET:
			return {
				...state,
				currentUser: null,
				userLoggedOut: true,
			};
		case USERS_LOGOUT_USER_SUCCESS:
			return {
				...state,
				currentUser: null,
				userLoggedOut: true,
			};
		case USERS_LOGOUT_USER_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_CHECK_EMAIL_EXISTS_SUCCESS:
			return {
				...state,
				emailExists: action.emailExists,
			};
		case USERS_CHECK_EMAIL_EXISTS_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_CHECK_EMAIL_EXISTS_RESET:
			return {
				...state,
				emailExists: false,
			};
		case USERS_CREATE_PROVISIONAL_EMAIL_SUCCESS:
			return {
				...state,
				provisionalEmailCreated: action.provisionalEmailCreated,
			};
		case USERS_CREATE_PROVISIONAL_EMAIL_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_CREATE_PROVISIONAL_EMAIL_RESET:
			return {
				...state,
				provisionalEmailCreated: false,
			};
		case USERS_CHECK_PASSWORD_SUCCESS:
			return {
				...state,
				passwordCorrect: action.passwordCorrect,
			};
		case USERS_CHECK_PASSWORD_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_CHECK_PASSWORD_RESET:
			return {
				...state,
				passwordCorrect: false,
			};
		case USERS_CREATE_NEW_PASSWORD_SUCCESS:
			return {
				...state,
				passwordCreated: action.passwordCreated,
			};
		case USERS_CREATE_NEW_PASSWORD_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_CREATE_NEW_PASSWORD_RESET:
			return {
				...state,
				passwordCreated: false,
			};
		case USERS_ADD_NEW_IMAGE_SUCCESS:
			return {
				...state,
				imageAdded: action.imageAdded,
			};
		case USERS_ADD_NEW_IMAGE_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_ADD_NEW_IMAGE_RESET:
			return {
				...state,
				imageAdded: false,
			};
		case USERS_ADD_NEW_NAME_SUCCESS:
			return {
				...state,
				nameAdded: action.nameAdded,
			};
		case USERS_ADD_NEW_NAME_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_ADD_NEW_NAME_RESET:
			return {
				...state,
				nameAdded: false,
			};
		case USERS_ADD_EMAIL_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				addedEmailNotification: action.addedEmailNotification,
			};
		case USERS_ADD_EMAIL_NOTIFICATIONS_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_ADD_EMAIL_NOTIFICATIONS_RESET:
			return {
				...state,
				addedEmailNotification: false,
			};
		case USERS_LOGIN_USER_SUCCESS:
			return {
				...state,
				userLoggedIn: action.userLoggedIn,
			};
		case USERS_LOGIN_USER_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_LOGIN_USER_RESET:
			return {
				...state,
				userLoggedIn: false,
			};
		case USERS_EMAIL_CONFIRMATION_SUCCESS:
			return {
				...state,
				emailConfirmed: action.emailConfirmed,
			};
		case USERS_EMAIL_CONFIRMATION_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
		case USERS_EMAIL_CONFIRMATION_RESET:
			return {
				...state,
				emailConfirmed: false,
			};
		case USERS_AUTHENTICATE_USER_SUCCESS:
			return {
				...state,
				userAuthenticated: action.userAuthenticated,
			};
		case USERS_AUTHENTICATE_USER_ERROR:
			return {
				...state,
				errors: state.errors.concat([
					{
						body: action.error.toString(),
						time: new Date(),
					},
				]),
			};
	}
	return state;
};

export default reducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
	injectStripe,
	Elements,
	StripeProvider,
} from 'react-stripe-elements';

import { getQuery } from '../../lib/Utils';

import { checkSubscriptionCompleted, updateSubscription } from '../../components/Subscriptions/actions';

import { authenticateUser, getCurrentUser } from '../../components/Users/actions';

import './index.scss';

class subscriptionPage extends Component {
	render() {
		return (
			<StripeProvider apiKey="pk_test_b1Xrt3fyAuobfmMtNEbbReuH0026wQKPKR">
				<div className="stripe-page">
					<div className="stripe-page-container">
						<h1 className="stripe-page-title">Subscription</h1>
						<Elements>
							<CheckoutForm
								subscriptionId={getQuery(this.props.location.search, 's')}
								token={getQuery(this.props.location.search, 't')}
								history={this.props.history}
							/>
						</Elements>
					</div>
				</div>
			</StripeProvider>
		);
	}
}

class _CheckoutForm extends Component {
	componentDidMount() {
		const subscriptionId = this.props.subscriptionId;
		const token = this.props.token;
		if (token && subscriptionId) {
			this.props.authenticateUser(subscriptionId, token);
		} else {
			console.log('token or subscriptionId is not present in the url.');
		}
	}
	componentDidUpdate(prevProps) {
		if (this.props.userAuthenticated === true) {
			this.props.getCurrentUser();
			const subscriptionId = this.props.subscriptionId;
			if (subscriptionId) {
				this.props.checkSubscriptionCompleted(subscriptionId);
			} else {
				console.log('subscriptionId is not present in the url.');
			}
		}
		if (this.props.userAuthenticated === false) {
			this.props.history.push('/login');
		}
		if (this.props.completed !== prevProps.completed) {
			const subscriptionId = this.props.subscriptionId;
			this.props.history.push(`/workspace-registration?s=${subscriptionId}`);
		}
		if (this.props.updatedSubscription !== prevProps.updatedSubscription) {
			const subscriptionId = this.props.subscriptionId;
			this.props.history.push(`/workspace-registration?s=${subscriptionId}`);
		}
	}
	handleSubmit = async ev => {
		ev.preventDefault();
		const subscriptionId = this.props.subscriptionId;
		let { token } = await this.props.stripe.createToken({ name: 'Name' });
		this.props.updateSubscription(token.id, subscriptionId);
	};
	render() {
		return (
			<form className="stripe-form" onSubmit={this.handleSubmit}>
				<label className="stripe-label">
					Card number
					<CardNumberElement />
				</label>
				<label className="stripe-label">
					Expiration date
					<CardExpiryElement />
				</label>
				<label className="stripe-label">
					CVC
					<CardCVCElement />
				</label>
				<button className="stripe-button">Pay</button>
			</form>
		);
	}
}

const mapStateToProps = state => ({
	completed: state.subscriptions.completed,
	updatedSubscription: state.subscriptions.updatedSubscription,
	userAuthenticated: state.users.userAuthenticated,
});

const connected = connect(
	mapStateToProps,
	{
		checkSubscriptionCompleted,
		authenticateUser,
		getCurrentUser,
		updateSubscription,
	}
)(_CheckoutForm);

const CheckoutForm = injectStripe(connected);

export default subscriptionPage;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';

import Form from '../Form';

import { inviteUsers, inviteUsersReset } from '../Workspaces/actions';

import { checkEmailExists, checkEmailExistsReset } from '../Users/actions';

import { getWorkspaceUsers, updateWorkspaceUser, updateWorkspaceUserReset } from '../WorkspaceUsers/actions';

import './index.scss';

class Members extends Component {
	initialState = {
		emailBeingChecked: '',
		showPopup: false,
		deactivatedWorkspaceUser: '',
	};
	state = {
		...this.initialState,
		emails: {
			'id-1': {
				value: '',
			},
		},
	};
	componentDidMount() {
		const workspaceId = this.props.selectedSubscription.workspace._id;
		this.props.getWorkspaceUsers(workspaceId);
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedSubscription !== this.props.selectedSubscription) {
			// reset the whole state
			this.setState(state => {
				return {
					...this.initialState,
					emails: {
						'id-1': {
							value: '',
						},
					},
				};
			});
			const workspaceId = this.props.selectedSubscription.workspace._id;
			this.props.getWorkspaceUsers(workspaceId);
		}
		if (prevProps.emailExists !== this.props.emailExists && this.state.emailBeingChecked) {
			this.setState(state => {
				const newEmails = { ...state.emails };
				newEmails[this.state.emailBeingChecked].exists = this.props.emailExists;
				return { ...state, emails: newEmails, emailBeingChecked: '' };
			});
			this.props.checkEmailExistsReset();
		}
		if (this.props.updatedWorkspaceUser) {
			this.props.updateWorkspaceUserReset();
			const workspaceId = this.props.selectedSubscription.workspace._id;
			this.props.getWorkspaceUsers(workspaceId);
			notify.show('User has been successfully updated.', 'success');
		}
		if (this.props.sentEmails) {
			this.props.inviteUsersReset();
			const workspaceId = this.props.selectedSubscription.workspace._id;
			this.props.getWorkspaceUsers(workspaceId);
			notify.show('Emails have been sent to the invited users.', 'success');
		}
	}
	changeEmail = ({ target: { value, name } }) => {
		const emails = Object.values(this.state.emails)
			.filter(email => email.value)
			.map(email => email.value);
		const email = value;
		const emailRegex = new RegExp(`^[^\\.\\s@:](?:[^\\s@:]*[^\\s@:\\.])?@[^\\.\\s@]+(?:\\.[^\\.\\s@]+)*$`);
		const emailValid = emailRegex.test(email);
		if (email && emails.includes(email)) {
			notify.show('email already exists', 'error');
			this.setState(state => {
				const newEmails = { ...state.emails };
				newEmails[name].value = email;
				newEmails[name].valid = false;
				delete newEmails[name].exists;
				return { ...state, emails: newEmails };
			});
			return;
		}
		if (email === this.props.currentUser.email) {
			notify.show('You can not invite yourself!', 'error');
			return;
		}
		this.setState(state => {
			const newEmails = { ...state.emails };
			newEmails[name].value = email;
			newEmails[name].valid = emailValid;
			delete newEmails[name].exists;
			return { ...state, emails: newEmails };
		});
	};
	onBlur = ({ target: { value, name } }) => {
		const email = value;
		this.props.checkEmailExists(email);
		this.setState(state => {
			return { ...state, emailBeingChecked: name };
		});
	};
	inviteMore = () => {
		this.setState(state => {
			const newEmails = { ...state.emails };
			newEmails[`id-${Object.keys(this.state.emails).length + 1}`] = {};
			return { ...state, emails: newEmails };
		});
	};
	inviteLess = id => {
		this.setState(state => {
			const newEmails = { ...state.emails };
			delete newEmails[id];
			return { ...state, emails: newEmails };
		});
	};
	submitForm = () => {
		const workspaceId = this.props.selectedSubscription.workspace._id;
		const emails = Object.values(this.state.emails)
			.filter(email => email.value)
			.map(email => email.value);
		const invalidEmails = Object.values(this.state.emails).filter(email => email.exists || !email.valid);
		if (invalidEmails.length > 0) {
			notify.show('Please ensure that all emails are valid.', 'error');
			return;
		}
		this.props.inviteUsers(workspaceId, emails);
		this.setState(state => {
			return {
				...this.initialState,
				emails: {
					'id-1': {
						value: '',
					},
				},
			};
		});
	};
	changeWorkspaceUserRole = workspaceUserId => ({ target: { value } }) => {
		this.props.updateWorkspaceUser(workspaceUserId, { role: value });
	};
	togglePopup = workspaceUser => event => {
		event.preventDefault();
		this.setState({
			showPopup: !this.state.showPopup,
			deactivatedWorkspaceUser: workspaceUser ? workspaceUser : undefined,
		});
	};
	changeWorkspaceUserStatus = () => {
		this.setState({
			showPopup: !this.state.showPopup,
		});
		this.props.updateWorkspaceUser(this.state.deactivatedWorkspaceUser._id, { status: 'inactive' });
	};
	renderPopup = () => {
		return (
			<div className="members-delete-members-popup">
				<div className="members-delete-members-popup_inner">
					<h5 className="members-delete-members-popup_inner-title">Delete user?</h5>
					<p className="members-delete-members-popup_inner-text">
						{`${this.state.deactivatedWorkspaceUser.user.fullName} will not be able to access "${this.props.selectedSubscription.workspace.slug}" after this action`}
					</p>
					<button className="members-delete-members-popup_inner-cancel-button" onClick={this.togglePopup()}>
						Cancel
					</button>
					<button
						className="members-delete-members-popup_inner-delete-button"
						onClick={this.changeWorkspaceUserStatus}>
						Delete user
					</button>
				</div>
			</div>
		);
	};
	render() {
		return (
			<div className="members">
				<h5 className="members-title">Workspace {this.props.selectedSubscription.workspace.slug}</h5>
				<hr className="members-divider" />

				<Form submit={this.submitForm} className="form-horizontal members-invites">
					<h5 className="members-invites-title">Invite members</h5>
					{Object.keys(this.state.emails).map((key, index) => (
						<div key={key} className="form-group members-invites-group">
							<label className="col-sm-1 control-label members-invites-group-label" htmlFor={'email'}>
								Email:
							</label>
							<div className="col-sm-6">
								<input
									className={
										'form-control members-invites-group-input ' +
										(this.state.emails[key].valid === false
											? 'members-invites-group-input-red-border'
											: this.state.emails[key].exists === true
											? 'members-invites-group-input-red-border'
											: 'members-invites-group-input-green-border')
									}
									name={key}
									type="email"
									required={true}
									placeholder="name@company.com"
									value={this.state.emails[key].value}
									onChange={this.changeEmail}
									onBlur={this.onBlur}
								/>
							</div>
							<div className="col-sm-4 members-invites-group-feedback">
								{index > 0 && (
									<button
										className="trkr-icon-close trkr-btn-close members-invites-group-feedback-close"
										onClick={() => this.inviteLess(key)}></button>
								)}
								{this.state.emails[key].valid === false ? (
									<p>
										<span className="glyphicon glyphicon-remove" />
										Email invalid!
									</p>
								) : this.state.emails[key].exists === true ? (
									<p>
										<span className="glyphicon glyphicon-remove" />
										Already exist!
									</p>
								) : this.state.emails[key].valid === true ? (
									<p>
										<span className="glyphicon glyphicon-ok" />
										Email valid!
									</p>
								) : (
									''
								)}
							</div>
						</div>
					))}

					<div className="row">
						<div className="col-sm-7">
							<div className="pull-right members-invites-invite-more">
								<button className="members-invites-invite-more-icon">+</button>
								<button
									className="members-invites-invite-more-button"
									type="button"
									onClick={this.inviteMore}>
									Invite more
								</button>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-7">
							<div className="pull-right">
								{/* prevent submission if form is not valid */}
								<button className="btn btn-primary members-invites-invite-button" type="submit">
									INVITE
								</button>
							</div>
						</div>
					</div>
				</Form>

				{this.props.invitesPending && this.props.invitesPending.length > 0 && (
					<Fragment>
						<h5 className="">Invites pending</h5>

						<ul>
							{this.props.invitesPending.map(invite => (
								<li key={invite.user._id}>{invite.user.email}</li>
							))}
						</ul>
					</Fragment>
				)}

				<hr className="members-divider" />
				<div className="members-table">
					<h5 className="members-table-title">Workspace members</h5>
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th>Role</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{this.props.workspaceUsers.map(workspaceUser => (
								<tr key={workspaceUser._id}>
									<td>{workspaceUser.user.fullName}</td>
									<td>{workspaceUser.user.email}</td>
									<td>
										<label htmlFor={`${workspaceUser._id}-owner`}>
											Owner
											<input
												type="radio"
												id={`${workspaceUser._id}-owner`}
												value="owner"
												checked={workspaceUser.role === 'owner'}
												onChange={this.changeWorkspaceUserRole(workspaceUser._id)}
												disabled={true}
											/>
											<span />
										</label>
										<label htmlFor={`${workspaceUser._id}-admin`}>
											Admin
											<input
												type="radio"
												id={`${workspaceUser._id}-admin`}
												value="admin"
												checked={workspaceUser.role === 'admin'}
												onChange={this.changeWorkspaceUserRole(workspaceUser._id)}
												disabled={workspaceUser.role === 'owner'}
											/>
											<span />
										</label>
										<label htmlFor={`${workspaceUser._id}-member`}>
											Member
											<input
												type="radio"
												id={`${workspaceUser._id}-member`}
												value="member"
												checked={workspaceUser.role === 'member'}
												onChange={this.changeWorkspaceUserRole(workspaceUser._id)}
												disabled={workspaceUser.role === 'owner'}
											/>
											<span />
										</label>
									</td>
									<td>
										<button onClick={this.togglePopup(workspaceUser)}>Delete</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					{this.state.showPopup ? this.renderPopup() : null}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	sentEmails: state.workspaces.sentEmails,
	emailExists: state.users.emailExists,
	workspaceUsers: state.workspaceUsers.workspaceUsers,
	updatedWorkspaceUser: state.workspaceUsers.updatedWorkspaceUser,
	currentUser: state.users.currentUser,
});

export default connect(
	mapStateToProps,
	{
		inviteUsers,
		inviteUsersReset,
		checkEmailExists,
		checkEmailExistsReset,
		getWorkspaceUsers,
		updateWorkspaceUser,
		updateWorkspaceUserReset,
	}
)(Members);

import React, { Component } from 'react';
import { Glyphicon } from 'react-bootstrap';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import agent from '../../agent';

import './index.scss';

class FileUploader extends Component {
	state = { text: '0%', width: '0%', files: [] };
	// default properties (overwritten by props passed by parent component)
	static defaultProps = {
		disabled: false,
	};
	componentDidUpdate(prevProps) {
		if (
			this.props.files &&
			prevProps.files !== this.props.files &&
			this.props.files.length > 0 &&
			this.state.files.length > 0
		) {
			notify.show('File or files successfully uploaded.', 'success');
			this.setState({ files: [] });
		}
	}
	clickInputToStartFileUpload = () => {
		const id = 'default';
		const element = document.getElementById(id);
		element.click();
	};
	convertFilesToFormData = files => {
		let formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			formData.append('uploads[]', file, file.name);
		}
		return formData;
	};
	giveAxiosAFunctionForProgressOfUpload = () => {
		return {
			onUploadProgress: progressEvent => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				this.setState({ text: percentCompleted + '%', width: percentCompleted + '%' });
				if (percentCompleted === 100 && !this.props.settings) {
					this.setState({ text: 'File or files received. Please wait for processing.' });
				}
				if (percentCompleted === 100 && this.props.settings) {
					this.setState({ text: 'processing' });
				}
			},
			headers: {},
		};
	};
	async uploadFilesToAPI(url, fileData, config) {
		return await axios.post(url, fileData, config);
	}
	uploadFileToServer = async event => {
		const files = event.target.files;
		if (files.length > 0) {
			const formData = this.convertFilesToFormData(files);
			const config = this.giveAxiosAFunctionForProgressOfUpload();
			const response = await this.uploadFilesToAPI(`${agent.API_ROOT}/attachments`, formData, config);
			if (response && response.data) {
				const files = response.data.files;
				this.props.returnedFiles(files);
				this.setState({ text: '0%', width: '0%', files: files });
			}
		}
	};
	render() {
		return (
			<div
				className={`file-uploader ${this.props.className} ${
					this.props.settings ? 'file-uploader-settings-container' : ''
				}`}>
				{this.props.settings ? (
					<div className="row">
						<div className="col-xs-4">
							<button
								className={'file-uploader-settings-button' + (this.props.disabled ? ' disabled' : '')}
								type="button"
								onClick={this.clickInputToStartFileUpload}>
								<Glyphicon glyph="edit" />
							</button>
						</div>
						<div className="col-xs-8">{this.state.width !== '0%' && <p>{this.state.text}</p>}</div>
					</div>
				) : (
					<div className="row vertical-align-middle">
						<div className="col-xs-4">
							<button
								className={'btn trkr-btn' + (this.props.disabled ? ' disabled' : '')}
								type="button"
								onClick={this.clickInputToStartFileUpload}>
								<Glyphicon style={{ marginRight: '10px' }} glyph="paperclip" />
								Upload file
							</button>
						</div>
						<div className="col-xs-8">
							{this.state.width !== '0%' && (
								<div className="progress-bar">
									<div className="progress-bar" role="progressbar">
										<p className="trim-text-1">{this.state.text}</p>
									</div>
								</div>
							)}
						</div>
					</div>
				)}

				<input
					id="default"
					type="file"
					name="uploads[]"
					multiple={this.props.multiple}
					accept="image/jpg,image/jpeg,image/x-png,image/gif,application/pdf,application/msword,video/mp4,video/quicktime"
					onClick={event => {
						event.target.value = null;
					}}
					onChange={this.uploadFileToServer}
					disabled={this.props.disabled}
				/>
			</div>
		);
	}
}

export default FileUploader;

import React from 'react';

import './projectTag.scss';

const ProjectTag = props => {
	if (!props.projectTitle) return null;

	return (
		<label className="trkr-label__project">
			<span className={`trkr-label__project-symbol`}></span>
			<span className="trkr-label__project-name">{props.projectTitle}</span>
		</label>
		// <div className={`trkr-project-tag ${props.className ? props.className : ''}`}>
		// 	<p className="project-label wrap-text font-color-white-1">
		// 		<i className="trkr-icon-project-tag" style={{ color: props.projectColour || '#000000' }} />
		// 		<span className="transparent-75">{props.projectTitle}</span>
		// 	</p>
		// </div>
	);
};

export default ProjectTag;

import agent from '../../agent';
import parseDomain from 'parse-domain';

import {
	USERS_GET_CURRENT_USER_SUCCESS,
	USERS_GET_CURRENT_USER_ERROR,
	USERS_GET_CURRENT_USER_RESET,
	USERS_LOGOUT_USER_SUCCESS,
	USERS_LOGOUT_USER_ERROR,
	USERS_CHECK_EMAIL_EXISTS_SUCCESS,
	USERS_CHECK_EMAIL_EXISTS_ERROR,
	USERS_CHECK_EMAIL_EXISTS_RESET,
	USERS_CREATE_PROVISIONAL_EMAIL_SUCCESS,
	USERS_CREATE_PROVISIONAL_EMAIL_ERROR,
	USERS_CREATE_PROVISIONAL_EMAIL_RESET,
	USERS_CHECK_PASSWORD_SUCCESS,
	USERS_CHECK_PASSWORD_ERROR,
	USERS_CHECK_PASSWORD_RESET,
	USERS_CREATE_NEW_PASSWORD_SUCCESS,
	USERS_CREATE_NEW_PASSWORD_ERROR,
	USERS_CREATE_NEW_PASSWORD_RESET,
	USERS_ADD_NEW_IMAGE_SUCCESS,
	USERS_ADD_NEW_IMAGE_ERROR,
	USERS_ADD_NEW_IMAGE_RESET,
	USERS_ADD_NEW_NAME_SUCCESS,
	USERS_ADD_NEW_NAME_ERROR,
	USERS_ADD_NEW_NAME_RESET,
	USERS_ADD_EMAIL_NOTIFICATIONS_SUCCESS,
	USERS_ADD_EMAIL_NOTIFICATIONS_ERROR,
	USERS_ADD_EMAIL_NOTIFICATIONS_RESET,
	USERS_LOGIN_USER_SUCCESS,
	USERS_LOGIN_USER_ERROR,
	USERS_LOGIN_USER_RESET,
	USERS_EMAIL_CONFIRMATION_SUCCESS,
	USERS_EMAIL_CONFIRMATION_ERROR,
	USERS_EMAIL_CONFIRMATION_RESET,
	USERS_AUTHENTICATE_USER_SUCCESS,
	USERS_AUTHENTICATE_USER_ERROR,
} from './constants';

export const getCurrentUser = async () => {
	try {
		const { user: currentUser } = await agent.Auth.current();
		return { type: USERS_GET_CURRENT_USER_SUCCESS, currentUser };
	} catch (error) {
		return { type: USERS_GET_CURRENT_USER_ERROR, error };
	}
};

export const getCurrentUserReset = async () => {
	return { type: USERS_GET_CURRENT_USER_RESET };
};

export const logoutUser = async () => {
	try {
		const response = await agent.Auth.logout();
		return { type: USERS_LOGOUT_USER_SUCCESS };
	} catch (error) {
		return { type: USERS_LOGOUT_USER_ERROR, error };
	}
};

export const checkEmailExists = async email => {
	try {
		const response = await agent.Users.checkEmailExists(email);
		const emailExists = response.success;
		return { type: USERS_CHECK_EMAIL_EXISTS_SUCCESS, emailExists };
	} catch (error) {
		return { type: USERS_CHECK_EMAIL_EXISTS_ERROR, error };
	}
};

export const checkEmailExistsReset = () => {
	return { type: USERS_CHECK_EMAIL_EXISTS_RESET };
};

export const createProvisionalEmail = async (email, password) => {
	try {
		const response = await agent.Auth.createNewEmail(email, password);
		const provisionalEmailCreated = response.provisionalEmailCreated;
		return { type: USERS_CREATE_PROVISIONAL_EMAIL_SUCCESS, provisionalEmailCreated };
	} catch (error) {
		return { type: USERS_CREATE_PROVISIONAL_EMAIL_ERROR, error };
	}
};

export const createProvisionalEmailReset = () => {
	return { type: USERS_CREATE_PROVISIONAL_EMAIL_RESET };
};

export const checkPassword = async password => {
	try {
		const response = await agent.Auth.checkPassword(password);
		const passwordCorrect = response.passwordCorrect;
		return { type: USERS_CHECK_PASSWORD_SUCCESS, passwordCorrect };
	} catch (error) {
		return { type: USERS_CHECK_PASSWORD_ERROR, error };
	}
};

export const checkPasswordReset = () => {
	return { type: USERS_CHECK_PASSWORD_RESET };
};

export const createNewPassword = async password => {
	try {
		const response = await agent.Auth.update({ password });
		const passwordCreated = response.success;
		return { type: USERS_CREATE_NEW_PASSWORD_SUCCESS, passwordCreated };
	} catch (error) {
		return { type: USERS_CREATE_NEW_PASSWORD_ERROR, error };
	}
};

export const createNewPasswordReset = () => {
	return { type: USERS_CREATE_NEW_PASSWORD_RESET };
};

export const addNewImage = async image => {
	try {
		const response = await agent.Auth.update({ image });
		const imageAdded = response.success;
		return { type: USERS_ADD_NEW_IMAGE_SUCCESS, imageAdded };
	} catch (error) {
		return { type: USERS_ADD_NEW_IMAGE_ERROR, error };
	}
};

export const addNewImageReset = () => {
	return { type: USERS_ADD_NEW_IMAGE_RESET };
};

export const addNewName = async fullName => {
	try {
		const response = await agent.Auth.update({ fullName });
		const nameAdded = response.success;
		return { type: USERS_ADD_NEW_NAME_SUCCESS, nameAdded };
	} catch (error) {
		return { type: USERS_ADD_NEW_NAME_ERROR, error };
	}
};

export const addNewNameReset = () => {
	return { type: USERS_ADD_NEW_NAME_RESET };
};

export const addEmailNotifications = async boolean => {
	try {
		const response = await agent.Auth.update({ allowEmailNotifications: boolean });
		const addedEmailNotification = response.success;
		return { type: USERS_ADD_EMAIL_NOTIFICATIONS_SUCCESS, addedEmailNotification };
	} catch (error) {
		return { type: USERS_ADD_EMAIL_NOTIFICATIONS_ERROR, error };
	}
};

export const addEmailNotificationsReset = () => {
	return { type: USERS_ADD_EMAIL_NOTIFICATIONS_RESET };
};

export const loginUser = async (email, password) => {
	try {
		const response = await agent.Auth.login(email, password);

		console.log("response",response);
		

		// redirect to an active workspace sent from the back-end
		if (response.activeWorkspace) {
			const parsedDomain = parseDomain(window.location.href);
			const domain = parsedDomain.domain;
			const tld = parsedDomain.tld;
			const subDomain = parsedDomain.subdomain;
			const url = `${window.location.protocol}//${response.activeWorkspace}.${domain}.${tld}`;
			window.location.replace(url);
		}
		const userLoggedIn = response.success;

		return { type: USERS_LOGIN_USER_SUCCESS, userLoggedIn };
	} catch (error) {
		return { type: USERS_LOGIN_USER_ERROR, error };
	}
};

export const loginUserReset = () => {
	return { type: USERS_LOGIN_USER_RESET };
};

export const confirmEmailAddress = async token => {
	try {
		const response = await agent.Auth.confirmEmailAddress(token);
		const emailConfirmed = response.emailConfirmed;
		return { type: USERS_EMAIL_CONFIRMATION_SUCCESS, emailConfirmed };
	} catch (error) {
		return { type: USERS_EMAIL_CONFIRMATION_ERROR, error };
	}
};

export const confirmEmailAddressReset = () => {
	return { type: USERS_EMAIL_CONFIRMATION_RESET };
};

export const authenticateUser = async (subscriptionId, token) => {
	try {
		const response = await agent.Auth.authenticate(subscriptionId, token);
		const userAuthenticated = response.userAuthenticated;
		return { type: USERS_AUTHENTICATE_USER_SUCCESS, userAuthenticated };
	} catch (error) {
		return { type: USERS_AUTHENTICATE_USER_ERROR, error };
	}
};

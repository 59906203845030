import React, { useEffect, useRef } from 'react';

import { formatDuration, tick } from '../../../lib/Utils';

const useShowRunningTimerInWindowTitle = (props) => {
    let intervalID = useRef();

    // if an interval has stopped running, remove the running timer.
	useEffect(() => {
		if (intervalID.current && props?.intervals[0]?.endDateTime) {
			clearInterval(intervalID.current);
			intervalID.current = false;
			document.title = `TRKR`;
		}
	}, [props?.intervals[0]?.endDateTime]);

    // Show the running timer if an interval is running.
	useEffect(() => {
		if (props?.intervals[0] && !props?.intervals[0]?.endDateTime) {
			clearInterval(intervalID.current);
			intervalID.current = false;
			document.title = `TRKR | #${props?.intervals[0]?.task?.workspaceCounter}`;

			if (!intervalID.current) {
				intervalID.current = setInterval(() => {
					const interval = props?.intervals[0];
					const currentRunningTime = tick(interval, props.serverOffset);
					var timerString = formatDuration(currentRunningTime);
					if (intervalID.current && interval.task) {
						document.title = `${timerString} | #${interval.task.workspaceCounter}`;
					} else {
						document.title = `TRKR`;
					}
				}, 1000);
			}
		}
	}, [props?.intervals, props.serverOffset]);

    // componentWillUnmount
	useEffect(() => {
		return () => {
			if (intervalID.current) {
				clearInterval(intervalID.current);
				intervalID.current = false;
				document.title = `TRKR`;
			}
		};
	}, []);
};

export default useShowRunningTimerInWindowTitle;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';

import { getActiveWorkspaceUsers, getActiveWorkspaceUsersReset } from '../WorkspaceUsers/actions';

import {
	getInvoicesForSubscription,
	getInvoicesForSubscriptionReset,
	cancelSubscription,
	cancelSubscriptionReset,
} from '../Subscriptions/actions';

import { logoutUser } from '../Users/actions';

import './index.scss';

class Billing extends Component {
	state = {
		showPopup: false,
		infoHovering: false,
		itemsToShow: 5,
		showMore: false,
	};
	componentDidMount() {
		this.props.getActiveWorkspaceUsers(
			this.props.selectedSubscription.workspace._id,
			this.props.selectedSubscription._id
		);
		this.props.getInvoicesForSubscription(this.props.selectedSubscription._id);
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.selectedSubscription !== prevProps.selectedSubscription) {
			this.props.getActiveWorkspaceUsers(
				this.props.selectedSubscription.workspace._id,
				this.props.selectedSubscription._id
			);
			this.props.getInvoicesForSubscription(this.props.selectedSubscription._id);
		}
		if (this.props.subscriptionCancelled) {
			notify.show('Your subscription has been successfully cancelled.', 'success');
			this.props.getActiveWorkspaceUsersReset();
			this.props.getInvoicesForSubscriptionReset();
			this.props.cancelSubscriptionReset();
			this.props.logoutUser();
		}
	}
	togglePopup = () => {
		this.setState({
			showPopup: !this.state.showPopup,
		});
	};
	cancelSubscription = () => {
		this.setState({
			showPopup: !this.state.showPopup,
		});
		this.props.cancelSubscription(this.props.selectedSubscription._id);
	};
	handleInfoHover = () => {
		this.setState(state => {
			return {
				isHovering: !state.isHovering,
			};
		});
	};
	showMore = () => {
		this.state.showMore === false
			? this.setState({ itemsToShow: this.props.invoices.length, showMore: true })
			: this.setState({ itemsToShow: 5, showMore: false });
	};
	renderPopup = () => {
		return (
			<div className="billing-cancellation-popup">
				<div className="billing-cancellation-popup_inner">
					<h5 className="billing-cancellation-popup_inner-title">
						Click “Finish cancellation” below to cancel your membership
					</h5>
					<ul>
						<li>
							Cancellation will be affective at the end of your current billing period on 20th August 2019
						</li>
						<li>Access will be removed for all users of this workspace after this date</li>
						<li>You can restart your plan anytime</li>
					</ul>
					<button className="billing-cancellation-popup_inner-cancel-button" onClick={this.togglePopup}>
						Cancel
					</button>
					<button
						className="billing-cancellation-popup_inner-finish-button"
						onClick={this.cancelSubscription}>
						Finish cancellation
					</button>
				</div>
			</div>
		);
	};
	render() {
		return (
			<div className="billing">
				<h5 className="billing-title">Workspace {this.props.selectedSubscription.workspace.slug}</h5>
				<hr className="billing-divider" />
				<h5 className="billing-plan-title">This workspace is currently on:</h5>
				<p className="billing-plan-details">
					{this.props.selectedSubscription.product.paymentFrequency === 'monthly' ? 'Monthly' : 'Yearly'} Plan
					- {this.props.selectedSubscription.product.currency === 'GBP' ? '£' : '$'}
					{this.props.selectedSubscription.product.priceInPennies / 100}
					/user/workspace
				</p>
				<div className="billing-active-users">
					<div className="billing-active-users-details">
						<h5 className="billing-active-users-details-title">Number of active users this month:</h5>
						<p className="billing-active-users-details-info">
							{this.props.activeUsers} active users from the {this.props.startOfBillingPeriod}{' '}
							<span
								className="glyphicon glyphicon-info-sign billing-active-users-details-info-icon"
								onMouseEnter={this.handleInfoHover}
								onMouseLeave={this.handleInfoHover}
							/>
						</p>
					</div>
					{this.state.isHovering && (
						<div className="billing-active-users-info">
							<span className="glyphicon glyphicon-info-sign billing-active-users-info-icon" />
							<p>
								An active user has to have had logged into this workspace at least once during the
								billing period
							</p>
						</div>
					)}
				</div>
				<div className="billing-invoices">
					<h5 className="billing-invoices-title">Invoices:</h5>
					<table className="table">
						<thead>
							<tr>
								<th>Date</th>
								<th>Cost</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{this.props.invoices.slice(0, this.state.itemsToShow).map(invoice => (
								<tr key={invoice.id}>
									<td>
										{invoice.startOfBillingPeriod} - {invoice.endOfBillingPeriod}
									</td>
									<td>
										{invoice.currency === 'gbp' ? '£ ' : '$ '}
										{invoice.total / 100}
									</td>
									<td>
										<a href={invoice.hosted_invoice_url} target="_blank">
											View
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<button className="billing-invoices-button" type="button" onClick={this.showMore}>
						{this.state.showMore ? <span>show less</span> : <span>show more</span>}
					</button>
				</div>
				<div className="billing-cancellation">
					{this.state.showPopup ? this.renderPopup() : null}
					<button className="billing-cancellation-button" onClick={this.togglePopup}>
						Cancel plan
					</button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	activeUsers: state.workspaceUsers.activeUsers,
	startOfBillingPeriod: state.workspaceUsers.startOfBillingPeriod,
	invoices: state.subscriptions.invoices,
	subscriptionCancelled: state.subscriptions.subscriptionCancelled,
});

export default connect(
	mapStateToProps,
	{
		getActiveWorkspaceUsers,
		getActiveWorkspaceUsersReset,
		getInvoicesForSubscription,
		getInvoicesForSubscriptionReset,
		cancelSubscription,
		cancelSubscriptionReset,
		logoutUser,
	}
)(Billing);

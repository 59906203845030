import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';

import agent from '../../agent';

import Form from '../../components/Form';
import ListErrors from '../../components/ListErrors';
import PageView from '../../components/PageView';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
	onChangeEmail: value => dispatch({ type: 'UPDATE_FIELD_AUTH', key: 'email', value }),
	onChangePassword: value => dispatch({ type: 'UPDATE_FIELD_AUTH', key: 'password', value }),
	onSubmit: (email, password) => {
		const payload = agent.Auth.register(email, password);
		dispatch({ type: 'REGISTER', payload });
	},
	onRedirect: () => dispatch({ type: 'REDIRECT' }),
});

class RegisterPage extends React.Component {
	constructor() {
		super();

		this.state = {
			showFormSuccess: false,
		};

		this.changeEmail = event => this.props.onChangeEmail(event.target.value);
		this.changePassword = event => this.props.onChangePassword(event.target.value);

		this.submitForm = () => {
			var { email, password } = this.props;

			console.log('submitForm', email, password);

			this.props.onSubmit(email, password);
		};
	}

	componentWillReceiveProps(nextProps) {
		console.log('[Register.componentWillReceiveProps]', nextProps);

		if (nextProps.redirectTo) {
			// this.context.router.replace(nextProps.redirectTo);
			this.props.history.push(nextProps.redirectTo);
			this.props.onRedirect();
		}
	}

	render() {
		const { email, password } = this.props;

		return (
			<PageView token={this.props.token}>
				<div className="auth-page">
					<div className="container page">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-xs-12">
								<h1 className="text-xs-center">Sign Up</h1>
								<p className="text-xs-center">
									<Link to="/login">Have an account?</Link>
								</p>

								<ListErrors errors={this.props.errors} />

								<Form submit={this.submitForm}>
									<div className="form-group">
										<label htmlFor={'email'}>Email</label>
										<input
											className="form-control"
											name="email"
											type="email"
											required={true}
											placeholder="Email"
											value={this.props.email}
											onChange={this.changeEmail}
										/>
										<div className="invalid-feedback" />
									</div>

									<div className="form-group">
										<label htmlFor={'password'}>Password</label>
										<small style={{ display: 'block' }} className="form-text text-muted">
											Must be at least 6 characters long, contain letters and numbers
										</small>
										<input
											className="form-control"
											name="password"
											type="password"
											required={true}
											placeholder="Password"
											value={this.props.password}
											minLength={6}
											pattern="(?=.*\d)(?=.*[a-z]).{6,}"
											onChange={this.changePassword}
										/>
										<div className="invalid-feedback" />
									</div>

									<button
										className="btn btn-primary pull-xs-right"
										type="submit"
										disabled={this.props.inProgress}>
										Register
									</button>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</PageView>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RegisterPage);

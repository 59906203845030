import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, currentUser, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			currentUser ? (
				<Fragment>
					<Component {...props} />
				</Fragment>
			) : (
				<Redirect
					to={{
						pathname: '/login',
						state: { from: props.location },
					}}
				/>
			)
		}
	/>
);

import agent from '../../agent';
import {
	WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_SUCCESS,
	WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_ERROR,
	WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_RESET,
	WORKSPACE_USERS_GET_WORKSPACE_USERS_SUCCESS,
	WORKSPACE_USERS_GET_WORKSPACE_USERS_ERROR,
	WORKSPACE_USERS_UPDATE_WORKSPACE_USER_SUCCESS,
	WORKSPACE_USERS_UPDATE_WORKSPACE_USER_ERROR,
	WORKSPACE_USERS_UPDATE_WORKSPACE_USER_RESET,
	WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_SUCCESS,
	WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_ERROR,
} from './constants';

export const getActiveWorkspaceUsers = async (workspaceId, subscriptionId) => {
	try {
		const response = await agent.WorkspaceUsers.getActiveWorkspaceUsers(workspaceId, subscriptionId);
		const activeUsers = response.activeUsers;
		const startOfBillingPeriod = response.startOfBillingPeriod;
		return { type: WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_SUCCESS, activeUsers, startOfBillingPeriod };
	} catch (error) {
		return { type: WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_ERROR, error };
	}
};

export const getActiveWorkspaceUsersReset = () => {
	return { type: WORKSPACE_USERS_GET_ACTIVE_WORKSPACE_USERS_RESET };
};

export const getWorkspaceUsers = async workspaceId => {
	try {
		// NOTE: requires user population from back end
		const { workspaceUsers } = await agent.WorkspaceUsers.getWorkspaceUsers(workspaceId);

		// TODO: filter for
		// 1. active users
		const activeUsers = workspaceUsers.filter(wsUser => {
			if (wsUser.status === 'active') return true;

			return false;
		});
		// 2. de-activated users
		const inactiveUsers = workspaceUsers.filter(wsUser => {
			if (wsUser.status === 'inactive' && wsUser.user.status === 'inactive') return true;

			return false;
		});
		// 3. pending invites
		const invitesPending = workspaceUsers.filter(wsUser => {
			if (wsUser.status === 'unverified') return true;

			return false;
		});

		return {
			type: WORKSPACE_USERS_GET_WORKSPACE_USERS_SUCCESS,
			workspaceUsers: [...activeUsers, ...inactiveUsers],
			invitesPending,
		};
	} catch (error) {
		return { type: WORKSPACE_USERS_GET_WORKSPACE_USERS_ERROR, error };
	}
};

export const updateWorkspaceUser = async (workspaceId, update) => {
	try {
		const response = await agent.WorkspaceUsers.update(workspaceId, update);
		const updatedWorkspaceUser = response.success;
		return { type: WORKSPACE_USERS_UPDATE_WORKSPACE_USER_SUCCESS, updatedWorkspaceUser };
	} catch (error) {
		return { type: WORKSPACE_USERS_UPDATE_WORKSPACE_USER_ERROR, error };
	}
};

export const updateWorkspaceUserReset = () => {
	return { type: WORKSPACE_USERS_UPDATE_WORKSPACE_USER_RESET };
};

export const getWorkspaceUsersForUser = async userId => {
	try {
		const { workspaceUsers: workspaceUsersForUser } = await agent.WorkspaceUsers.getByUser(userId);
		return {
			type: WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_SUCCESS,
			workspaceUsersForUser,
		};
	} catch (error) {
		return { type: WORKSPACE_USERS_GET_WORKSPACE_USERS_FOR_USER_ERROR, error };
	}
};

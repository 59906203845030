// Makes sure that action and reducers use the same strings.
import {
	LOGIN,
	REDIRECT_TO,
	REDIRECT,
	SHOW_TIMER_MENU,
	HIDE_TIMER_MENU,
	ASYNC_START,
	GET_ALL_LISTED_DATA_SUCCESS,
	SHOW_MODAL_NEW_TASK,
	SHOW_MODAL_EXISTING_TASK,
	SHOW_MODAL_NEW_STORY,
	SHOW_MODAL_EXISTING_STORY,
	SHOW_MODAL_NEW_RELEASE,
	HIDE_MODAL,
} from './constants';

const defaultState = {
	appName: 'TRKR',
	tasks: null,
	currentUser: null,
	allUsers: [],
};

export default (state = defaultState, action) => {
	// console.log('[common.js]',action, state);

	switch (action.type) {
		case LOGIN:

		// TODO: deprecate redux redirection, utilise /lib/history.push directly
		case REDIRECT_TO:
			return { ...state, redirectTo: action.redirectLink };
		case REDIRECT:
			return { ...state, redirectTo: null };

		case SHOW_TIMER_MENU:
			// console.log('SHOW_TIMER_MENU', action);
			return {
				...state,
				showTimerMenu: true,
			};
		case HIDE_TIMER_MENU:
			// console.log('HIDE_TIMER_MENU', action);
			return {
				...state,
				showTimerMenu: false,
			};
		case ASYNC_START:
			return { ...state, inProgress: true };

		case GET_ALL_LISTED_DATA_SUCCESS:
			console.log('GET_ALL_LISTED_DATA_SUCCESS', action);

			if (!action.payload || action.error) {
				return state;
			}

			const {
				categories,
				categoryOptions,
				priorities,
				priorityOptions,
				status,
				statusOptions,
				users,
				userOptions,
			} = action.payload;

			return {
				...state,
				allCategories: categories,
				categoryOptions,
				allPriorities: priorities,
				priorityOptions,
				allStatus: status,
				statusOptions,
				allUsers: users,
				userOptions,
				loadingLists: false,
			};

		case SHOW_MODAL_NEW_TASK:
			// TODO: get form defaults from action
			const { defaultStatus, defaultPriority } = action;
			return {
				...state,
				showModal: {
					type: 'newTask',
					props: {
						defaultStatus,
						defaultPriority,
						// defaultProject: 'trkr', // should come from board filters
						// defaultAssignedTo: 'id', // should come from board filters
					},
				},
			};
		case SHOW_MODAL_EXISTING_TASK:
			console.log('[SHOW_MODAL_EXISTING_TASK]', action);
			const { _id } = action;
			return {
				...state,
				showModal: {
					type: 'existingTask',
					props: {
						_id,
					},
				},
			};
		case SHOW_MODAL_NEW_STORY:
			return {
				...state,
				showModal: {
					type: 'newStory',
					props: {},
				},
			};
		case SHOW_MODAL_EXISTING_STORY:
			return {
				...state,
				showModal: {
					type: 'existingStory',
					props: {
						_id: action._id,
					},
				},
			};
		case SHOW_MODAL_NEW_RELEASE:
			return {
				...state,
				showModal: {
					type: 'newRelease',
					props: {},
				},
			};
		case HIDE_MODAL:
			return {
				...state,
				showModal: false,
			};

		default:
			return state;
	}
};

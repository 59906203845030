import React from 'react';
import agent from '../../agent';
import { connect } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import moment from 'moment';

import './index.scss';
import { formatDate } from '../../lib/Utils';

import Avatar from '../Icons/Avatar';
import ActivityEntry from './ActivityEntry';
import MessageEntry from './MessageEntry';
import CommitMessage from './CommitMessage';

import { onSendMessage, onSendMessageReset } from '../Messages/actions';

import { getChatsByTask, getChatsByTaskReset, getChatsByStory } from '../Activities/actions';

const Promise = global.Promise;

const defaultStyle = {
	control: {
		fontSize: 14,
		fontWeight: 'normal',
	},
	highlighter: {
		overflow: 'hidden',
	},
	input: {
		margin: 0,
		overflow: 'auto',
		height: 30,
	},
	'&multiLine': {
		control: {
			fontFamily: 'monospace',
			border: '1px solid silver',
		},
		highlighter: {
			padding: 9,
		},
		input: {
			padding: 9,
			minHeight: 3,
			outline: 0,
			border: 0,
		},
	},
	suggestions: {
		backgroundColor: '#464c58',
		bottom: '0',
		top: 'unset',
		list: {
			backgroundColor: 'inherit',
			border: '1px solid rgba(0,0,0,0.15)',
			fontSize: 14,
		},
		item: {
			padding: '5px 15px',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: '#2a4b8d',
			},
		},
	},
};

const defaultMentionStyle = {
	backgroundColor: '#2a4b8d',
};

class Chat extends React.Component {
	paginationDayCount = 5;
	scrollPosition = 0;
	state = {
		users: [],
		message: '',
		startDate: moment
			.utc(new Date())
			.startOf('day')
			.subtract(this.paginationDayCount, 'days'),
		endDate: moment.utc(new Date()).endOf('day'),
	};
	constructor() {
		super();
		this.myRef = null;
		this.setRef = element => {
			this.myRef = element;
		};
	}

	// all data calls to be actioned here
	componentDidMount() {
		this.setState(state => {
			return {
				...state,
			};
		});
		const users = this.props.allUsers.map(user => {
			return {
				id: user._id,
				display: `@${user.fullName}`,
			};
		});
		this.setState({ users });

		if (this.props.forTask) {
			agent.Socket.emit('join', 'taskChat-' + this.props.forTask);
		} else if (this.props.forStory) {
			agent.Socket.emit('join', 'storyChat-' + this.props.forStory);
		}

		this.pageLoad();

		// NOTE: listen to specific channel emits

		agent.Socket.on('activity', msg => {
			// console.log('[socket.io] message received', msg);
			this.pageLoad();
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const activities = this.props[this.props.forTask];
		const oldActivities = prevProps[this.props.forTask];
		if (this.props.messageSent) {
			this.props.onSendMessageReset();
			this.updatePagination(true);
		}
		if (this.state.startDate !== prevState.startDate) {
			this.pageLoad();
		}
		// happens only once
		if (!oldActivities && activities) {
			this.scrollToBottom();
		}
		// scroll to previous top position after pagination
		if (
			oldActivities?.feedByDateArray.length > 0 &&
			oldActivities?.feedByDateArray.length !== activities?.feedByDateArray.length
		) {
			this.scrollToPosition();
		}
		// scroll to the bottom when a message is added.
		if (
			oldActivities?.feedByDateArray[oldActivities?.feedByDateArray.length - 1]?.feeds.length !==
			activities?.feedByDateArray[activities?.feedByDateArray.length - 1]?.feeds.length
		) {
			this.scrollToBottom();
		}
		if (this.props.taskUpdated) {
			this.pageLoad();
		}
	}

	componentWillReceiveProps(nextProps) {
		// console.log('[Chat.componentWillReceiveProps] current:', this.props.forTask, 'next:', nextProps.forTask);

		// TODO:
		// 1. reload data if a different "forTask" is detected
		// 2. reload data if a different "forStory" is detected
		// 3. remap sockets to new task

		if (nextProps.forTask && this.props.forTask !== nextProps.forTask) {
			agent.Socket.emit('leave', 'taskChat-' + this.props.forTask);
			agent.Socket.emit('join', 'taskChat-' + nextProps.forTask);
			this.pageLoad(nextProps.forTask);
		}
		if (nextProps.forStory && this.props.forStory !== nextProps.forStory) {
			agent.Socket.emit('leave', 'storyChat-' + this.props.forStory);
			agent.Socket.emit('join', 'storyChat-' + nextProps.forStory);
			this.pageLoad(nextProps.forStory);
		}
	}

	componentWillUnmount() {
		// TODO: disconnect from sesh to avoid duplications
		//agent.Socket.emit('join', 'taskChat-'+this.props.forTask);
		if (this.props.forTask) {
			agent.Socket.emit('leave', 'taskChat-' + this.props.forTask);
		} else if (this.props.forTask) {
			agent.Socket.emit('leave', 'storyChat-' + this.props.forStory);
		}

		agent.Socket.off('message');
		agent.Socket.off('activity');
		this.props.getChatsByTaskReset();
	}

	updatePagination = (initialLoad = false) => {
		// local references to pagination filters
		let startDate;
		let endDate;
		// re-calculate pagination dates
		if (initialLoad === false) {
			endDate = this.state.startDate;
			startDate = moment.utc(this.state.startDate).subtract(this.paginationDayCount, 'days');
		} else {
			// reset to the original timinigs
			// scenario - if the filters on the view have changed, we want to retrieve the most recent relevant activity for that
			startDate = moment
				.utc(new Date())
				.startOf('day')
				.subtract(this.paginationDayCount, 'days');
			endDate = moment.utc(new Date()).endOf('day');
		}
		// setCurrentDatePagination({ startDate, endDate });
		this.setState(state => {
			return { ...this.state, startDate, endDate };
		});
	};

	// forceId => for manual url changes
	pageLoad = forceId => {
		if (this.props.forTask) {
			const paginationQuery = {
				startDate: this.state.startDate.format().toString(),
				endDate: this.state.endDate.format().toString(),
			};
			this.props.getChatsByTask(forceId || this.props.forTask, paginationQuery, this.props.currentUser._id);
		} else if (this.props.forStory) {
			this.props.getChatsByStory(forceId || this.props.forStory);
		}
	};

	scrollToBottom = () => {
		// TODO: add condition to check if user is scrolling to prevent unneccesarry jumping
		if (this.myRef) {
			this.myRef.scrollTop = this.myRef.scrollHeight;
		}
	};

	scrollToPosition = () => {
		if (this.myRef && this.scrollPosition) {
			this.myRef.scrollTop = this.myRef.scrollHeight - this.scrollPosition;
		}
	};

	handleScroll = event => {
		let isTop = event.currentTarget.scrollTop === 0;
		const scrollHeight = event.currentTarget.scrollHeight;
		if (isTop) {
			// console.log('Reached top');
			this.scrollPosition = scrollHeight;
			this.updatePagination();
		}
	};

	changeMessage = ev => {
		this.setState(state => {
			return { ...state, message: ev.target.value };
		});
	};

	msgSubmit = ev => {
		// console.log('submit message to socket', this.props);

		if (ev) ev.preventDefault();

		// TODO:
		// 1. post new message via api route
		// 2. listen for new messages via socket.io

		var message = {
			// task:this.props.forTask,
			content: this.state.message,
		};

		const mentionedUsersRegex = /[^{}]+(?=})/g;
		const mentionedUsers = this.state.message.match(mentionedUsersRegex);
		message.content = message.content.replace(/{[^}]*}/g, '');
		message.mentions = mentionedUsers;

		if (this.props.forTask) {
			message.task = this.props.forTask;
		} else if (this.props.forStory) {
			message.story = this.props.forStory;
		}

		this.props.onSendMessage(message);

		this.setState(state => {
			return { ...state, message: '' };
		});

		return false;
	};

	keyEntry = e => {
		var code = e.keyCode ? e.keyCode : e.which;
		// console.log('[keyEntry]',code);

		// if shift and enter then create a new line, if enter only then send message
		if (code === 13 && !e.shiftKey) {
			e.preventDefault();
			e.stopPropagation();

			this.msgSubmit();
		}
	};

	render() {
		const activities = this.props[this.props.forTask];
		if (!activities) {
			return <div className="chat-wrapper">Loading...</div>;
		}

		return (
			<div className={`chat-wrapper flex-container ${this.props.className}`}>
				<div className="chat-participants flex-header">
					<ul className="horizontal-list text-small">
						<li className="font-bold">
							Participants:{' '}
							{(!activities.participants || activities.participants.length == 0) && (
								<span className="font-italic">none</span>
							)}
						</li>
						{activities.participants &&
							activities.participants.map(user => {
								return <li key={`chat-participants-${user._id}`}>{user.fullName || user.email}</li>;
							})}
					</ul>
				</div>

				{/* NOTE: messages are grouped by date (on the back end) within the feed */}
				<div className="chat-history flex-body" ref={this.setRef} onScroll={this.handleScroll}>
					<div className="trkr-full-width">
						{activities.feedByDateArray.map(feedGroup => {
							return (
								<div className="message-group" key={`message-group-${feedGroup.date}`}>
									<p className="date-label">{formatDate(feedGroup.date, 'dddd, Do MMMM')}</p>
									{feedGroup.feeds.map(msg => {
										if (msg.type === 'commit') {
											return (
												<CommitMessage
													message={msg}
													currentUser={this.props.currentUser}
													key={`message-entry-commit-${msg._id}`}
												/>
											);
										}
										if (msg.type === 'activity') {
											return (
												<ActivityEntry
													key={`message-entry-activity-${msg._id}`}
													{...msg}
													currentUser={this.props.currentUser}
												/>
											);
										} else {
											return (
												<MessageEntry
													key={`message-entry-msg-${msg._id}`}
													{...msg}
													currentUser={this.props.currentUser}
												/>
											);
										}
									})}
								</div>
							);
						})}
					</div>
				</div>

				<div className="chat-input flex-footer">
					<div className="trkr-full-width">
						<form className="row">
							<fieldset className="form-group col-xs-10" style={{ paddingRight: 0 }}>
								<MentionsInput
									value={this.state.message}
									onChange={this.changeMessage}
									placeholder="message.."
									style={defaultStyle}>
									<Mention
										markup="[__display__]{__id__}"
										data={this.state.users}
										style={defaultMentionStyle}
									/>
								</MentionsInput>
							</fieldset>

							<div className="col-xs-2 text-center">
								<button
									className="btn trkr-btn send-btn"
									disabled={this.props.inProgress}
									onClick={this.msgSubmit}>
									<i className="ion-android-send" />
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state.activities,
	messageSent: state.messages.messageSent,
	currentUser: state.users.currentUser,
	allUsers: state.app.allUsers,
	taskUpdated: state.tasks.taskUpdated,
});

const mapDispatchToProps = {
	getChatsByTask,
	getChatsByTaskReset,
	getChatsByStory,
	onSendMessage,
	onSendMessageReset,
};

// TODO: expose socket functions as needed
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Chat);

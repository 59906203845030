import './index.scss';
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Notifications, { notify } from 'react-notify-toast';
import CreatableSelect from 'react-select/lib/Creatable';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import agent from '../../agent';
import ListErrors from '../ListErrors';
import Form from '../Form';

import { formatDuration, formatDate } from '../../lib/Utils';

const mapStateToProps = state => ({
	...state.releaseEditor,
});

// TODO:
// 1. Allow release to be removed
// 2. allow release to be re-assigned to another project

/**
 * `mapDispatchToProps()` needs separate actions for adding/removing
 * tags, submitting an task, updating individual fields, and cleaning
 * up after navigating away from the page.
 */
const mapDispatchToProps = dispatch => ({
	onLoad: payload => dispatch({ type: 'RELEASE_EDITOR_PAGE_LOADED', payload }),
	onSubmit: payload => dispatch({ type: 'RELEASE_SUBMITTED', payload }),
	onUnload: payload => dispatch({ type: 'RELEASE_EDITOR_PAGE_UNLOADED' }),
	onUpdateField: (key, value) => dispatch({ type: 'RELEASE_UPDATE_FIELD', key, value }),
	onRedirect: () => dispatch({ type: 'REDIRECT' }),
});

class ReleaseModal extends React.Component {
	static defaultProps = {};

	constructor() {
		super();

		const updateFieldEvent = key => ev => this.props.onUpdateField(key, ev.target.value);

		this.changeName = updateFieldEvent('name');
		this.changeDescription = updateFieldEvent('description');

		this.showToastr = notify.createShowQueue();
	}

	changeContentView = target => {
		console.log('[StoryModal.changeContentView]', target);
		this.setState({ activeView: target });
	};

	componentWillReceiveProps(nextProps) {
		console.log('[ReleaseModal.componentWillReceiveProps]', this.props, nextProps);

		if (nextProps.redirectTo) {
			// this.context.router.replace(nextProps.redirectTo);
			console.log('redirect!', nextProps.redirectTo);
			// TODO: go to previous screen if there is history, else return to kanban

			if (this.props.history.length && this.props.history.length > 0) {
				this.props.history.goBack();
			} else {
				// TODO: go to previous screen
				this.props.history.push('kanban');
			}

			this.props.onRedirect();
		}
	}

	componentDidMount() {
		this.pageLoad();
	}
	componentWillMount() {
		this.pageLoad();

		// TODO: implement chat and stories listings for release modal
		this.changeContentView('inactive');
	}

	toggleMobilePanel = () => {
		// console.log('[TaskModal.changeContentView]', target);
		let show = !this.state.showMobilePanel || false;
		this.setState({ showMobilePanel: show });
	};

	pageLoad = () => {
		if (this.props.id) {
			return this.props.onLoad(Promise.all([agent.Releases.get(this.props.id), agent.Projects.all()]));
		}
	};

	componentWillUnmount() {
		this.props.onUnload();
	}

	submitForm = () => {
		if (!this.props.name) {
			console.log('ReleaseModal.submitForm', this.props.name);

			this.showToastr('Please provide required information', 'error');
			return;
		}

		var release = {
			id: this.props.id,
			name: this.props.name,
			description: this.props.description,
			dueDate: this.props.dueDate,
		};

		console.log('submitting form', release);

		this.props.onSubmit(agent.Releases.update(release));
	};

	onDueDateChange = date => {
		this.props.onUpdateField('dueDate', date);
	};

	renderForm = () => {
		return (
			<Form submit={this.submitForm}>
				<div className="form-group">
					<label htmlFor={'name'}>Name*</label>
					<input
						className="form-control"
						type="text"
						name={'name'}
						required={true}
						placeholder="Release name"
						value={this.props.name}
						onChange={this.changeName}
					/>
					<div className="invalid-feedback" />
				</div>

				<div className="form-group">
					<label htmlFor={'dueDate'}>Due date</label>
					<DatePicker
						className="form-control"
						selected={this.props.dueDate ? moment(this.props.dueDate) : null}
						onChange={this.onDueDateChange}
						showTimeSelect
						name="dueDate"
						timeFormat="HH:mm"
						timeIntervals={15}
						dateFormat="LLL"
						locale="en-gb"
					/>
					<div className="invalid-feedback" />
				</div>

				<div className="form-group">
					<label htmlFor={'description'}>Description</label>
					<textarea
						className="form-control"
						rows="3"
						name={'description'}
						placeholder="Further detail"
						value={this.props.description}
						onChange={this.changeDescription}
					/>
					<div className="invalid-feedback" />
				</div>
			</Form>
		);
	};

	render() {
		if (!this.props.history || !this.state) {
			return <div>Loading...</div>;
		}

		// console.log('[ReleaseModal.render] props', this.props, this.state);
		return (
			<div className="row modal-content">
				<div className="flex-container trkr-padding-lr-60 xs-trkr-padding-lr-30 md-display-flex">
					<div className="modal-header flex-header md-trkr-padding-tb-15">
						<div className="row vertical-align-middle">
							<div className="col-xs-10">
								<h5 className="header-title trkr-margin-top-0">
									{this.props.name ? this.props.name : 'New release'}
								</h5>
								{this.props.release && this.props.release.project && (
									<p className="text-small trkr-margin-top-10">
										<Link
											to={`${this.props.baseUrl}/project/${String(
												this.props.release.project.slug
											).toLowerCase()}`}
											className="font-color-white">
											{this.props.release.project.title}
										</Link>
									</p>
								)}
							</div>

							<div className="col-xs-2 text-right">
								<button className="trkr-icon-close trkr-btn-close" onClick={this.props.onClose} />
							</div>
						</div>
					</div>

					<div className="flex-body">
						{/* Should this be within the form? */}
						<ListErrors errors={this.props.errors} />

						<div className="scrollable-content trkr-full-width trkr-margin-top-30">{this.renderForm()}</div>
					</div>

					<div className="flex-footer">
						<div className="trkr-full-width trkr-padding-tb-10 bg-grey-2" style={{ zIndex: 1 }}>
							<div className="row vertical-align-middle">
								<div className="col-xs-6">
									{this.props.release && (
										<p className="text-light text-small test">
											Last updated {formatDate(this.props.release.updatedAt)}
										</p>
									)}
								</div>

								<div className="col-xs-6 text-right">
									<button
										className="trkr-btn"
										type="submit"
										disabled={this.props.isLoading == true}
										onClick={() => {
											this.submitForm();
										}}>
										Update Release
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReleaseModal);

// test push
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import Joyride from 'react-joyride';
import Notifications, { notify } from 'react-notify-toast';

// TODO: move the contents of this to the main index.js?

import { Router, Route, Link, Redirect, Switch } from 'react-router-dom';
import GA from '@letscreateltd/lc-google-analytics';

import 'bootstrap/dist/css/bootstrap.css'; // add Boostrapp CSS into the DOM.
import './index.scss';

import { PrivateRoute } from '../../lib/PrivateRoute'; // ensures the user is authenticated, otherwise will redirect
import agent from '../../agent';

// navigation
import Header from '../Navigation/Header';
import TimerMenu from '../TimerMenu';
import history from '../../lib/History';
import Axios from '../../lib/Axios';
import ExistingTaskModal from '../TaskModal/components/ExistingTaskModal';
import NewTaskModal from '../TaskModal/components/NewTaskModal';
import NewStoryModal from '../NewStoryModal';
import NewReleaseModal from '../NewReleaseModal';
import ExistingStoryModal from '../ExistingStoryModal';
import { ModalController } from '../ModalController';

// pages
import TasksPage from '../../pages/TasksPage';
import SubscriptionPage from '../../pages/SubscriptionPage';
import WorkspaceRegistrationPage from '../../pages/WorkspaceRegistrationPage';
import TeamInvitePage from '../../pages/TeamInvitePage';
import ProjectCreationPage from '../../pages/ProjectCreationPage';

import LoginPage from '../../pages/Auth/LoginPage';
import RegisterPage from '../../pages/Auth/RegisterPage';
import NotFound from '../../pages/NotFound';
import PasswordFormPage from '../../pages/PasswordFormPage';

import useInitLoad from './hooks/useInitLoad';
import useKeepKanbanTasksUpToDate from './hooks/useKeepKanbanTasksUpToDate';
import useShowRunningTimerInWindowTitle from './hooks/useShowRunningTimerInWindowTitle';

import { loadAllDataLists } from './actions';

import { getCurrentUser } from '../Users/actions';

import { getAllProjects } from '../Projects/actions';

import { getTasksByViewByStatus } from '../Tasks/actions';

import { getServerOffset, getRunningIntervalsByTask, tickRunningIntervals } from '../Intervals/actions';

import Reports from '../Reports';

import moment from 'moment';

const Tooltip = ({
	continuous,
	index,
	isLastStep,
	step,
	backProps,
	closeProps,
	primaryProps,
	skipProps,
	tooltipProps,
}) => {
	return (
		<div className="tooltip-body" {...tooltipProps}>
			{step.title && <h2 className="tooltip-title">{step.title}</h2>}
			{step.content && <div className="tooltip-content">{step.content}</div>}
			<div className="tooltip-footer">
				<button className="tooltip-button" {...primaryProps}>
					DONE
				</button>
			</div>
		</div>
	);
};

const App = (props) => {
	const [steps, setSteps] = useState([
		{
			target: '.onboarding',
			content: 'Add projects, tasks and invite team members from this menu',
			disableBeacon: true,
			placement: 'bottom',
		},
	]);
	const [run, setRun] = useState(false);
	useInitLoad({
		currentUser: props.currentUser,
		allUsers: props.allUsers,
		getCurrentUser: props.getCurrentUser,
		getRunningIntervalsByTask: props.getRunningIntervalsByTask,
		loadAllDataLists: props.loadAllDataLists,
		getAllProjects: props.getAllProjects,
		getServerOffset: props.getServerOffset
	});
	useKeepKanbanTasksUpToDate({
		views: props.views,
		getTasksByViewByStatus: props.getTasksByViewByStatus,
		viewsKanbanInfo: props.viewsKanbanInfo,
	});
	useShowRunningTimerInWindowTitle({
		intervals: props?.intervals,
		serverOffset: props.serverOffset
	});

	useEffect(() => {
		agent.Socket.on('disconnect', (reason) => {
			if (reason === 'io server disconnect') {
			}
		});
		if ((props.tasks && props.tasks.length === 1) || (props.allProjects && props.allProjects.length === 1)) {
			setRun(false);
		}
		setInterval(() => {
			props.tickRunningIntervals();
		}, 1000);
	}, []);

	useEffect(() => {
		if (props.redirectTo) {
			history.push(props.redirectTo);
		}
	}, [props.redirectTo]);

	// Show onboarding message if only 1 task exists for workspace.
	useEffect(() => {
		if (props.tasks && props.tasks.length === 1) {
			props.currentUser &&
				notify.show(`Welcome ${props.currentUser.fullName}.`, 'custom', 5000, {
					background: '#768298',
					text: '#FEFEFE',
				});
		}
	}, [props.tasks]);

	// Show onboarding message if only 1 project exists for workspace.
	useEffect(() => {
		if (props.allProjects && props.allProjects.length === 1) {
			props.currentUser &&
				notify.show(`Welcome ${props.currentUser.fullName}.`, 'custom', 5000, {
					background: '#768298',
					text: '#FEFEFE',
				});
		}
	}, [props.allProjects]);

	// componentWillUnmount
	useEffect(() => {
		return () => {
			setRun(false);
		};
	}, []);

	const renderModal = () => {
		switch (props?.showModal?.type) {
			case 'newTask':
				return <NewTaskModal {...props.showModal.props} />;
			case 'existingTask':
				return <ExistingTaskModal {...props.showModal.props} />;
			case 'newStory':
				return <NewStoryModal {...props.showModal.props} />;
			case 'existingStory':
				return <ExistingStoryModal {...props.showModal.props} />;
			case 'newRelease':
				return <NewReleaseModal {...props.showModal.props} />;
			default:
				return null;
		}
	};

	return (
		<Router history={history}>
			<Fragment>
				{renderModal()}

				<Notifications options={{ zIndex: 3000 }} />
				<Joyride
					run={run}
					steps={steps}
					tooltipComponent={Tooltip}
					styles={{
						options: {
							arrowColor: '#5c6576',
						},
					}}
				/>
				<ModalController />

				{GA.init('UA-136955369-1') && <Route path="/" component={GA.RouteTracker} />}
				<LoadingBar
					updateTime={100}
					maxProgress={100}
					progressIncrease={10}
					style={{
						backgroundColor: '#1E8DE8',
						height: '3px',
						zIndex: '5000',
					}}
				/>

				<Axios currentUser={props.currentUser} />

				{props.currentUser || props.userLoggedOut ? (
					<Switch>
						<Route path="/subscription" component={SubscriptionPage} />
						<Route path="/workspace-registration" component={WorkspaceRegistrationPage} />
						<Route path="/team-invites" component={TeamInvitePage} />
						<Route path="/project-creation" component={ProjectCreationPage} />
						<Route path="/reset-password" component={PasswordFormPage} />
						<Route
							path="/"
							render={() => {
								return <Fragment>
									<Header />
									{/* TimerMenu visibility toggled */}
									{props.currentUser?._id && (
										
										<TimerMenu showTimerMenu={props.showTimerMenu} match={props.match} />
									)}
									<Switch>
										{
											<Route
												exact
												path="/"
												render={() =>
													props.currentUser?._id ? (
														<Redirect to="/boards" />
													) : (
														<Redirect to="/login" />
													)
												}
											/>
										}
										<Route exact path="/login" component={LoginPage} />
										<PrivateRoute
											path="/boards"
											component={TasksPage}
											currentUser={props.currentUser}
										/>
										<PrivateRoute
											path="/reports"
											component={Reports}
											currentUser={props.currentUser}
										/>
										<Route path="*" component={NotFound} />
									</Switch>
								</Fragment>
							}}
						/>
					</Switch>
				) : (
					<div>Not loaded...</div>
				)}
			</Fragment>
		</Router>
	);
};

const mapStateToProps = (state) => ({
	...state.app,
	appName: state.app.appName,
	currentUser: state.users.currentUser,
	userLoggedOut: state.users.userLoggedOut,
	intervals: state.intervals.intervals,
	showTimerMenu: state.app.showTimerMenu,
	serverOffset: state.intervals.serverOffset,
	views: state.views.views,
	allProjects: state.projects.allProjects,
	runningIntervalsByTask: state.intervals.runningIntervalsByTask,
	viewsKanbanInfo: state.tasks.viewsKanbanInfo
});

export default connect(mapStateToProps, {
	getCurrentUser,
	loadAllDataLists,
	getAllProjects,
	getTasksByViewByStatus,
	getServerOffset,
	getRunningIntervalsByTask,
	tickRunningIntervals,
})(App);
